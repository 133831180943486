<template>
  <div
    class="score"
    :class="{
      'score--disabled': disabled,
      'score--locked': locked,
      'score--yes-no': yesNo,
      'score--num': num,
    }"
  >
    <template v-if="yesNo">
      <p @click="setValue(true)" :class="{ active: value === true }"><span class="icon2">D</span></p>
      <p @click="setValue(false)" :class="{ active: value === false }"><span class="icon2">E</span></p>
    </template>
    <template v-else-if="num">
      <p @click="setValue(1)" :class="{ active: value === 1 }"><span>1</span></p>
      <p @click="setValue(2)" :class="{ active: value === 2 }"><span>2</span></p>
      <p @click="setValue(3)" :class="{ active: value === 3 }"><span>3</span></p>
      <p @click="setValue(4)" :class="{ active: value === 4 }"><span>4</span></p>
      <p @click="setValue(5)" :class="{ active: value === 5 }"><span>5</span></p>
    </template>

    <template v-else>
      <p @click="setValue('low')" :class="{ active: value === 'low' }"><span class="icon2">A</span></p>
      <p @click="setValue('medium')" :class="{ active: value === 'medium' }">
        <span class="icon2">B</span>
      </p>
      <p @click="setValue('high')" :class="{ active: value === 'high' }"><span class="icon2">C</span></p>
    </template>
  </div>
</template>

<script>
export default {
  props: ['value', 'disabled', 'locked', 'yesNo', 'num'],
  methods: {
    setValue(v) {
      if (!this.disabled && !this.locked && this.value !== v) {
        this.$emit('input', v);
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/scss/base.scss';
.score {
  display: flex;
  height: 40px;
  overflow: hidden;
  user-select: none;
  width: 100%;

  &.score--yellow {
    p {
      background: #f6f5c0 0% 0% no-repeat padding-box;
      &.active {
        .icon2 {
          color: #f6f5c0;
        }
      }
    }
  }
  &.score--yes-no {
    p {
      width: 50%;
    }
  }
  &.score--num {
    span {
      font-size: 18px;
    }
  }

  p {
    align-items: center;
    background: #f1f8ff 0% 0% no-repeat padding-box;
    border: 1px solid #9eafc1;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 33%;
    @include transition($transition);

    &.active {
      background: #004d94 0% 0% no-repeat padding-box;
      border: 1px solid #9eafc1;
      span {
        color: #f1f8ff;
        opacity: 1;
      }
    }

    &:hover:not(.active) {
      span {
        color: #004d94;
        opacity: 0.8;
      }
    }
    span {
      font-size: 28px;
      opacity: 0.2;
      @include transition($transition);
    }
  }
}
</style>