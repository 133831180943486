<template>
  <div class="document-form-type">
    <div class="row center text-center column">
      <h1>
        ДОПЪЛНИТЕЛНО СПОРАЗУМЕНИЕ <br />
        ЗА ПРОМЯНА В ОСНОВНАТА ЗАПЛАТА
      </h1>
      <p class="doc-number" v-if="d.number">№{{ d.numberPrefix }}-{{ d.number }}</p>
    </div>

    <section class="rows-wrapper">
      <div class="row">
        <Input
          icon="+"
          placeholder="№"
          label="Допълнително споразумение №"
          v-model="d.form.annexNumber"
          :locked="d._id && !editMode && !canEdit"
        />
        <Input
          icon="C"
          placeholder="Дата"
          label="От дата"
          type="datepicker"
          :locked="d._id && !editMode && !canEdit"
          v-model="d.form.annexNumberDate"
        />
      </div>
      <div class="row">
        <Input
          icon="+"
          placeholder="№"
          label="Към Трудов Договор №"
          v-model="d.form.contractNumber"
          :locked="d._id && !editMode && !canEdit"
        />
        <Input
          icon="C"
          placeholder="Дата"
          label="От дата"
          :maxlength="4"
          :minlength="4"
          :digitsOnly="true"
          :locked="d._id && !editMode && !canEdit"
          :yearFirst="true"
          v-model="d.form.contractNumberDate"
        />
      </div>
    </section>

    <div class="row center text-center">
      <p class="smaller-line-height">
        <strong>ОТНОСНО ПРОМЯНА В ОСНОВНАТА ЗАПЛАТА</strong>
      </p>
    </div>

    <div class="row center text-center first-letter--margin">
      <p>
        Днес,
        <strong class="underline" v-if="d.form.annexNumberDate">{{ d.form.annexNumberDate | formatDate }}</strong>
        <strong v-else class="underline"> ДАТА </strong> г. в гр. Пловдив, на основание чл. 119 от КТ и заявление №
        <strong class="underline">{{ d.parent ? `${d.parent.numberPrefix}-${d.parent.number}` : ' - ' }}</strong> от
        служителя <br />
        <strong>КЦМ АД, ЕИК 115007471</strong>, представлявано от <strong>инж. Иван Николов Добрев</strong> -
        Изпълнителен директор, наричан по-долу РАБОТОДАТЕЛ, от една страна и
      </p>
    </div>

    <section class="rows-wrapper">
      <div class="row">
        <Input
          icon="F"
          placeholder="Име Презиме Фамилия"
          label="От Работник/Служител (изберете или потърсете име)"
          type="dropdown"
          @fetch="$emit(autoFillFields ? 'fetch-employees-from-trdocs' : 'fetch-employees', $event)"
          :locked="d._id"
          :list="employees"
          prop="name"
          identifier="_id"
          @input="!d._id && autoFillFields ? $emit('get-parent-trdoc') : null"
          v-model="d.form.user"
        />
      </div>

      <div class="row">
        <Input
          icon="+"
          placeholder="ЕГН"
          label="ЕГН"
          :value="d.parent ? d.parent.form.egn : ''"
          :maxlength="10"
          :digitsOnly="true"
          :locked="d._id"
          :autoFill="true"
          v-if="autoFillFields"
        />
        <Input
          icon="+"
          placeholder="ЕГН"
          label="ЕГН"
          v-model="d.form.egn"
          :maxlength="10"
          :digitsOnly="true"
          :locked="d._id"
          v-else
        />
        <Input
          icon="+"
          placeholder="№"
          label="Служебен номер"
          v-model="d.form.user.number"
          :autoFill="true"
          :locked="d._id"
        />
      </div>
    </section>

    <div class="row center text-center">
      <p class="smaller-line-height">
        <strong>Назначен на длъжност</strong>
      </p>
    </div>

    <div class="row">
      <Input
        icon="b"
        placeholder="Длъжност"
        label="Назначен на длъжност"
        :value="d.form.user.position ? d.form.user.position.name : ''"
        :locked="true"
      />
      <Input
        icon="/"
        prop="name"
        placeholder="Структурно звено"
        label="Работно място"
        :locked="true"
        :value="d.form.user.structuralUnit ? d.form.user.structuralUnit.name : ''"
      />
    </div>

    <div class="row center text-center">
      <p>се споразумяха за следните изменения на трудовия договор, влизащи в сила от</p>
    </div>

    <div class="row">
      <Input
        icon="C"
        placeholder="Дата"
        label="Влизащ в сила от"
        type="datepicker"
        v-model="d.parent.form.probationFrom"
        :autoFill="!editMode && !canEdit"
        :locked="d._id && !editMode && !canEdit"
        v-if="autoFillFields && d.parent"
      />
      <Input
        icon="C"
        placeholder="Дата"
        label="Влизащ в сила от"
        type="datepicker"
        v-model="d.form.yearFrom"
        :locked="d._id && !editMode && !canEdit"
        v-else
      />
    </div>

    <div class="row center text-center">
      <p class="smaller-line-height">
        <strong>По Възнаграждението</strong>
      </p>
    </div>

    <div class="row center text-center first-letter--margin">
      <p>
        За точно и добросъвестно изпълнение на задълженията по предмета на договора, Работодателят заплаща на
        работника/служителя основно месечно възнаграждение в размер на
      </p>
    </div>

    <div class="row">
      <Input
        style="width: 25%"
        icon=","
        placeholder="Сума с цифри"
        label="сума в лева, с цифри"
        :digitsOnly="true"
        v-model="d.parent.form.mainSalary"
        :locked="d._id && !editMode && !canEdit"
        :autoFill="!editMode && !canEdit"
        v-if="autoFillFields && d.parent"
      />
      <Input
        style="width: 25%"
        icon=","
        placeholder="Сума с цифри"
        label="сума в лева, с цифри"
        :digitsOnly="true"
        v-model="d.form.price"
        :locked="d._id && !editMode && !canEdit"
        v-else
      />
      <Input
        style="width: 75%"
        icon=","
        placeholder="Сума словом"
        label="сума в лева, словом (попълва се автоматично)"
        :value="
          autoFillFields
            ? d.parent
              ? d.parent.form.mainSalary
                ? wordify(d.parent.form.mainSalary)
                : ''
              : ''
            : d.form.price
            ? wordify(d.form.price)
            : ''
        "
        :autoFill="true"
      />
    </div>

    <div class="row">
      <Input
        type="textarea"
        icon="Q"
        :maxlength="165"
        placeholder="Попълнете свободен текст (до 165 символа)"
        label="Попълнете свободен текст"
        v-model="d.form.freeText"
        :nonRequired="true"
        :locked="d._id && !editMode && !canEdit"
      />
    </div>

    <div class="row center text-center">
      <p>Всички останали условия по договора остават без промяна.</p>
    </div>

    <div class="row center text-center">
      <p>Настоящото допълнително споразумение се сключи в два еднообразни екземпляра, по един за всяка от страните.</p>
    </div>

    <div class="row center text-center">
      <p>Препис от заповедта да се връчи на лицето, ръководителя и специалист ТРЗ за сведение и изпълнение.</p>
    </div>

    <section class="rows-wrapper">
      <div class="row row--background">
        <Input
          icon="C"
          placeholder="Дата на създаване"
          label="Дата на създаване"
          v-model="d.form.createdAt"
          type="datepicker"
          :locked="d._id"
        />
        <Input
          icon="$"
          :placeholder="d.form.firstHr"
          label="Служител отдел УЧР"
          type="signature"
          :value="d.form.signatures.firstHr"
          @signed="d.form.signatures.firstHr = $event"
          :locked="d._id"
        />
      </div>

      <!-- <div class="row">
        <div style="width: 50%"></div>
        <Input
          style="width: 50%"
          icon="$"
          placeholder="инж. Иван Добрев"
          label="Изпълнителен директор"
          type="signature"
          :value="d.form.signatures.director"
          @signed="d.form.signatures.director = $event"
        />
      </div> -->

      <!-- <div class="row">
        <div style="width: 50%"></div>
        <Input
          style="width: 50%"
          icon="$"
          :placeholder="d.form.user.name ? d.form.user.name : 'Име, Фамилия'"
          label="Служител - име и подпис"
          type="signature"
          :value="d.form.signatures.user"
          :disabled="!isCurrUserDocUser"
          @signed="d.form.signatures.user = $event"
        />
      </div> -->
    </section>

    <template v-if="d._id">
      <div class="brake">Попълва се от Минка бакалова, рък-л отдел "УЧР"</div>

      <div class="row row--background">
        <Input
          icon="C"
          placeholder="Дата на създаване"
          label="Дата на създаване"
          v-model="d.form.createdAtHr"
          type="datepicker"
          :disabled="!isCurrUserDocHr"
        />
        <Input
          icon="$"
          placeholder="Минка Димитрова Бакалова-Каменова"
          label="Р-л отдел УЧР"
          type="signature"
          :value="d.form.signatures.hr"
          @signed="d.form.signatures.hr = $event"
          :disabled="!isCurrUserDocHr"
          :locked="!isCurrUserDocHr"
        />
      </div>
    </template>
    <FormPrintButtons :d="d" />
  </div>
</template>

<script>
import Input from '../CreateDocumentInput';
import wordifyBG from '@/js/wordify.bg.js';
import FormPrintButtons from './FormPrintButtons.vue';
export default {
  components: {
    Input,
    FormPrintButtons,
  },
  props: ['d', 'employees', 'invalid', 'autoFillFields', 'isCurrUserDocUser', 'isCurrUserDocHr', 'editMode', 'canEdit'],
  methods: {
    wordify(num) {
      return wordifyBG(num);
    },
  },
  beforeMount() {
    if (!this.d._id) {
      this.$set(this.d.form.signatures, 'firstHr', null);
      this.$set(this.d.form.signatures, 'hr', null);
      // this.$set(this.d.form.signatures, 'director', null);
    }
    if (!this.d.form.signatures.firstHr) this.d.form.createdAt = new Date();
    if (!this.d.form.signatures.hr) this.d.form.createdAtHr = new Date();
  },
};
</script>
