<template>
  <main class="document-form-type">
    <input
      ref="fileRef"
      type="file"
      :multiple="true"
      @input="$emit('input', $event.target.files), ($event.target.value = null)"
      accept=".pdf, .jpg, .png, .doc, .docx"
    />

    <section class="folder-img" v-if="!value.length">
      <img v-if="!value" src="@/assets/img/not-uploaded-files.svg" alt="" />
      <img v-else src="@/assets/img/uploaded-files.svg" alt="" />

      <section v-if="!value.length">
        <Radio
          label="Изпращане на много индивидуални документи към много служители"
          sublabel="Броят избрани документи = броя служители"
          :value="!isMultipleEmployees"
          @input="($event) => ($event ? $emit('update:isMultipleEmployees', false) : null)"
        />

        <hr />

        <h2>Получателите са различен брой от документите</h2>

        <div class="switch-and-counter">
          <Radio
            label="Брой служители - ръчно въвеждане"
            sublabel="Ръчно ще трябва да се избират служителите, на които да се прати документа"
            :value="isMultipleEmployees === 'selectManually'"
            @input="($event) => ($event ? $emit('update:isMultipleEmployees', 'selectManually') : null)"
          />

          <ToggleSize
            :allowZero="true"
            :disabled="isMultipleEmployees !== 'selectManually'"
            @clear="$emit('update:multipleEmployees', [])"
            v-model="multipleEmployees"
            :maxSize="999"
            @add="$emit('add')"
          />
        </div>
        <div class="switch-and-counter">
          <Radio
            label="Автоматично добавяне на служители"
            sublabel="Поставете служебните номера само на тези, които трябва да получат документа (Paste / CTRL+V)"
            :value="isMultipleEmployees === 'byNumber'"
            @input="($event) => ($event ? $emit('update:isMultipleEmployees', 'byNumber') : null)"
          />

          <Input
            type="number"
            class="mini"
            :disabled="isMultipleEmployees !== 'byNumber'"
            :value="byNumberInput"
            @input="$emit('update:byNumberInput', $event.trim())"
          />
        </div>
        <Radio
          label="Изпрати до всички служители"
          :value="isMultipleEmployees === 'toAll'"
          @input="($event) => ($event ? $emit('update:isMultipleEmployees', 'toAll') : null)"
        />

        <div class="switch-and-counter">
          <Radio
            label="Изпрати до всички служители, без избраните тук"
            sublabel="Поставете служебните номера само на тези, които НЕ трябва да получат документа (Paste / CTRL+V)"
            :value="isMultipleEmployees === 'exceptNumbers'"
            @input="($event) => ($event ? $emit('update:isMultipleEmployees', 'exceptNumbers') : null)"
          />
          <Input
            type="number"
            class="mini"
            :disabled="isMultipleEmployees !== 'exceptNumbers'"
            :value="exceptNumbersInput"
            @input="$emit('update:exceptNumbersInput', $event.trim())"
          />
        </div>
        <button
          @click="addFiles()"
          :disabled="
            (isMultipleEmployees === 'byNumber' && !byNumberInput) ||
            (isMultipleEmployees === 'exceptNumbers' && !exceptNumbersInput) ||
            loading
          "
        >
          {{ loading ? 'МОЛЯ ИЗЧАКАЙТЕ...' : 'ИЗБЕРИ ФАЙЛОВЕ' }}
        </button>
      </section>
    </section>
    <section class="documents" v-if="!isMultipleEmployees">
      <section v-for="(file, i) of value" :key="`${file}-${i}`">
        <label class="document-wrapper" :class="{ invalid: file.invalidFile }">
          <section class="icon-and-name">
            <span class="icon">v</span>
            <div class="texts">
              <p>
                <input
                  type="text"
                  placeholder="Добавете име на документа"
                  :class="{ invalid: file.invalidFilename }"
                  v-model="file.docName"
                  @input="(file.invalidFilename = false), updateFile(i, 'docName', $event.target.value)"
                />
              </p>
              <span>{{ file.name }}</span>
            </div>
          </section>
          <span class="icon delete" @click.prevent="$emit('deleteFile', i)">X</span>
        </label>
        <Input
          icon="O"
          placeholder="Получател на документа"
          label="Получател на документа"
          type="dropdown"
          prop="name"
          propTwo="number"
          :list="employees"
          identifier="_id"
          :invalid="file.invalidReceiver"
          :locked="isReceiversInputLocked"
          @fetch="$emit('getEmployees', $event)"
          @input="(file.invalidReceiver = false), updateFile(i, 'receiver', $event), $emit('getEmployees', $event)"
          v-model="file.receiver"
        />
        <Input
          icon="0"
          placeholder="Категория"
          label="Категория документ"
          type="dropdown"
          :list="getCategoryNames"
          @add="$emit('add')"
          :locked="!currentUser.roles.hr || sameCategoryForAll ? i != 0 : false"
          @input="updateFile(i, 'category', $event)"
          v-model="file.category"
        />
        <div class="document-wrapper">
          <Checkbox
            v-model="file.needsConfirmation"
            type="switch"
            label="Получателят трябва да потвърди че се е запознал с прикачения документ"
          />
        </div>
      </section>
    </section>
    <main v-else-if="isMultipleEmployees ? multipleEmployees.length && value.length : value.length" class="documents">
      <section v-for="(item, i) in test" :key="i">
        <label
          class="document-wrapper"
          :class="{ invalid: value[i] && value[i].invalidFile, transparent: isMultipleEmployees && !value[i] }"
        >
          <template v-if="value[i]">
            <section class="icon-and-name">
              <span class="icon">v</span>
              <div class="texts">
                <p>
                  <input
                    type="text"
                    placeholder="Добавете име на документа"
                    :class="{ invalid: value[i].invalidFilename }"
                    v-model="value[i].docName"
                    @input="(value[i].invalidFilename = false), updateFile(i, 'docName', $event.target.value, value)"
                  />
                </p>
                <span>{{ value[i].name }}</span>
              </div>
            </section>
            <span class="icon delete" @click.prevent="$emit('deleteFile', i)">X</span>
          </template>
          <p v-else-if="!value[i] && value[i - 1]" class="error">
            ⚠️ Ще изпратите качените документи до всички избрани служители.
          </p>
        </label>

        <template v-if="multipleEmployees[i]">
          <Input
            icon="O"
            placeholder="Получател на документа"
            label="Получател на документа"
            type="dropdown"
            prop="name"
            propTwo="number"
            :list="employees"
            identifier="_id"
            :invalid="multipleEmployees[i].invalidReceiver"
            :locked="isReceiversInputLocked"
            @fetch="$emit('getEmployees', $event)"
            @input="
              (multipleEmployees[i].invalidReceiver = false),
                updateFile(i, 'receiver', $event, multipleEmployees),
                $emit('getEmployees', $event)
            "
            v-model="multipleEmployees[i].receiver"
          />
          <Input
            icon="0"
            placeholder="Категория"
            label="Категория документ"
            type="dropdown"
            :list="getCategoryNames"
            :invalid="multipleEmployees[i].invalidCategory"
            :locked="!currentUser.roles.hr || sameCategoryForAll ? i != 0 : false"
            @input="
              updateFile(i, 'category', $event, multipleEmployees), (multipleEmployees[i].invalidCategory = false)
            "
            v-model="multipleEmployees[i].category"
          />
          <div class="document-wrapper">
            <Checkbox
              v-model="multipleEmployees[i].needsConfirmation"
              type="switch"
              label="Получателят трябва да потвърди че се е запознал с прикачения документ"
            />
          </div>
        </template>
        <template v-else>
          <div class="create-document-input-wrapper"></div>
          <div class="create-document-input-wrapper"></div>
          <div class="create-document-input-wrapper"></div>
        </template>
      </section>
    </main>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import Checkbox from '@/components/inputs/Checkbox';
import Radio from '@/components/inputs/Radio';
import Input from '../CreateDocumentInput.vue';
import ToggleSize from '../ToggleSize';
export default {
  props: [
    'value',
    'd',
    'getCategoryNames',
    'employees',
    'isReceiversInputLocked',
    'sameCategoryForAll',
    'multipleEmployees',
    'isMultipleEmployees',
    'byNumberInput',
    'exceptNumbersInput',
    'loading',
  ],
  components: {
    Checkbox,
    Radio,
    Input,
    ToggleSize,
  },
  computed: {
    ...mapGetters(['currentUser']),
    test() {
      return this.isMultipleEmployees
        ? this.multipleEmployees.length > this.value.length
          ? this.multipleEmployees.length
          : this.value.length
        : this.value.length;
    },
  },
  methods: {
    addFiles() {
      if (this.$refs['fileRef']) {
        this.$refs['fileRef'].click();
      }
    },
    updateFile(index, property, newValue, arr) {
      if (!arr) arr = this.value;

      if (property === 'category' && index === 0 && this.sameCategoryForAll) this.$emit('copyCategories');
      const file = arr[index];
      this.$set(file, property, newValue);
      this.$set(arr, index, file);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
@import '@/scss/manual-type.scss';
.document-form-type {
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 700px;
  height: 600px;
  width: 100%;

  .documents {
    display: flex;
    flex-direction: column;
    gap: 5px;

    > section {
      align-items: center;
      display: flex;
      gap: 5px;
    }
  }
  .create-document-input-wrapper {
    max-width: 320px;
  }

  .folder-img {
    align-items: center;
    display: flex;
    gap: 15px;
    flex-direction: column;
    margin: 0;

    section {
      display: flex;
      flex-direction: column;
      gap: 10px;
      text-align: center;
      max-width: 550px;

      hr {
        background: rgb(112, 112, 112, 0.5);
        border: 0;
        height: 1px;
        margin: 0;
        width: 100%;
      }

      h2 {
        font-size: 18px;
        margin-bottom: 0.5rem;
      }

      p {
        color: #0c518f;
        font-size: 14px;
        font-weight: 600;
      }
      span {
        color: #0099ff;
      }

      button {
        margin: 10px auto 0 auto;
        max-width: 70%;
      }

      .switch-and-counter {
        align-items: center;
        display: flex;
        justify-content: center;
        gap: 1rem;

        .checkbox-switch {
          min-width: 350px;
        }
      }
    }
    img {
      width: 190px;
    }
  }

  .documents {
    .document-wrapper {
      height: 62px;
      margin: initial;
      width: 35%;

      &.transparent {
        background: initial;
        border: initial;
      }

      &:last-child {
        max-width: 320px;
      }
    }
  }
}
</style>
