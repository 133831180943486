<template>
  <div class="document-form-type">
    <div class="row center text-center column">
      <h1>
        ИНФОРМАЦИЯ ЗА ОБРАБОТВАНЕ <br />
        НА ЛИЧНИ ДАННИ
      </h1>
      <p class="doc-number" v-if="d.number">№{{ d.numberPrefix }}-{{ d.number }}</p>
    </div>
    <div class="row center text-center">
      <p>
        Днес <strong>{{ date | formatDate }}</strong
        >, аз
      </p>
    </div>

    <section class="rows-wrapper">
      <div class="row">
        <Input
          style="width: 75%"
          icon="F"
          placeholder="Име Презиме Фамилия"
          label="От Работник/Служител (изберете или потърсете име)"
          type="dropdown"
          @fetch="$emit('fetch-employees', $event)"
          :locked="d._id || isCurrUserDocUser"
          :list="employees"
          prop="name"
          identifier="_id"
          v-model="d.form.user"
        />
        <Input
          style="width: 25%"
          icon="+"
          placeholder="№"
          label="Служебен номер"
          v-model="d.form.user.number"
          :autoFill="true"
        />
      </div>
      <div class="row">
        <Input
          icon="b"
          placeholder="Длъжност"
          label="Длъжност на работника или служителя (автом.)"
          :value="d.form.user.position ? d.form.user.position.name : ''"
          :autoFill="true"
        />
        <Input
          :icon="'&'"
          placeholder="Структурно звено"
          label="Структурно звено (участък, цех, лаборатория, отдел, отделение, група)"
          :value="d.form.user.structuralUnit ? d.form.user.structuralUnit.name : ''"
          :autoFill="true"
        />
      </div>
    </section>

    <div class="row center text-center">
      <p class="smaller-line-height">
        <strong>се запознах с настоящата Информация за лични данни и получих копие от нея.</strong>
      </p>
    </div>

    <div class="row row--background">
      <Input
        icon="C"
        placeholder="Дата на създаване"
        label="Дата на създаване"
        v-model="d.form.createdAt"
        type="datepicker"
      />
      <Input
        icon="$"
        :placeholder="d.form.user.name ? d.form.user.name : 'Име, Фамилия'"
        label="Служител - име и подпис"
        type="signature"
        :value="d.form.signatures.user"
        @signed="d.form.signatures.user = $event"
      />
    </div>

    <div class="row first-letter--margin">
      <p>
        С настоящата декларация, на основание чл. 13 и чл. 14 от Регламент (ЕС) 2016/679 относно защита на физическите
        лица във връзка с обработването на лични данни и относно свободното движение на такива данни и за отмяна на
        Директива 95/46/ЕО („Регламента“) се предоставя информация на лица, които имат сключен трудов договор с
        Администратора, във връзка с личните им данни, които Администратора обработва.
      </p>
    </div>

    <div class="row aside--row">
      <aside><strong>I.</strong></aside>
      <p>
        <strong>Администратор на лични данни: „КЦМ“ АД</strong>, ЕИК 11500747;
        <strong>Седалище и адрес на управление</strong>: гр. Пловдив 4009, Асеновградско шосе;
        <strong>Телефон за контакт</strong>: <a href="tel:032/609281" target="_blank"> 032/609281 </a>
      </p>
    </div>

    <div class="row aside--row">
      <aside><strong>II.</strong></aside>
      <p>
        <strong>Длъжностно лице по защита на данните</strong>
        Ако имате въпроси или други искания във връзка с Вашите лични данни, които се обработват от Администратора, може
        да се свържете с Длъжностното лице по защита на данните на ХОЛДИНГ КЦМ 2000, чиито координати са както следва:
        <strong>Адрес</strong>: Пловдив, ул. Асеновградско шосе; <strong>Телефон за контакт</strong>:<a
          href="tel:032/ 609323"
          target="_blank"
        >
          032/ 609323 </a
        >; Имейл:<a href="mailto:dpo@kcm.bg" target="_blank"> dpo@kcm.bg </a>
      </p>
    </div>

    <div class="row aside--row">
      <aside><strong>III.</strong></aside>
      <p>
        <strong>Вашите лични данни</strong>
      </p>
    </div>

    <div class="row aside--row">
      <aside><strong>1.</strong></aside>
      <p>Лични данни са всяка информация, която Ви идентифицира или би могла да Ви идентифицира като физическо лице.</p>
    </div>

    <div class="row aside--row">
      <aside><strong>2.</strong></aside>
      <p>
        Вашите лични данни, които получава Администратора, се предоставят във връзка с целите и на основания посочени в
        разделите от тази информация по-долу.
      </p>
    </div>

    <div class="row aside--row">
      <aside><strong>IV.</strong></aside>
      <p>
        <strong>Категории лични данни</strong>
      </p>
    </div>

    <div class="row aside--row">
      <aside></aside>

      <section>
        <span>
          Във връзка с трудовото правоотношение с Администратора въз основа на трудов договор Администраторът обработва
          следните Ваши лични данни:
        </span>

        <section class="section--aside">
          <aside>i.</aside>
          <p>
            <strong>Физическа идентичност</strong>: три имена, ЕГН, данни по лична карта, настоящ адрес по местоживеене;
            месторождение; имейл адрес; телефон; служебен номер; регистрационен номер на МПС; длъжност; подпис; снимка;
            видеозаписи; банкова сметка.
          </p>
        </section>
        <section class="section--aside">
          <aside>ii.</aside>
          <p>
            <strong>Социална идентичност</strong>: образование; регистрационен номер на документите за завършено
            образование; копие от документа за завършено образование; данни за правоспособност да се упражнява
            определена професия; регистрационен номер на документ за правоспособност при кандидатстване за работа; копие
            от документа за правоспособност; трудов стаж; копие от трудовата книжка; копие на военно-отчетна книжка към
            трудовото досие; копие на осигурителна книжка към трудовото досие; трудова дейност (данни за присъствие на
            работа, платен и неплатен отпуск (годишен, ученически), почасови отсъствия, болнични (включително за дете
            или друг член от семейството), изпитни резултати от обучение; копие то свидетелство за управление на МПС.
          </p>
        </section>
        <section class="section--aside">
          <aside>iii.</aside>
          <p><strong>Семейна идентичност</strong>: семейно положение (съпруг и деца).</p>
        </section>

        <section class="section--aside">
          <aside>iv.</aside>
          <p><strong>Физиологична идентичност</strong>: данни във връзка със здравословното ви състояние.</p>
        </section>
        <section class="section--aside">
          <aside>v.</aside>
          <p><strong>Психологическа идентичност</strong>: психическо състояние.</p>
        </section>
        <section class="section--aside">
          <aside>vi.</aside>
          <p>
            <strong>Икономическа идентичност</strong>: трудово възнаграждение, други доходи, допълнителни възнаграждения
            и награди, осигуровки, данъци, вноски за доброволно пенсионно осигуряване, вноски по кредити и заеми към
            взаимоспомагателни каси.
          </p>
        </section>
        <section class="section--aside">
          <aside>vii.</aside>
          <p><strong>Членство в организации</strong>: членство в синдикални организации.</p>
        </section>
      </section>
    </div>

    <div class="row aside--row">
      <aside><strong>V.</strong></aside>
      <p>
        <strong>Цели на обработването</strong>
      </p>
    </div>

    <div class="row aside--row">
      <aside></aside>

      <section>
        <span> Администраторът обработва Вашите лични данни, за да: </span>

        <section class="section--aside">
          <aside>i.</aside>
          <p>
            За сключване и изпълнение на трудови договори за наемане на работа , изплащане на възнаграждения по банкови
            сметки, удържане на плащания от трудовите възнаграждения към банки, застрахователни дружества, дружества за
            доброволно пенсионно осигуряване, взаимо-спомагателни каси, за които сте дали съгласие, и други Ваши
            задължения към лица, извън организацията на Администратора като например по образувани срещу Вас
            изпълнителни производства по искане на съдебни изпълнители;
          </p>
        </section>
        <section class="section--aside">
          <aside>ii.</aside>
          <p>
            Извърши оценка на Вашата медицинска пригодност за изпълняваната длъжност/работа при постъпване на работа и
            след всеки проведен профилактичен преглед, за да поддържа медицинското ви досие по време на трудовите
            правоотношения, както и да изпълнява задълженията си за осигуряване на профилактика и рехабилитация;
          </p>
        </section>
        <section class="section--aside">
          <aside>iii.</aside>
          <p>
            Изпълни своите нормативно установени задължения като работодател за осигуряване на безопасни и здравословни
            условия на труд, изготвяне и поддържане на досиета за трудови злополуки съобразно нормативните изисквания;
          </p>
        </section>
        <section class="section--aside">
          <aside>iv.</aside>
          <p>
            Извърши контрол върху лицата, моторните превозни средства и специализираната техника, които имат достъп до
            територията на Администратора за спазване на работно време, технологичната и трудова дисциплина, осигуряване
            на необходимата сигурност и защитеност на персонала, физическите и интелектуални активи и стоково-материални
            ценности;
          </p>
        </section>
        <section class="section--aside">
          <aside>v.</aside>
          <p>Обработва документи в деловодната система;</p>
        </section>
        <section class="section--aside">
          <aside>vi.</aside>
          <p>Ви включва в обучения, като съхранява информация за тях и документира резултатите от обученията.</p>
        </section>
      </section>
    </div>

    <div class="row aside--row">
      <aside><strong>VI.</strong></aside>
      <p>
        <strong>Правни основания на обработването</strong>
      </p>
    </div>

    <div class="row aside--row">
      <aside></aside>

      <section>
        <span>Администраторът обработва Вашите лични данни на основание:</span>

        <section class="section--aside">
          <aside>i.</aside>
          <p>
            сключените с Вас трудови договори за наемане на работа и в тази връзка за изплащане на възнагражденията по
            банкови сметки и удръжки, съгласно приложимите нормативни актове.
          </p>
        </section>
        <section class="section--aside">
          <aside>ii.</aside>
          <p>
            Нормативните изисквания на трудовото, осигурителното и данъчно законодателства към Администратора като
            работодател, както и на основание Колективния трудов договор между Администратора и синдикалните организации
            в предприятието;
          </p>
        </section>
        <section class="section--aside">
          <aside>iii.</aside>
          <p>
            Легитимни интереси на администратора като осигуряване на фирмената сигурност на Администратора и работещите
            за него лица, оценка на труда и провизиите за пенсии, както и за изпълнение на задължения по договори с
            търговски и други партньори, включително за провеждане на професионални обучения на техните работници и
            служители.
          </p>
        </section>
      </section>
    </div>

    <div class="row aside--row">
      <aside><strong>VII.</strong></aside>
      <p>
        <strong>На кого се предоставят Вашите лични данни</strong>
      </p>
    </div>

    <div class="row aside--row">
      <aside>1.</aside>

      <section>
        <span>
          Във връзка с целите, за които Вашите данни се обработват от Администратора, той може да предостави достъп до
          тези данни на:
        </span>

        <section class="section--aside">
          <aside>i.</aside>
          <p>Вас, като лице, за което личните данни се отнасят;</p>
        </section>
        <section class="section--aside">
          <aside>ii.</aside>
          <p>
            Лица, които са служители в различни отдели на Администратора и дружества от ХОЛДИНГ КЦМ 2000 във връзка с
            изпълнение на техните служебни задължения, както и на Вашето трудово/договорно правоотношение с
            Администратора;
          </p>
        </section>
        <section class="section--aside">
          <aside>iii.</aside>
          <p>
            На публични органи, актюери, банки, застрахователни дружества и дружества за доброволно пенсионно
            осигуряване, взаимо-спомагателни каси, съдебни изпълнители, синдикални организации;
          </p>
        </section>
        <section class="section--aside">
          <aside>iv.</aside>
          <p>
            Партньори на Администратора във връзка със съхранение и обработване на данни за здравословното състояние на
            служителите на Администратора, както и във връзка с организиране на специализиран транспорт, хранене, на
            профилактика и рехабилитация; осигуряване на фирмена сигурност и професионално обучение на служителите на
            Администратора и на лица извън неговата организация, както и във връзка с поддръжката на електронните
            системи на Администратора.
          </p>
        </section>
      </section>
    </div>

    <div class="row aside--row">
      <aside><strong>VIII.</strong></aside>
      <p>
        <strong>От кого получаваме Ваши данни</strong>
      </p>
    </div>

    <div class="row aside--row">
      <aside></aside>

      <section>
        <span> Администраторът получава Вашите данни от: </span>

        <section class="section--aside">
          <aside>i.</aside>
          <p>Вас при кандидатстване за работа и подготовка и сключване на трудовия договор.</p>
        </section>
        <section class="section--aside">
          <aside>ii.</aside>
          <p>
            Банки, застрахователни дружества и дружества за доброволно пенсионно осигуряване, взаимо-спомагателни каси,
            съдебни изпълнители, синдикални организации при обмен на информация във връзка с удръжки от Вашето
            възнаграждение за погасяване на Ваши задължения към съответните лица;
          </p>
        </section>
        <section class="section--aside">
          <aside>iii.</aside>
          <p>Други партньорски организации във връзка с осигуряване на храна, охрана, обучение и профилактика.</p>
        </section>
      </section>
    </div>

    <div class="row aside--row">
      <aside><strong>IX.</strong></aside>
      <p>
        <strong>Предаване на данни в трети държави или международни организации</strong>
      </p>
    </div>

    <div class="row aside--row">
      <aside></aside>

      <p>
        Към момента на получаване на тази информация Администраторът не предава Ваши данни в трети държави или
        международни организации.
      </p>
    </div>

    <div class="row aside--row">
      <aside><strong>X.</strong></aside>
      <p>
        <strong>Срок за обработване на личните данни</strong>
      </p>
    </div>

    <div class="row aside--row">
      <aside></aside>

      <p>
        Администраторът обработва Вашите лични данни за сроковете определени в приложимите нормативните актове, както и
        за срокове, които се определят от неговите легитимни цели.
      </p>
    </div>

    <div class="row aside--row">
      <aside><strong>XI.</strong></aside>
      <p>
        <strong>Вашите права за защита на личните данни</strong>
      </p>
    </div>

    <div class="row aside--row">
      <aside>1.</aside>

      <p>
        Вие имате право по всяко време и без забавяне да искате от Администратора:Да Ви предостави достъп до Вашите
        лични данни и информация за тях и тяхното обработване, както и безплатно копие от тях; Да искате тези данни да
        бъдат коригирани и да бъдат съхранявани в точен и актуален вид; Да искате личните Ви данни да бъдат изтрити,
        когато те се обработват или са били обработвани без основания и/или за цели, за които не са необходими вече; Да
        искате обработването им да бъде ограничено;
      </p>
    </div>
    <div class="row aside--row">
      <aside>2.</aside>

      <p>Вие имате право да възразите срещу обработването на личните Ви данни.</p>
    </div>
    <div class="row aside--row">
      <aside>3.</aside>

      <p>
        Повече информация във връзка с начините на упражняване на Вашите права може да получите от Длъжностното лице по
        защита на данните.
      </p>
    </div>

    <div class="row aside--row">
      <aside><strong>XII.</strong></aside>
      <p>
        <strong>Право на жалба до надзорния орган</strong>
      </p>
    </div>

    <div class="row aside--row">
      <aside></aside>

      <p>
        Ако Администраторът не се произнесе по Ваша жалба или откаже да разгледа Ваше искане в срок от 1 (един) месец от
        получаването на съответното искане на разбираем, ясен и прост език, Вие може да подадете жалба директно до
        надзорния орган: Комисия за защита на личните данни Адрес: гр. София 1592, бул. „Проф. Цветан Лазаров” №2,
        Имейл: <a href="mailto:kzld@cpdp.bg" target="_blank"> kzld@cpdp.bg </a>, Телефон:
        <a href="tel:02 9153 518" target="_blank"> 02 9153 518 </a> Интернет страница:
        <a href="https://cpdp.bg/" target="_blank"> www.cpdp.bg </a>, или да потърсите правата си пред съдебните органи.
      </p>
    </div>

    <div class="row aside--row">
      <aside><strong>XIII.</strong></aside>
      <p>
        <strong>Информация за автоматизирани решения</strong>
      </p>
    </div>

    <div class="row aside--row">
      <aside></aside>

      <p>
        Администраторът не използва автоматизирани решения към момента на запознаване от страна на субекта на данни с
        тази информация, включително профилиране.
      </p>
    </div>
  </div>
</template>

<script>
import Input from '../CreateDocumentInput';
export default {
  components: {
    Input,
  },
  data() {
    return {
      date: '',
    };
  },
  props: ['d', 'employees', 'invalid', 'isCurrUserDocUser'],
  beforeMount() {
    if (!this.d._id) this.date = new Date();
    else this.date = new Date(this.d.createdAt);
  },
};
</script>

<style>
</style>