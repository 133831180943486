<template>
  <label :class="{'checkbox-wrapper': !type,'checkbox-switch': type === 'switch'}" @click.stop>
    <input
      type="checkbox"
      :checked="value"
      :disabled="disabled"
      @change="$emit('input', $event.target.checked)"
    />
    <span v-if="type === 'switch'" class="slider round"></span>
    <span v-else class="checkmark"></span>
    <p class="label" v-if="label">{{ label }}</p>
  </label>
</template>

<script>
export default {
  props: ['value', 'label', 'disabled', 'type'],
};
</script>
<style lang="scss" scoped>
@import '@/scss/base.scss';
.checkbox-switch {
  align-items: center;
  display: flex;
  height: 20px;
  // min-width: 40px;
  position: relative;
  // width: 40px;
.label{
  padding-left: 40px;
}
  input {
    height: 0;
    opacity: 0;
    width: 0;

    &:checked + .slider {
      background-color: $darkBlue;

      &::before {
        @include transform(translateX(20px));
      }
    }

    &:focus + .slider {
      box-shadow: 0 0 1px $darkBlue;
    }
  }

  .slider {
    background-color: #ABABAB;
    border-radius: 20px;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    min-width: 40px;
    @include transition($transition);

    &::before {
      background-color: white;
      border-radius: 50%;
      bottom: 2px;
      content: '';
      height: 16px;
      left: 2px;
      position: absolute;
      @include transition($transition);
      width: 16px;
    }
  }

  &:hover {
    input {
      &:checked + .slider {
        background-color: rgba($color: $darkBlue, $alpha: 0.8);
      }
      &:not(:checked) + .slider {
        background-color: rgba($color: #ABABAB, $alpha: 0.8);
      }
    }
  }
}
.checkbox-wrapper {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  height: 20px;
  margin: 8px 10px;
  position: relative;
  padding-left: 30px;
  @include noselect();
  input {
    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;
    &:not(:disabled) ~ .checkmark {
      background-color: #e2e9ee;
    }
    &:checked ~ .checkmark {
      background-color: $mainColor;
      &:after {
        display: block;
      }
    }
  }
  .checkmark {
    background-color: #d9d9d9;
    border-radius: 4px;
    height: 20px;
    left: 0;
    position: absolute;
    top: 0;

    @include transition($transition);
    width: 20px;
    &:after {
      border: solid #fff;
      border-width: 0 2px 2px 0;
      content: '';
      display: none;
      height: 10px;
      left: 8px;
      position: absolute;
      top: 4px;
      @include transform(rotate(45deg));
      width: 5px;
    }
  }
  &:hover input:checked ~ .checkmark {
    background-color: rgba($color: $mainColor, $alpha: 0.8)
  }
}
</style>
