<template>
  <div class="document-form-type">
    <div class="row center text-center column">
      <h1>
        ДЕКЛАРАЦИЯ ЗА ВЗАИМНО <br />
        ПОДПОМАГАНЕ
      </h1>
      <p class="doc-number" v-if="d.number">№{{ d.numberPrefix  }}-{{  d.number }}</p>
    </div>

    <div class="row center text-center">
      <p class="smaller-line-height">
        <strong>Аз, долуподписаният:</strong>
      </p>
    </div>

    <section class="rows-wrapper">
      <div class="row">
        <Input
          style="width: 75%"
          icon="F"
          placeholder="Име Презиме Фамилия"
          label="От Работник/Служител (изберете или потърсете име)"
          type="dropdown"
          @fetch="$emit('fetch-employees', $event)"
          :locked="d._id || isCurrUserDocUser"
          :list="employees"
          prop="name"
          identifier="_id"
          v-model="d.form.user"
        />
        <Input
          style="width: 25%"
          icon="+"
          placeholder="№"
          label="Служебен номер"
          v-model="d.form.user.number"
          :autoFill="true"
        />
      </div>
      <div class="row">
        <Input
          icon="b"
          placeholder="Длъжност"
          label="Длъжност на работника или служителя (автом.)"
          :value="d.form.user.position ? d.form.user.position.name : ''"
          :autoFill="true"
        />
        <Input
          :icon="'&'"
          placeholder="Структурно звено"
          label="Структурно звено (участък, цех, лаборатория, отдел, отделение, група)"
          :value="d.form.user.structuralUnit ? d.form.user.structuralUnit.name : ''"
          :autoFill="true"
        />
      </div>
    </section>

    <div class="row center text-center">
      <p class="smaller-line-height">съм съгласен (съгласна) ежемесечно да се удържа от работната ми заплата по</p>
    </div>

    <div class="row">
      <Input
        style="width: 25%"
        icon=","
        placeholder="Сума с цифри"
        label="сума в лева, с цифри"
        v-model="d.form.price"
        :digitsOnly="true"
      />
      <Input
        style="width: 75%"
        icon=","
        placeholder="Сума словом"
        label="сума в лева, словом (попълва се автоматично)"
        :value="d.form.price ? wordify(d.form.price) : ''"
        :autoFill="true"
      />
    </div>

    <div class="row center text-center">
      <p>Сумата да се удържа до второ мое нареждане, за което ще уведомя устно или писмено специалистите ТРЗ.</p>
    </div>

    <div class="row center text-center">
      <p class="smaller-line-height"><strong>Известно ми е, че:</strong></p>
    </div>

    <div class="row aside--row row--align-center">
      <aside><p class="circle circle--mini"></p></aside>
      <p>
        Парите ще се разходват САМО за подпомагане на работещи в КЦМ АД, които са изпаднали в затруднено положение, в
        резултат на сериозен здравословен проблем или друга тежка житейска трагедия;
      </p>
    </div>

    <div class="row aside--row row--align-center">
      <aside><p class="circle circle--mini"></p></aside>
      <p>
        Решенията за подпомагане ще се вземат от Съвета за социално сътрудничество, по предварително подадени молби с
        придружаващи документи.
      </p>
    </div>

    <div class="row aside--row row--align-center">
      <aside><p class="circle circle--mini"></p></aside>
      <p>
        Ежемесечно в „Бюлетин КЦМ” ще се обявява събраната сума, лицата на които са гласувани помощи и размерa на
        помощта.
      </p>
    </div>

    <div class="row aside--row row--align-center">
      <aside><p class="circle circle--mini"></p></aside>
      <p>Съветът за социално сътрудничество е в състав, определен от КТД.</p>
    </div>

    <div class="row aside--row row--align-center">
      <aside><p class="circle circle--mini"></p></aside>
      <p>По всяко време имам право да поискам отчет на средствата, набрани като взаимно подпомагане.</p>
    </div>

    <div class="row aside--row row--align-center">
      <aside><p class="circle circle--mini"></p></aside>
      <p>По всяко време имам право да се откажа от участие във фонда за доброволно подпомагане.</p>
    </div>

    <div class="row row--background">
      <Input
        icon="C"
        placeholder="Дата на създаване"
        label="Дата на създаване"
        v-model="d.form.createdAt"
        type="datepicker"
      />
      <Input
        icon="$"
        :placeholder="d.form.user.name ? d.form.user.name : 'Име, Фамилия'"
        label="Служител - име и подпис"
        type="signature"
        :value="d.form.signatures.user"
        @signed="d.form.signatures.user = $event"
      />
    </div>
  </div>
</template>

<script>
import Input from '../CreateDocumentInput';
import wordifyBG from '@/js/wordify.bg.js';
export default {
  components: {
    Input,
  },
  props: ['d', 'employees', 'pdf', 'invalid', 'isCurrUserDocUser'],
  methods: {
    wordify(num) {
      return wordifyBG(num);
    },
  },
};
</script>