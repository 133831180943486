<template>
  <div class="document-form-type">
    <div class="row center text-center column">
      <h1>
        КАРТА ЗА ОЦЕНКА НА <br />
        УЧАЩ
      </h1>
      <p class="doc-number" v-if="d.number">№{{ d.numberPrefix }}-{{ d.number }}</p>
    </div>

    <section class="rows-wrapper">
      <div class="row">
        <Input
          style="width: 75%"
          icon="F"
          placeholder="Име Презиме Фамилия"
          label="От Работник/Служител (изберете или потърсете име)"
          type="dropdown"
          @fetch="$emit('fetch-employees', $event)"
          :locked="d._id"
          :list="employees"
          prop="name"
          identifier="_id"
          v-model="d.form.user"
        />
        <Input
          style="width: 25%"
          icon="+"
          placeholder="№"
          label="Служебен номер"
          v-model="d.form.user.number"
          :autoFill="true"
        />
      </div>
      <div class="row">
        <Input
          :icon="'&'"
          placeholder="Структурно звено"
          label="Структурно звено (участък, цех, лаборатория, отдел, отделение, група)"
          :value="d.form.user.structuralUnit ? d.form.user.structuralUnit.name : ''"
          :autoFill="true"
        />
      </div>

      <div class="row">
        <Input
          style="width: 75%"
          icon="b"
          placeholder="Длъжност"
          label="Длъжност на работника или служителя (автом.)"
          :value="d.form.user.position ? d.form.user.position.name : ''"
          :autoFill="true"
        />
        <Input
          style="width: 25%"
          icon="b"
          placeholder="Смяна"
          label="Смяна"
          :value="d.form.user.schedule ? d.form.user.schedule : ''"
          :autoFill="true"
        />
      </div>
    </section>

    <div class="row center text-center">
      <p>
        <strong>Кандидатстващ за даване на съгласие за платен ученически отпуск</strong>
      </p>
    </div>

    <div class="row wrap">
      <div style="width: 70%" class="title">
        <h1>ПОКАЗАТЕЛИ ЗА ИЗПЪЛНЕНИЕ И РЕЗУЛТАТИ</h1>
      </div>

      <section class="row column center box" style="width: 25%">
        <p>Оценка от пряк ръководител 1</p>
        <div class="desc">
          <span>незадово лително</span>
          <span>добро</span>
          <span>много добро</span>
        </div>
      </section>

      <section class="row column center box" style="width: 25%">
        <p>Оценка от р-л участък, р-л звено (Рък 2)</p>
        <div class="desc">
          <span>незадово лително</span>
          <span>добро</span>
          <span>много добро</span>
        </div>
      </section>
    </div>

    <section class="rows-wrapper rows--background rows--points">
      <div class="row">
        <section class="indicators" style="width: 75%">
          <span>1</span>
          <p>Правилно изпълнение поставените задачи</p>
        </section>
        <Score v-model="d.form.scores.correctPerformance" style="width: 25%" :locked="d._id" />
        <Score
          class="score--yellow"
          v-model="d.form.scores.correctPerformanceTwo"
          style="width: 25%"
          :locked="stageTwoDone || !isCurrUserDocReceiverTwo"
        />
      </div>

      <div class="row">
        <section class="indicators" style="width: 75%">
          <span>2</span>
          <p>Изпълнение на поставените задачи в срок</p>
        </section>
        <Score v-model="d.form.scores.fast" style="width: 25%" :locked="d._id" />
        <Score
          class="score--yellow"
          v-model="d.form.scores.fastTwo"
          style="width: 25%"
          :locked="stageTwoDone || !isCurrUserDocReceiverTwo"
        />
      </div>

      <div class="row">
        <section class="indicators" style="width: 75%">
          <span>3</span>
          <p>Познаване на технологичните процеси, които обслужва</p>
        </section>
        <Score v-model="d.form.scores.knowledgeOfProcess" style="width: 25%" :locked="d._id" />
        <Score
          class="score--yellow"
          v-model="d.form.scores.knowledgeOfProcessTwo"
          style="width: 25%"
          :locked="stageTwoDone || !isCurrUserDocReceiverTwo"
        />
      </div>

      <div class="row">
        <section class="indicators" style="width: 75%">
          <span>4</span>
          <p>Правилно използване на машините и оборудването, с които работи</p>
        </section>
        <Score v-model="d.form.scores.correctUse" style="width: 25%" :locked="d._id" />
        <Score
          class="score--yellow"
          v-model="d.form.scores.correctUseTwo"
          style="width: 25%"
          :locked="stageTwoDone || !isCurrUserDocReceiverTwo"
        />
      </div>

      <div class="row">
        <section class="indicators" style="width: 75%">
          <span>5</span>
          <p>Проявяване на полезна инициатива</p>
        </section>
        <Score v-model="d.form.scores.helpfulInitiative" style="width: 25%" :locked="d._id" />
        <Score
          class="score--yellow"
          v-model="d.form.scores.helpfulInitiativeTwo"
          style="width: 25%"
          :locked="stageTwoDone || !isCurrUserDocReceiverTwo"
        />
      </div>

      <div class="row">
        <section class="indicators" style="width: 75%">
          <span>6</span>
          <p>Спазване на изискванията па БЗР</p>
        </section>
        <Score v-model="d.form.scores.bzpUse" style="width: 25%" :locked="d._id" />
        <Score
          class="score--yellow"
          v-model="d.form.scores.bzpUseTwo"
          style="width: 25%"
          :locked="stageTwoDone || !isCurrUserDocReceiverTwo"
        />
      </div>

      <div class="row">
        <section class="indicators" style="width: 75%">
          <span>7</span>
          <p>Използване на ЛПС</p>
        </section>
        <Score v-model="d.form.scores.lpsUse" style="width: 25%" :locked="d._id" />
        <Score
          class="score--yellow"
          v-model="d.form.scores.lpsUseTwo"
          style="width: 25%"
          :locked="stageTwoDone || !isCurrUserDocReceiverTwo"
        />
      </div>

      <div class="row">
        <section class="indicators" style="width: 75%">
          <span>8</span>
          <p>Отношение към колеги/колектив/смяна</p>
        </section>
        <Score v-model="d.form.scores.friendly" style="width: 25%" :locked="d._id" />
        <Score
          class="score--yellow"
          v-model="d.form.scores.friendlyTwo"
          style="width: 25%"
          :locked="stageTwoDone || !isCurrUserDocReceiverTwo"
        />
      </div>

      <div class="row">
        <section class="indicators" style="width: 75%">
          <span>9</span>
          <p>Отношение към собствеността на Работодателя</p>
        </section>
        <Score v-model="d.form.scores.propertyCare" style="width: 25%" :locked="d._id" />
        <Score
          class="score--yellow"
          v-model="d.form.scores.propertyCareTwo"
          style="width: 25%"
          :locked="stageTwoDone || !isCurrUserDocReceiverTwo"
        />
      </div>
    </section>

    <div class="row wrap">
      <div style="width: 70%" class="title">
        <h1>ПОКАЗАТЕЛИ / ЛИЧНИ КАЧЕСТВА</h1>
      </div>

      <section class="row row--align-center center box" style="width: 25%">
        <p>Оценка от пряк ръководител 1</p>
      </section>

      <section class="row row--align-center center box" style="width: 25%">
        <p>Оценка от р-л участък, р-л звено (Рък 2)</p>
      </section>
    </div>

    <section class="rows-wrapper rows--background rows--points">
      <div class="row">
        <section class="indicators" style="width: 75%">
          <span>1</span>
          <p>Разчита се на него при трудна ситуация</p>
        </section>
        <Score v-model="d.form.scores.reliedOn" style="width: 25%" :yesNo="true" :locked="d._id" />
        <Score
          class="score--yellow"
          v-model="d.form.scores.reliedOnTwo"
          style="width: 25%"
          :yesNo="true"
          :locked="stageTwoDone || !isCurrUserDocReceiverTwo"
        />
      </div>

      <div class="row">
        <section class="indicators" style="width: 75%">
          <span>2</span>
          <p>Бързо се учи</p>
        </section>
        <Score v-model="d.form.scores.fastLearner" style="width: 25%" :yesNo="true" :locked="d._id" />
        <Score
          class="score--yellow"
          v-model="d.form.scores.fastLearnerTwo"
          style="width: 25%"
          :yesNo="true"
          :locked="stageTwoDone || !isCurrUserDocReceiverTwo"
        />
      </div>

      <div class="row">
        <section class="indicators" style="width: 75%">
          <span>3</span>
          <p>Издръжлив е на физическо натоварване и стрес</p>
        </section>
        <Score v-model="d.form.scores.durable" style="width: 25%" :yesNo="true" :locked="d._id" />
        <Score
          class="score--yellow"
          v-model="d.form.scores.durableTwo"
          style="width: 25%"
          :yesNo="true"
          :locked="stageTwoDone || !isCurrUserDocReceiverTwo"
        />
      </div>
    </section>

    <section class="rows-wrapper">
      <div class="row center text-center">
        <p>
          <strong>Оценка на потенциал за развитие от ръководител пр/во, дирекция, участък:</strong>
        </p>
      </div>

      <div class="row">
        <Input
          icon="."
          type="textarea"
          :maxlength="165"
          placeholder="Попълнете свободен текст (до 165 символа)"
          label="Оценка на потенциал за развитие от ръководител пр/во, дирекция, участък:"
          v-model="d.form.potentialScore"
        />
      </div>
      <div class="row">
        <Input
          icon="O"
          :placeholder="d.form.user.supervisorSecond ? d.form.user.supervisorSecond.name : 'Имена на Рък 2 (автом.)'"
          label="Оценяващ ръководител 2"
          :value="d.form.user.supervisorSecond ? d.form.user.supervisorSecond.name : '' "
          :locked="d._id"
          :autoFill="true"
        />
        <!-- <Input
          icon="O"
          placeholder="Изберете Ръководител 2"
          label="Ръководител 2"
          type="dropdown"
          @fetch="$emit('fetch-filtered-supervisors', $event)"
          :list="supervisorsTwo"
          prop="name"
          :identifier="d.form.receiverTwo ? '_id' : false"
          v-model="d.form.receiverTwo"
          :locked="d._id"
        /> -->
      </div>
    </section>

    <div class="row row--background">
      <Input
        icon="C"
        placeholder="Дата на създаване"
        label="Дата на създаване"
        v-model="d.form.createdAt"
        :locked="d._id"
        type="datepicker"
      />
      <Input
        icon="$"
        :placeholder="d.form.user.name ? d.form.user.supervisorFirst.name : 'Имена на Рък 1 (автом.)'"
        label="Оценяващ ръководител 1"
        type="signature"
        :value="d.form.signatures.receiver"
        :locked="d._id"
        @signed="d.form.signatures.receiver = $event"
      />
    </div>

    <template v-if="stageOneDone">
      <div class="brake">Попълва се от ръководител 2 - {{ d.form.receiverTwo.name }}</div>

      <div class="row">
        <Input
          icon="O"
          placeholder="Изберете Ръководител 3"
          label="Ръководител 3"
          type="dropdown"
          @fetch="$emit('fetch-filtered-supervisors', $event)"
          :list="supervisorsTwo"
          prop="name"
          :identifier="d.form.receiverThree ? '_id' : false"
          v-model="d.form.receiverThree"
          :disabled="!isCurrUserDocReceiverTwo && !canEdit"
          :locked="stageTwoDone && !canEdit"
        />
      </div>

      <div class="row row--background">
        <Input
          icon="C"
          placeholder="Дата на създаване"
          label="Дата на създаване"
          v-model="d.form.createdAtReceiverTwo"
          :disabled="!isCurrUserDocReceiverTwo"
          type="datepicker"
        />
        <Input
          icon="$"
          :placeholder="d.form.receiverTwo.name ? d.form.receiverTwo.name : 'Име, Фамилия'"
          label="Служител - име и подпис"
          type="signature"
          :value="d.form.signatures.receiverTwo"
          @signed="d.form.signatures.receiverTwo = $event"
          :disabled="!isCurrUserDocReceiverTwo"
        />
      </div>
    </template>

    <template v-if="stageTwoDone">
      <div class="brake">Попълва се от ръководител 3 - {{ d.form.receiverThree.name }}</div>

      <div class="row row--background">
        <Input
          icon="C"
          placeholder="Дата на създаване"
          label="Дата на създаване"
          v-model="d.form.createdAtReceiverThree"
          :disabled="!isCurrUserDocReceiverThree"
          type="datepicker"
        />
        <Input
          icon="$"
          :placeholder="d.form.receiverThree.name ? d.form.receiverThree.name : 'Име, Фамилия'"
          label="Служител - име и подпис"
          type="signature"
          :value="d.form.signatures.receiverThree"
          @signed="d.form.signatures.receiverThree = $event"
          :disabled="!isCurrUserDocReceiverThree"
        />
      </div>
    </template>
  </div>
</template>

<script>
import Input from '../CreateDocumentInput';
import Score from '../CreateDocumentScore';
export default {
  components: {
    Input,
    Score,
  },
  props: [
    'd',
    'employees',
    'pdf',
    'invalid',
    'isCurrUserDocReceiverTwo',
    'isCurrUserDocReceiverThree',
    'supervisorsTwo',
    'canEdit',
  ],
  watch: {
    'd.form.user'() {
      this.$set(this.d.form.user, 'supervisorFirst', this.d.form.user.supervisorFirst);
      this.$set(this.d.form, 'receiverTwo', this.d.form.user.supervisorSecond);
    },
  },
  data() {
    return {
      stageOneDone: false,
      stageTwoDone: false,
    };
  },
  beforeMount() {
    if (this.d.form.signatures.receiver) this.stageOneDone = true;
    if (this.d.form.signatures.receiverTwo) this.stageTwoDone = true;

    if (!this.d._id) {
      this.$set(this.d.form, 'scores', {});
      this.$set(this.d.form.signatures, 'receiver', null);
      this.$set(this.d.form.signatures, 'receiverTwo', null);
      this.$set(this.d.form.signatures, 'receiverThree', null);
    }
    if (this.d.form.signatures.receiver) this.d.form.createdAtReceiverTwo = new Date();
    if (this.d.form.signatures.receiverTwo) this.d.form.createdAtReceiverThree = new Date();
  },
};
</script>