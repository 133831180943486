<template>
  <div class="document-form-type">
    <div class="row center text-center column">
      <h1>
        ДЕКЛАРАЦИЯ ЗА <br />
        E-MAIL АДРЕС, ЛИЧНА КАРТА, <br />
        АДРЕС, ТЕЛЕФОН
      </h1>
      <p class="doc-number" v-if="d.number">№{{ d.numberPrefix }}-{{ d.number }}</p>
    </div>

    <section class="rows-wrapper">
      <div class="row">
        <Input
          style="width: 75%"
          icon="F"
          placeholder="Име Презиме Фамилия"
          label="От Работник/Служител (изберете или потърсете име)"
          type="dropdown"
          @fetch="$emit('fetch-employees', $event)"
          :locked="d._id || isCurrUserDocUser"
          :list="employees"
          prop="name"
          identifier="_id"
          v-model="d.form.user"
        />
        <Input
          style="width: 25%"
          icon="+"
          placeholder="№"
          label="Служебен номер"
          v-model="d.form.user.number"
          :autoFill="true"
          :locked="d._id"
        />
      </div>
      <div class="row">
        <Input
          icon="b"
          placeholder="Длъжност"
          label="Длъжност на работника или служителя (автом.)"
          :value="d.form.user.position ? d.form.user.position.name : ''"
          :autoFill="true"
          :locked="d._id"
        />
        <Input
          :icon="'&'"
          placeholder="Структурно звено"
          label="Структурно звено (участък, цех, лаборатория, отдел, отделение, група)"
          :value="d.form.user.structuralUnit ? d.form.user.structuralUnit.name : ''"
          :autoFill="true"
          :locked="d._id"
        />
      </div>
    </section>

    <div class="row first-letter--margin">
      <p>
        Във връзка с получаване на фиш за работната заплата и други данни по трудовото правоотношение с КЦМ АД, по
        електронен път,
      </p>
    </div>
    <div class="row center text-center">
      <p class="smaller-line-height"><strong>I. ДЕКЛАРИРАМ,</strong></p>
    </div>

    <div class="row first-letter--margin">
      <p>
        че желая фиш за работна заплата, както и друга информация относно трудовото ми правоотношение с КЦМ АД, да
        получавам на следният актуален e-mail адрес:
      </p>
    </div>

    <div class="row">
      <Input
        icon="%"
        placeholder="Актуален e-mail адрес"
        label="може да се посочи служебен или личен e-mail"
        v-model="d.form.email"
        :locked="d._id"
        :invalid="invalid.form.email"
        :maxlength="30"
        @input="$set(invalid.form, 'email', false)"
        :nonRequired="true"
      />
    </div>

    <div class="row first-letter--margin">
      <p>
        В тази връзка съм запознат, че на генерираното автоматично съобщение от системата, ще отговоря с данните на моят
        служебен номер в КЦМ АД.
      </p>
    </div>

    <div class="row center text-center">
      <p class="smaller-line-height"><strong>II. ДЕКЛАРИРАМ И СЛЕДНИТЕ ЛИЧНИ АКТУАЛНИ ДАННИ ЗА:</strong></p>
    </div>

    <section class="rows-wrapper">
      <div class="row">
        <Input
          icon="'"
          placeholder="ЛК Номер"
          label="Лична карта (ЛК)"
          v-model="d.form.lk.number"
          :lkNumber="true"
          :locked="d._id"
          :maxlength="isNewLkType ?  15 : 9"
          :minlength="isNewLkType ?  15 : 9"
          :nonRequired="isLkInfoRequired"
          @update:isNewLkType="setNewLkType"
          @input="
            $set(invalid.form, 'lkIssuedAt', false),
              $set(invalid.form, 'lkIssuedBy', false),
              $set(invalid.form, 'lkValidTo', false)
          "
        />
        <Input
          icon="C"
          placeholder="Дата"
          label="Издадена през година"
          type="datepicker"
          v-model="d.form.lk.issuedAt"
          :yearFirst="true"
          :locked="d._id"
          :nonRequired="isLkInfoRequired"
          :invalid="invalid.form.lkIssuedAt"
          @input="$set(invalid.form, 'lkIssuedAt', false)"
        />
      </div>
      <div class="row">
        <Input
          icon="("
          placeholder="МВР/Град"
          label="Издадена от МВР"
          v-model="d.form.lk.issuedBy"
          type="dropdown"
          :list="mvr"
          :locked="d._id || isNewLkType"
          :nonRequired="isLkInfoRequired"
          :invalid="invalid.form.lkIssuedBy"
          @input="setIssuedBy"
        />
        <Input
          icon="C"
          placeholder="Дата"
          label="Срок на валидност до"
          type="dropdown"
          v-model="d.form.lk.validTo"
          prop="name"
          propTwo="label"
          :list="validToOptions"
          :disabled="!d.form.lk.issuedAt"
          :locked="d._id"
          :nonRequired="isLkInfoRequired"
          :invalid="invalid.form.lkValidTo"
          @input="$set(invalid.form, 'lkValidTo', false)"
        />
      </div>
      <div class="row">
        <Input
          icon=")"
          placeholder="Телефон"
          label="Номер на мобилен телефон 1"
          maxlength="10"
          :digitsOnly="true"
          :locked="d._id"
          v-model="d.form.mobile1"
          :nonRequired="true"
        />
        <Input
          icon=")"
          placeholder="Телефон"
          label="Номер на мобилен телефон 2"
          maxlength="10"
          :digitsOnly="true"
          :locked="d._id"
          :nonRequired="true"
          v-model="d.form.mobile2"
        />
      </div>

      <div class="row">
        <p>Адрес по лична карта</p>
      </div>
      <div class="row">
        <Input
          icon="*"
          placeholder="Град/село"
          label="Град/село"
          type="dropdown"
          prop="bua"
          :identifier="d.form.city1 ? 'bua' : false"
          propTwo="pk"
          :locked="d._id"
          :list="cities"
          v-model="d.form.city1"
          :nonRequired="true"
        />

        <Input
          icon="*"
          placeholder="ПК (автом.)"
          label="Пощенски код"
          :value="d.form.city1 ? d.form.city1.pk : ''"
          :locked="true"
          :nonRequired="true"
        />
      </div>

      <div class="row">
        <Input
          icon="*"
          placeholder="ул. (ЖК), №, вход, етаж, апартамент"
          label="ул. (ЖК), №, вход, етаж, апартамент"
          v-model="d.form.address1"
          :removeChars="regex"
          :locked="d._id"
          :nonRequired="!d.form.city1.pk ? true : false"
        />
      </div>

      <div class="row">
        <p>Адрес по местоживеене</p>
      </div>
      <div class="row">
        <Input
          icon="*"
          placeholder="Град/село"
          label="Град/село"
          type="dropdown"
          prop="bua"
          :identifier="d.form.city2 ? 'bua' : false"
          propTwo="pk"
          :locked="d._id"
          :list="cities"
          v-model="d.form.city2"
          :nonRequired="true"
        />
        <Input
          icon="*"
          placeholder="ПК (автом.)"
          label="Пощенски код"
          :value="d.form.city2 ? d.form.city2.pk : ''"
          :locked="true"
          :nonRequired="true"
        />
      </div>
      <div class="row">
        <Input
          icon="*"
          placeholder="ул. (ЖК), №, вход, етаж, апартамент"
          label="ул. (ЖК), №, вход, етаж, апартамент"
          v-model="d.form.address2"
          :removeChars="regex"
          :locked="d._id"
          :nonRequired="!d.form.city2.pk ? true : false"
        />
      </div>
    </section>

    <div class="row first-letter--margin">
      <p>
        Уведомен/а съм, че предоставените от мен данни ще се използват с цел получаване на информация за работна заплата
        и други аспекти на трудовото ми правоотношение с КЦМ АД, а данните ще се съхраняват в личното ми трудово досие,
        за срока на действие на трудовото правоотношение.
      </p>
    </div>

    <div class="row first-letter--margin">
      <p>
        На основание чл. 9, параграф 2, б. „а“ от Регламент (ЕС) 2016/679 относно защита на физическите лица, във връзка
        с обработването на лични данни, давам своето информирано и доброволно съгласие за обработване на посочените от
        мен данни в качеството ми нает на трудов договор в КЦМ АД за целите и сроковете посочени по-горе.
      </p>
    </div>

    <div class="row row--background">
      <Input
        icon="C"
        placeholder="Дата на създаване"
        label="Дата на създаване"
        v-model="d.form.createdAt"
        type="datepicker"
        :locked="d._id"
      />
      <Input
        icon="$"
        :placeholder="d.form.user.name ? d.form.user.name : 'Име, Фамилия'"
        label="Служител - име и подпис"
        type="signature"
        :value="d.form.signatures.user"
        @signed="d.form.signatures.user = $event"
        :locked="d._id"
      />
    </div>
  </div>
</template>

<script>
import Input from '../CreateDocumentInput';
import mvr from '@/data/mvr.json';
import cities from '@/data/cities.json';
export default {
  components: {
    Input,
  },
  props: ['d', 'employees', 'pdf', 'invalid', 'isCurrUserDocUser'],
  computed: {
    isLkInfoRequired() {
      return !this.d.form.lk.number && !this.d.form.lk.issuedAt && !this.d.form.lk.issuedBy && !this.d.form.lk.validTo;
    },
  },
  data() {
    return {
      mvr,
      cities,
      validToOptions: [],
      isNewLkType: false,
      regex: /,|'|"|`|„|“/g,
    };
  },
  watch: {
    'd.form.lk.issuedAt'() {
      if (this.d.form.lk.issuedAt) {
        const fourYears = new Date(this.d.form.lk.issuedAt);
        fourYears.setFullYear(fourYears.getFullYear() + 4);
        const tenYears = new Date(this.d.form.lk.issuedAt);
        tenYears.setFullYear(tenYears.getFullYear() + 10);

        this.validToOptions = [
          {
            name: `Валидна до ${this.$options.filters.formatDate(fourYears)}`,
            label: 'Валидност 4 години',
          },
          {
            name: `Валидна до ${this.$options.filters.formatDate(tenYears)}`,
            label: 'Валидност 10 години',
          },
          {
            name: `Безсрочно`,
            label: 'Безсрочна валидност',
          },
        ];
      }
    },
  },
  methods: {
    setNewLkType(e) {
      this.isNewLkType = e;

      if (e) {
        this.d.form.lk.issuedBy = 'МВР';
      } else if (!e && this.d.form.lk.issuedBy) this.d.form.lk.issuedBy = '';
    },
    setIssuedBy(e) {
      this.$set(this.invalid.form, 'lkIssuedBy', false);
      this.$set(this.d.form.lk, 'issuedBy', e);
    },
  },
  beforeMount() {
    if (!this.d._id) {
      this.$set(this.d.form, 'lk', {});
      this.$set(this.d.form, 'city1', {});
      this.$set(this.d.form, 'city2', {});
    } else {
      if (!this.d.form.lk) this.$set(this.d.form, 'lk', {});
      if (!this.d.form.city1) this.$set(this.d.form, 'city1', {});
      if (!this.d.form.city2) this.$set(this.d.form, 'city2', {});
    }
  },
};
</script>
