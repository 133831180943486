<template>
  <main class="document-form-type">
    <section class="folder-img" v-if="!addMultiple">
      <img v-if="!value" src="@/assets/img/not-uploaded-files.svg" alt="" />
      <img v-else src="@/assets/img/uploaded-files.svg" alt="" />
    </section>

    <section class="documents">
      <label class="document-wrapper" :class="{ invalid: invalid.file }">
        <input
          type="file"
          :multiple="addMultiple"
          @input="$emit('input', $event.target.files), ($event.target.value = null)"
          accept=".pdf, .jpg, .png, .doc, .docx"
        />
        <section class="icon-and-name">
          <span class="icon">v</span>

          <div class="texts" v-if="value">
            <p>
              <input
                type="text"
                placeholder="Добавете име на документа"
                :class="{ invalid: invalid.filename }"
                :value="name"
                @input="$emit('update-name', $event.target.value)"
              />
            </p>
            <span>{{ value.name }}</span>
          </div>
          <div class="texts" v-else>
            <p>
              <strong>Прикачи файл...</strong>
            </p>
            <span>Може да прикачвате следните файлове: pdf, doc, docx, jpg, png</span>
          </div>
        </section>
        <span class="icon delete" v-if="value" @click.prevent="$emit('input', null)">X</span>
      </label>
      <template v-if="addMultiple">
        <Input
          icon="O"
          placeholder="Получател на документа"
          label="Получател на документа"
          type="dropdown"
          prop="name"
          propTwo="number"
          :list="employees"
          identifier="_id"
          :invalid="invalid.receiver"
          :locked="isReceiversInputLocked"
          @fetch="getEmployees"
          @input="(invalid.receiver = false), getEmployees"
          v-model="d.receiver"
        />

        <Input
          icon="0"
          placeholder="Категория"
          label="Категория документ"
          type="dropdown"
          :list="getCategoryNames"
          :invalid="invalid.category"
          :locked="!currentUser.roles.hr"
          @input="
            invalid.category = false;
            setNumberPrefix();
          "
          v-model="d.category"
        />
      </template>

      <Checkbox
        v-model="d.needsConfirmation"
        type="switch"
        label="Получателят трябва да потвърди че се е запознал с прикачения документ"
      />
    </section>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import Checkbox from '@/components/inputs/Checkbox';
import Input from '../CreateDocumentInput.vue';
export default {
  props: ['value', 'name', 'invalid', 'd', 'addMultiple'],
  components: {
    Checkbox,
    Input,
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  data() {
    return {
      manualFiles: [],
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
@import '@/scss/manual-type.scss';
.checkbox-switch {
  margin-top: 15px;
}
</style>