<template>
  <div class="create-document-form" v-if="document.type">
    <section class="form-progress" v-if="isMobile && !document.finalized">
      <div :class="{ active: !isFormFilled, ready: isFormFilled }">
        <span>1</span>
        <p>БЛАНКА</p>
      </div>

      <p class="icon"><img src="@/assets/img/next-step.svg" alt="" /></p>

      <div :class="{ active: isFormFilled }">
        <span>2</span>
        <p>ДОП. ИНФОРМАЦИЯ</p>
      </div>
    </section>
    <section class="form-aside-wrapper" :class="{ 'form-aside-wrapper--multiple': allToggleOn }">
      <main
        :class="{
          hide: isMobile ? isMainFormHidden : false,
          'main--manual-document': document.type.type === 'manualUpload',
        }"
      >
        <template v-if="(document.finalized || document.preFinalized) && !editMode && !printMode">
          <embed
            v-if="document.file"
            :src="`/api/trdocs/file/${this.document._id}#toolbar=0&navpanes=0`"
            width="100%"
            height="100%"
            frameBorder="0"
          />
        </template>
        <FormTypeManualUpload
          v-else-if="document.type.type === 'manualUpload' && !allToggleOn"
          :d="document"
          :value="file"
          :employees="employees"
          @input="handleFile"
          :name="document.type.name"
          @update-name="
            document.type.name = $event;
            invalid.filename = false;
          "
          :invalid="invalid"
        />
        <FormTypeMultipleManual
          v-else-if="document.type.type === 'manualUpload' && allToggleOn"
          :d="document"
          :value="multipleFiles"
          :multipleEmployees="multipleEmployees"
          :isMultipleEmployees="isMultipleEmployees"
          :addMultiple="allToggleOn"
          :employees="employees"
          :isReceiversInputLocked="isReceiversInputLocked"
          :getCategoryNames="getCategoryNames"
          :sameCategoryForAll="sameCategoryForAll"
          :byNumberInput="byNumberInput"
          :exceptNumbersInput="exceptNumbersInput"
          :loading="loadingMultipleManual"
          @input="handleFile"
          @add="addToMultipleEmployees"
          @update:isMultipleEmployees="isMultipleEmployees = $event"
          @update:multipleEmployees="multipleEmployees = $event"
          @update:byNumberInput="($event) => (byNumberInput = $event)"
          @update:exceptNumbersInput="($event) => (exceptNumbersInput = $event)"
          @getEmployees="getEmployees"
          @copyCategories="toggleAll('category', true)"
          @deleteFile="deleteFile"
        />
        <template v-for="pdf in [true, false]" v-else>
          <FormTypeEmail
            v-if="document.type.type === 'email'"
            :d="document"
            :employees="employees"
            @fetch-employees="getEmployees"
            :pdf="pdf"
            :ref="pdf ? 'form' : 'nonPdfForm'"
            :key="`form-type-email-pdf-${pdf}`"
            :class="{ pdf, firefox: firefox && loading }"
            :invalid="invalid"
            :isCurrUserDocUser="isCurrUserDocUser"
          />
          <FormTypePersonal
            v-if="document.type.type === 'personal'"
            :d="document"
            :employees="employees"
            @fetch-employees="getEmployees"
            :ref="pdf ? 'form' : 'nonPdfForm'"
            :key="`form-type-personal-pdf-${pdf}`"
            :class="{ pdf, firefox: firefox && loading }"
            :invalid="invalid"
            :isCurrUserDocUser="isCurrUserDocUser"
          />
          <FormTypeIntroduction
            v-if="document.type.type === 'introduction'"
            :d="document"
            :employees="employees"
            :carBrands="carBrands"
            @fetch-employees="getEmployees"
            :ref="pdf ? 'form' : 'nonPdfForm'"
            :key="`form-type-introduction-pdf-${pdf}`"
            :class="{ pdf, firefox: firefox && loading }"
            :invalid="invalid"
            :isCurrUserDocUser="isCurrUserDocUser"
          />
          <FormTypeIntroductionChange
            v-if="document.type.type === 'introductionChange'"
            :d="document"
            :employees="employees"
            :carBrands="carBrands"
            @fetch-employees="getEmployees"
            :pdf="pdf"
            :ref="pdf ? 'form' : 'nonPdfForm'"
            :key="`form-type-introductionChange-pdf-${pdf}`"
            :class="{ pdf, firefox: firefox && loading }"
            :invalid="invalid"
            :isCurrUserDocUser="isCurrUserDocUser"
          />
          <FormTypeJoining
            v-if="document.type.type === 'joining'"
            :d="document"
            :employees="employees"
            @fetch-employees="getEmployees"
            :pdf="pdf"
            :ref="pdf ? 'form' : 'nonPdfForm'"
            :key="`form-type-joining-pdf-${pdf}`"
            :class="{ pdf, firefox: firefox && loading }"
            :invalid="invalid"
            :isCurrUserDocUser="isCurrUserDocUser"
          />
          <FormTypeHelping
            v-if="document.type.type === 'helping'"
            :d="document"
            :employees="employees"
            @fetch-employees="getEmployees"
            :pdf="pdf"
            :ref="pdf ? 'form' : 'nonPdfForm'"
            :key="`form-type-helping-pdf-${pdf}`"
            :class="{ pdf, firefox: firefox && loading }"
            :invalid="invalid"
            :isCurrUserDocUser="isCurrUserDocUser"
          />
          <FormTypeNewPlastic
            v-if="document.type.type === 'newPlastic'"
            :d="document"
            :employees="employees"
            @fetch-employees="getEmployees"
            :pdf="pdf"
            :ref="pdf ? 'form' : 'nonPdfForm'"
            :key="`form-type-newPlastic-pdf-${pdf}`"
            :class="{ pdf, firefox: firefox && loading }"
            :invalid="invalid"
            :isCurrUserDocUser="isCurrUserDocUser"
          />
          <FormTypeSalary
            v-if="document.type.type === 'salary'"
            :d="document"
            :employees="employees"
            :nkpd="nkpd"
            :supervisors="supervisors"
            :supervisorsTwo="supervisorsTwo"
            @fetch-supervisors="getSupervisors"
            @fetch-filtered-supervisors="getSupervisorsTwo($event)"
            @fetch-employees="getEmployees"
            :pdf="pdf"
            :ref="pdf ? 'form' : 'nonPdfForm'"
            :key="`form-type-salary-pdf-${pdf}`"
            :class="{ pdf, firefox: firefox && loading }"
            :invalid="invalid"
            :isCurrUserDocUser="isCurrUserDocUser"
            :isCurrUserDocHr="isCurrUserDocHr"
            :isCurrUserDocReceiver="isCurrUserDocReceiver"
            :isCurrUserDocReceiverTwo="isCurrUserDocReceiverTwo"
          />
          <FormTypePositionAgreement
            v-if="document.type.type === 'positionAgreement'"
            :d="document"
            :nkpd="nkpd"
            :employees="employees"
            @fetch-employees-from-trdocs="getEmployeesFromTrdocs($event, 'position')"
            @fetch-employees="getEmployees"
            :pdf="pdf"
            :ref="pdf ? 'form' : 'nonPdfForm'"
            :key="`form-type-positionAgreement-pdf-${pdf}`"
            :class="{ pdf, firefox: firefox && loading }"
            :invalid="invalid"
            :autoFillFields="document._id ? document.parent : autoFillFields"
            @get-parent-trdoc="getParentTrdoc"
            :isCurrUserDocUser="isCurrUserDocUser"
            :isCurrUserDocHr="isCurrUserDocHr"
            :editMode="editMode"
            :canEdit="currentUser.roles.admin || currentUser.roles.hr"
          />
          <FormTypePosition
            v-if="document.type.type === 'position'"
            :d="document"
            :employees="employees"
            :supervisors="supervisors"
            :supervisorsTwo="supervisorsTwo"
            :nkpd="nkpd"
            @fetch-supervisors="getSupervisors"
            @fetch-filtered-supervisors="getSupervisorsTwo($event, true)"
            @fetch-employees="getEmployees"
            :pdf="pdf"
            :ref="pdf ? 'form' : 'nonPdfForm'"
            :key="`form-type-position-pdf-${pdf}`"
            :class="{ pdf, firefox: firefox && loading }"
            :invalid="invalid"
            :isCurrUserDocUser="isCurrUserDocUser"
            :isCurrUserDocHr="isCurrUserDocHr"
            :isCurrUserDocReceiver="isCurrUserDocReceiver"
            :isCurrUserDocReceiverTwo="isCurrUserDocReceiverTwo"
          />
          <FormTypeSalaryAgreement
            v-if="document.type.type === 'salaryAgreement'"
            :d="document"
            :employees="employees"
            @fetch-employees-from-trdocs="getEmployeesFromTrdocs($event, 'salary')"
            @fetch-employees="getEmployees"
            :pdf="pdf"
            :ref="pdf ? 'form' : 'nonPdfForm'"
            :key="`form-type-salaryAgreement-pdf-${pdf}`"
            :class="{ pdf, firefox: firefox && loading }"
            :invalid="invalid"
            :autoFillFields="document._id ? document.parent : autoFillFields"
            @get-parent-trdoc="getParentTrdoc"
            :isCurrUserDocUser="isCurrUserDocUser"
            :isCurrUserDocHr="isCurrUserDocHr"
            :editMode="editMode"
            :canEdit="currentUser.roles.admin || currentUser.roles.hr"
          />
          <FormTypeScoreCardPersonal
            v-if="document.type.type === 'scoreCardPersonal'"
            :d="document"
            :employees="employees"
            @fetch-employees="getEmployees"
            :pdf="pdf"
            :ref="pdf ? 'form' : 'nonPdfForm'"
            :key="`form-type-scoreCardPersonal-pdf-${pdf}`"
            :class="{ pdf, firefox: firefox && loading }"
            :invalid="invalid"
            :isCurrUserDocUser="isCurrUserDocUser"
            :isCurrUserUserSupervisorTwo="isCurrUserUserSupervisorTwo"
          />
          <FormTypeScoreCardExpert
            v-if="document.type.type === 'scoreCardExpert'"
            :d="document"
            :employees="employees"
            @fetch-employees="getEmployees"
            :pdf="pdf"
            :ref="pdf ? 'form' : 'nonPdfForm'"
            :key="`form-type-scoreCardExpert-pdf-${pdf}`"
            :class="{ pdf, firefox: firefox && loading }"
            :invalid="invalid"
            :isCurrUserDocUser="isCurrUserDocUser"
            :isCurrUserUserSupervisorTwo="isCurrUserUserSupervisorTwo"
          />
          <FormTypeScoreCardSupervisor
            v-if="document.type.type === 'scoreCardSupervisor'"
            :d="document"
            :employees="employees"
            @fetch-employees="getEmployees"
            :pdf="pdf"
            :ref="pdf ? 'form' : 'nonPdfForm'"
            :key="`form-type-scoreCardSupervisor-pdf-${pdf}`"
            :class="{ pdf, firefox: firefox && loading }"
            :invalid="invalid"
            :isCurrUserDocUser="isCurrUserDocUser"
            :isCurrUserUserSupervisorTwo="isCurrUserUserSupervisorTwo"
          />
          <FormTypeScoreCardStudent
            v-if="document.type.type === 'scoreCardStudent'"
            :d="document"
            :employees="employees"
            :supervisorsTwo="supervisorsTwo"
            @fetch-employees="getEmployees"
            @fetch-filtered-supervisors="getSupervisorsTwo($event, true)"
            :pdf="pdf"
            :ref="pdf ? 'form' : 'nonPdfForm'"
            :key="`form-type-scoreCardStudent-pdf-${pdf}`"
            :class="{ pdf, firefox: firefox && loading }"
            :invalid="invalid"
            :isCurrUserDocReceiverTwo="isCurrUserDocReceiverTwo"
            :isCurrUserDocReceiverThree="isCurrUserDocReceiverThree"
            :canEdit="currentUser.roles.admin"
          />
          <FormTypeRetirement
            v-if="document.type.type === 'retirement'"
            :d="document"
            :employees="employees"
            :supervisors="supervisors"
            :supervisorsTwo="supervisorsTwo"
            @fetch-employees="getEmployees"
            @fetch-supervisors="getSupervisors"
            @fetch-filtered-supervisors="getSupervisorsTwo($event, true)"
            :pdf="pdf"
            :ref="pdf ? 'form' : 'nonPdfForm'"
            :key="`form-type-retirement-pdf-${pdf}`"
            :class="{ pdf, firefox: firefox && loading }"
            :invalid="invalid"
            :isCurrUserDocUser="isCurrUserDocUser"
            :isCurrUserDocHr="isCurrUserDocHr"
            :isCurrUserDocReceiver="isCurrUserDocReceiver"
            :isCurrUserDocReceiverTwo="isCurrUserDocReceiverTwo"
            :canEdit="currentUser.roles.admin"
          />
          <FormTypeSchoolOtpusk
            v-if="document.type.type === 'schoolOtpusk'"
            :d="document"
            :employees="employees"
            @fetch-employees="getEmployees"
            :pdf="pdf"
            :ref="pdf ? 'form' : 'nonPdfForm'"
            :key="`form-type-schoolOtpusk-pdf-${pdf}`"
            :class="{ pdf, firefox: firefox && loading }"
            :invalid="invalid"
            @get-parent-trdoc="getParentTrdoc"
            :isCurrUserDocMainReceiver="isCurrUserDocMainReceiver"
            :isCurrUserDocHr="isCurrUserDocHr"
            :editMode="editMode"
            :canEdit="currentUser.roles.admin || currentUser.roles.hr"
          />
          <FormTypeMentor
            v-if="document.type.type === 'mentor'"
            :d="document"
            :employees="employees"
            @fetch-employees="getEmployees"
            :pdf="pdf"
            :ref="pdf ? 'form' : 'nonPdfForm'"
            :key="`form-type-mentor-pdf-${pdf}`"
            :class="{ pdf, firefox: firefox && loading }"
            :invalid="invalid"
            @get-parent-trdoc="getParentTrdoc"
            :isCurrUserDocUser="isCurrUserDocUser"
            :isCurrUserDocHr="isCurrUserDocHr"
            :editMode="editMode"
            :canEdit="currentUser.roles.admin || currentUser.roles.hr"
          />
          <FormTypeMentorNew
            v-if="document.type.type === 'mentorNew'"
            :d="document"
            :employees="employees"
            @fetch-employees="getEmployees"
            :pdf="pdf"
            :ref="pdf ? 'form' : 'nonPdfForm'"
            :key="`form-type-mentorNew-pdf-${pdf}`"
            :class="{ pdf, firefox: firefox && loading }"
            :invalid="invalid"
            :isCurrUserDocUser="isCurrUserDocUser"
            :isCurrUserDocHr="isCurrUserDocHr"
          />
        </template>
        <button
          @click="isValid() ? (isFormFilled = true) : null"
          v-if="isMobile && !loading && !document.preFinalized && !document.finalized"
        >
          ПРОДЪЛЖИ
        </button>
      </main>
      <aside :class="{ hide: isMobile ? isAsideFormHidden : false }">
        <div class="inputs" v-if="!allToggleOn">
          <Input
            icon="O"
            placeholder="Получател на документа"
            label="Получател на документа"
            type="dropdown"
            prop="name"
            propTwo="number"
            :list="document.type.type === 'manualUpload' ? employees : validReceivers"
            :identifier="document.type.type === 'manualUpload' ? '_id' : 'email'"
            :invalid="invalid.receiver"
            :locked="isReceiversInputLocked"
            @fetch="getEmployees"
            @input="(invalid.receiver = false), getEmployees"
            v-model="document.receiver"
          />
          <Input
            icon="0"
            placeholder="Категория"
            label="Категория документ"
            type="dropdown"
            :list="getCategoryNames"
            :invalid="invalid.category"
            :locked="document.type.type === 'manualUpload' ? !currentUser.roles.hr : true"
            @input="
              invalid.category = false;
              setNumberPrefix();
            "
            v-model="document.category"
          />
          <router-link
            class="parent-link"
            v-if="document.parent"
            :to="`/?openDocument=${document.parent._id}`"
            target="_blank"
          >
            <Input
              icon="a"
              label="Свързано заявление"
              :autoFill="true"
              :value="`${document.parent ? this.$options.filters.formatDate(document.parent.createdAt) : ''}`"
              :locked="true"
            />
          </router-link>
          <template v-if="document.type.type === 'salaryAgreement' || document.type.type === 'positionAgreement'">
            <Input
              v-if="canAddConfirmDocument"
              icon="0"
              placeholder="Изберете документ"
              label="Свързан документ"
              type="dropdown"
              prop="type"
              arrayProp="name"
              :list="manualDocsList"
              :disabled="!document.form.user._id || !(currentUser.roles.admin || currentUser.roles.hr)"
              :identifier="document.confirmDocument ? '_id' : false"
              :invalid="invalid.confirmDocument"
              @fetch="getManualDocs($event)"
              @input="(invalid.confirmDocument = false), getManualDocs($event)"
              v-model="document.confirmDocument"
            />
            <router-link
              v-else-if="document.confirmDocument && document.confirmDocument.preFinalized"
              :to="`/api/trdocs/file/${document.confirmDocument._id}#toolbar=0&navpanes=0`"
              target="_blank"
              class="parent-link"
            >
              <Input
                icon="0"
                placeholder="Изберете документ"
                label="Свързан документ"
                :locked="true"
                :value="document.confirmDocument.type.name"
              />
            </router-link>
          </template>
          <template
            v-if="
              document.type.type === 'salary' ||
              document.type.type === 'salaryAgreement' ||
              document.type.type === 'positionAgreement'
            "
          >
            <Input
              v-if="canAddConfirmDocument"
              icon="0"
              placeholder="Карта за оценка"
              label="Свързани документи"
              type="dropdown"
              prop="type"
              arrayProp="name"
              :list="scoreCardsForSalaryList"
              :disabled="!document.form.user._id"
              :identifier="document.scoreCard ? '_id' : false"
              :invalid="invalid.scoreCard"
              @fetch="getScoreCards($event)"
              @input="(invalid.scoreCard = false), getScoreCards($event)"
              v-model="document.scoreCard"
            />
            <!-- :disabled="!document.form.user._id || !(currentUser.roles.admin || currentUser.roles.hr)" -->
            <!-- v-else-if="(document.scoreCard && document.scoreCard.preFinalized) || (document.confirmDocument && document.confirmDocument.preFinalized)" -->
            <!-- v-else-if="document.scoreCard || (document.confirmDocument && document.confirmDocument.preFinalized)" -->
            <router-link
              v-else-if="document.scoreCard && !canAddConfirmDocument"
              :to="`/api/trdocs/file/${document.scoreCard._id}#toolbar=0&navpanes=0`"
              target="_blank"
              class="parent-link"
            >
              <Input
                icon="0"
                placeholder="Изберете документ"
                label="Свързан документ"
                :locked="true"
                :value="document.scoreCard.type.name"
              />
            </router-link>
          </template>
          <template v-if="document.type.type === 'schoolOtpusk'">
            <p class="linked-docs-title">Свързани документи</p>
            <Input
              icon="a"
              placeholder="Уверение от ВУЗ"
              label="Свързани документи 1"
              type="file"
              :invalid="invalid.filesVuz"
              :locked="document._id"
              @input="(invalid.filesVuz = false), handleFiles('vuz', $event)"
              :selectedFiles="uploadFiles.vuz"
              :uploadedFiles="document.vuzFiles"
            />
            <Input
              icon="a"
              placeholder="Диплома + приложения"
              label="Свързани документи 2"
              type="file"
              :invalid="invalid.filesDiploma"
              :locked="document._id"
              @input="(invalid.filesDiploma = false), handleFiles('diploma', $event)"
              :selectedFiles="uploadFiles.diploma"
              :uploadedFiles="document.diplomaFiles"
            />
            <Input
              v-if="!document._id"
              icon="0"
              placeholder="Карта за оценка"
              label="Свързани документи 3"
              type="dropdown"
              prop="type"
              arrayProp="name"
              :list="studentScoreCardList"
              :disabled="!document.form.user._id"
              :identifier="document.scoreCard ? '_id' : false"
              :invalid="invalid.scoreCard"
              @fetch="getStudentScoreCards($event)"
              @input="(invalid.scoreCard = false), getStudentScoreCards($event)"
              v-model="document.scoreCard"
            />
            <!-- :disabled="!document.form.user._id || !(currentUser.roles.admin || currentUser.roles.hr)" -->
            <router-link
              v-else
              :to="`/api/trdocs/file/${document.scoreCard._id}#toolbar=0&navpanes=0`"
              target="_blank"
              class="parent-link"
            >
              <Input
                icon="0"
                placeholder="Изберете документ"
                label="Свързан документ 3"
                :locked="true"
                :value="document.scoreCard.type.name"
              />
            </router-link>
          </template>
        </div>
        <div v-else-if="allToggleOn && multipleFiles.length">
          <Checkbox label="Без име на документа" type="switch" @input="toggleAll('name', $event)" />
          <Checkbox
            label="Категорията е една и съща"
            v-model="sameCategoryForAll"
            type="switch"
            @input="toggleAll('category', $event)"
          />
          <Checkbox
            label="Всички служители трябва да се запознаят с документите"
            type="switch"
            @input="toggleAll('needsConfirmation', $event)"
          />
          <template v-if="isMultipleEmployees === 'selectManually'">
            <Checkbox
              label="Бр. служители"
              v-model="isMultipleEmployees"
              type="switch"
              @input="toggleAll('sameEmployees', $event)"
            />
            <ToggleSize
              :disabled="!isMultipleEmployees"
              v-model="multipleEmployees"
              @clear="multipleEmployees = []"
              :maxSize="999"
              @add="addToMultipleEmployees"
            />
          </template>
        </div>
        <section class="signatures-and-button" v-if="allToggleOn ? multipleFiles.length : true">
          <DocumentSignatures
            :d="document"
            v-if="document.type.type !== 'manualUpload'"
            @selected="
              document.confirmDocument && $event ? (isAgreementModalOpen = $event) : (confirmSelectedModal = $event)
            "
          />
          <button
            :disabled="loading"
            @click="reSave"
            v-if="
              document.finalized && !document.signedWithCertificate && (currentUser.roles.admin || currentUser.roles.hr)
            "
          >
            ПРЕЗАПИСВАНЕ
          </button>
          <button
            :disabled="loading"
            @click="editMode = true"
            v-if="
              !document.finalized &&
              document.preFinalized &&
              !document.signedWithCertificate &&
              !editMode &&
              currentUser.roles.admin
            "
          >
            РЕДАКТИРАЙ
          </button>
          <button
            :disabled="loading"
            @click="save"
            v-if="
              (!document.finalized &&
                !document.preFinalized &&
                !(!document.form.approved && document.form.signatures.hr && stageTwoDone)) ||
              (editMode && !document.finalized)
            "
          >
            ЗАПИШИ
          </button>
          <template v-else-if="!document.finalized && document.preFinalized && !document.signedWithCertificate">
            <p class="nexu-error" v-if="typeof isNexuReady === 'boolean' && !isNexuReady">
              NexU не е стартиран! Стартирайте и отворете документа отново. Ако го нямате, изтеглете от
              <a href="https://github.com/nowina-solutions/nexu/releases/download/nexu-1.22/nexu-bundle-1.22.zip"
                >тук</a
              >
            </p>
            <button v-if="currentUser._id === document.receiver._id" :disabled="signLoading" @click="sign">
              ПОДПИШИ С КЕП
            </button>
          </template>
          <button class="button--print" :disabled="loading" @click="togglePrint" v-if="document.finalized && !editMode">
            ПЕЧАТ
          </button>
        </section>
      </aside>
    </section>
    <CreateDocumentModalConfirm
      v-if="isCreateDocumentModalConfirmOpen"
      question="Моля, изберете как да се попълват данните в споразумението?"
      yes="АВТОМАТИЧНО, ОТ ЗАЯВЛЕНИЕТО"
      no="РЪЧНО ОТ ЧР"
      @selected="
        autoFillFields = $event;
        isCreateDocumentModalConfirmOpen = false;
      "
    />
    <CreateDocumentModalConfirm
      v-if="decisionModalOpen"
      question="След като свалите и прегледате файла, трябва да изберете дали сте съгласни или не."
      yes="СЪГЛАСЕН СЪМ"
      no="НЕ СЪМ СЪГЛАСЕН"
      :link="decisionModalOpen"
      @selected="confirmSelectedModal = $event"
    />
    <CreateDocumentModalConfirm
      v-if="typeof confirmSelectedModal === 'boolean'"
      :question="
        confirmSelectedModal
          ? `<span style=\'color: #35AADF\'>Вие приехте споразумението${
              document.confirmDocument ? ' и потвърдихте, че сте се запознали с Длъжностната характеристика' : '.'
            }</span> <br />Моля, потвърдете избора си`
          : '<span style=\'color: #8F0000\'>Вие НЕ приехте споразумението.</span><br />Моля, потвърдете избора си'
      "
      html="true"
      yes="ПОТВЪРЖДАВАМ"
      no="ПРОМЕНИ ИЗБОРА"
      @selected="userDecision($event)"
    />
    <CreateDocumentModalConfirm
      v-if="isAgreementModalOpen"
      yes="ЗАПОЗНАХ СЕ"
      :question="`Моля, потвърдете, че сте се запознали с прикачения документ`"
      :post="`<p>Запознах се с ${
        document.confirmDocument.type.name
      }, на <span style=\'color: #35AADF\'> ${this.$options.filters.formatDate(new Date())} </span></p>`"
      html="true"
      @selected="(isAgreementModalOpen = false), (confirmSelectedModal = true)"
    />
    <!-- Modal for save confirmation or to bring the user back for changes in the form  -->
    <transition name="fade" mode="out-in">
      <CreateDocumentModalConfirm
        v-if="confirmSaving && confirmSaving !== 'confirmed'"
        :question="
          confirmSaving === 'files' ? 'Не е прикачен свързан документ' : 'Не са попълнени всички данни в бланката'
        "
        :yes="`⬅️ ВЪРНИ СЕ И ${confirmSaving === 'files' ? 'ПРИКАЧИ' : 'РЕДАКТИРАЙ'}`"
        no="ПРОДЪЛЖИ И ЗАПИШИ ➡️"
        :noDanger="true"
        @selected="confirmSavingAction"
      />
    </transition>
  </div>
</template>

<script>
import pdfMake from 'pdfmake';
import html2canvas from 'html2canvas';
// import { toBlob } from 'dom-to-image';
import { mapGetters } from 'vuex';
import Checkbox from '@/components/inputs/Checkbox';
import Input from './CreateDocumentInput.vue';
import ToggleSize from './ToggleSize';
import categories from '@/data/trdocCategories.json';
import carBrands from '@/data/carBrands.json';
import nkpd from '@/data/nkpd.json';
import FormTypeEmail from './documentForms/FormTypeEmail.vue';
import FormTypePersonal from './documentForms/FormTypePersonal.vue';
import FormTypeIntroduction from './documentForms/FormTypeIntroduction.vue';
import FormTypeIntroductionChange from './documentForms/FormTypeIntroductionChange.vue';
import FormTypeJoining from './documentForms/FormTypeJoining.vue';
import FormTypeHelping from './documentForms/FormTypeHelping.vue';
import FormTypeNewPlastic from './documentForms/FormTypeNewPlastic.vue';
import FormTypeSalary from './documentForms/FormTypeSalary.vue';
import FormTypePositionAgreement from './documentForms/FormTypePositionAgreement.vue';
import FormTypePosition from './documentForms/FormTypePosition.vue';
import FormTypeSalaryAgreement from './documentForms/FormTypeSalaryAgreement.vue';
import FormTypeManualUpload from './documentForms/FormTypeManualUpload.vue';
import FormTypeMultipleManual from './documentForms/FormTypeMultipleManual.vue';
import FormTypeScoreCardPersonal from './documentForms/FormTypeScoreCardPersonal.vue';
import FormTypeScoreCardExpert from './documentForms/FormTypeScoreCardExpert.vue';
import FormTypeScoreCardSupervisor from './documentForms/FormTypeScoreCardSupervisor.vue';
import FormTypeScoreCardStudent from './documentForms/FormTypeScoreCardStudent.vue';
import FormTypeRetirement from './documentForms/FormTypeRetirement.vue';
import FormTypeMentor from './documentForms/FormTypeMentor.vue';
import FormTypeMentorNew from './documentForms/FormTypeMentorNew.vue';
import FormTypeSchoolOtpusk from './documentForms/FormTypeSchoolOtpusk.vue';
import CreateDocumentModalConfirm from './CreateDocumentModalConfirm.vue';
import DocumentSignatures from './DocumentSignatures.vue';
import receivers from '../../data/trdocReceivers.json';
import axios from 'axios';
import { isValid, parse } from 'date-fns';
export default {
  components: {
    Input,
    FormTypeEmail,
    FormTypePersonal,
    FormTypeIntroduction,
    FormTypeIntroductionChange,
    FormTypeJoining,
    FormTypeHelping,
    FormTypeNewPlastic,
    FormTypeSalary,
    FormTypePositionAgreement,
    FormTypePosition,
    FormTypeSalaryAgreement,
    FormTypeManualUpload,
    FormTypeMultipleManual,
    FormTypeScoreCardPersonal,
    FormTypeScoreCardExpert,
    FormTypeScoreCardSupervisor,
    FormTypeScoreCardStudent,
    FormTypeMentor,
    FormTypeMentorNew,
    FormTypeSchoolOtpusk,
    CreateDocumentModalConfirm,
    FormTypeRetirement,
    DocumentSignatures,
    Checkbox,
    ToggleSize,
  },
  props: ['id', 'type', 'files', 'allToggleOn'],
  watch: {
    allToggleOn() {
      this.file = null;
      this.multipleFiles = [];
    },
  },
  computed: {
    ...mapGetters(['currentUser']),
    getCategoryNames() {
      return Object.keys(this.categories);
    },
    isMainFormHidden() {
      if (this.document.finalized) return true;
      else return this.isFormFilled;
    },
    isAsideFormHidden() {
      if (this.document.finalized) return false;
      else return !this.isFormFilled;
    },
    isCurrUserDocUser() {
      if (this.document.form.user) {
        return this.document.form.user._id === this.currentUser._id;
      }
      return false;
    },
    isCurrUserDocHr() {
      return this.currentUser.number === '3553';
    },
    isCurrUserDocMainReceiver() {
      if (this.document.receiver) {
        return this.currentUser._id === this.document.receiver._id;
      }
      return false;
    },
    isCurrUserDocReceiver() {
      if (this.document.form.receiver) {
        return this.currentUser._id === this.document.form.receiver._id;
      }
      return false;
    },
    isCurrUserDocReceiverTwo() {
      if (this.document.form.receiverTwo) {
        return this.currentUser._id === this.document.form.receiverTwo._id;
      }
      return false;
    },
    isCurrUserUserSupervisorTwo() {
      if (this.document.form.user.supervisorSecond) {
        return this.currentUser._id === this.document.form.user.supervisorSecond._id;
      }
      return false;
    },
    isCurrUserDocReceiverThree() {
      if (this.document.form.receiverThree) {
        return this.currentUser._id === this.document.form.receiverThree._id;
      }
      return false;
    },
    validReceivers() {
      return ['salaryAgreement', 'positionAgreement', 'schoolOtpusk'].indexOf(this.document.type.type) > -1
        ? this.receivers.filter((x) => x.canSign)
        : this.receivers;
    },
    isReceiversInputLocked() {
      //temp
      if (this.document.type.type === 'manualUpload') return this.currentUser.roles.hr ? false : true;

      if (['salaryAgreement', 'positionAgreement'].indexOf(this.document.type.type) > -1) {
        return this.document.form.signatures.hr ? true : false;
      }
      if (['schoolOtpusk'].indexOf(this.document.type.type) > -1) {
        return this.currentUser.roles.hr ? false : true;
      }

      return true;
    },
    canAddConfirmDocument() {
      if (
        (this.document.scoreCard && this.document.scoreCard.preFinalized) ||
        (this.document.confirmDocument && this.document.confirmDocument.preFinalized)
      )
        return false;
      else if (this.document.type.type === 'salary') return !this.stageTwoDone;
      else if (this.document.type.type !== 'salary') return !this.document.preFinalized;

      return true;
    },
  },
  data() {
    return {
      categories,
      carBrands,
      nkpd,
      receivers,
      firefox: navigator.userAgent.toLowerCase().indexOf('firefox') > -1,
      isNexuReady: null,
      stageTwoDone: false,
      sameCategoryForAll: false,
      stageThreeDone: false,
      editMode: false, // hide pdf and enable form for another edit pre digital signing
      printMode: false,
      signLoading: false,
      signTimeout: null,
      isCreateDocumentModalConfirmOpen: false,
      autoFillFields: true,
      isMobile: false,
      decisionModalOpen: false,
      confirmSelectedModal: null,
      isAgreementModalOpen: false,
      isFormFilled: false,
      supervisors: [],
      supervisorsTwo: [],
      manualDocsList: [],
      scoreCardsForSalaryList: [],
      studentScoreCardList: [],
      employees: [],
      loading: false,
      loadingMultipleManual: false,
      confirmSaving: '',
      pdf: null, // auto generated pdf
      file: null, // file for manual uploading instead of auto generated pdf
      multipleFiles: [],
      multipleEmployees: [],
      isMultipleEmployees: false,
      byNumberInput: '',
      exceptNumbersInput: '',
      uploadFiles: {
        vuz: [],
        diploma: [],
      },
      invalid: {
        invalid: false,
        form: {},
      },
      document: {
        receiver: {},
        category: '',
        form: {
          user: {},
          createdAt: new Date(),
          signatures: {
            user: null,
          },
        },
      },
    };
  },
  methods: {
    addToMultipleEmployees() {
      this.multipleEmployees.push({ receiver: this.document.receiver });
    },
    updateFile(index, property, newValue, arr) {
      if (!arr) arr = this.multipleFiles;
      const file = arr[index];
      this.$set(file, property, newValue);
      this.$set(arr, index, file);
    },
    toggleAll(type, v) {
      let arr = this.multipleFiles;
      if (this.isMultipleEmployees && type !== 'name') arr = this.multipleEmployees;
      let cat;
      if (type === 'category') cat = arr[0].category;

      for (let i = 0; i < arr.length; i++) {
        if (type === 'needsConfirmation') {
          this.updateFile(i, 'needsConfirmation', v, arr);
        } else if (type === 'name') {
          if (v) this.updateFile(i, 'docName', arr[i].name.replace(/\.[^/.]+$/, ''), arr);
          else this.updateFile(i, 'docName', '', arr);
        } else if (type === 'category') {
          if (v) this.updateFile(i, 'category', cat, arr);
          // else this.updateFile(i, 'category', '');
        }
      }
    },
    getManualDocs(search) {
      if (this.document.form.user._id) {
        let url = `/trdocs?receiver=${this.document.form.user._id}&needsConfirmation=true`;

        if (this.document.type.type === 'salaryAgreement' || this.document.type.type === 'positionAgreement')
          // for Agreements
          url += '&manual=true';
        else url += '&scoreCard=true'; // for scoreCards

        if (search) url += `&search=${search}`;

        this.$apiService.get(url).then((res) => {
          this.manualDocsList = res.data;
        });
      }
    },
    getScoreCards(search) {
      if (this.document.form.user._id) {
        let url = `/trdocs?receiver=${this.document.form.user._id}`;

        if (this.document.type.type === 'salary') {
          url += '&scoreCardsForSalary=true';
        } else url += '&scoreCards=true';

        if (search) url += `&search=${search}`;

        this.$apiService.get(url).then((res) => {
          this.scoreCardsForSalaryList = res.data;
        });
      }
    },
    getStudentScoreCards(search) {
      if (this.document.form.user._id) {
        let url = `/trdocs?receiver=${this.document.form.user._id}&scoreCardStudent=true`;

        if (search) url += `&search=${search}`;

        this.$apiService.get(url).then((res) => {
          this.studentScoreCardList = res.data;
        });
      }
    },
    setNumberPrefix() {
      this.$set(this.document, 'numberPrefix', this.categories[this.document.category]);
    },
    isMobileMode() {
      if (window.innerWidth < 1280) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    // TODO: unify to one
    getSupervisors(search, filter) {
      let url = `/users/supervisors?filter=${filter}`;
      if (search) url += `&search=${search}`;
      this.$apiService.get(url).then((res) => {
        this.supervisors = res.data;
      });
    },
    getSupervisorsTwo(search) {
      let url = `/users/supervisors-two`;
      if (search) url += `?search=${search}`;
      this.$apiService.get(url).then((res) => {
        this.supervisorsTwo = res.data.map((s) => s.user);
      });
    },
    getEmployees(search) {
      let url = '/users/employees';
      if (search) url += `?search=${search}`;
      this.$apiService.get(url).then((res) => {
        this.employees = res.data;
      });
    },
    async getAllEmployees({ numbers, except } = {}) {
      let url = '/users/employees?all=true';
      if (numbers) url += `&numbers=${numbers.split(' ').join(',')}`;
      if (except) url += `&except=${except.split(' ').join(',')}`;
      const res = await this.$apiService.get(url);
      return res.data;
    },
    getEmployeesFromTrdocs(search, type) {
      let url = `/trdocs/employees/${type}`;
      if (search) url += `?search=${search}`;
      this.$apiService.get(url).then((res) => {
        this.employees = res.data;
      });
    },
    // to here ^
    getParentTrdoc() {
      let url = `/trdocs/parent/${this.document.form.user._id}/`;
      if (this.document.type.type === 'salaryAgreement') url += 'salary';
      else if (this.document.type.type === 'positionAgreement') url += 'position';

      this.$apiService.get(url).then((res) => {
        if (res.data) {
          // stop potential errors (not tested)
          if (!res.data.form.user.structuralUnit) this.$set(res.data.form.user, 'structuralUnit', {});
          if (!res.data.form.user.position) this.$set(res.data.form.user, 'position', {});
          //

          this.$set(this.document, 'parent', res.data);
          this.$snotify.success(
            'Последната молба е заредена и нужните полета ще бъдат автоматично попълнени спрямо нея'
          );
        } else {
          this.$snotify.error('Не може да създадете допълнително споразумение без наличието на заявление');
          this.$emit('close');
        }
      });
    },
    userDecision(decision) {
      if (!decision) {
        if (
          !this.document.finalized &&
          this.document.user === this.currentUser._id &&
          this.document.signedWithCertificate &&
          this.isMobile
        ) {
          this.decisionModalOpen = `/api/trdocs/file/${this.document._id}#toolbar=0&navpanes=0`;
        }
        this.confirmSelectedModal = null;
        return;
      }

      if (this.confirmSelectedModal && this.document.confirmDocument) {
        // only when the document is confirmed
        this.document.confirmDocument.finalizedAt = new Date();
        this.document.confirmDocument.finalized = true;
        this.document.confirmDocument.userDecision = this.confirmSelectedModal;

        this.$apiService
          .put(
            `/trdocs/user-decision/${this.document.confirmDocument._id}?confirmDocument=true`,
            this.document.confirmDocument
          )
          .catch((err) => console.log(err));
      }

      this.document.finalizedAt = new Date();
      this.document.finalized = true;
      this.document.userDecision = this.confirmSelectedModal;
      this.$apiService
        .put(`/trdocs/user-decision/${this.document._id}`, this.document)
        .then(() => {
          this.confirmSelectedModal = null;
          this.decisionModalOpen = null;
          this.getDoc();
        })
        .catch((err) => console.log(err));
    },
    getDoc() {
      this.$apiService
        .get(`/trdocs/${this.id}`)
        .then((res) => {
          this.document = res.data;
          this.$emit('set-type', this.document.type);

          if (typeof this.document.form.approved === 'boolean' && this.document.form.signatures.hr)
            this.stageTwoDone = true;
          if (this.document.form.signatures.receiver) this.stageThreeDone = true;

          if (
            (this.document.type.type === 'mentor' || this.document.type.type === 'mentorNew') &&
            this.document.form.signatures.hr
          ) {
            this.$set(this.document, 'stageTwoDone', true);
          }
          setTimeout(() => {
            if (
              !this.document.finalized &&
              this.document.user === this.currentUser._id &&
              this.document.signedWithCertificate &&
              this.isMobile
            ) {
              this.decisionModalOpen = `/api/trdocs/file/${res.data._id}#toolbar=0&navpanes=0`;
            }
          }, 500);
        })
        .catch((err) => {
          console.log(err);
          this.$snotify.error('Нямате право да отворите този документ');
          this.$emit('close');
        });
    },
    confirmSavingAction(e) {
      if (e) {
        this.confirmSaving = '';
      } else {
        this.confirmSaving = 'confirmed';
        this.save();
      }
    },
    isValid() {
      let output = true;
      if (this.document.type.type !== 'manualUpload') {
        // takes all inputs from current form and loops to check which inputs are empty
        let form = this.$refs.nonPdfForm;

        for (let c of form[0].$children) {
          if (c.$options.name !== 'DocumentInput' || c.nonRequired || c.disabled) continue;

          const wrapper = c.$refs.input;
          const input = c.$refs.input.getElementsByTagName('input')[0] || c.$refs.ta;
          const isInputLengtNotCorrect =
            input.minLength > 0 &&
            input.maxLength > 0 &&
            (input.value.length < input.minLength || input.value.length > input.maxLength);

            const manualDate = input.getAttribute('manualdate');

          const isDateInvalid = (dateString) => {
            const dateFormat = 'dd.MM.yyyy';

            const parsedDate = parse(dateString, dateFormat, new Date());

            const isValidDate = isValid(parsedDate);

            if (!isValidDate) {
              this.$snotify.warning('Попълнете датата във формат ДД.ММ.ГГГГ');
              return true;
            }

            return false;
          };

          if (
            input.placeholder === 'Дата' &&
            ((manualDate && isDateInvalid(input.value)) || isInputLengtNotCorrect)
          ) {
            if (!wrapper.classList.contains('create-document-input--invalid')) {
              wrapper.classList.add('create-document-input--invalid');
            }

            return false;
          }

          // input.className !== 'has-value' skips all dropdowns that have value in them
          // !input.readOnly skips values that are readonly(with autoFill value) and disabled inputs(except inputs with type datepicker and signature)
          if (
            (typeof value !== 'number' &&
              typeof wrapper !== 'number' &&
              !input.value &&
              !input.length &&
              input.className !== 'has-value' &&
              !input.readOnly) ||
            (input.placeholder === 'ЕГН' && input.value.length < 10) ||
            (input.placeholder === 'Код по НКПД' && input.value.length < 8) ||
            (input.type === 'textarea' && (!input.value.trim().length || input.value.trim().length < 5)) // no white spaces and min 5 length of input
          ) {
            if (!wrapper.classList.contains('create-document-input--invalid'))
              wrapper.classList.add('create-document-input--invalid');

            output = false;
          }

          if (input.placeholder === 'ЛК Номер' && isInputLengtNotCorrect) {
            if (!wrapper.classList.contains('create-document-input--invalid')) {
              wrapper.classList.add('create-document-input--invalid');
            }
            this.$snotify.warning('Броят символи в полето за ЛК не е точен. Проверете отново.');

            return false;
          }
        }

        if (this.document.form.email) {
          if (
            !String(this.document.form.email)
              .toLowerCase()
              .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              )
          ) {
            this.$set(this.invalid.form, 'email', true);
            output = false;
          }
          // fancier regex if needed
          // (?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])
        }

        if (this.document.type.type === 'email') {
          const list = [
            this.document.form.email,
            this.document.form.lk.number,
            this.document.form.lk.issuedAt,
            this.document.form.lk.issuedBy,
            this.document.form.lk.validTo,
            this.document.form.mobile1,
            this.document.form.mobile2,
            this.document.form.city1.bua,
            this.document.form.city1.pk,
            this.document.form.address1,
            this.document.form.city2.bua,
            this.document.form.city2.pk,
            this.document.form.address2,
          ];

          let hasAtLeastOneValue = false;
          let hasMissingValues = false;

          for (let l of list) {
            if (l) {
              hasAtLeastOneValue = true;
            } else {
              hasMissingValues = true;
            }
          }

          if (!hasAtLeastOneValue) {
            this.$snotify.warning('Трябва да попълните поне едно от незадължителните полета!');
            return false;
          }

          if (hasMissingValues && this.confirmSaving !== 'confirmed') {
            this.confirmSaving = 'fields';
            return;
          }
        }

        // if (this.document.type.type === 'introduction') {
        //   if (typeof this.document.form.ownTransport !== 'boolean') {
        //     this.$snotify.warning('Изборът дали идвате или не с лично МПС в т. III е задължителен');
        //     return false;
        //   } else if (this.document.form.ownTransport) {
        //     if (typeof this.document.form.isDriver !== 'boolean') {
        //       this.$snotify.warning('Трябва да изберете дали управлявате сами или не личното МПС');
        //       return false;
        //     }

        //     if (!this.document.form.vehicles.filter((v) => v.enabled).length) {
        //       this.$snotify.warning('Трябва да добавите поне едно превозно средство');
        //       return false;
        //     }
        //   }
        // }

        if (this.document.type.type === 'position' || this.document.type.type === 'salary') {
          if (typeof this.document.form.approved !== 'boolean' && this.isCurrUserDocHr && this.document._id) {
            this.$snotify.warning('Изборът на УЧР, ОДОБРЯВА/НЕ ОДОБРЯВА е задължителен');
            return false;
          } else if (
            this.document.form.signatures.receiver &&
            typeof this.document.form.approvedByRecieverTwo !== 'boolean' &&
            this.isCurrUserDocReceiverTwo &&
            this.stageThreeDone
          ) {
            this.$snotify.warning('Изборът на РЪКОВОДИТЕЛ 2, ОДОБРЯВАМ/НЕ ОДОБРЯВАМ е задължителен');
            return false;
          }

          if (
            !this.document.scoreCard &&
            this.confirmSaving !== 'confirmed' &&
            this.document.type.type === 'salary' &&
            !this.document.finalized &&
            (this.isMobile ? this.isFormFilled : true)
          ) {
            this.confirmSaving = 'files';
            return;
          }
        }

        if (
          (this.document.type.type === 'positionAgreement' ||
            (this.document.type.type === 'salaryAgreement' && (this.isMobile ? this.isFormFilled : true))) &&
          // this.document.form.signatures.hr &&
          !this.document.confirmDocument &&
          this.confirmSaving !== 'confirmed'
        ) {
          this.confirmSaving = 'files';
          return;
          // this.$snotify.warning('Не сте прикачили свързан документ');
          // this.$snotify.warning('Трябва да изберете свързан документ');
          // this.$set(this.invalid, 'confirmDocument', true);
          // return false;
        }
        if (
          (this.document.type.type === 'mentor' || this.document.type.type === 'mentorNew') &&
          this.isCurrUserDocUser &&
          this.document.stageTwoDone &&
          typeof this.document.form.approved !== 'boolean'
        ) {
          this.$snotify.warning('Изборът на Служителя, СЪГЛАСЕН СЪМ/НЕ СЪМ СЪГЛАСЕН е задължителен');
          return false;
        }

        if (this.document.type.type === 'schoolOtpusk') {
          if (this.isCurrUserDocHr && this.document._id && typeof this.document.form.approved !== 'boolean') {
            this.$snotify.warning('Изборът на рък УЧР, ОДОБРЯВАМ/НЕ ОДОБРЯВАМ е задължителен');
            return false;
          }

          if (
            this.isCurrUserDocMainReceiver &&
            this.document.form.signatures.hr &&
            typeof this.document.form.approvedByReciever !== 'boolean'
          ) {
            this.$snotify.warning('Изборът на Изпълнителния директор, РАЗРЕШАВАМ/НЕ РАЗРЕШАВАМ е задължителен');
            return false;
          }
        }

        if (!this.document.receiver.email) {
          this.$set(this.invalid, 'receiver', true);
          output = false;
        }
        if (!this.document.category) {
          this.$set(this.invalid, 'category', true);
          output = false;
        }

        if (this.document.type.type === 'retirement') {
          if (typeof this.document.form.hasUsedPaidLeave !== 'boolean') {
            this.$snotify.warning('Изборът в т. I. Ползване на неплатен отпуск през годините е задължителен');
            return false;
          } else if (typeof this.document.form.selfExcommunications !== 'boolean') {
            this.$snotify.warning('Изборът в т. II. Допускане на самоотлъчки е задължителен');
            return false;
          }
        }

        if (
          ['scoreCardStudent', 'scoreCardPersonal', 'scoreCardExpert', 'scoreCardSupervisor'].indexOf(
            this.document.type.type
          ) > -1
        ) {
          let size = Object.keys(this.document.form.scores).length;

          if (['scoreCardPersonal', 'scoreCardExpert', 'scoreCardSupervisor'].indexOf(this.document.type.type) > -1) {
            let neededScoresSize = 18;

            if (this.document.type.type === 'scoreCardSupervisor') neededScoresSize = 24;

            if (!this.document._id && size !== neededScoresSize) {
              this.$snotify.warning('Не сте дали вашите оценки');
              return false;
            } else if (!this.document.form.scoreType) {
              this.$snotify.warning('Моля изберете "Вид на оценката"');
              return false;
            } else if (this.document._id && typeof this.document.form.approvedByUser !== 'boolean') {
              this.$snotify.warning('Моля изберете, дали сте съгласни с оценката ви');
              return false;
            }
          } else if (this.document.type.type === 'scoreCardStudent') {
            if (!this.document._id && size !== 12) {
              this.$snotify.warning('Не сте дали вашите оценки (Рък 1)');
              return false;
            } else if (this.document._id && this.isCurrUserDocReceiverTwo && size !== 24) {
              this.$snotify.warning('Не сте дали вашите оценки (Рък 2)');
              return false;
            }
          }
        }
        if (this.document.type.type === 'schoolOtpusk' && (this.isMobile ? this.isFormFilled : true)) {
          if (!this.document._id) {
            if (!this.uploadFiles.vuz.length) {
              this.$set(this.invalid, 'filesVuz', true);
              output = false;
            }
            if (!this.uploadFiles.diploma.length) {
              this.$set(this.invalid, 'filesDiploma', true);
              output = false;
            }
            if (!this.document.scoreCard) {
              this.$set(this.invalid, 'scoreCard', true);
              output = false;
            }
          }
        }

        if (this.document.type.type === 'introductionChange') {
          const list = [this.document.form.partnerName, this.document.form.partnerJob];

          let hasMissingValues = false;

          for (let l of list) {
            if (!l) {
              hasMissingValues = true;
              break;
            }
          }

          if (
            (this.document.form.vehicles ? !this.document.form.vehicles.length : false) ||
            (this.document.form.children ? !this.document.form.children.length : false)
          ) {
            hasMissingValues = true;
          }

          if (hasMissingValues && this.confirmSaving !== 'confirmed') {
            this.confirmSaving = 'fields';
            return;
          }
        }
      } else {
        if (this.allToggleOn) {
          for (let i = 0; i < this.multipleFiles.length; i++) {
            let f = this.multipleFiles[i];

            if (!f.docName) {
              this.updateFile(i, 'invalidFilename', true);
              output = false;
            }
            if (!this.isMultipleEmployees) {
              if (!f.receiver) {
                this.updateFile(i, 'invalidReceiver', true);
                output = false;
              }
            }
          }
        } else {
          if (!this.file) {
            this.$set(this.invalid, 'file', true);
            output = false;
          }
          if (!this.document.type.name) {
            this.$set(this.invalid, 'filename', true);
            output = false;
          }
        }
      }

      if (!output) {
        this.confirmSaving = '';
        this.$snotify.warning('Не са попълнени всички задължителни полета');
      }

      return output;
    },
    validateFile(f) {
      if (f) {
        if (!f.name.toLowerCase().match(/\.(pdf|jpg|png|doc|docx)$/)) {
          this.$snotify.error(
            'Може да качвате само (PDF,DOC,DOCX) файлове или (PNG, JPG) изображения (.pdf, .jpg, .png, .doc, .docx).'
          );
          return false;
        }

        // const maxAllowedMB = 5242880; // 5 MB
        // if (f.size > maxAllowedMB) {
        //   this.$snotify.error('Размера на файла не може да надвишава повече от 5 MB!');
        //   return false;
        // }
        return true;
      }
    },
    deleteFile(i) {
      console.log('delete');
      this.multipleFiles.splice(i, 1);
    },
    async handleFile(f) {
      this.loadingMultipleManual = true;
      const files = [...f];

      if (this.isMultipleEmployees) {
        if (this.isMultipleEmployees === 'selectManually' && this.multipleEmployees.length) {
          this.multipleEmployees.map((f) => {
            f.receiver = this.document.receiver;
            f.category = this.document.category;
          });
        } else {
          this.multipleEmployees = [];

          let employees = [];

          if (this.isMultipleEmployees === 'byNumber') {
            employees = await this.getAllEmployees({ numbers: this.byNumberInput });
          } else if (this.isMultipleEmployees === 'toAll') {
            employees = await this.getAllEmployees();
          } else if (this.isMultipleEmployees === 'exceptNumbers') {
            employees = await this.getAllEmployees({ except: this.exceptNumbersInput });
          }

          if (!employees.length) {
            this.loadingMultipleManual = false;
            return this.$snotify.error('Не са намерени служители');
          } else {
            let msg = `Ще се изпрати до ${employees.length} ${employees.length === 1 ? 'служител' : 'служителя'}`;
            this.$nextTick(() => this.$snotify.warning(msg));
          }

          this.multipleEmployees = employees.map((e) => {
            return {
              receiver: e,
              category: this.document.category,
            };
          });
        }
      }

      if (files && files.length) {
        for (let f of files) {
          if (this.validateFile(f) || !f) {
            if (this.allToggleOn) {
              f.receiver = this.document.receiver;
              f.category = this.document.category;
              this.multipleFiles.push(f);
            } else {
              // this.file = f;
              this.file = f;
              this.invalid.file = false;
            }
          }
        }
      } else {
        // reset
        this.file = files;
        this.invalid.file = false;
      }

      this.loadingMultipleManual = false;
    },
    handleFiles(t, files) {
      this.uploadFiles[t] = [];
      for (let f of files) {
        if (this.validateFile(f)) {
          this.uploadFiles[t].push(f);
        }
      }
    },
    getNumber() {
      return new Promise((res, rej) => {
        this.$apiService
          .get(`/trdocs/get-number/${this.document.numberPrefix}`)
          .then((response) => {
            res(response.data);
          })
          .catch((err) => {
            console.log(err);
            rej(err);
          });
      });
    },
    reSave() {
      this.editMode = true;

      this.$nextTick(() => {
        this.save();
      });
    },

    async uploadMultipleManualDocs() {
      const promises = [];

      if (!this.isMultipleEmployees) {
        for (let f of this.multipleFiles) {
          const fd = new FormData();

          fd.append('file', f);
          const toStr = JSON.stringify(f);
          const toObject = JSON.parse(toStr);

          toObject.form = { user: {} };
          toObject.type = {};
          toObject.type.type = 'manualUpload';
          toObject.type.name = toObject.docName;

          fd.append('data', JSON.stringify(toObject));

          promises.push(
            await new Promise((resolve) => {
              this.$apiService
                .post('/trdocs', fd)
                .then(() => {
                  resolve();
                })
                .catch((err) => {
                  this.error = err.response.data.message;
                });
            })
          );
        }
      } else if (this.isMultipleEmployees && this.multipleEmployees) {
        for (let u of this.multipleEmployees) {
          for (let f of this.multipleFiles) {
            const fd = new FormData();

            this.$set(f, 'receiver', u.receiver);
            this.$set(f, 'category', u.category);

            fd.append('file', f);
            const toStr = JSON.stringify(f);
            const toObject = JSON.parse(toStr);

            toObject.form = { user: {} };
            toObject.type = {};
            toObject.receiver = u.receiver;
            toObject.category = u.category;
            toObject.type.type = 'manualUpload';
            toObject.type.name = toObject.docName;
            toObject.needsConfirmation = u.needsConfirmation;

            fd.append('data', JSON.stringify(toObject));

            promises.push(
              await new Promise((resolve) => {
                this.$apiService
                  .post('/trdocs', fd)
                  .then(() => {
                    resolve();
                  })
                  .catch((err) => {
                    this.error = err.response.data.message;
                  });
              })
            );
          }
        }
      }

      if (promises.length) {
        Promise.all(promises)
          .then(() => {
            this.closeManualDocUpload();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    async save() {
      await this.$nextTick();
      if (this.isValid()) {
        // class create-document-input--saving is for the fake-textarea to show before the PDF generation
        let taInputs = document.getElementsByClassName('create-document-input--textarea');
        if (taInputs) {
          for (let ta of taInputs) {
            ta.classList.add('create-document-input--saving');
          }
        }

        this.loading = true;
        this.isFormFilled = false;

        // if finished get number before pdf render
        if (this.document.type.type !== 'manualUpload') {
          if (!this.document.number) {
            let condition =
              this.document.type.type === 'salary' || this.document.type.type === 'position'
                ? this.document.form.signatures.hr &&
                  this.document.form.signatures.receiver &&
                  this.document.form.signatures.receiverTwo
                : true;

            if (condition) {
              const n = await this.getNumber();
              this.$set(this.document, 'number', n);
            }
          }

          if (this.document.type.type === 'salary' && !this.document.scoreCard && !this.stageTwoDone) {
            this.$snotify.warning('Без документ "Карта за оценка"');
          }
        }

        if (this.document.confirmDocument && !this.document.confirmDocument.preFinalized) {
          // preFinalizes the manualDoc so it cant be chosen in another agreement and cant be confirmed in the docs table
          this.document.confirmDocument.preFinalized = true;

          this.$apiService
            .put(
              `/trdocs/user-decision/${this.document.confirmDocument._id}?prefinalizeConfirmDocument=true`,
              this.document.confirmDocument
            )
            .catch((err) => console.log(err));
        }

        await this.$nextTick();

        const fd = new FormData();

        if (this.document.type.type === 'manualUpload') {
          if (this.allToggleOn) return this.uploadMultipleManualDocs();
          else fd.append('file', this.file);
        } else {
          const element = this.$refs.form;

          if (element.length) {
            const canvas = await html2canvas(element[0].$el);
            const data = canvas.toDataURL();

            const width = canvas.width * 0.75; // in points
            const height = canvas.height * 0.75; // in points

            const docDefinition = {
              pageSize: {
                width: width,
                height: height,
              },
              pageMargins: [10, 10, 10, 10],
              content: [
                {
                  image: data,
                  width: width - 20,
                  height: height - 20,
                },
              ],
            };

            await new Promise((res) => {
              pdfMake.createPdf(docDefinition).getBlob((blob) => {
                fd.append('file', new File([blob], 'form.pdf'));
                res();
              });
            });

            if (this.uploadFiles.vuz) {
              for (let f of this.uploadFiles.vuz) {
                fd.append('vuzFiles', f);
              }
            }
            if (this.uploadFiles.diploma) {
              for (let f of this.uploadFiles.diploma) {
                fd.append('diplomaFiles', f);
              }
            }
          }
          // if (element.length) { // toBlob pdf creation
          //   const cpt = getComputedStyle(element[0].$el);
          //   const opts = {
          //     height: element[0].$el.getBoundingClientRect().height,
          //     width: element[0].$el.getBoundingClientRect().width,
          //     style: {
          //       left: '50%',
          //       right: '50%',
          //       bottom: '50%',
          //       top: '50%',
          //       width: cpt.width,
          //       height: cpt.height,
          //     },
          //   };

          //   if (!this.firefox) {
          //     opts.style.transform = 'translateX(-50%) translateY(-50%) scale(3)';
          //   }

          //   let data;

          //   try {
          //     if (this.firefox) {
          //       // element[0].$el.style.position = 'static';
          //       // element[0].$el.style.transform = 'initial';

          //       await this.$nextTick();
          //     }
          //     data = await toBlob(element[0].$el, opts);

          //     // element[0].$el.style.position = 'absolute'
          //     // data = await toPng(element[0].$el, opts);
          //   } catch (err) {
          //     this.$snotify.error('Нещо се обърка');
          //     return console.log(err);
          //   }
          //   fd.append('file', new File([data], Date.now()));

          //   // const width = opts.width * 0.75; // in points
          //   // const height = opts.height * 0.75; // in points

          //   // const docDefinition = {
          //   //   pageSize: {
          //   //     width: width,
          //   //     height: height,
          //   //   },
          //   //   pageMargins: [10, 10, 10, 10],
          //   //   content: [
          //   //     {
          //   //       svg: data.replace('data:image/svg+xml;charset=utf-8,', ''),
          //   //       width: width - 20,
          //   //       height: height - 20,
          //   //     },
          //   //   ],
          //   // };

          //   // await new Promise((res) => {
          //   //   pdfMake.createPdf(docDefinition).getBlob((blob) => {
          //   //     fd.append('file', new File([blob], 'form.pdf'));
          //   //     res();
          //   //   });
          //   // });
          // }
        }

        // sets finalized if the receiverTwo has approved the doc
        if (this.document.type.type === 'salary' || this.document.type.type === 'position') {
          if (
            this.document.form.signatures.receiverTwo &&
            !this.document.finalized &&
            this.document.form.approvedByRecieverTwo
          ) {
            this.$set(this.document, 'finalized', true);
          }
          if (
            this.document.form.signatures.receiverTwo &&
            !this.document.preFinalized &&
            !this.document.form.approvedByRecieverTwo
          ) {
            this.$set(this.document, 'preFinalized', true);
          }
        }

        fd.append('data', JSON.stringify(this.document)); // this is set before the number fix it

        const method = this.id ? 'put' : 'post';

        this.$apiService[method]('/trdocs', fd)
          .then(() => {
            if (this.document.type.type === 'manualUpload') {
              this.closeManualDocUpload();
            } else {
              if (this.currentUser._id !== this.document.form.user._id) {
                const query = { ...this.$route.query, t: new Date().getTime(), type: 'all' };
                this.$emit('close', { path: this.$route.path, query: query });
              } else {
                this.$emit('close');
              }
            }
          })
          .catch((err) => console.log(err));
      }
    },
    closeManualDocUpload() {
      if (this.$route.name !== 'ManuallyUploaded') {
        let type = 'myManual';
        if (this.currentUser._id !== this.document.form.user._id) type = 'allManual';
        this.$router.push(`/manually-uploaded?type=${type}`).then(this.$emit('close'));
      } else {
        this.$router
          .push({ path: this.$route.path, query: { ...this.$route.query, t: new Date().getTime() } })
          .then(this.$emit('close'));
      }
    },
    async sign() {
      this.signLoading = true;

      let file = await this.$apiService.get(`/trdocs/file/${this.document._id}?buf=1`);

      file = new Blob([new Uint8Array(file.data.data).buffer], { type: 'application/pdf' });
      file = new File([file], 'documentToSign.pdf', { type: 'application/pdf' });

      const fd = new FormData();
      fd.append('documentToSign', file);

      await axios
        .get('http://localhost:9795/nexu-info')
        .then(() => {
          this.isNexuReady = true;
        })
        .catch(() => {
          this.signLoading = false;
          this.isNexuReady = false;
          return;
        });

      await axios.get(`${window.location.origin}/dss/sign-a-pdf`).catch((err) => {
        console.log(err);
      });

      await axios.post(`${window.location.origin}/dss/sign-a-pdf`, fd).catch((err) => {
        console.log(err);
      });

      axios
        .post('http://localhost:9795/rest/certificates')
        .then((crt) => {
          if (crt.data.success) {
            axios
              .post(`${window.location.origin}/dss/sign-a-pdf/get-data-to-sign`, {
                certificateChain: crt.data.response.certificateChain,
                encryptionAlgorithm: crt.data.response.encryptionAlgorithm,
                signingCertificate: crt.data.response.certificate,
              })
              .then((dts) => {
                axios
                  .post('http://localhost:9795/rest/sign', {
                    digestAlgorithm: 'SHA256',
                    keyId: crt.data.response.keyId,
                    tokenId: crt.data.response.tokenId,
                    toBeSigned: {
                      bytes: dts.data.dataToSign,
                    },
                  })
                  .then((s) => {
                    if (s.data.success) {
                      axios
                        .post(`${window.location.origin}/dss/sign-a-pdf/sign-document`, {
                          signatureValue: s.data.response.signatureValue,
                        })
                        .then((sd) => {
                          if (sd.data.urlToDownload) {
                            axios
                              .get(`${window.location.origin}/dss/sign-a-pdf/download`, { responseType: 'blob' })
                              .then((d) => {
                                this.finishSigning(d.data);
                              })
                              .catch((err) => {
                                console.log(err);
                                this.$snotify.error('Нещо се обърка при подписването. Моля опитайте отново');
                              });
                          }
                        })
                        .catch((err) => {
                          console.log(err);
                          this.$snotify.error('Нещо се обърка при подписването. Моля опитайте отново');
                        });
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    this.$snotify.error('Нещо се обърка при подписването. Моля опитайте отново');
                  });
              })
              .catch((err) => {
                console.log(err);
                this.$snotify.error('Нещо се обърка при подписването. Моля опитайте отново');
              });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$snotify.error('Нещо се обърка при избирането на сертификат. Моля опитайте отново');
        });
    },
    finishSigning(signed) {
      let file = new File([signed], 'signed.pdf', { type: 'application/pdf' });

      const fd = new FormData();

      fd.append('file', file);
      fd.append('data', JSON.stringify(this.document));

      this.$apiService
        .post(`/trdocs/finish-signing/${this.document._id}`, fd)
        .then(() => {
          this.$snotify.success('Документът е подписан успешно');
          this.$emit('close');
        })
        .catch((err) => {
          console.log(err);
          this.$snotify.error('Нещо се обърка! Моля опитайте отново');
        });
    },
    togglePrint() {
      this.printMode = true;

      setTimeout(() => {
        this.$nextTick(() => {
          window.print();
        });
      }, 250);
    },
  },
  mounted() {
    this.isMobileMode();
    window.addEventListener('resize', this.isMobileMode);
    if (this.id) this.getDoc();
    else {
      this.$set(this.document, 'type', this.type);

      if (this.document.type.type !== 'manualUpload') {
        this.document.category =
          this.getCategoryNames[['salaryAgreement', 'positionAgreement'].indexOf(this.document.type.type) > -1 ? 1 : 2];
        this.setNumberPrefix();

        if (['salaryAgreement', 'positionAgreement'].indexOf(this.document.type.type) > -1) {
          this.document.receiver = this.validReceivers[0];
        } else if (['salary', 'position'].indexOf(this.document.type.type) > -1) {
          this.document.receiver = this.validReceivers[1];
        } else {
          this.document.receiver = this.validReceivers[0];
        }
      } else if (this.document.type.type === 'manualUpload') {
        if (!this.currentUser.roles.hr) {
          this.document.category = 'Други документи';
          this.setNumberPrefix();
        }
        this.document.receiver = this.currentUser;
      }

      if (this.files) {
        if (this.validateFile(this.files[0])) this.file = this.files[0];
      }

      if (!(this.currentUser.roles.admin || this.currentUser.roles.hr || this.currentUser.roles.supervisor)) {
        if (this.type.type !== 'salaryAgreement' && this.type.type !== 'positionAgreement')
          this.document.form.user = this.currentUser;
      }
      if (
        this.currentUser.roles.hr &&
        ['salaryAgreement', 'positionAgreement', 'mentor', 'mentorNew'].indexOf(this.type.type) > -1
      ) {
        this.document.form.firstHr = this.currentUser.name;
      }
    }

    if (!this.id && (this.type.type === 'salaryAgreement' || this.type.type === 'positionAgreement')) {
      this.isCreateDocumentModalConfirmOpen = true;
    }

    window.onafterprint = () => {
      this.printMode = false;
    };
  },
  destroyed() {
    window.removeEventListener('resize', this.isMobileMode);
    clearTimeout(this.signTimeout);
  },
};
</script>

<style lang="scss">
@import '@/scss/base.scss';
.create-document-form {
  display: flex;
  flex-direction: column;
  gap: 10px;

  button {
    background: #0099ff;
    border-radius: 6px;
    border: 0;
    color: #fff;
    font-size: $px15;
    font-weight: 700;
    height: 40px;
    width: 100%;
    @include hover();

    & + button {
      margin-top: 0.5rem;
    }

    &.button--print {
      background: #064168;
    }

    &:disabled {
      cursor: default;
      opacity: 0.25;
    }
  }

  .form-aside-wrapper {
    display: flex;
    gap: 10px;
    padding: 0 10px 10px;

    &.form-aside-wrapper--multiple {
      flex-direction: column;

      > main {
        width: 100%;
      }

      > aside {
        align-items: center;
        flex-direction: row;
        width: 100%;

        > div {
          align-items: center;
          display: flex;
          gap: 5px;
        }
      }
    }

    > main {
      background: #cad8df;
      border: 1px solid #9eafc1;
      border-radius: 6px;
      height: 80vh;
      overflow: auto;
      position: relative;
      width: calc(100% - 320px);
      @include scrollbar(5px, #9eafc1);
      &.main--manual-document {
        height: initial;
      }
    }

    > aside {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 320px;

      .inputs {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 100%;
      }
      .parent-link {
        position: relative;

        input {
          color: #35aadf;
          text-decoration: underline;
          @include hover();
        }
        // &::before {
        //   content: '';
        //   cursor: pointer;
        //   height: 100%;
        //   top: 0;
        //   position: absolute;
        //   left: 0;
        //   width: 100%;
        //   z-index: 1;
        // }
      }
      .linked-docs-title {
        margin: 1rem 0;
        text-align: center;
        width: 100%;
      }
      .nexu-error {
        color: #c62828;
        margin-bottom: 10px;
        text-align: center;
        width: 100%;

        a {
          color: inherit;
          font-weight: 700;
          text-decoration: underline;
          @include hover();
        }
      }
    }

    .document-form-type {
      padding: 30px;

      .brake {
        align-items: center;
        border-radius: 3px;
        background: #637382;
        color: white;
        display: flex;
        font-size: $px16;
        justify-content: center;
        line-height: $px25;
        padding: 15px;
        text-transform: uppercase;
        margin: 0 -5px 30px -5px;
      }
      .rows-wrapper {
        margin-bottom: 30px;
        &.rows--background {
          background: #acbfd4 0% 0% no-repeat padding-box;
          border-radius: 7px;
          padding: 5px;

          .create-document-input {
            min-height: 40px;
            border: 1px solid #9eafc1;
            border-radius: 0px 6px 6px 0px;
          }

          &.row {
            gap: 5px;
            &:not(:last-child):not(section) {
              margin-bottom: 5px;
            }
          }
          .title {
            padding: 10px 5px;
          }
        }

        .row {
          &:not(:last-child):not(section) {
            margin-bottom: 10px;
          }
        }
      }
      .row {
        display: flex;
        gap: 10px;

        .indicators {
          align-items: center;
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #9eafc1;
          border-radius: 6px 0px 0px 6px;
          display: flex;
          padding: 15px 20px 15px 10px;
          gap: 5px;
          height: 40px;
          span {
            font-weight: 600;
          }
        }
        &.row--no-wrap {
          white-space: nowrap;
        }
        &.wrap {
          font-weight: 600;
          gap: 5px;

          &:not(:last-child):not(section) {
            margin-bottom: 10px;
          }

          .title {
            align-items: center;
            background: #acbfd4 0% 0% no-repeat padding-box;
            border-radius: 6px 0px 0px 6px;
            display: flex;
            padding: 10px;
            font-size: 9px;
          }

          .points {
            background: #004d94 0% 0% no-repeat padding-box;
            color: white;
            justify-content: space-between;
            padding: 0 10px;
            &:not(:first-of-type) {
              border-radius: 0px 6px 6px 0px;
            }
          }
          .box {
            gap: 20px;
            background: #f1f8ff 0% 0% no-repeat padding-box;

            &:not(:first-of-type) {
              background: #f6f5c0 0% 0% no-repeat padding-box;
              border-radius: 0px 6px 6px 0px;
            }
            p {
              flex-grow: 2;
              font-size: 12px;
              line-height: 12px;
            }
            .desc {
              display: flex;
              gap: 5px;

              span {
                align-items: center;
                display: flex;
                font-size: 9px;
                height: 100%;
                justify-content: center;
                line-height: 8px;
                width: 40px;

                &:nth-child(2) {
                  border-left: 2px solid #9eafc1;
                  border-right: 2px solid #9eafc1;
                }
              }
            }
          }
        }
        &.row--score-table {
          gap: 30px;

          p {
            &:not(:nth-of-type(2)) {
              font-weight: bold;
            }
            &:nth-of-type(2) {
              flex-grow: 2;
            }
          }
        }
        &.row--space-between {
          justify-content: space-between;
        }
        &.row--align-center {
          align-items: center;
        }

        &.row--score-sum {
          p {
            background: #004d94 0% 0% no-repeat padding-box;
            border: 1px solid #9eafc1;
            border-radius: 1px;
            color: white;
            padding: 5px 20px;
            margin: 5px 5px 0 0;
          }
        }

        &.row--points {
          background: #ffffff 0% 0% no-repeat padding-box;
          border-radius: none;
          border-bottom: 1px solid #9eafc1;
          border-right: 1px solid #9eafc1;
          border-left: 1px solid #9eafc1;
          padding: 5px;

          &:first-of-type {
            border-radius: 6px 6px 0 0;
            border-top: 1px solid #9eafc1;
          }
          &:last-of-type {
            border-radius: 0 0 6px 6px;
          }

          &:not(:last-child):not(section) {
            margin-bottom: 0px;
          }
        }
        &:not(:last-child):not(section) {
          margin-bottom: 30px;
        }
        &.column {
          align-items: center;
          flex-direction: column;
          width: 100%;
        }
        &.aside--row {
          font-size: $px16;
          line-height: $px25;
          aside {
            margin-right: 15px;
            min-width: 15px;
          }
          > section {
            span {
              &:first-child {
                display: block;
                margin-bottom: 10px;
              }
            }
          }
          .section--aside {
            display: flex;
            margin-bottom: 10px;
          }
        }
        &.checkboxes {
          section {
            display: flex;
            gap: 0.5rem;
            font-weight: bold;
            flex-wrap: wrap;
          }
          p {
            font-size: $px16;
            line-height: $px16;
          }
        }

        text-align: justify;

        &.center {
          justify-content: center;
          text-align: center;
        }

        &.right {
          justify-content: flex-end;
        }

        &.text-center {
          text-align: center;
        }
        &.text-right {
          text-align: right;
        }

        a {
          color: #004d94;
          text-decoration: underline;
        }
        > h1 {
          font-size: $px14 * 2;
          font-weight: 900;
          line-height: $px15 * 2;
          letter-spacing: 5.6px;
        }
        .doc-number {
          background: #9eafc1 0% 0% no-repeat padding-box;
          border-radius: 271px;
          color: white;
          font-size: 12px;
          letter-spacing: 0.12px;
          padding: 0px 20px;
          margin-bottom: 1rem;
        }
        > p {
          font-size: $px16;
          line-height: $px25;
          .underline {
            text-decoration: underline;
          }
          &.smaller-line-height {
            line-height: $px18;
          }
        }
      }

      &:not(.pdf) {
        .row {
          &.first-letter--margin {
            > p {
              &::first-letter {
                margin-left: 2rem;
              }
            }
          }
          &.row--background {
            position: relative;
            &::before {
              background: #c0c4cc;
              border-radius: 7px;
              content: '';
              height: calc(100% + 10px);
              left: -5px;
              position: absolute;
              top: -5px;
              width: calc(100% + 10px);
            }
          }
        }
      }

      &.pdf {
        background-color: #fff;
        overflow: initial;
        position: absolute;
        // transform: scale(3); // toBlob pdf creation
        top: -999999vh;
        z-index: 1;
        // potential fix
        font-variant: normal !important;
        -webkit-font-variant: normal !important;
        font-variant-ligatures: none !important;
        -webkit-font-variant-ligatures: none !important;

        &.firefox {
          position: initial;
          top: initial;
          transform: initial;
        }

        .create-document-input {
          // align-items: flex-start;
          background: transparent;
          border: 0;
          cursor: default;

          .vehicle-toggles,
          .vehicle-toggle {
            display: none;
          }

          &.create-document-input--disabled:not(.create-document-input--locked),
          &.create-document-input--auto-fill {
            opacity: 1;
          }

          > main {
            align-items: flex-start;
            padding: 0;
            > .icon {
              display: none;
            }
            > div {
              input,
              textarea,
              .fake-textarea {
                border-bottom: 1px dashed #000;
                background: transparent;
                margin-bottom: 0;
                padding: 0 0.5rem 0.3rem;
                overflow: hidden;

                -ms-overflow-style: none;
                scrollbar-width: none;
                &::-webkit-scrollbar {
                  display: none;
                }
              }
              p {
                padding: 0.3rem 0.5rem 0;
              }
            }
          }
          .create-document-input__dropdown {
            display: none;
          }
        }

        .row {
          &:not(.row--align-center) {
            .create-document-input {
              align-items: flex-start;
            }
          }
          &.row--background {
            align-items: flex-start;
            .create-document-input-wrapper {
              &:first-child {
                flex-basis: 25%;
                min-width: 115px;
              }
            }
            .create-document-input {
              align-items: flex-end;
              > main {
                align-items: flex-end;
              }
            }
            .fake-input {
              border-bottom: 1px dashed #000;
              padding-right: 100px;
            }
            .signature {
              display: flex;
              flex-direction: column;
              // height: 100%;
              // justify-content: flex-end;
              padding: 0;
              position: absolute;
              right: 0;
              top: 15px;
              .icon {
                display: none;
              }
              .hidden {
                border-top: 1px dashed #000;
                color: transparent;
                // display: block;
                display: none;
                font-size: $px12;
                padding: 0.3rem 0.5rem 0;
              }
            }
          }
        }
        .toggle-size-wrapper {
          display: none;
        }
        .approval,
        .checkbox-container {
          opacity: 1;
        }

        .approval-big {
          > .icon {
            display: none;
          }
        }
      }
    }
  }
}
@media screen and (max-width: $sm) {
  .create-document-form {
    .form-progress {
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        position: relative;
        margin: 0 -17px 0 -10px;
      }

      > div {
        align-items: center;
        background: #d5e9fe;
        color: #004d94;
        display: flex;
        font-weight: bold;
        gap: 5px;
        padding: 5px 10px;
        justify-content: center;
        width: 50%;
        @include transition($transition);

        span {
          align-items: center;
          border-radius: 50%;
          background: #004d94;
          display: flex;
          color: #d5e9fe;
          justify-content: center;
          height: 20px;
          padding: 5px;
          width: 20px;
        }

        &.active {
          background: #004d94;
          color: white;
          span {
            background: white;
            color: #004d94;
          }
        }
        &.ready {
          background: #639400;
          color: white;
          span {
            background: white;
            color: #639400;
          }
        }
      }
    }
    .form-aside-wrapper {
      padding: 0;
      > aside,
      > main {
        height: 60vh;

        &.hide {
          display: none;
        }
        &.main--manual-document {
          height: 60vh;
        }
        button {
          margin-bottom: 30px;
          min-height: 40px;
        }
      }
      > aside {
        align-items: center;
        display: flex;
        gap: 10px;
        overflow: auto;
        padding: 0 1rem;
        width: 100%;
        .signatures-and-button {
          width: 100%;
        }
      }
      > main {
        align-items: center;
        background: initial;
        border: none;
        display: flex;
        flex-direction: column;
        width: 100%;

        .document-form-type {
          padding: 30px 10px;
          main {
            width: 100% !important;
          }
          .row {
            flex-direction: column;

            &.row--score-table {
              gap: 5px;
            }
            &.row--score-sum {
              p {
                width: 100%;
              }
            }
            &.wrap {
              flex-direction: row;
              flex-wrap: wrap;
              .title {
                border-radius: 6px;
                min-width: 100%;
              }
              .box,
              .points {
                border-radius: 0px;
                padding: 10px;
                margin: 0 auto;
                min-width: calc(50% - 5px);
              }
            }
            &.aside--row {
              flex-direction: row;
              aside {
                margin-right: 5px;
                min-width: fit-content;
              }
            }
            &.checkboxes {
              main {
                width: fit-content;
              }
              section {
                display: flex;
                justify-content: center;
                text-align: center;
                p {
                  &:first-child,
                  &:last-child {
                    width: 100%;
                  }
                }
              }
            }
          }
          .rows-wrapper {
            &.rows--points {
              .row {
                flex-direction: row;
                flex-wrap: wrap;

                .indicators {
                  min-width: 100%;
                  border-radius: 6px;
                }
                .score {
                  margin: 0 auto;
                  min-width: calc(50% - 5px);
                }
              }
            }
          }
        }
      }
    }
  }
  .button--print {
    display: none;
  }
}

@media print {
  .create-document-form {
    display: block;
    .form-aside-wrapper {
      display: block;
      padding: 0;
      > main {
        border: 0;
        height: initial;
        overflow: visible;
        position: static;
        width: 100%;
      }
      > aside {
        display: none;
      }

      .document-form-type {
        &:not(.pdf) {
          display: none;
        }
        div {
          break-inside: avoid;
        }
        &.pdf {
          overflow: visible;
          page-break-inside: avoid;
          position: absolute;
          top: 0;
          z-index: 99999999;
          width: 100%;
        }
      }
      // > main {
      //   height: 100%;
      //   left: 0;
      //   position: fixed;
      //   top: 0;
      //   width: 100%;
      //   z-index: 9999999;
      // }
    }
  }
}

// @page {
//       margin-top: 30mm !important;
// }
</style>
