<template>
  <div
    class="approval-big"
    :class="{
      'approval-big--disabled': disabled,
      'approval-big--locked': locked,
    }"
  >
    <span class="icon" v-if="icon">{{ icon }}</span>
    <div>
      <p
        :class="{ linetrough: typeof value === 'boolean' && !value }"
        @click="disabled || locked ? null : $emit('input', true)"
      >
        {{ preYes }}
        <span>{{ yes }}</span>
        {{ postYes }}
      </p>
      <p
        :class="{ linetrough: typeof value === 'boolean' && value }"
        @click="disabled || locked ? null : $emit('input', false)"
      >
        {{ preNo }}
        <span>{{ no }}</span>
        {{ postNo }}
      </p>
      <p>Изберете вярното</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['value', 'icon', 'preYes', 'yes', 'postYes', 'preNo', 'no', 'postNo', 'disabled', 'locked'],
};
</script>

<style lang="scss">
@import '@/scss/base.scss';
.approval-big {
  @include noselect();
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #9eafc1;
  display: flex;
  padding: 1rem;
  width: 100%;

  > .icon {
    color: #004d94;
    font-size: $px20;
    margin-right: 10px;
  }

  p {
    font-size: $px16;

    &:not(:last-child) {
      margin-bottom: 5px;
    }

    &:last-child {
      font-size: $px12;
      opacity: 0.5;
    }

    > span {
      font-weight: 600;
      text-decoration: underline;
    }
    &:first-child {
      > span {
        color: #35aadf;
      }
    }
    &:nth-child(2) {
      > span {
        color: #cc1616;
      }
    }

    &.linetrough {
      text-decoration: line-through;
      opacity: 0.3;
    }
  }

  &:not(.approval-big--locked) {
    p {
      &:not(:last-child) {
        @include hover();
      }

      &:hover {
        text-decoration: none;
      }
    }
  }
}
</style>
