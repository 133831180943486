var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[_c('div',{staticClass:"signatures"},[_c('p',{staticClass:"signatures-title"},[_vm._v("СЪБРАНИ ПОДПИСИ")]),(_vm.ds)?_c('section',{staticClass:"stages-wrapper"},[_c('div',{staticClass:"stage",class:{
          signed: _vm.ds.one.s,
        }},[_c('aside',[_c('span',{staticClass:"icon"},[_vm._v("\\")]),(_vm.canHaveStages)?_c('span',{staticClass:"stage-line"}):_vm._e()]),_c('div',{staticClass:"info"},[_c('p',[_vm._v(_vm._s(_vm.ds.one.p))]),_c('span',[_vm._v(_vm._s(_vm.ds.one.span))])])]),(_vm.canHaveStages)?[_c('div',{staticClass:"stage",class:{
            signed: _vm.ds.two.s,
          }},[_c('aside',[_c('span',{staticClass:"icon"},[_vm._v("]")]),(_vm.ds.three)?_c('span',{staticClass:"stage-line"}):_vm._e()]),_c('div',{staticClass:"info"},[_c('p',[_vm._v(_vm._s(_vm.ds.two.p))]),_c('span',[_vm._v(_vm._s(_vm.ds.two.span))])])]),(_vm.ds.three)?_c('div',{staticClass:"stage",class:{
            signed: _vm.ds.three.s,
          }},[_c('aside',[_c('span',{staticClass:"icon"},[_vm._v("^")]),(_vm.ds.four)?_c('span',{staticClass:"stage-line"}):_vm._e()]),_c('div',{staticClass:"info"},[_c('p',[_vm._v(" "+_vm._s(_vm.ds.three.p)+" ")]),(_vm.ds.three.span)?_c('span',[_vm._v(_vm._s(_vm.ds.three.span))]):_vm._e()])]):_vm._e(),(_vm.ds.four)?_c('div',{staticClass:"stage",class:{
            signed: _vm.ds.four.s,
          }},[_vm._m(0),_c('div',{staticClass:"info"},[_c('p',[_vm._v(_vm._s(_vm.ds.four.p))]),_c('span',[_vm._v(_vm._s(_vm.ds.four.span))])])]):_vm._e()]:_vm._e()],2):_vm._e()]),(_vm.d.preFinalized && !_vm.d.signedWithCertificate && _vm.d.form.signatures.hr && !_vm.d.form.signatures.receiverTwo)?_c('div',{staticClass:"signatures"},[_c('p',{staticClass:"signatures-title"},[_vm._v("ЧАКАЩ ПОДПИС")]),_c('section',{staticClass:"stages-wrapper stages-wrapper--kep"},[_c('div',{staticClass:"stage"},[_vm._m(1),_c('div',{staticClass:"info kep"},[_c('section',[_c('p',[_vm._v(_vm._s(_vm.d.receiver.name))]),_c('span',[_vm._v("Изпълнителен директор")])]),_c('span',{staticClass:"icon blue"},[_vm._v("_")])])])])]):_vm._e(),(_vm.d.preFinalized && _vm.d.signedWithCertificate)?_c('div',{staticClass:"signatures"},[(!_vm.d.finalized)?_c('p',{staticClass:"signatures-title"},[_vm._v("ЧАКАЩ ПОДПИС")]):_vm._e(),_c('p',{staticClass:"signatures-title"},[_vm._v(_vm._s(_vm.d.form.user.name))]),(!_vm.d.finalizedAt)?_c('div',{staticClass:"buttons",class:{ 'buttons--disabled': _vm.disabled }},[_c('a',{on:{"click":function($event){_vm.disabled ? null : _vm.$emit('selected', true)}}},[_vm._v("СЪГЛАСЕН СЪМ")]),_vm._v("  /  "),_c('a',{staticClass:"red",on:{"click":function($event){_vm.disabled ? null : _vm.$emit('selected', false)}}},[_vm._v("НЕ СЪМ СЪГЛАСЕН")])]):_c('div',{staticClass:"buttons"},[_c('a',{staticClass:"decision",class:{ red: !_vm.d.userDecision }},[_vm._v("ДОКУМЕНТЪТ "+_vm._s(_vm.d.userDecision ? 'E ПРИЕТ' : 'НЕ E ПРИЕТ')+" ОТ СЛУЖИТЕЛЯ НА "+_vm._s(_vm._f("formatDateFull")(_vm.d.finalizedAt)))])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('aside',[_c('span',{staticClass:"icon"},[_vm._v("`")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('aside',{staticClass:"kep"},[_c('span',{staticClass:"icon"},[_vm._v("$")])])
}]

export { render, staticRenderFns }