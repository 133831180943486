<template>
  <div class="document-form-type">
    <div class="row center text-center column">
      <h1>
        ДОПЪЛНИТЕЛНО СПОРАЗУМЕНИЕ ЗА <br />
        НАСТАВНИК ПРЕНАЗНАЧЕН РАБОТНИК
      </h1>
      <p class="doc-number" v-if="d.number">№{{ d.numberPrefix }}-{{ d.number }}</p>
    </div>

    <div class="row">
      <p>На основание чл. 26 от "ПРАВИЛА ЗА РАБОТНАТА ЗАПЛАТА",</p>
    </div>

    <div class="row center text-center">
      <p class="smaller-line-height">
        <strong>I. ВЪЗЛАГАМ НА</strong>
      </p>
    </div>

    <section class="rows-wrapper">
      <div class="row">
        <Input
          style="width: 75%"
          icon="F"
          placeholder="Име Презиме Фамилия"
          label="От Работник/Служител (изберете или потърсете име)"
          type="dropdown"
          @fetch="$emit('fetch-employees', $event)"
          :locked="d._id"
          :list="employees"
          prop="name"
          identifier="_id"
          v-model="d.form.user"
        />
        <Input
          style="width: 25%"
          icon="+"
          placeholder="№"
          label="Служебен номер"
          v-model="d.form.user.number"
          :autoFill="true"
          :locked="d._id"
        />
      </div>

      <div class="row">
        <Input
          icon="b"
          placeholder="Длъжност"
          label="Назначен на длъжност"
          :value="d.form.user.position ? d.form.user.position.name : ''"
          :locked="true"
        />
        <Input
          icon="/"
          prop="name"
          placeholder="Структурно звено"
          label="Работно място"
          :locked="true"
          :value="d.form.user.structuralUnit ? d.form.user.structuralUnit.name : ''"
        />
      </div>

      <div class="row">
        <Input
          icon="C"
          placeholder="Изберете дата"
          label="От дата"
          type="datepicker"
          :disabled="!canEdit"
          v-model="d.form.fromDate"
        />
        <Input
          icon="C"
          placeholder="Дата"
          label="До дата, вкл."
          type="datepicker"
          :disabled="!canEdit"
          v-model="d.form.toDate"
        />
      </div>

      <div class="row">
        <p>да изпълнява задълженията на „Наставник“ на</p>
      </div>

      <div class="row">
        <Input
          icon="F"
          placeholder="Име Презиме Фамилия"
          label="От Работник/Служител (изберете или потърсете име)"
          type="dropdown"
          @fetch="$emit('fetch-employees', $event)"
          :list="employees"
          prop="name"
          :identifier="d.form.employeeSubstitute ? '_id' : false"
          v-model="d.form.employeeSubstitute"
          :disabled="!canEdit"
          :locked="stageTwoDone"
        />
      </div>

      <div class="row">
        <p>при изпълнение на задълженията и за длъжността</p>
      </div>

      <div class="row">
        <Input
          icon="b"
          placeholder="Длъжност"
          label="Назначен на длъжност"
          :value="d.form.employeeSubstitute ? d.form.employeeSubstitute.position.name : ''"
          :locked="true"
        />
      </div>

      <div class="row">
        <p>като:</p>
      </div>
    </section>

    <div class="row first-letter--margin">
      <p>
        1.1. Спомага за безпроблемната адаптация и професионалната реализация на наставлявания, като го запознава
        детайлно със задълженията на заеманата длъжност на практика;
      </p>
    </div>
    <div class="row first-letter--margin">
      <p>
        1.2. Обучава наставлявания и следи за усвояването на необходимите знания и умения за правилното изпълнение на
        длъжностните задължения.
      </p>
    </div>
    <div class="row first-letter--margin">
      <p>
        1.3. Предава своя опит, съветва и подкрепя, споделя с наставлявания впечатленията и наблюденията си от неговото
        представяне, и служи за модел на подражание.
      </p>
    </div>

    <div class="row center text-center">
      <p class="smaller-line-height">
        <strong>2. ОПРЕДЕЛЯМ</strong>
      </p>
    </div>

    <div class="row">
      <p>
        възнаграждение в размера на 110 лева месечно за срока по т. 1 от заповедта. Сумата е дължима за реално
        отработено време.
      </p>
    </div>

    <div class="row row--background">
      <Input
        icon="C"
        placeholder="Дата на създаване"
        label="Дата на създаване"
        v-model="d.form.createdAt"
        type="datepicker"
        :locked="d._id"
      />
      <Input
        icon="$"
        :placeholder="d.form.firstHr"
        label="Служител УЧР"
        type="signature"
        :value="d.form.signatures.firstHr"
        @signed="d.form.signatures.firstHr = $event"
        :locked="d._id"
      />
    </div>

    <template v-if="d._id">
      <div class="brake">ПОДПИСВА СЕ ОТ РЪКОВОДИТЕЛ УЧР</div>

      <div class="row row--background">
        <Input
          icon="C"
          placeholder="Дата на създаване"
          label="Дата на създаване"
          v-model="d.form.createdAtHr"
          type="datepicker"
          :disabled="!isCurrUserDocHr"
        />
        <Input
          icon="$"
          placeholder="Минка Димитрова Бакалова-Каменова"
          label="Р-л отдел УЧР"
          type="signature"
          :value="d.form.signatures.hr"
          @signed="d.form.signatures.hr = $event"
          :disabled="!isCurrUserDocHr"
          :locked="!isCurrUserDocHr"
        />
      </div>
    </template>

    <template v-if="stageTwoDone">
      <div class="brake">ПОПЪЛВА СЕ ОТ СЛУЖИТЕЛЯ</div>

      <section class="rows-wrapper">
        <div class="row center">
          <Approval yes="СЪГЛАСЕН СЪМ " no="НЕ СЪМ СЪГЛАСЕН" v-model="d.form.approved" :disabled="!isCurrUserDocUser" />
        </div>

        <div class="row">
          <p>Запознах се със заповедта, получих екземпляр от нея и съм съгласен да изпълнявам възложените ми задачи.</p>
        </div>

        <div class="row">
          <p>Съгласен съм, номера на мобилния ми телефон да бъде предоставен на наставлявания от мен работник.</p>
        </div>
      </section>

      <div class="row row--background">
        <Input
          icon="C"
          placeholder="Дата на връчване"
          label="Дата на връчване"
          v-model="d.form.createdAtUser"
          type="datepicker"
          :disabled="!isCurrUserDocUser"
        />
        <Input
          icon="$"
          :placeholder="d.form.user.name ? d.form.user.name : 'Име, Фамилия'"
          label="Име, Подпис"
          type="signature"
          :value="d.form.signatures.user"
          @signed="d.form.signatures.user = $event"
          :disabled="!isCurrUserDocUser"
          :locked="!isCurrUserDocUser"
        />
      </div>
    </template>
  </div>
</template>

<script>
import Input from '../CreateDocumentInput';
import Approval from '../CreateDocumentApproval';
export default {
  components: {
    Input,
    Approval,
  },
  props: ['d', 'employees', 'invalid', 'isCurrUserDocUser', 'isCurrUserDocHr', 'editMode', 'canEdit'],
  data() {
    return {
      stageTwoDone: false,
    };
  },
  watch: {
    'd.form.fromDate'() {
      if (this.d.form.fromDate) {
        let copy = new Date(this.d.form.fromDate);
        copy.setMonth(copy.getMonth() + 1)
        this.$set(this.d.form, 'toDate', copy.setDate(copy.getDate() - 1));
      }
    },
  },
  beforeMount() {
    if (!this.d._id) {
      this.$set(this.d.form.signatures, 'firstHr', null);
      this.$set(this.d.form.signatures, 'hr', null);
      this.$set(this.d.form.signatures, 'user', null);
    }

    if (this.d.form.signatures.hr) (this.stageTwoDone = true), (this.d.form.createdAtUser = new Date());
    if (this.d.form.signatures.firstHr) this.d.form.createdAtHr = new Date();
    if (this.d.form.signatures.hr) this.d.form.createdAtUser = new Date();
  },
};
</script>
