<template>
  <div class="modal-wrapper" :class="{ 'modal-wrapper--sidepanel': isSidePanelOpen }" @click.self="$emit('close')">
    <transition name="modal" appear>
      <div class="modal" :class="{ 'modal--decision': link }">
        <a v-if="link" class="pdf-link" :href="link" target="_blank">Преглед на Допълнителното споразумение (PDF)</a>
        <p v-if="html" v-html="question"></p>
        <p v-else>{{ question }}</p>
        <span v-if="link">{{ currentUser.name }}</span>
        <span v-if="post && html" v-html="post"></span>
        <span v-else-if="post && !html">{{ post }}</span>
        <div class="buttons" :class="{ 'buttons--no-danger': noDanger }">
          <a @click="$emit('selected', true)">{{ yes }}</a>
          <template v-if="link"> &nbsp;/&nbsp; </template>
          <a @click="$emit('selected', false)" v-if="no">{{ no }}</a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: ['question', 'yes', 'no', 'link', 'html', 'post', 'noDanger'],
  computed: {
    ...mapGetters(['isSidePanelOpen', 'currentUser']),
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.modal-wrapper {
  align-items: center;
  background-color: rgba($color: #fff, $alpha: 0.8);
  height: 100%;
  display: flex;
  justify-content: center;
  left: 0;
  padding-bottom: $nav;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 101;
  @include transition($transition);

  .modal {
    background: #fff;
    box-shadow: 0px 0px 99px #00000099;
    border-radius: 13px;
    overflow: hidden;
    width: 320px;
    &.modal--decision {
      overflow: hidden;
      .pdf-link {
        font-size: $px16;
        padding: 11px 2rem;
        text-decoration: underline;
      }
      p {
        font-size: $px14;
        padding: 2rem;
      }
      .buttons {
        align-items: center;
        display: flex;
        font-weight: 600;
        a {
          border-top: initial;
          text-decoration: underline;
          width: 50%;
        }
      }
    }

    p {
      font-size: $px16;
      font-weight: 600;
      padding: 3rem 2rem 2rem;
      text-align: center;
    }
    span {
      display: flex;
      font-weight: bold;
      justify-content: center;
      text-align: center;
      margin-bottom: 10px;
    }
    a {
      border-top: 1px solid rgba(0, 0, 0, 0.16);
      color: #cc1616;
      cursor: pointer;
      display: block;
      font-weight: 600;
      font-size: $px14;
      padding: 11px;
      text-align: center;
      @include transition($transition);

      &:first-child {
        color: #35aadf;
      }

      &:hover:not(.pdf-link) {
        background-color: #f5f5f5;
      }
    }

    .buttons--no-danger {
      a {
        color: #35aadf;
      }
    }

    // @include scrollbar(5px, #004d94);
  }
}

@media screen and (min-width: $sm) {
  .modal-wrapper {
    &.modal-wrapper--sidepanel {
      padding-left: $sidepanel;
    }
  }
}
</style>
