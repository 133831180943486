<template>
  <div class="sidepanel__settings">
    <Button class="button--big button--grey" @click="backToTrdocs">
      <span class="icon">B</span>
      НАЗАД КЪМ Е-ДОСИЕТА
    </Button>
    <!-- TODO: ADD LINK TO SCHEDULES -->
    <template v-if="currentUser._id">
      <div class="sidepanel__texts">
        <p class="title">Настройки е-Досиета</p>
        <p>Настройки</p>
      </div>
      <template v-if="currentUser.roles.admin || currentUser.roles.hr">
        <router-link class="sidepanel__link" to="/settings/supervisors">СПИСЪК РЪКОВОДИТЕЛ 2</router-link>
        <router-link class="sidepanel__link" to="/settings/executive-and-operational-management">ИЗПЪЛНИТЕЛНО И ОПЕРАТИВНО РЪКОВОДСТВО</router-link>
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Button from '../Button';
export default {
  components: {
    Button,
  },
  computed: {
    ...mapGetters(['currentUser', 'activeCompany']),
  },
  methods: {
    backToTrdocs() {
      this.$router.push('/');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';

</style>
