<template>
  <div class="modal-wrapper" :class="{ 'modal-wrapper--sidepanel': isSidePanelOpen }" @click.self="$emit('close')">
    <transition name="modal" appear>
      <div class="modal">
        <header>
          <p>ПРЕИМЕНУВАНЕ НА ДОКУМЕНТ</p>
        </header>
        <main>
          <Input v-model="newName" placeholder="Ново име" />
        </main>
        <div class="buttons">
          <a @click="save()">ЗАПАЗИ</a>
          <a @click="$emit('close')">ОТКАЗ</a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Input from '../../components/inputs/Input.vue';
export default {
  components: { Input },
  props: ['d'],
  computed: {
    ...mapGetters(['isSidePanelOpen', 'currentUser']),
  },
  data() {
    return {
      newName: '',
    };
  },
  methods: {
    save() {
      this.$apiService
        .put(`/trdocs/rename/${this.d._id}`, { name: this.newName })
        .then(() => {
          this.$snotify.success('Документът е преименуван успешно.');
          this.$emit('close', true);
        })
        .catch((err) => console.log(err));
    },
  },
  mounted() {
    this.newName = this.d.type.name;
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.modal-wrapper {
  align-items: center;
  background-color: rgba($color: #fff, $alpha: 0.8);
  height: 100%;
  display: flex;
  justify-content: center;
  left: 0;
  padding-bottom: $nav;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 101;
  @include transition($transition);

  .modal {
    background: #fff;
    box-shadow: 0px 0px 99px #00000099;
    border-radius: 13px;
    overflow: hidden;
    width: 320px;

    header {
      color: black;
      padding: 20px 20px 0;
      text-align: center;
      p {
        font-size: $px18;
        &:last-of-type {
          opacity: 0.8;
        }
      }
    }

    main {
      padding: 1rem;
      > * {
        width: 100%;
      }
    }

    a {
      border-top: 1px solid rgba(0, 0, 0, 0.16);
      color: #cc1616;
      cursor: pointer;
      display: block;
      font-weight: 600;
      font-size: $px14;
      padding: 11px;
      text-align: center;
      @include transition($transition);

      &:first-child {
        color: #35aadf;
      }

      &:hover:not(.pdf-link) {
        background-color: #f5f5f5;
      }
    }

    .buttons--no-danger {
      a {
        color: #35aadf;
      }
    }

    // @include scrollbar(5px, #004d94);
  }
}

@media screen and (min-width: $sm) {
  .modal-wrapper {
    &.modal-wrapper--sidepanel {
      padding-left: $sidepanel;
    }
  }
}
</style>
