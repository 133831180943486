<template>
  <div class="modal-wrapper" :class="{ 'modal-wrapper--sidepanel': isSidePanelOpen }" @click.self="close()">
    <transition name="modal" appear>
      <div class="modal" :class="{'modal--big': allToggleOn}">
        <header class="no-print">
          <p>
            <span v-if="!id" :class="{ left: type, flashing: type ? type.warningText : '' }">
              {{ getDocName(type) }}
            </span>
            <span v-else :class="{ left: id }">ДОКУМЕНТ - {{ oldDocument ? oldDocument.name : '' }}</span>
          </p>
          <div v-if="canSeeToggle()" class="multiple-toggle">
            <p @click="allToggleOn = false" :class="{ active: !allToggleOn }">Ръчно прикачване на документи за един служител</p>
            <p @click="allToggleOn = true" :class="{ active: allToggleOn }">Ръчно прикачване на документи за много служители</p>
          </div>
          <span class="icon" @click="close()">g</span>
        </header>
        <transition name="fade" mode="out-in">
          <CreateDocumentModalTypes v-if="!type && !id" @selected="type = $event" @close="close()" />
          <CreateDocumentModalForm
            v-else
            :id="id"
            :type="type"
            :allToggleOn="allToggleOn"
            :files="files"
            @close="close(true, $event)"
            @set-type="oldDocument = $event"
          />
        </transition>
        <transition name="fade" mode="out-in">
          <CreateDocumentModalConfirm
            v-if="isCreateDocumentModalConfirmOpen"
            question="Имате незапазени промени. Сигурни ли сте, че искате да затворите документа?"
            yes="НЕ ЗАТВАРЯЙ ДОКУМЕНТА"
            no="ЗАТВОРИ (И ИЗТРИЙ ПРОМЕНИТЕ)"
            @selected="
              !$event ? close(true) : '';
              isCreateDocumentModalConfirmOpen = false;
            "
          />
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CreateDocumentModalForm from './CreateDocumentModalForm';
import CreateDocumentModalTypes from './CreateDocumentModalTypes';
import CreateDocumentModalConfirm from './CreateDocumentModalConfirm.vue';
export default {
  components: {
    CreateDocumentModalTypes,
    CreateDocumentModalForm,
    CreateDocumentModalConfirm,
  },
  props: ['id', 'files'],
  // props: ['id', 'oldDocument', 'files'],
  computed: {
    ...mapGetters(['currentUser', 'isSidePanelOpen']),
  },
  data() {
    return {
      type: null,
      allToggleOn: false,
      oldDocument: null,
      isCreateDocumentModalConfirmOpen: false,
    };
  },
  methods: {
    canSeeToggle() {
      if (this.type && this.type.type === 'manualUpload' && (this.currentUser.roles.admin || this.currentUser.roles.hr)) {
        return true;
      }
    },
    getDocName(t) {
      if (t && t.warningText) return t.warningText;
      else if( this.type && this.type.type === 'manualUpload') return 'ПРИКАЧИ НОВ ДОКУМЕНТ'
      else return `СЪЗДАЙ НОВ ДОКУМЕНТ ${t ? `- ${t.name}` : ''}`;
    },
    close(close, route) {
      let form = document.getElementsByClassName('document-form-type')[1];
      let changed = false;
      if (form) {
        const inputs = form.getElementsByTagName('label');
        for (let i of inputs) {
          if (i.classList.contains('create-document-input--changed')) {
            changed = true;
            this.isCreateDocumentModalConfirmOpen = true;
          }
        }
      }
      if (!changed || close) this.$emit('close', route);
    },
  },
  mounted() {
    if (typeof this.files !== 'undefined') {
      this.type = { name: '', type: 'manualUpload' };
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.modal-wrapper {
  align-items: center;
  background-color: rgba($color: #fff, $alpha: 0.8);
  height: 100%;
  display: flex;
  justify-content: center;
  left: 0;
  padding-bottom: $nav;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  @include transition($transition);

  .modal {
    background: transparent linear-gradient(180deg, #eff6fc 0%, #e8e9eb 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 99px #00000099;
    border-radius: 13px;
    max-width: 1110px;
    overflow: hidden;
    position: relative;
    width: 1110px;
    @include transition($transition);
    @include scrollbar(5px, #004d94);

    &.modal--big{
      max-width: 1550px;
      width: 1550px;
    }

    > header {
      align-items: center;
      color: #004d94;
      display: flex;
      font-size: $px14;
      font-weight: 700;
      justify-content: space-between;
      padding: 1rem;

      .multiple-toggle {
        background: #ffffff;
        border: 1px solid #a2a9b4;
        border-radius: 6px;
        display: flex;
        padding: 5px;

        > p {
          border-radius: 4px;
          border: 1px solid transparent;
          cursor: pointer;
          padding: 5px 10px;
          white-space: nowrap;
          @include transition($transition);

          &.active {
            background: #009cdb 0% 0% no-repeat padding-box;
            border: 1px solid #f4f7fd;
            color: #ffffff;
          }
        }
      }

      p {
        position: relative;
        // width: 100%;
        > span {
          // position: absolute;
          // left: 50%;
          // top: 50%;
          text-transform: uppercase;
          white-space: nowrap;
          @include transform(translateY(-50%) translateX(-50%));
          @include transition($transition);
          &.left {
            left: 0;
            @include transform(translateY(-50%));
          }
          &.flashing {
            animation: flashAnimation 2s 1s forwards infinite;
            transition: initial;
            padding: 2px;

            @keyframes flashAnimation {
              0% {
                background: transparent;
                color: inherit;
              }
              100% {
                background: #ff0000;
                color: #ffffff;
              }
            }
          }
        }
      }
      .icon {
        font-size: $px20;
        font-weight: 400;
        @include transition(inherit);
        @include hover();
      }
    }
  }
}

@media screen and (max-width: $sm) {
  .modal-wrapper {

    .modal{
      header{
        flex-wrap: wrap;

        .multiple-toggle{
          // margin: 10px auto;
          // order: 3;
          display: none;
        }
      }
    }
  }
}
@media screen and (min-width: $sm) {
  .modal-wrapper {
    &.modal-wrapper--sidepanel {
      padding-left: $sidepanel;
    }
  }
}
@media screen and (max-width: $s), (orientation: landscape) and (max-width: $landscape) {
  .modal-enter,
  .modal-leave-to {
    opacity: 0;
    @include transform(translateY(50%));
  }

  .modal-wrapper {
    .modal {
      border-radius: 13px 13px 0 0;
      bottom: 0;
      filter: drop-shadow(0 -167px 99px #004d947a);
      position: absolute;
      width: 100%;
      z-index: 999;

      header {
        font-size: $px12;
        padding: 1rem 1rem 0;

        p {
          span {
            white-space: initial;
            width: 100%;
          }
        }
      }
    }
  }
}

@media print {
  .modal-wrapper {
    display: block;
    position: relative;
    height: initial;
    padding: 0;
    .modal {
      height: initial;
      overflow: visible;
      max-width: initial;
      filter: none;
      width: 100%;
      // max-height: 0px;
      position: relative;
      bottom: initial;
      top: 0;
      > header {
        display: none;
      }
    }
  }
}
</style>
