<template>
  <div class="document-form-type">
    <div class="row center text-center column">
      <h1>
        ДОПЪЛНИТЕЛНО СПОРАЗУМЕНИЕ <br />
        ЗА ПРОМЯНА НА ДЛЪЖНОСТ/НИВО
      </h1>
      <p class="doc-number" v-if="d.number">№{{ d.numberPrefix }}-{{ d.number }}</p>
    </div>

    <section class="rows-wrapper">
      <div class="row">
        <Input
          icon="+"
          placeholder="№"
          label="Допълнително споразумение №"
          v-model="d.form.annexNumber"
          :locked="d._id && !editMode && !canEdit"
        />
        <Input
          icon="C"
          placeholder="Дата"
          label="От дата"
          type="datepicker"
          :locked="d._id && !editMode && !canEdit"
          v-model="d.form.annexNumberDate"
        />
      </div>
      <div class="row">
        <Input
          icon="+"
          placeholder="№"
          label="Към Трудов Договор №"
          v-model="d.form.contractNumber"
          :locked="d._id && !editMode && !canEdit"
        />
        <Input
          icon="C"
          placeholder="Дата"
          label="От дата"
          :maxlength="4"
          :minlength="4"
          :digitsOnly="true"
          :locked="d._id && !editMode && !canEdit"
          :yearFirst="true"
          v-model="d.form.contractNumberDate"
        />
      </div>
    </section>

    <div class="row center text-center">
      <p class="smaller-line-height">
        <strong>ОТНОСНО ПРОМЯНА НА ДЛЪЖНОСТ</strong>
      </p>
    </div>

    <div class="row center text-center first-letter--margin">
      <p>
        Днес,
        <strong class="underline" v-if="d.form.annexNumberDate">{{ d.form.annexNumberDate | formatDate }}</strong>
        <strong v-else class="underline"> ДАТА </strong> г. в гр. Пловдив, на основание чл. 119 от КТ и заявление №
        <strong class="underline">{{ d.parent ? `${d.parent.numberPrefix}-${d.parent.number}` : ' - ' }}</strong> от
        служителя <br />
        <strong>КЦМ АД, ЕИК 115007471</strong>, представлявано от <strong>инж. Иван Николов Добрев</strong> -
        Изпълнителен директор, наричан по-долу РАБОТОДАТЕЛ, от една страна и
      </p>
    </div>

    <section class="rows-wrapper">
      <div class="row">
        <Input
          icon="F"
          placeholder="Име Презиме Фамилия"
          label="От Работник/Служител (изберете или потърсете име)"
          type="dropdown"
          @fetch="$emit(autoFillFields ? 'fetch-employees-from-trdocs' : 'fetch-employees', $event)"
          :locked="d._id"
          :list="employees"
          prop="name"
          identifier="_id"
          v-model="d.form.user"
          @input="!d._id && autoFillFields ? $emit('get-parent-trdoc') : null"
        />
      </div>

      <div class="row">
        <Input
          icon="+"
          placeholder="ЕГН"
          label="ЕГН"
          :value="d.parent ? d.parent.form.egn : ''"
          :maxlength="10"
          :digitsOnly="true"
          :locked="d._id"
          :autoFill="true"
          v-if="autoFillFields"
        />
        <Input
          icon="+"
          placeholder="ЕГН"
          label="ЕГН"
          v-model="d.form.egn"
          :maxlength="10"
          :digitsOnly="true"
          :locked="d._id"
          v-else
        />
        <Input
          icon="+"
          placeholder="№"
          label="Служебен номер"
          :value="d.form.user ? d.form.user.number : ''"
          :locked="d._id"
          :autoFill="true"
        />
      </div>
    </section>

    <div class="row center text-center">
      <p>се споразумяха за следните изменения на трудовия договор, влизащи в сила от</p>
    </div>

    <div class="row">
      <Input
        icon="C"
        placeholder="Дата"
        label="Влизащ в сила от"
        type="datepicker"
        v-model="d.parent.form.probationFrom"
        :autoFill="!editMode && !canEdit"
        :locked="d._id && !editMode && !canEdit"
        v-if="autoFillFields && d.parent"
      />
      <Input
        icon="C"
        placeholder="Дата"
        label="Влизащ в сила от"
        type="datepicker"
        v-model="d.form.contractFrom"
        :locked="d._id && !editMode && !canEdit"
        v-else
      />
    </div>

    <div class="row center text-center">
      <p class="smaller-line-height">
        <strong>По предмета на договора</strong>
      </p>
    </div>

    <div class="row">
      <div class="row column">
        <strong>ОТ</strong>
        <Input
          icon="/"
          placeholder="Структурно звено"
          label="От работно място"
          v-model="d.parent.form.user.structuralUnit.name"
          :autofill="!editMode && !canEdit"
          v-if="d.parent"
        />
        <Input
          icon="/"
          prop="name"
          placeholder="Структурно звено"
          label="От работно място"
          :locked="!editMode && !canEdit"
          :value="d.form.user.structuralUnit ? d.form.user.structuralUnit.name : ''"
          v-else
        />
        <Input
          icon="b"
          placeholder="Длъжност"
          label="От длъжност"
          v-model="d.parent.form.user.position.name"
          :autofill="!editMode && !canEdit"
          v-if="d.parent"
        />
        <Input
          icon="b"
          placeholder="Длъжност"
          label="От длъжност"
          :value="d.form.user.position ? d.form.user.position.name : ''"
          :locked="!editMode && !canEdit"
          v-else
        />
        <Input
          icon="+"
          placeholder="код по НКПД"
          label="код по НКПД"
          type="dropdown"
          prop="name"
          propTwo="label"
          reversedProp="true"
          :list="nkpd"
          v-model="d.form.nkdp"
          :locked="d._id && !editMode && !canEdit"
          :maxlength="8"
          :minlength="8"
          :selectSearch="true"
        />
        <Input
          icon="/"
          placeholder="Дл. ниво"
          label="Длъжностно ниво"
          v-model="d.form.jobLevel"
          :locked="d._id && !editMode && !canEdit"
        />
      </div>

      <div class="row column">
        <strong>НА</strong>
        <Input
          icon="/"
          placeholder="Структурно звено"
          label="На работно място"
          v-model="d.parent.form.structuralUnit"
          :autoFill="!editMode && !canEdit"
          v-if="d.parent"
        />
        <Input
          icon="/"
          placeholder="Структурно звено"
          label="На работно място"
          v-model="d.form.structuralUnit"
          :locked="!editMode && !canEdit"
          v-else
        />
        <Input
          icon="b"
          placeholder="Длъжност"
          label="На длъжност"
          v-model="d.parent.form.position"
          :autoFill="!editMode && !canEdit"
          v-if="d.parent"
        />
        <Input
          icon="b"
          placeholder="Длъжност"
          label="На длъжност"
          v-model="d.form.position"
          :locked="!editMode && !canEdit"
          v-else
        />
        <Input
          icon="+"
          placeholder="код по НКПД"
          label="код по НКПД"
          type="dropdown"
          prop="name"
          propTwo="label"
          reversedProp="true"
          :list="nkpd"
          v-model="d.parent.form.nkpd"
          :maxlength="8"
          :minlength="8"
          :autoFill="!editMode && !canEdit"
          :selectSearch="true"
          v-if="d.parent"
        />
        <Input
          icon="+"
          placeholder="код по НКПД"
          label="код по НКПД"
          type="dropdown"
          prop="name"
          propTwo="label"
          reversedProp="true"
          :list="nkpd"
          :locked="!editMode && !canEdit"
          v-model="d.form.nkpd"
          :maxlength="8"
          :minlength="8"
          :selectSearch="true"
          v-else
        />
        <Input
          icon="/"
          placeholder="Дл. ниво"
          label="Длъжностно ниво"
          v-model="d.parent.form.jobLevel"
          :autoFill="!editMode && !canEdit"
          v-if="d.parent"
        />
        <Input
          icon="/"
          placeholder="Дл. ниво"
          label="Длъжностно ниво"
          v-model="d.form.newJobLevel"
          :locked="!editMode && !canEdit"
          v-else
        />
      </div>
    </div>

    <div class="row center text-center">
      <p class="smaller-line-height">
        <strong>По работното време</strong>
      </p>
    </div>

    <div class="row">
      <Input
        icon="q"
        placeholder="часа"
        label="Продължителност на раб. ден"
        v-model="d.parent.form.workDayDuration"
        :autoFill="!editMode && !canEdit"
        v-if="d.parent"
      />
      <Input
        icon="q"
        placeholder="часа"
        label="Продължителност на раб. ден"
        v-model="d.form.workDayDuration"
        :locked="!editMode && !canEdit"
        v-else
      />
      <Input
        icon="q"
        placeholder="часа"
        label="Продължителност Смяна"
        v-model="d.parent.form.shiftDuration"
        :autoFill="!editMode && !canEdit"
        :nonRequired="true"
        v-if="d.parent"
      />
      <Input
        icon="q"
        placeholder="часа"
        label="Продължителност Смяна"
        :nonRequired="true"
        v-model="d.form.shiftDuration"
        :locked="!editMode && !canEdit"
        v-else
      />
      <Input
        icon="q"
        placeholder="часа"
        label="Продължителност Седмица"
        v-model="d.parent.form.weekDuration"
        :autoFill="!editMode && !canEdit"
        v-if="d.parent"
      />
      <Input
        icon="q"
        placeholder="часа"
        label="Продължителност Седмица"
        v-model="d.form.weekDuration"
        :locked="!editMode && !canEdit"
        v-else
      />
    </div>

    <div class="row center text-center">
      <p class="smaller-line-height">
        <strong>По Възнаграждението</strong>
      </p>
    </div>

    <div class="row center text-center first-letter--margin">
      <p>
        За точно и добросъвестно изпълнение на задълженията по предмета на договора, Работодателят заплаща на
        работника/служителя основно месечно възнаграждение в размер на
      </p>
    </div>

    <div class="row">
      <Input
        style="width: 25%"
        icon=","
        placeholder="Сума с цифри"
        label="сума в лева, с цифри"
        :digitsOnly="true"
        v-model="d.parent.form.mainSalary"
        :locked="d._id && !editMode && !canEdit"
        :autoFill="!editMode && !canEdit"
        v-if="d.parent"
      />
      <Input
        style="width: 25%"
        icon=","
        placeholder="Сума с цифри"
        label="сума в лева, с цифри"
        :digitsOnly="true"
        v-model="d.form.mainSalary"
        :locked="!editMode && !canEdit"
        v-else
      />
      <Input
        style="width: 75%"
        icon=","
        placeholder="Сума словом"
        label="сума в лева, словом (попълва се автоматично)"
        :value="d.parent ? (d.parent.form.mainSalary ? wordify(d.parent.form.mainSalary) : '') : ''"
        :autoFill="true"
        v-if="d.parent"
      />
      <Input
        style="width: 75%"
        icon=","
        placeholder="Сума словом"
        label="сума в лева, словом (попълва се автоматично)"
        :value="d.form.mainSalary ? wordify(d.form.mainSalary) : ''"
        :autoFill="true"
        v-else
      />
    </div>

    <div class="row center text-center">
      <p class="smaller-line-height">
        <strong>По Срока на договора</strong>
      </p>
    </div>

    <div class="row">
      <section class="row column">
        <Input
          label="Без промяна"
          type="checkbox"
          :value="d.form.contractTerm === 'Без промяна'"
          @input="
            $set(
              d.form,
              'contractTerm',
              $event ? 'Без промяна' : ''
            );
            d.form.contractTime ? (d.form.contractTime = '') : null;
          "
          :locked="d._id && !editMode && !canEdit"
        />
        <Input
          label="Срок на изпитване за новата длъжност – 6 месеца от датата на преназначаване"
          type="checkbox"
          :value="d.form.contractTerm === 'Срок на изпитване за новата длъжност – 6 месеца от датата на преназначаване'"
          @input="
            $set(
              d.form,
              'contractTerm',
              $event ? 'Срок на изпитване за новата длъжност – 6 месеца от датата на преназначаване' : ''
            );
            d.form.contractTime ? (d.form.contractTime = '') : null;
          "
          :locked="d._id && !editMode && !canEdit"
        />
        <Input
          label="Страните се съгласяват на нов срок на договора – срочен до"
          type="checkbox"
          :value="d.form.contractTerm === 'Страните се съгласяват на нов срок на договора – срочен до'"
          @input="
            $set(d.form, 'contractTerm', $event ? 'Страните се съгласяват на нов срок на договора – срочен до' : '')
          "
          :locked="d._id && !editMode && !canEdit"
        />
        <Input
          icon="q"
          placeholder="Срок"
          label="Срок до"
          v-model="d.form.contractTime"
          :locked="
            d.form.contractTerm === 'Срок на изпитване за новата длъжност – 6 месеца от датата на преназначаване'
              ? false
              : d._id && !editMode && !canEdit
          "
          :disabled="
            d.form.contractTerm !== 'Страните се съгласяват на нов срок на договора – срочен до'
          "
        />
      </section>
    </div>

    <div class="row">
      <Input
      type="textarea"
        icon="Q"
        :maxlength="165"
        placeholder="Попълнете свободен текст (до 165 символа)"
        label="Попълнете свободен текст"
        v-model="d.form.freeText"
        :nonRequired="true"
        :locked="d._id && !editMode && !canEdit"
      />
    </div>

    <div class="row center text-center">
      <p>Всички останали условия по договора остават без промяна.</p>
    </div>

    <div class="row center text-center">
      <p>Настоящото допълнително споразумение се сключи в два еднообразни екземпляра, по един за всяка от страните.</p>
    </div>

    <section class="rows-wrapper">
      <div class="row row--background">
        <Input
          icon="C"
          placeholder="Дата на създаване"
          label="Дата на създаване"
          v-model="d.form.createdAt"
          type="datepicker"
          :locked="d._id"
        />
        <Input
          icon="$"
          :placeholder="d.form.firstHr"
          label="Служител отдел УЧР"
          type="signature"
          :value="d.form.signatures.firstHr"
          @signed="d.form.signatures.firstHr = $event"
          :locked="d._id"
        />
      </div>

      <!-- <div class="row">
        <div style="width: 50%"></div>
        <Input
          style="width: 50%"
          icon="$"
          placeholder="инж. Иван Добрев"
          label="Изпълнителен директор"
          type="signature"
          :value="d.form.signatures.director"
          @signed="d.form.signatures.director = $event"
        />
      </div> -->

      <!-- <div class="row">
        <div style="width: 50%"></div>
        <Input
          style="width: 50%"
          icon="$"
          :placeholder="d.form.user.name ? d.form.user.name : 'Име, Фамилия'"
          label="Служител - име и подпис"
          type="signature"
          :value="d.form.signatures.user"
          @signed="d.form.signatures.user = $event"
        />
      </div> -->
    </section>

    <template v-if="d._id">
      <div class="brake">Попълва се от Минка Бакалова, рък-л отдел "УЧР"</div>

      <div class="row row--background">
        <Input
          icon="C"
          placeholder="Дата на създаване"
          label="Дата на създаване"
          v-model="d.form.createdAtHr"
          type="datepicker"
          :disabled="!isCurrUserDocHr"
        />
        <Input
          icon="$"
          placeholder="Минка Димитрова Бакалова-Каменова"
          label="Р-л отдел УЧР"
          type="signature"
          :value="d.form.signatures.hr"
          @signed="d.form.signatures.hr = $event"
          :disabled="!isCurrUserDocHr"
          :locked="!isCurrUserDocHr"
        />
      </div>
    </template>
    <FormPrintButtons :d="d" />
  </div>
</template>

<script>
import Input from '../CreateDocumentInput';
import wordifyBG from '@/js/wordify.bg.js';
import FormPrintButtons from './FormPrintButtons';
export default {
  components: {
    Input,
    FormPrintButtons
  },
  props: ['d', 'employees', 'nkpd', 'invalid', 'autoFillFields', 'isCurrUserDocUser', 'isCurrUserDocHr', 'editMode', 'canEdit'],
  methods: {
    wordify(num) {
      return wordifyBG(num);
    },
  },
  beforeMount() {
    if (!this.d._id) {
      this.$set(this.d.form.signatures, 'firstHr', null);
      this.$set(this.d.form.signatures, 'hr', null);
      this.$set(
        this.d.form,
        'contractTerm',
        'Без промяна'
      );
    }
    if (!this.d.form.signatures.firstHr) this.d.form.createdAt = new Date();
    if (!this.d.form.signatures.hr) this.d.form.createdAtHr = new Date();
  },
};
</script>
