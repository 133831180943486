<template>
  <div class="document-form-type">
    <div class="row center text-center column">
      <h1>
        КАРТА ЗА ОЦЕНКА НА <br />
        РЪКОВОДИТЕЛ
      </h1>
      <p class="doc-number" v-if="d.number">№{{ d.numberPrefix }}-{{ d.number }}</p>
    </div>

    <section class="rows-wrapper">
      <div class="row">
        <Input
          style="width: 75%"
          icon="F"
          placeholder="Име Презиме Фамилия"
          label="От Работник/Служител (изберете или потърсете име)"
          type="dropdown"
          @fetch="$emit('fetch-employees', $event)"
          :locked="d._id || isCurrUserDocUser"
          :list="employees"
          prop="name"
          identifier="_id"
          v-model="d.form.user"
        />
        <Input
          style="width: 25%"
          icon="+"
          placeholder="№"
          label="Служебен номер"
          v-model="d.form.user.number"
          :autoFill="true"
        />
      </div>
      <div class="row">
        <Input
          :icon="'&'"
          placeholder="Структурно звено"
          label="Структурно звено (участък, цех, лаборатория, отдел, отделение, група)"
          :value="d.form.user.structuralUnit ? d.form.user.structuralUnit.name : ''"
          :autoFill="true"
        />
      </div>
      <div class="row">
        <Input
          style="width: 75%"
          icon="b"
          placeholder="Длъжност"
          label="Длъжност на работника или служителя (автом.)"
          :value="d.form.user.position ? d.form.user.position.name : ''"
          :autoFill="true"
        />
        <Input
          style="width: 25%"
          icon="b"
          placeholder="Смяна"
          label="Смяна"
          :value="d.form.user.schedule ? d.form.user.schedule : ''"
          :autoFill="true"
        />
      </div>
    </section>

    <div class="row center text-center">
      <p class="smaller-line-height">
        <strong
          >Оценка на трудовото представяне по отделните критерии и показатели, <br />
          за следния период:</strong
        >
      </p>
    </div>

    <div class="row">
      <Input
        icon="C"
        placeholder="ДД.ММ.ГГГГ"
        label="От дата"
        v-model="d.form.user.fromDate"
        :minlength="10"
        :maxlength="10"
        :dateRegex="true"
        :locked="d._id"
      />
      <Input
        icon="C"
        placeholder="ДД.ММ.ГГГГ"
        label="До дата"
        v-model="d.form.user.toDate"
        :minlength="10"
        :maxlength="10"
        :locked="d._id"
        :dateRegex="true"
      />
    </div>

    <div class="row row--no-wrap row--align-center">
      <p><strong>Вид на оценката:</strong></p>
      <Input
        label="изтичане на изпитателен срок"
        type="checkbox"
        :value="d.form.scoreType === 'изтичане на изпитателен срок'"
        :locked="d._id"
        @input="$set(d.form, 'scoreType', $event ? 'изтичане на изпитателен срок' : '')"
      />

      <Input
        label="годишна атестация"
        type="checkbox"
        :value="d.form.scoreType === 'годишна атестация'"
        :locked="d._id"
        @input="$set(d.form, 'scoreType', $event ? 'годишна атестация' : '')"
      />

      <Input
        label="при необходимост"
        type="checkbox"
        :value="d.form.scoreType === 'при необходимост'"
        :locked="d._id"
        @input="$set(d.form, 'scoreType', $event ? 'при необходимост' : '')"
      />
    </div>

    <div class="row wrap">
      <div style="width: 80%" class="title">
        <h1>КРИТЕРИИ ЗА ОЦЕНЯВАНЕ НА РАБОТАТА</h1>
      </div>

      <section class="row row--align-center center box" style="width: 25%">
        <p>ОЦЕНКА</p>
      </section>
    </div>

    <section class="rows-wrapper rows--background rows--points">
      <div>
        <p class="title">
          <strong>1. Изпълнение на дейността, разпределение и контрол на реусрсите</strong>
        </p>
        <div class="row row--align-center row--points">
          <section style="width: 80%">
            <p>
              Изпълнение на поставените цели и задачи, утвърдените бюджети и параметри за дейността на ръководеното
              звено през периода на оценяване
            </p>
          </section>
          <Score v-model="d.form.scores.one" style="width: 20%" :num="true" :locked="d._id" />
        </div>

        <div class="row row--align-center row--points">
          <section style="width: 80%">
            <p>
              Изпълнение на задълженията и отговорностите по организацията и ръководството на работния процес, за
              своевременно и качествено изпълнение на поставените цели и задачи
            </p>
          </section>
          <Score v-model="d.form.scores.two" style="width: 20%" :num="true" :locked="d._id" />
        </div>

        <div class="row row--align-center row--points">
          <section style="width: 80%">
            <p>
              Участие (лично или на/със сътрудници от ръководеното звено) при разработване и внедряване на нови проекти,
              програми, продукти, технологии и др. под.
            </p>
          </section>
          <Score v-model="d.form.scores.three" style="width: 20%" :num="true" :locked="d._id" />
        </div>

        <div class="row row--align-center row--points">
          <section style="width: 80%">
            <p>
              Ефективност и ефикасност на управлението на ресурсите (финансови, материални и човешки); разпределение и
              контрол над правилното им използване (икономия/преразход на суровини, материали и парични средства;
              текучество, вътрешно движение на персонала)
            </p>
          </section>
          <Score v-model="d.form.scores.four" style="width: 20%" :num="true" :locked="d._id" />
        </div>

        <section class="row row--score-sum right">
          <p>
            Брой точки:
            {{
              (d.form.scores.one || 0) +
              (d.form.scores.two || 0) +
              (d.form.scores.three || 0) +
              (d.form.scores.four || 0)
            }}
          </p>
        </section>
      </div>

      <div>
        <p class="title">
          <strong>2. Професионални знания и умения, поведение в критични ситуации</strong>
        </p>
        <div class="row row--align-center row--points">
          <section style="width: 80%">
            <p>
              Умения за приоретизиране на поставените задачи, координиране и контрол на изпълнението им; за ангажиране
              на хората от екипа си да работят активно, съвестно и с желание, за постигане на екипните и корпоративни
              цели
            </p>
          </section>
          <Score v-model="d.form.scores.five" style="width: 20%" :num="true" :locked="d._id" />
        </div>

        <div class="row row--align-center row--points">
          <section style="width: 80%">
            <p>
              Способности за аналитично мислене и творчески подход към изпълнение на задачите; умения за адаптиране на
              процесите към работни ситуации с променящи се условия
            </p>
          </section>
          <Score v-model="d.form.scores.six" style="width: 20%" :num="true" :locked="d._id" />
        </div>

        <div class="row row--align-center row--points">
          <section style="width: 80%">
            <p>
              Вземане на точни и навременни решения; идентифициране на възможните рискове, проблеми и затруднения, и
              намиране на варианти/решения за преодоляването им.;
            </p>
          </section>
          <Score v-model="d.form.scores.seven" style="width: 20%" :num="true" :locked="d._id" />
        </div>

        <div class="row row--align-center row--points">
          <section style="width: 80%">
            <p>
              Поведение в критични ситуации или при възлагане на спешни задачи; способности за избор на начин на
              действие и вземане на отговорни решения; поемане на лична отговорност за взетите решения и резултатите от
              тях
            </p>
          </section>
          <Score v-model="d.form.scores.eight" style="width: 20%" :num="true" :locked="d._id" />
        </div>

        <section class="row row--score-sum right">
          <p>
            Брой точки:
            {{
              (d.form.scores.five || 0) +
              (d.form.scores.six || 0) +
              (d.form.scores.seven || 0) +
              (d.form.scores.eight || 0)
            }}
          </p>
        </section>
      </div>

      <div>
        <p class="title">
          <strong>3. Управление на документи </strong>
        </p>
        <div class="row row--align-center row--points">
          <section style="width: 80%">
            <p>
              Способности за изготвяне /разработване на документи, съставяне на отчети, доклади, писмени материали;
              съблюдаване на сроковете и изискванията към тях
            </p>
          </section>
          <Score v-model="d.form.scores.nine" style="width: 20%" :num="true" :locked="d._id" />
        </div>

        <div class="row row--align-center row--points">
          <section style="width: 80%">
            <p>
              Познаване и използване на вътрешно-нормативните документи; осъществяване на ефективен контрол по
              изпълнението/прилагането им; принос в разработването и актуализирането на документацията на звеното и
              дружеството
            </p>
          </section>
          <Score v-model="d.form.scores.ten" style="width: 20%" :num="true" :locked="d._id" />
        </div>

        <div class="row row--align-center row--points">
          <section style="width: 80%">
            <p>
              Познаване на националното законодателство, касаещо зоната на отговорност; прилагане и осъществяване на
              контрол върху изпълнението на законовите разпоредби и изисквания
            </p>
          </section>
          <Score v-model="d.form.scores.eleven" style="width: 20%" :num="true" :locked="d._id" />
        </div>
        <div class="row row--align-center row--points">
          <section style="width: 80%">
            <p>
              Познаване на изискванията и документираните процедури на СУК, СУОС и СУБЗР; използване на системите за
              по-ефективно управление и осъществяване на системен контрол върху спазването на изискванията и
              управлението на документите от целия персонал; лична ангажираност към разработването, актуализирането и
              подобряването на системите
            </p>
          </section>
          <Score v-model="d.form.scores.twelve" style="width: 20%" :num="true" :locked="d._id" />
        </div>

        <section class="row row--score-sum right">
          <p>
            Брой точки:

            {{
              (d.form.scores.nine || 0) +
              (d.form.scores.ten || 0) +
              (d.form.scores.eleven || 0) +
              (d.form.scores.twelve || 0)
            }}
          </p>
        </section>
      </div>

      <div>
        <p class="title">
          <strong>4. Делегиране и комуникация</strong>
        </p>
        <div class="row row--align-center row--points">
          <section style="width: 80%">
            <p>
              Обезпечаване на служителите с точни указания и разяснения при възлагане на задачи/ задължения;
              своевременно предоставяне на наличната информация за тяхното изпълнение
            </p>
          </section>
          <Score v-model="d.form.scores.thirteen" style="width: 20%" :num="true" :locked="d._id" />
        </div>

        <div class="row row--align-center row--points">
          <section style="width: 80%">
            <p>
              Умения за делегиране и разпределение на задачите, за постигане на максимална икономия на време и ресурси;
              ефективност на контрола върху изпълнението
            </p>
          </section>
          <Score v-model="d.form.scores.fourteen" style="width: 20%" :num="true" :locked="d._id" />
        </div>

        <div class="row row--align-center row--points">
          <section style="width: 80%">
            <p>
              Поддържане на добри взаимоотношения в трудовия колектив; отношение към личните проблеми на хората;
              справяне в конфликтни ситуации
            </p>
          </section>
          <Score v-model="d.form.scores.fiveteen" style="width: 20%" :num="true" :locked="d._id" />
        </div>

        <div class="row row--align-center row--points">
          <section style="width: 80%">
            <p>
              Насърчаване на работата в екип и постигането на консенсус при възлагане, изпълнение и отчитане на
              резултатите от работата на структурните единици в ръководеното звено
            </p>
          </section>
          <Score v-model="d.form.scores.sixteen" style="width: 20%" :num="true" :locked="d._id" />
        </div>

        <section class="row row--score-sum right">
          <p>
            Брой точки:
            {{
              (d.form.scores.thirteen || 0) +
              (d.form.scores.fourteen || 0) +
              (d.form.scores.fiveteen || 0) +
              (d.form.scores.sixteen || 0)
            }}
          </p>
        </section>
      </div>

      <div>
        <p class="title">
          <strong>5. Внимание към служителите, мотивация и обучение </strong>
        </p>
        <div class="row row--align-center row--points">
          <section style="width: 80%">
            <p>
              Коректност при общуване с колегите и подчинените; обективност при използване на методите за материално
              стимулиране и умения за използване на нематериални стимули
            </p>
          </section>
          <Score v-model="d.form.scores.seventeen" style="width: 20%" :num="true" :locked="d._id" />
        </div>

        <div class="row row--align-center row--points">
          <section style="width: 80%">
            <p>
              Мотивация на персонала чрез личен пример; създаване на среда и условия за работа, при които хората да са
              мотивирани и сплотени
            </p>
          </section>
          <Score v-model="d.form.scores.eighteen" style="width: 20%" :num="true" :locked="d._id" />
        </div>

        <div class="row row--align-center row--points">
          <section style="width: 80%">
            <p>Готовност за поемане на лична отговорност, в т.ч. и за резултатите на звеното</p>
          </section>
          <Score v-model="d.form.scores.nineteen" style="width: 20%" :num="true" :locked="d._id" />
        </div>
        <div class="row row--align-center row--points">
          <section style="width: 80%">
            <p>
              Ангажираност към процеса на обучение и квалификация на подчинените и личен принос в този процес;
              инициативност за усъвършенстване; оказване на помощ
            </p>
          </section>
          <Score v-model="d.form.scores.twenty" style="width: 20%" :num="true" :locked="d._id" />
        </div>

        <section class="row row--score-sum right">
          <p>
            Брой точки:
            {{
              (d.form.scores.seventeen || 0) +
              (d.form.scores.eighteen || 0) +
              (d.form.scores.nineteen || 0) +
              (d.form.scores.twenty || 0)
            }}
          </p>
        </section>
      </div>

      <div>
        <p class="title">
          <strong>6. Координация и взаимодействие </strong>
        </p>
        <div class="row row--align-center row--points">
          <section style="width: 80%">
            <p>
              Способности за водене на преговори; поддържане на добри отношения и сътрудничество с другите ръководители
              и специалисти в и извън звеното и дружеството
            </p>
          </section>
          <Score v-model="d.form.scores.twentyone" style="width: 20%" :num="true" :locked="d._id" />
        </div>

        <div class="row row--align-center row--points">
          <section style="width: 80%">
            <p>
              Координиране на усилията на различни работни групи или подразделения за постигане целите на звеното и
              дружеството; поддържане на конструктивен диалог с колеги от други СЗ при изпълнение на общи задачи
            </p>
          </section>
          <Score v-model="d.form.scores.twentytwo" style="width: 20%" :num="true" :locked="d._id" />
        </div>

        <div class="row row--align-center row--points">
          <section style="width: 80%">
            <p>
              Участие/принос при вземането на управленски решения от Ръководството на дружеството, за оптимизиране на
              организацията на труда и намиране на решения на актуални проблеми
            </p>
          </section>
          <Score v-model="d.form.scores.twentythree" style="width: 20%" :num="true" :locked="d._id" />
        </div>
        <div class="row row--align-center row--points">
          <section style="width: 80%">
            <p>Обезпечаване на висшето управленско ниво с пълна, достоверна и навременна информация</p>
          </section>
          <Score v-model="d.form.scores.twentyfour" style="width: 20%" :num="true" :locked="d._id" />
        </div>

        <section class="row row--score-sum right">
          <p>
            Брой точки:
            {{
              (d.form.scores.twentyone || 0) +
              (d.form.scores.twentytwo || 0) +
              (d.form.scores.twentythree || 0) +
              (d.form.scores.twentyfour || 0)
            }}
          </p>
        </section>
      </div>
    </section>

    <div class="row wrap">
      <div style="width: 80%" class="title">
        <h1>КРАЙНА ОЦЕНКА на трудовото представяне за периода</h1>
      </div>

      <section class="row row--align-center center points" style="width: 25%">
        <p>ТОЧКИ:</p>
        <p>{{ total.points }}</p>
      </section>
      <section class="row row--align-center center points" style="width: 25%">
        <p>ОЦЕНКА:</p>
        <p>{{ total.score }}</p>
      </section>
    </div>

    <section class="rows-wrapper">
      <div class="row center text-center">
        <p>Оценката може да бъде</p>
      </div>

      <section class="rows-wrapper rows--background">
        <div class="row row--score-table">
          <p>Оценка 1</p>
          <p>“Неприемливо представяне”</p>
          <p>точки 24 - 36</p>
        </div>

        <div class="row row--score-table">
          <p>Оценка 2</p>
          <p>“Приемливо представяне, но е необходимо подобрение”</p>
          <p>точки 37 - 60</p>
        </div>

        <div class="row row--score-table">
          <p>Оценка 3</p>
          <p>“Представянето отговаря на изискванията”</p>
          <p>точки 61 - 84</p>
        </div>

        <div class="row row--score-table">
          <p>Оценка 4</p>
          <p>“Представянето е над изискванията”</p>
          <p>точки 85 - 108</p>
        </div>

        <div class="row row--score-table">
          <p>Оценка 5</p>
          <p>“Много добро представяне”</p>
          <p>точки 109 - 120</p>
        </div>
      </section>

      <div class="row">
        <p><strong>Аргументиране на оценката, препоръки за подобряване и цели за развитие на оценявания</strong></p>
      </div>

      <div class="row">
        <p>1. Относно представянето през изминалия оценъчен период:</p>
      </div>

      <div class="row">
        <Input
          type="textarea"
          icon="."
          placeholder="Попълнете свободен текст (до 165 символа)"
          :maxlength="165"
          label="(попълва се задължително при оценки 1 и 5)"
          :nonRequired="total.score === 1 || total.score === 5 ? false : true"
          :locked="d._id"
          v-model="d.form.performanceText"
        />
      </div>

      <div class="row">
        <p>2. Препоръки за усъвършенстване:</p>
      </div>

      <div class="row">
        <Input
          type="textarea"
          icon="."
          placeholder="Попълнете свободен текст (до 165 символа)"
          :maxlength="165"
          label="(попълва се задължително при оценки 1 и 2)"
          :nonRequired="total.score === 1 || total.score === 2 ? false : true"
          :locked="d._id"
          v-model="d.form.improovementsText"
        />
      </div>
    </section>

    <div class="row row--background">
      <Input
        icon="C"
        placeholder="Дата на създаване"
        label="Дата на създаване"
        v-model="d.form.createdAtReceiver"
        :locked="d._id"
        type="datepicker"
      />
      <Input
        icon="$"
        :placeholder="d.form.user.supervisorFirst ? d.form.user.supervisorFirst.name : 'Имена на Рък 1 (автом.)'"
        label="Оценяващ ръководител 1"
        type="signature"
        :value="d.form.signatures.receiver"
        :locked="d._id"
        @signed="d.form.signatures.receiver = $event"
      />
    </div>

    <template v-if="d._id">
      <div class="brake">ПОПЪЛВА СЕ ОТ СЛУЖИТЕЛЯ</div>

      <section class="rows-wrapper">
        <div class="row center text-center">
          <p><strong>Коментар на оценявания:</strong></p>
        </div>

        <div class="row">
          <p>1. Относно оценката:</p>
        </div>

        <div class="row">
          <Input
            type="textarea"
            :maxlength="165"
            icon="."
            placeholder="Попълнете свободен текст (до 165 символа)"
            label="(попълва се по желание)"
            :nonRequired="true"
            :locked="stageTwoDone || !isCurrUserDocUser"
            v-model="d.form.aboutScoreText"
          />
        </div>

        <div class="row">
          <p>2. Относно препоръките:</p>
        </div>

        <div class="row">
          <Input
            type="textarea"
            :maxlength="165"
            icon="."
            placeholder="Попълнете свободен текст (до 165 символа)"
            label="(попълва се по желание)"
            :nonRequired="true"
            :locked="stageTwoDone || !isCurrUserDocUser"
            v-model="d.form.aboutImproovementsText"
          />
        </div>

        <div class="row center">
          <Approval
            pre="ПОТВЪРЖДАВАМ С ПОДПИСА СИ,"
            yes="ЧЕ СЪМ"
            no="НЕ СЪМ"
            post="СЪГЛАСЕН С ОЦЕНКАТА НА МОЯТА РАБОТА"
            v-model="d.form.approvedByUser"
            :locked="stageTwoDone || !isCurrUserDocUser"
          />
        </div>
      </section>

      <div class="row row--background">
        <Input
          icon="C"
          placeholder="Дата на създаване"
          label="Дата на създаване"
          v-model="d.form.createdAt"
          :locked="stageTwoDone || !isCurrUserDocUser"
          type="datepicker"
        />
        <Input
          icon="$"
          :placeholder="d.form.user.name ? d.form.user.name : 'Име, Фамилия'"
          label="Служител - име и подпис"
          type="signature"
          :value="d.form.signatures.user"
          @signed="d.form.signatures.user = $event"
          :locked="stageTwoDone || !isCurrUserDocUser"
        />
      </div>
    </template>

    <template v-if="stageTwoDone">
      <div class="brake">ПОПЪЛВА СЕ ОТ {{ d.form.user.supervisorSecond.name }}</div>

      <section class="rows-wrapper">
        <div class="row center text-center">
          <p><strong>Мнение на контролиращия ръководител 2:</strong></p>
        </div>

        <div class="row">
          <p>1. Относно оценката за представянето :</p>
        </div>

        <div class="row">
          <Input
            type="textarea"
            :maxlength="165"
            icon="."
            placeholder="Попълнете свободен текст (до 165 символа)"
            label="(попълва се по желание)"
            :nonRequired="true"
            :locked="!isCurrUserUserSupervisorTwo"
            v-model="d.form.isCurrUserUserSupervisorTwo"
          />
        </div>

        <div class="row">
          <p>2. Относно коментара на оценявания :</p>
        </div>

        <div class="row">
          <Input
            type="textarea"
            :maxlength="165"
            icon="."
            placeholder="Попълнете свободен текст (до 165 символа)"
            label="(попълва се по желание)"
            :nonRequired="true"
            :locked="!isCurrUserUserSupervisorTwo"
            v-model="d.form.aboutImproovementsTextSupervisor"
          />
        </div>
      </section>

      <div class="row row--background">
        <Input
          icon="C"
          placeholder="Дата на създаване"
          label="Дата на създаване"
          v-model="d.form.createdAtReceiverTwo"
          :locked="!isCurrUserUserSupervisorTwo"
          type="datepicker"
        />
        <Input
          icon="$"
          :placeholder="d.form.user.supervisorSecond ? d.form.user.supervisorSecond.name : 'Имена на Рък 2 (автом.)'"
          label="Служител - име и подпис"
          type="signature"
          :value="d.form.signatures.receiverTwo"
          :locked="!isCurrUserUserSupervisorTwo"
          @signed="d.form.signatures.receiverTwo = $event"
        />
      </div>
    </template>
  </div>
</template>

<script>
import Approval from '../CreateDocumentApproval';
import Input from '../CreateDocumentInput';
import Score from '../CreateDocumentScore';
export default {
  components: {
    Approval,
    Input,
    Score,
  },
  props: ['d', 'employees', 'pdf', 'invalid', 'isCurrUserDocUser', 'isCurrUserReceiver', 'isCurrUserUserSupervisorTwo'],
  computed: {
    total() {
      let points = 0;
      let score = 0;
      let scores = this.d.form.scores;
      let size = Object.keys(scores).length;

      for (const property in scores) {
        points += scores[property];
      }

      if (size === 24) {
        if (points >= 24 && points <= 36) score = 1;
        if (points >= 37 && points <= 60) score = 2;
        if (points >= 61 && points <= 84) score = 3;
        if (points >= 85 && points <= 108) score = 4;
        if (points >= 109 && points <= 120) score = 5;
      }

      return { points, score };
    },
  },
  watch: {
    'd.form.user'() {
      this.$set(this.d.form.user, 'supervisorSecond', this.d.form.user.supervisorSecond);
    },
  },
  data() {
    return {
      stageTwoDone: false,
      first: 0,
    };
  },
  beforeMount() {
    if (this.d.form.signatures.user) this.stageTwoDone = true;

    if (!this.d._id) {
      this.$set(this.d.form, 'scores', {});
      this.$set(this.d.form.signatures, 'receiver', null);
      this.$set(this.d.form.signatures, 'user', null);
      this.$set(this.d.form.signatures, 'receiverTwo', null);
      this.d.form.createdAtReceiver = new Date();
    }
    if (this.d.form.signatures.receiver) this.d.form.createdAt = new Date();
    if (this.d.form.signatures.user) this.d.form.createdAtReceiverTwo = new Date();
  },
};
</script>