<template>
  <div
    class="datepicker-modal"
    :class="{ 'datepicker-modal--sidepanel': isSidePanelOpen }"
    @click.self="$emit('close')"
  >
    <transition name="modal" fade>
      <div class="modal">
        <Datepicker
          :language="bg"
          :inline="true"
          :monday-first="true"
          :minimumView="currentView ? currentView : maxView ? maxView : 'day'"
          :initial-view="currentView ? currentView : yearFirst ? 'year' : ''"
          :disabled-dates="disabledDates"
          :full-month-name="true"
          @changedMonth="changedMonth"
          :value="value"
          @selected="currentView ? resetCurrentView($event) : $emit('selected', $event)"
        >
          <!-- :maximumView="maxView || yearFirst ?  'year' : 'day' " -->
          <header slot="beforeCalendarHeader" class="custom-header">
            <div class="month-year" :class="{ 'month-year---hidden': currentView !== '' }">
              <span @click="currentView = 'month'" class="day__month_btn up">{{ dateMonth }}</span>
              <span @click="currentView = 'year'" class="day__month_btn up">{{ dateYear }}</span>
            </div>
          </header>
        </Datepicker>
      </div>
    </transition>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { bg } from 'vuejs-datepicker/dist/locale';
import { mapGetters } from 'vuex';
import { getMonth, getYear } from 'date-fns';
export default {
  props: ['value', 'disabledDates', 'maxView', 'yearFirst'],
  computed: {
    ...mapGetters(['isSidePanelOpen']),
  },
  components: {
    Datepicker,
  },
  data() {
    return {
      bg,
      currentView: '',
      dateMonth: '',
      dateYear: '',
      currentDate: '',
    };
  },
  methods: {
    resetCurrentView(v) {
      this.currentView = '';
      if(v) this.changedMonth(v);
    },
    changedMonth(v) {
      if (v.month) this.dateMonth = v.month;
      else {
        this.dateMonth = this.bg._months[getMonth(new Date(v))];
        this.dateYear = getYear(new Date(v));
      }
      this.resetCurrentView();
    },
    getCurrentMonth() {
      return this.bg._months[getMonth(new Date(this.currentDate))];
    },
  },
  mounted() {
    this.currentDate = this.value || new Date();
    this.dateMonth = this.getCurrentMonth();
    this.dateYear = getYear(new Date(this.value || new Date()));
  },
};
</script>

<style lang="scss">
@import '@/scss/base.scss';
.datepicker-modal {
  align-items: center;
  background-color: rgba($color: #000, $alpha: 0.4);
  height: 100%;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  @include transition($transition);

  .modal {
    box-shadow: 0 0 60px 0 rgba($color: #000, $alpha: 0.2);
  }

  .vdp-datepicker__calendar {
    border-radius: 4px;
    @include noselect();
    header {
      &:not(.custom-header) {
        position: relative;

        .day__month_btn {
          visibility: hidden;
        }
      }
      &.custom-header {
        display: flex;
        position: absolute;
        width: 100%;

        .month-year {
          display: flex;
          flex-grow: 2;
          justify-content: center;

          &.month-year---hidden {
            span {
              visibility: hidden;
            }
          }

          span {
            width: calc(40% - 10px);
          }
        }
      }

      span {
        @include transition($transition);
      }
      .month__year_btn {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
      .prev,
      .next {
        border-bottom-right-radius: 4px;
        border-top-left-radius: 4px;
        color: transparent;
        text-indent: initial;
        &::after {
          align-items: center;
          border: 0;
          content: 'D';
          color: #000;
          display: flex;
          font-size: 0.8rem;
          font-family: 'icons';
          height: 100%;
          justify-content: center;
          left: 0;
          margin: 0;
          opacity: 1;
          top: 0;
          width: 100%;
          @include transform(initial);
        }
        &.disabled::after {
          border: 0;
          opacity: 0.1;
        }
      }
      .next {
        border-radius: 0;
        border-bottom-left-radius: 4px;
        border-top-right-radius: 4px;
        &::after {
          content: 'V';
        }
      }
      .prev:not(.disabled):hover,
      .next:not(.disabled):hover,
      .up:not(.disabled):hover {
        background-color: rgba($color: $lightAccent, $alpha: 0.2);
      }
    }
    .cell {
      border-radius: 4px;
      height: 36px;
      line-height: 36px;
      margin: 2px;
      position: relative;
      width: calc(14.285714285714286% - 4px);
      @include transition($transition);
      &.month,
      &.year {
        width: calc(33.33% - 4px);
      }
      &:not(.blank):not(.disabled):not(.selected).day:hover,
      &:not(.blank):not(.disabled):not(.selected).month:hover,
      &:not(.blank):not(.disabled):not(.selected).year:hover {
        background-color: rgba($color: $lightAccent, $alpha: 0.2);
        border: 1px solid transparent;
      }
      &.selected {
        background-color: $lightAccent;
        color: #fff;
      }
    }
  }
}

@media screen and (min-width: $sm) {
  .datepicker-modal {
    &.datepicker-modal--sidepanel {
      padding-left: $sidepanel;
    }
  }
}
</style>
