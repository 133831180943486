<template>
  <div class="modal-wrapper">
    <transition name="modal" appear>
      <div class="modal">
        <div class="icon close" @click="close">g</div>
        <div class="user-data">
          <div class="avatar">
            <img src="@/assets/img/avatar.png" alt="Avatar" />
          </div>
          <div class="texts">
            <p>{{ currentUser.name }}</p>
            <p>{{ currentUser.company }}</p>
          </div>
        </div>
        <div class="links">
          <router-link :to="`/users/${currentUser._id}`" @click.native="close()">
            <span class="icon">N</span>
            Преглед и редакция на моя профил
          </router-link>
          <a
            @click="
              $store.dispatch('selectCompany', null);
              close();
            "
          >
            <span class="icon">P</span>
            Промяна на фирма от групата на КЦМ
          </a>
          <a @click="logout">
            <span class="icon">g</span>
            Изход от портала
          </a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import config from '../../config-public.json';
export default {
  computed: {
    ...mapGetters(['currentUser']),
  },
  data() {
    return {
      config,
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    logout() {
      this.$store.dispatch('logout').then(() => {
        location.reload();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.modal-wrapper {
  align-items: center;
  background-color: #102538cc;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 12;
  .modal {
    align-items: center;
    background-color: #fbfbfb;
    border-radius: 10px;
    flex-direction: column;
    display: flex;
    padding: 11px;
    .close {
      align-self: flex-end;
      border-radius: 50%;
      color: #a9b1b5;
      font-size: 35px;
      @include hover();
    }

    .user-data {
      .avatar {
        border: 1px solid #35aadf;
        border-radius: 50%;
        display: flex;
        height: 120px;
        overflow: hidden;
        width: 120px;
        img {
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }
      .texts {
        margin-bottom: 3rem;
        margin-top: 8px;
        p {
          color: #004d94;
          text-align: center;
          &:first-child {
            font-size: $px16;
            font-weight: 500;
          }
          &:last-child {
            font-size: $px12;
            opacity: 0.8;
          }
        }
      }
    }
    .links {
      a {
        align-items: center;
        background-color: #effaff;
        border: 2px solid #35aadf;
        border-radius: 10px;
        color: #35aadf;
        cursor: pointer;
        display: flex;
        font-size: $px14;
        font-weight: 500;
        padding: 12px 10px;
        white-space: nowrap;

        &:not(:last-child) {
          margin-bottom: 8px;
        }

        .icon {
          display: inline-block;
          font-size: $px24;
          margin-right: 12px;
        }
      }
    }
  }
}
</style>