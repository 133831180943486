<template>
  <transition name="fade">
    <div class="modal-wrapper" @click.self="close(false)">
      <transition name="modal" appear>
        <div class="modal">
          <header>
            <p>ПРОМЯНА НА КАТЕГОРИЯ</p>
            <p>Изберете нова категория на документа</p>
          </header>
          <main>
            <div v-for="(c, i) in categoriesArray" :key="`${c}-${i}`" @click="close(c.origin)">
              <span class="icon">{{ c.icon }}</span>
              <p>{{ c.name }}</p>
            </div>
          </main>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import categories from '@/data/trdocCategories.json';
export default {
  props: ['d'],
  computed: {
    getCategoryNames() {
      return Object.keys(this.categories);
    },
  },
  data() {
    return {
      categories,
      categoriesArray: [
        { name: 'ЛИЧНИ', origin: 'Лични документи, образование, квалификация и опит', icon: '2' },
        { name: 'ДОГОВОРИ И АНЕКСИ', origin: 'Договори и допълнителни споразумения', icon: 'y' },
        { name: 'ЗАЯВЛЕНИЯ И ДЕКЛАРАЦИИ', origin: 'Заявления, декларации и длъжностни характеристики', icon: 'z' },
        { name: 'СЛУЖЕБНИ БЕЛЕЖКИ', origin: 'Служебни бележки', icon: '0' },
        { name: 'ДРУГИ', origin: 'Други документи', icon: '1' },
      ],
    };
  },
  methods: {
    close(change) {
      if (change && change !== this.d.category) {
        this.$set(this.d, 'category', change);
        this.$set(this.d, 'numberPrefix', this.categories[change]);
      }
      this.$emit('close', change);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';

.modal-wrapper {
  align-items: center;
  background-color: #00000033;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;

  .modal {
    background-color: #ffff;
    box-shadow: 0px 0px 50px #00000029;
    border-radius: 12px;
    color: #fff;
    max-height: 400px;
    max-width: 400px;
    position: relative;
    overflow: auto;
    @include noselect();

    header {
      color: black;
      padding: 20px 20px 40px;
      text-align: center;
      p {
        font-size: $px18;
        &:last-of-type {
          opacity: 0.8;
        }
      }
    }

    main {
      color: #0095f6;
      width: 100%;

      div {
        align-items: center;
        border-top: 1px solid #dbdbdb;
        display: flex;
        gap: 10px;
        height: 40px;
        justify-content: center;
        padding: 10px;
        width: 100%;
        @include transition($transition);
        &:hover {
          background: #f5f5f5 0% 0% no-repeat padding-box;
          cursor: pointer;
        }

        p {
          font-size: $px15;
        }
        .icon {
          font-size: $px18;
        }
      }
    }
  }
}
</style>