<template>
  <main class="toggle-size-wrapper" :class="{ disabled: disabled, 'toggle-size-wrapper--small': !placeholder }">
    <p class="placeholder">
      {{ placeholder }}
    </p>

    <div class="plus--minus">
      <section>
        <span @click="remove()">-</span>
      </section>

      <p class="num" v-if="placeholder">{{ this.value.length }}</p>
      <input class="num" v-else type="text" placeholder="0" v-model="size" @input="test($event.target.value)" />

      <section>
        <span @click="add()">+</span>
      </section>
    </div>
  </main>
</template>

<script>
export default {
  props: ['placeholder', 'value', 'index', 'allowZero', 'disabled', 'maxSize'],
  data() {
    return {
      size: null,
    };
  },
  watch: {
    value() {
      if (this.value.length) this.size = this.value.length;
    },
  },
  methods: {
    test(t) {
      this.$emit('clear');
      t = t.replace(/\D/g, '');
      this.size = Number(t);
      for (let i = 0; i < this.size; i++) {
        this.$emit('add');
      }
    },
    remove() {
      if (!this.disabled) {
        if (this.value.length > (this.allowZero ? 0 : 1)) {
          this.value.splice(this.value.length - 1, 1);
        }
      }
    },
    add() {
      if (!this.disabled) {
        if (this.maxSize) {
          if (this.maxSize > this.value.length) this.value.push({});
          else this.$snotify.warning(`Максималният брой е ${this.maxSize}`);
        } else !this.placeholder ? this.$emit('add') : this.value.push({});
      }
    },
  },
  mounted() {
    if (this.value && this.value.length) this.size = this.value.length;
  },
};
</script>

<style lang="scss">
@import '@/scss/base.scss';
.toggle-size-wrapper {
  align-items: center;
  background: rgb(99, 115, 130, 0.5) 0% 0% no-repeat padding-box;
  border-radius: 161px;
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 7px 15px;
  width: 100%;
  @include noselect();
  @include transition($transition);

  &.toggle-size-wrapper--small {
    background: initial;
    width: fit-content;

    .plus--minus {
      .num {
        background: #0c518f;
        border-radius: 3px;
        border: none;
        color: #ffffff;
        padding: 5px;
        text-align: center;
        min-width: 24px;
        outline: none;
        width: 36px;

        &::placeholder {
          color: #ffffff; /* Change this to your desired color */
        }
      }

      > section {
        background: #858585;
        color: #ffffff;
      }
    }
  }
  .placeholder {
    max-width: 80%;
  }

  .plus--minus {
    align-items: center;
    display: flex;
    gap: 0.5rem;

    > section {
      background: white;
      border-radius: 50%;
      color: rgb(99, 115, 130, 0.5);
      cursor: pointer;
      font-size: 22px;
      height: 20px;
      text-align: center;
      position: relative;
      width: 20px;
    }
    span {
      align-items: center;
      content: '';
      display: flex;
      height: 100%;
      justify-content: center;
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    }
  }

  &.disabled {
    opacity: 0.5;
    .plus--minus {
      > section {
        cursor: default;
      }
    }
  }
}
@media screen and (max-width: $s), (orientation: landscape) and (max-width: $landscape) {
  .toggle-size-wrapper {
    border-radius: 13px;
    flex-direction: column;
    gap: 1rem;
    padding: 7px 5px;

    .plus--minus {
      gap: 15px;
      font-size: 22px;

      section {
        font-size: 35px;
        height: 25px;
        width: 25px;
      }
    }
  }
}
</style>