<template>
  <div class="document-form-type">
    <div class="row center text-center column">
      <h1>
        ДЕКЛАРАЦИЯ ЗА <br />
        ПРЕИЗДАВАНЕ НА ПЛАСТИКА <br />
        ЗА Е-ВАУЧЕР
      </h1>
      <p class="doc-number" v-if="d.number">№{{ d.numberPrefix }}-{{ d.number }}</p>
    </div>

    <div class="row center text-center">
      <p class="smaller-line-height">
        <strong>Аз, долуподписаният:</strong>
      </p>
    </div>

    <section class="rows-wrapper">
      <div class="row">
        <Input
          style="width: 75%"
          icon="F"
          placeholder="Име Презиме Фамилия"
          label="От Работник/Служител (изберете или потърсете име)"
          type="dropdown"
          @fetch="$emit('fetch-employees', $event)"
          :locked="d._id || isCurrUserDocUser"
          :list="employees"
          prop="name"
          identifier="_id"
          v-model="d.form.user"
        />
        <Input
          style="width: 25%"
          icon="+"
          placeholder="№"
          label="Служебен номер"
          v-model="d.form.user.number"
          :autoFill="true"
        />
      </div>
      <div class="row">
        <Input
          icon="b"
          placeholder="Длъжност"
          label="Длъжност на работника или служителя (автом.)"
          :value="d.form.user.position ? d.form.user.position.name : ''"
          :autoFill="true"
        />
        <Input
          :icon="'&'"
          placeholder="Структурно звено"
          label="Структурно звено (участък, цех, лаборатория, отдел, отделение, група)"
          :value="d.form.user.structuralUnit ? d.form.user.structuralUnit.name : ''"
          :autoFill="true"
        />
      </div>
    </section>

    <div class="row center text-center">
      <p class="smaller-line-height">
        съм уведомен, че трябва да заплатя такса за преиздаване на пластика за дигитални ваучери по Наредба 7 от 2003 г., в
        размер на 10 (десет) лева.
      </p>
    </div>

    <div class="row">
      <p>Съгласен съм сумата да бъде удържана от работната ми заплата.</p>
    </div>

    <div class="row row--background">
      <Input
        icon="C"
        placeholder="Дата на създаване"
        label="Дата на създаване"
        v-model="d.form.createdAt"
        type="datepicker"
      />
      <Input
        icon="$"
        :placeholder="d.form.user.name ? d.form.user.name : 'Име, Фамилия'"
        label="Служител - име и подпис"
        type="signature"
        :value="d.form.signatures.user"
        @signed="d.form.signatures.user = $event"
      />
    </div>
  </div>
</template>

<script>
import Input from '../CreateDocumentInput';
export default {
  components: {
    Input,
  },
  props: ['d', 'employees', 'pdf', 'invalid', 'isCurrUserDocUser'],
  methods: {},
};
</script>