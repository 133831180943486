<template>
  <transition name="fade">
    <div class="modal-wrapper" @click.self="close" v-if="!activeCompany">
      <transition name="modal" appear>
        <div class="modal">
          <span class="close" @click="close">
            <span class="icon">X</span>
          </span>
          <header>
            <p>ИЗБЕРЕТЕ ФИРМА ОТ ГРУПАТА НА КЦМ</p>
            <p>Разделите, които виждате зависят от избраната фирма.</p>
            <p>Изборът винаги може да се промени от менюто в горният десен ъгъл.</p>
          </header>
          <main>
            <button
              v-for="(c, i) in companies"
              :class="{ active: c === selected }"
              :key="`select-company-${i}`"
              :disabled="i > 1"
              @click="selected = c"
            >
              {{ c }}
            </button>
          </main>
          <footer>
            <button :disabled="!selected" @click="save">Избери</button>
          </footer>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['activeCompany']),
  },
  data() {
    return {
      companies: [
        'КЦМ АД',
        'КЦМ 2000',
        'КЦМ Технолоджи ЕООД',
        'Комплексен индустриален сервиз ЕООД',
        'Металургремонт 21 АД',
        'Стопански дейности КЦМ ООД',
        'Стройсервиз ЕООД',
        'Индустриална зона КЦМ ЕООД',
        'Лъки Инвест АД',
        'Върба-Батанци АД',
        'BLZ Metalikon Ltd',
        'MCM BV',
      ],
      selected: '',
    };
  },
  methods: {
    close() {
      this.$store.dispatch('selectLatestCompany');
      this.selected = this.activeCompany;
    },
    save() {
      if (!this.selected) {
        this.$snotify.error('Трябва да изберете компания!');
      }
      this.$store.dispatch('selectCompany', this.selected);
    },
  },
  mounted() {
    this.selected = this.activeCompany;
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.modal-wrapper {
  align-items: center;
  background-color: #00000033;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;

  .modal {
    background-color: $mainColor;
    border-radius: 10px;
    color: #fff;
    max-height: calc(100% - 20px);
    max-width: calc(100% - 20px);
    padding: 24px 50px 36px;
    position: relative;
    @include noselect();

    .close {
      align-items: center;
      border-radius: 4px;
      cursor: pointer;
      display: flex;
      height: 20px;
      justify-content: center;
      position: absolute;
      right: 20px;
      top: 20px;
      width: 20px;
      @include transition($transition);
      .icon {
        font-size: 1rem;
      }

      &:hover {
        background-color: rgba($color: #fff, $alpha: 0.1);
      }
    }

    header {
      font-size: $px12;
      text-align: center;

      p {
        &:first-child {
          margin-bottom: $px11;
        }
        &:not(:first-child) {
          color: rgba($color: #fff, $alpha: 0.5);
        }
      }
    }

    main {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin: 36px 0 29px;
      max-width: 515px;
      max-height: 50vh;
      overflow: auto;
      @include scrollbar(5px, $mainColor);
      button {
        align-items: center;
        background-color: transparent;
        border: 2px solid $lightBlue;
        border-radius: 10px;
        color: $lightBlue;
        display: inline-flex;
        font-size: $px16;
        font-weight: 500;
        justify-content: center;
        height: 60px;
        text-align: center;
        width: 250px;

        &:not(:disabled):not(.active) {
          cursor: pointer;
          @include transition($transition);
          &:hover {
            background-color: #176fbf80;
          }
        }

        &.active {
          background-color: #176fbf;
          border-color: #176fbf;
          color: #fff;
        }

        &:disabled {
          opacity: 0.5;
        }
      }
    }

    footer {
      text-align: center;
      button {
        background-color: #1661a7;
        border-radius: 24px;
        border: 0;
        color: #fff;
        font-size: $px14;
        font-weight: 700;
        height: 48px;
        width: 370px;

        @include hover();

        &:disabled {
          cursor: default;
          opacity: 0.5;
        }
      }
    }
  }
}

@media screen and (max-width: $s), (orientation: landscape) and (max-width: $landscape) {
  .modal-wrapper {
    .modal {
      main {
        button {
          width: 100%;
        }
      }

      footer {
        button {
          width: 100%;
        }
      }
    }
  }
}
</style>