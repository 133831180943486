<template>
  <div class="settings__templates">
    <div class="tiny__content">
      <p class="tiny__content__title">ИЗПЪЛНИТЕЛНО И ОПЕРАТИВНО РЪКОВОДСТВО</p>
      <hr class="line" />
      <p class="text">
        <strong> Списък на изпълнително и оперативно ръководство</strong>
      </p>
      <div class="add" @click="add">
        <span class="icon">A</span>
        <p>ДОБАВИ СЛУЖИТЕЛ</p>
      </div>

      <section class="row-wrapper">
        <div class="row" v-for="(f, i) in managers" :key="`m-${i}`">
          <Input
            v-model="f.user"
            type="dropdown"
            prop="name"
            noRightIcon="true"
            :invalid="invalid === i"
            @input="invalid = ''"
            @fetch="getEmployees($event)"
            :list="employees"
            readonly="true"
          />
          <span class="delete icon" @click="deleteSupervisor(f.user._id, i)">{{ '{' }}</span>
        </div>
      </section>
      <div class="tiny__content__buttons">
        <transition name="fade">
          <Loading :success="success" v-if="loading" />
        </transition>
        <Button @click="cancel">Откажи</Button>
        <Button class="button--blue" @click="save" :disabled="loading">Запиши</Button>
      </div>
    </div>
  </div>
</template>

<script>
import Input from '@/components/inputs/Input';
import Button from '@/components/Button';
import Loading from '@/components/Loading';
export default {
  components: {
    Input,
    Button,
    Loading,
  },
  data() {
    return {
      loading: false,
      invalid: '',
      success: undefined,
      employees: [],
      managers: [],
    };
  },
  methods: {
    cancel() {
      this.$router.push('/');
    },
    add() {
      this.managers.unshift({});
    },
    deleteSupervisor(id, index) {
      if (confirm('Сигурни ли сте, че искате да изтриете този ръководител?')) {
        if (id) {
          let url = `/users/executive-and-operational-management/${id}`;
          this.$apiService.post(url).then(() => {
            this.getManagers();
          });
        }
        this.managers.splice(index, 1);
        this.invalid = '';
      }
    },
    getManagers() {
      let url = `/users/executive-and-operational-management`;

      this.$apiService.get(url).then((res) => {
        const result = res.data.map((u) => {
          return { user: u };
        });

        this.managers = result;
      });
    },
    getEmployees(search) {
      let url = `/users/active-employees`;
      if (search) url += `?search=${search}`;
      this.$apiService.get(url).then((res) => {
        this.employees = res.data;
      });
    },
    isValid() {
      for (let i = 0; i < this.managers.length; i++) {
        let supervisor = this.managers[i];

        if (!supervisor.user) {
          this.invalid = i;
          this.$snotify.error('Всички полета са задължителни!');
          return false;
        }
      }

      return true;
    },
    save() {
      if (this.isValid()) {
        this.loading = true;
        this.$apiService
          .post('/users/executive-and-operational-management', this.managers)
          .then(() => {
            this.success = true;
            this.$snotify.success('Имената са запазени успешно');
          })
          .catch((err) => {
            this.success = false;
            console.log(err);
          })
          .then(() => {
            setTimeout(() => {
              this.loading = false;
              this.success = undefined;
              this.getManagers();
            }, 1000);
          });
      }
    },
  },
  mounted() {
    this.getEmployees();
    this.getManagers();
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.text {
  margin-bottom: calc($px11 / 2);
}
.add {
  align-items: center;
  background: #e2e9ee 0% 0% no-repeat padding-box;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 5px;
  margin: 15px 0;
  width: 100%;
  @include noselect();
  .icon {
    color: #005a9e;
    font-size: 20px;
    margin-right: 5px;
  }
  p {
    font-weight: bold;
  }
}
.row-wrapper {
  max-height: 40vh;
  min-height: 40vh;
  overflow: auto;
  padding: 0 1px;
  @include transition($transition);
}
.row {
  position: relative;
  margin-top: 5px;
  width: 100%;
  &:hover {
    .delete {
      display: block;
    }
  }
  .input-wrapper {
    width: 100%;
  }
  .delete {
    bottom: 8px;
    cursor: pointer;
    color: #b71818;
    display: none;
    font-size: 20px;
    position: absolute;
    right: 10px;
    @include noselect();
  }
}
.tiny__content__buttons {
  align-items: center;
}
</style>
