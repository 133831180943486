import Supervisors from '@/views/settings/Supervisors.vue'
import ExecutiveAndOperationalManagement from '@/views/settings/ExecutiveAndOperationalManagement.vue'


export default [{
    path: '/settings',
    beforeEnter: (to, from, next) => {
      next({
        path: '/settings/supervisors'
      })
    }
  },
  {
    path: '/settings/supervisors',
    name: "settingsSupervisors",
    component: Supervisors,
    meta: {
      requiresAuth: true,
      rolesAllowed: ['admin']
    },
  },
  {
    path: '/settings/executive-and-operational-management',
    name: "settingsExecutiveAndOperationalManagement",
    component: ExecutiveAndOperationalManagement,
    meta: {
      requiresAuth: true,
      rolesAllowed: ['admin']
    },
  },
]