<template>
  <div class="document-status">
    <div class="stages" v-if="canHaveStages && ds">
      <section class="signed">
        <span class="icon">\</span>
      </section>
      <!-- <section :class="{ waiting: !d.form.signatures.hr, signed: d.form.signatures.hr }"> -->
      <section :class="{ waiting: !ds.two, signed: ds.two }">
        <span class="stage-line"></span>
        <span class="icon">{{ `]` }}</span>
      </section>
      <!-- :class="{
          waiting: d.form.signatures.hr && !d.form.signatures.receiver && !d.signedWithCertificate,
          signed: !agreementStages ? d.form.signatures.receiver : d.signedWithCertificate,
        }" -->
      <section
        :class="{
          waiting: ds.three.w,
          signed: ds.three.s,
        }"
      >
        <span class="stage-line"></span>
        <span class="icon">^</span>
      </section>
      <!-- :class="{
          waiting: !agreementStages
            ? d.form.signatures.receiver && !d.form.signatures.receiverTwo
            : d.signedWithCertificate && !d.finalized,
          signed: !agreementStages ? d.form.signatures.receiverTwo : d.finalized, -->
      <section
        v-if="ds.four"
        :class="{
          waiting: ds.four.w,
          signed: ds.four.s,
        }"
      >
        <span class="stage-line"></span>
        <span class="icon">`</span>
      </section>
    </div>

    <div :class="`status status--${type}`">
      <p>
        <template v-if="d.annulled">АНУЛИРАН</template>
        <template v-else-if="type === 'approved'">ЗАВЪРШЕН ДОКУМЕНТ</template>
        <template v-else-if="type === 'waiting'">{{ state }}</template>
        <template v-else-if="type === 'not-approved'">НЕОДОБРЕН</template>
        <template v-else-if="type === 'used-as-parent'">ГЕНЕРИРАНО ДС</template>
        <template v-else-if="type === 'used-as-parent-card'">ГЕНЕРИРАНО ЗАЯВЛЕНИЕ</template>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['type', 'd'],
  computed: {
    ds() {
      let s = this.d.form.signatures;
      let d = this.d;

      if (['positionAgreement', 'salaryAgreement', 'position', 'salary', 'retirement'].indexOf(d.type.type) > -1) {
        return {
          two: s.hr,
          three: {
            w: s.hr && !s.receiver && !d.signedWithCertificate,
            s: !this.agreementStages ? s.receiver : d.signedWithCertificate,
          },
          four: {
            w: !this.agreementStages ? s.receiver && !s.receiverTwo : d.signedWithCertificate && !d.finalized,
            s: !this.agreementStages ? s.receiverTwo : d.finalized,
          },
        };
      } else if (d.type.type === 'mentor' || d.type.type === 'mentorNew') {
        return {
          two: s.hr,
          three: {
            w: !s.user,
            s: s.user,
          },
        };
      } else if (d.type.type === 'scoreCardStudent') {
        return {
          two: s.receiverTwo,
          three: {
            w: !s.receiverThree,
            s: s.receiverThree,
          },
        };
      } else if (['scoreCardPersonal', 'scoreCardExpert', 'scoreCardSupervisor'].indexOf(d.type.type) > -1) {
        return {
          two: s.user,
          three: {
            w: !s.receiverTwo,
            s: s.receiverTwo,
          },
        };
      } else if (d.type.type === 'schoolOtpusk') {
        return {
          two: s.hr,
          three: {
            w: !s.receiver,
            s: s.receiver,
          },
        };
      }

      return false;
    },
    canHaveStages() {
      return (
        ['email', 'personal', 'introduction', 'introductionChange', 'joining', 'helping', 'newPlastic'].indexOf(this.d.type.type) ===
          -1 && this.type === 'waiting'
      );
    },
    state() {
      let state = '';
      let s = this.d.form.signatures;

      if (['positionAgreement', 'salaryAgreement', 'salary', 'position', 'retirement'].indexOf(this.d.type.type) > -1) {
        if (!s.hr) {
          state = 'ОЧАКВА СЕ ПОДПИС УЧР';
        }

        if (this.agreementStages && s.hr) {
          if (!this.d.signedWithCertificate) {
            state = `ОЧАКВА СЕ КЕП: ${this.d.receiver.name}`;
          } else if (!this.d.finalized) {
            state = `ОЧАКВА СЕ ОДОБРЕНИЕ СЛУЖИТЕЛ`;
          }
        } else if (!this.agreementStages && s.hr) {
          if (!s.receiver) {
            state = 'ОЧАКВА СЕ ПОДПИС РЪК-Л 1';
          } else if (!s.receiverTwo) {
            state = 'ОЧАКВА СЕ ПОДПИС РЪК-Л 2';
          }
        }
      } else if (this.d.type.type === 'mentor' || this.d.type.type === 'mentorNew') {
        if (!s.hr) {
          state = 'ОЧАКВА СЕ ПОДПИС УЧР';
        } else if (!s.user) {
          state = 'ОЧАКВА СЕ ПОДПИС СЛУЖИТЕЛ';
        }
      } else if (this.d.type.type === 'scoreCardStudent') {
        if (!s.receiverTwo) {
          state = 'ОЧАКВА СЕ ПОДПИС РЪК-Л 2';
        } else if (!s.receiverThree) {
          state = 'ОЧАКВА СЕ ПОДПИС РЪК-Л 3';
        }
      } else if (['scoreCardPersonal', 'scoreCardExpert', 'scoreCardSupervisor'].indexOf(this.d.type.type) > -1) {
        if (!s.user) {
          state = 'ОЧАКВА СЕ ПОДПИС СЛУЖИТЕЛ';
        } else if (!s.receiverTwo) {
          state = 'ОЧАКВА СЕ ПОДПИС РЪК-Л 2';
        }
      } else if (this.d.type.type === 'schoolOtpusk') {
        if (!s.hr) {
          state = 'ОЧАКВА СЕ ПОДПИС УЧР';
        } else if (!s.receiver) {
          state = 'ОЧАКВА СЕ ПОДПИС ДИРЕКТОР';
        }
      }

      return state;
    },
  },
  data() {
    return {
      agreementStages: false,
    };
  },
  mounted() {
    this.agreementStages = this.d.type.type === 'positionAgreement' || this.d.type.type === 'salaryAgreement';
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.document-status {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 185px;

  .stages {
    display: flex;
    justify-content: center;

    section {
      align-items: center;
      display: flex;

      &.signed {
        .icon {
          color: #5c8541;
        }
        .stage-line {
          background: #5c8541;
        }
      }
      &.waiting {
        .icon {
          color: #e18040;
        }
        .stage-line {
          background: #e18040;
        }
      }

      .icon {
        color: #9dabb8;
        font-size: 20px;
        position: relative;
        z-index: 2;
      }

      .stage-line {
        background: #9dabb8;
        display: flex;
        height: 3px;
        position: relative;
        width: 15px;

        &::before {
          background: inherit;
          content: '';
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          text-align: center;
          width: calc(100% + 1px);
        }
      }
    }
  }
  .status {
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 178px;
    padding: 3px;

    &.status--approved {
      background: #97c678;
    }
    &.status--waiting {
      background: #e18040;
    }
    &.status--not-approved {
      background: #c15252;
    }
    &.status--used-as-parent,
    &.status--used-as-parent-card {
      background: #5c8541;
    }
    &.status--annulled {
      background: #d23e3e;
    }
  }

  p {
    color: #fff;
    font-size: $px10;
    text-transform: uppercase;
  }
}
</style>