import { ApiService } from '@/common/api.service';

const state = {
  errors: null,
  user: {},
  twoFA: false,
  isAuthenticated: undefined,
};
const getters = {
  authErrors: (state) => state.errors,
  currentUser: (state) => state.user,
  twoFA: (state) => state.twoFA,
  isAuthenticated: (state) => state.isAuthenticated,
};

const actions = {
  checkAuth(context) {
    return new Promise((res) => {
      context.commit('purgeError');
      ApiService.get('/auth/validation')
        .then((response) => {
          if (response.data.user) {
            context.commit('setAuth', response.data);
          } else {
            context.dispatch('logout');
          }
          res();
        })
        .catch(() => {
          context.dispatch('logout');
          res();
        });
    });
  },
  login(context, user) {
    return new Promise((res, rej) => {
      ApiService.post('/auth/login', user)
        .then((response) => {
          context.commit('setAuth', response.data);
          res(response);
        })
        .catch((err) => {
          rej(err);
        });
    });
  },
  logout(context) {
    return new Promise((res, rej) => {
      ApiService.post('/auth/logout')
        .then((response) => {
          context.commit('purgeAuth');
          res(response);
        })
        .catch((err) => {
          rej(err);
        });
    });
  },
};

const mutations = {
  setAuth(state, data) {
    state.user = data.user;
    state.twoFA = data['2fa'];
    state.isAuthenticated = true;
    state.errors = null;
  },
  purgeAuth(state) {
    state.user = {};
    state.isAuthenticated = false;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
