import axios from 'axios';
import store from '@/store/index';

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) store.dispatch('error', error.response.data.message);
    return Promise.reject(error);
  }
);

export const ApiService = {
  get(resource) {
    return axios.get(`/api${resource}`);
  },
  post(resource, data) {
    return axios.post(`/api${resource}`, data);
  },
  put(resource, data) {
    return axios.put(`/api${resource}`, data);
  },
  delete(resource) {
    return axios.delete(`/api${resource}`);
  },
};
