import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import store from '@/store/index';
import { loginPath } from '../../config-public.json';
import settings from './settings';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true,
      companiesAllowed: ['КЦМ АД'],
    },
  },
  {
    path: '/manually-uploaded',
    name: 'ManuallyUploaded',
    component: Home,
    meta: {
      requiresAuth: true,
      companiesAllowed: ['КЦМ АД'],
    },
  },
  {
    path: '/styleguide',
    name: 'styleguide',
    component: () => import('@/views/Styleguide.vue'),
  },
  ...settings
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  store.dispatch('checkAuth').then(() => {
    if (!store.getters.twoFA) {
      if (from.query.openDocument) window.location.href = loginPath + `?2fa=1&openDocument=${from.query.openDocument}`;
      else window.location.href = loginPath + '?2fa=1';
    }
    // inactive or fired
    // if (store.getters.currentUser.inactive || store.getters.currentUser.fired) {

    if (store.getters.currentUser.fired) {
      store.dispatch('logout').then(() => {
        window.location.href = loginPath;
      });
    }
    if (to.meta.companiesAllowed) {
      if (to.meta.companiesAllowed.indexOf(store.getters.activeCompany) < 0) {
        Vue.$snotify.warning(`Нямате достъп до Досиетата`);
        store.dispatch('logout').then(() => {
          window.location.href = loginPath;
        });
      }
    }

    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (store.getters.isAuthenticated) {
        if (store.getters.currentUser.companies.indexOf(store.getters.activeCompany) < 0) {
          Vue.$snotify.warning(`Нямате достъп до страниците от ${store.getters.activeCompany}`);
          return next({ path: '/' });
        }

        // if only specific roles are allowed
        if (to.meta.rolesAllowed) {
          let match = false;
          for (let r of to.meta.rolesAllowed) {
            if (store.getters.currentUser.roles[r]) {
              match = true;
              break;
            }
          }
          if (!match) {
            return next({
              path: from.path || '/',
            });
          }
        }
        if (to.meta.companiesAllowed) {
          if (to.meta.companiesAllowed.indexOf(store.getters.activeCompany) < 0) {
            return next({
              path: from.path || '/',
            });
          }
        }
        // no role required
        return next();
      } else if (!store.getters.isSidePanelOpen) {
        store.dispatch('toggleSidePanel');
      }
      window.location.href = `${loginPath}?exFrom=${window.location.href}`;
    } else {
      next();
    }
  });
});

export default router;
