<template>
  <div class="document-form-type">
    <div class="row center text-center column">
      <h1>
        ЗАЯВЛЕНИЕ за <br />
        ИСКАНЕ НА СЪГЛАСИЕ ЗА ПЛАТЕН УЧЕНИЧЕСКИ ОТПУСК
      </h1>
      <p class="doc-number" v-if="d.number">№{{ d.numberPrefix }}-{{ d.number }}</p>
    </div>

    <div class="row center text-center">
      <p>От</p>
    </div>

    <section class="rows-wrapper">
      <div class="row">
        <Input
          style="width: 75%"
          icon="F"
          placeholder="Име Презиме Фамилия"
          label="На Работник/Служител (изберете или потърсете име)"
          type="dropdown"
          @fetch="$emit('fetch-employees', $event)"
          :locked="d._id"
          :list="employees"
          prop="name"
          identifier="_id"
          v-model="d.form.user"
        />
        <Input
          style="width: 25%"
          icon="+"
          placeholder="№"
          label="Служебен номер"
          v-model="d.form.user.number"
          :autoFill="true"
          :locked="d._id"
        />
      </div>

      <div class="row">
        <Input
          icon="b"
          placeholder="Длъжност"
          label="Назначен на длъжност"
          :value="d.form.user.position ? d.form.user.position.name : ''"
          :locked="true"
        />
        <Input
          icon="/"
          prop="name"
          placeholder="Структурно звено"
          label="Работно място"
          :locked="true"
          :value="d.form.user.structuralUnit ? d.form.user.structuralUnit.name : ''"
        />
      </div>

      <div class="row">
        <Input
          icon=")"
          placeholder="Телефон"
          label="Номер на мобилен телефон"
          maxlength="10"
          :digitsOnly="true"
          :autoFill="true"
          :value="d.form.user.phone ? d.form.user.phone : ''"
        />
      </div>
    </section>

    <div class="row first-letter--margin">
      <p>Г-н Изп. директор,</p>
    </div>
    <div class="row first-letter--margin">
      <p>
        С настоящата молба кандидатствам за даване на съгласие за ползване на платен ученически отпуск, на основание чл.
        169 КТ.
      </p>
    </div>

    <section class="rows-wrapper">
      <div class="row">
        <p>Записан съм за обучение в:</p>
      </div>

      <div class="row">
        <Input
          icon="Q"
          placeholder="име на учебното заведение"
          label="име на учебното заведение"
          v-model="d.form.school"
          :locked="d._id"
        />
      </div>

      <div class="row">
        <Input icon="Q" placeholder="град/село" label="град/село" v-model="d.form.city" :locked="d._id" />
      </div>

      <section class="row row--space-between">
        <div style="width: 30%">
          <div class="row">
            <p>за степен</p>
          </div>

          <div class="row column">
            <Input
              label="средно професионално"
              type="checkbox"
              :value="d.form.study === 'средно професионално'"
              :locked="d._id"
              @input="$set(d.form, 'study', $event ? 'средно професионално' : '')"
            />
            <Input
              label="Бакалавър"
              type="checkbox"
              :value="d.form.study === 'Бакалавър'"
              :locked="d._id"
              @input="$set(d.form, 'study', $event ? 'Бакалавър' : '')"
            />
            <Input
              label="Магистър"
              type="checkbox"
              :value="d.form.study === 'Магистър'"
              :locked="d._id"
              @input="$set(d.form, 'study', $event ? 'Магистър' : '')"
            />
          </div>
        </div>

        <div>
          <div class="row">
            <p>за учебната</p>
          </div>
          <Input
            icon="Q"
            placeholder="ДД.ММ.ГГГГ"
            label="учебна година"
            :minlength="9"
            :maxlength="9"
            :dateRegex="true"
            :locked="d._id"
            v-model="d.form.schoolYear"
          />
        </div>
        <div>
          <div class="row">
            <p>ще бъда курс</p>
          </div>
          <Input
            icon="Q"
            placeholder="Курс"
            label="Курс"
            :maxlength="2"
            :digitsOnly="true"
            :locked="d._id"
            v-model="d.form.course"
          />
        </div>
      </section>
    </section>

    <section class="rows-wrapper">
      <div class="row">
        <p>Прилагам следните документи:</p>
      </div>

      <div class="row first-letter--margin">
        <p>1. Уверение от ВУЗ</p>
      </div>
      <div class="row first-letter--margin">
        <p>2. Дипломата за основно/ средно/ висше образование със съответните приложения.</p>
      </div>

      <div class="row">
        <p>Мотивите ми да продължа образованието си, както и да запиша тази специалност са следните</p>
      </div>

      <div class="row">
        <Input
          icon="Q"
          type="textarea"
          :maxlength="165"
          placeholder="Свободен текст (полето ще се разшири автом. според текста)"
          label="Мотиви ми да продължа образованието си (свободен текст)"
          :locked="d._id"
          :nonRequired="true"
          v-model="d.form.motives"
        />
      </div>
    </section>

    <div class="row row--background">
      <Input icon="C" placeholder="Дата" label="Дата" v-model="d.form.createdAt" type="datepicker" />
      <Input
        icon="$"
        :placeholder="d.form.user.name ? d.form.user.name : 'Име, Фамилия'"
        label="Служител - име и подпис"
        type="signature"
        :value="d.form.signatures.user"
        @signed="d.form.signatures.user = $event"
      />
    </div>

    <template v-if="stageOneDone">
      <div class="brake">ПОДПИСВА СЕ ОТ РЪКОВОДИТЕЛ УЧР</div>

      <div class="row wrap">
        <div style="width: 74%" class="title">
          <h1>ПОКАЗАТЕЛИ / ТРУДОВАТА ДИСЦИПЛИНА ЗА 12 МЕСЕЦА НАЗАД ОТ ДАТАТА НА ИЗГОТВЯНЕ НА КАРТАТА</h1>
        </div>

        <section class="row center row--align-center box" style="width: 25%">
          <p>Попълва се от ОУЧР</p>
        </section>
      </div>

      <section class="rows-wrapper rows--background">
        <div class="row">
          <section class="indicators" style="width: 75%">
            <span>1</span>
            <p>Лицето работи в КЦМ АД от</p>
          </section>
          <Input v-model="d.form.worksInKcm" style="width: 25%" :locked="stageTwoDone || !isCurrUserDocHr" />
        </div>

        <div class="row">
          <section class="indicators" style="width: 75%">
            <span>2</span>
            <p>Има наложени наказания</p>
          </section>
          <Input v-model="d.form.hasPenalties" style="width: 25%" :locked="stageTwoDone || !isCurrUserDocHr" />
        </div>

        <div class="row">
          <section class="indicators" style="width: 75%">
            <span>3</span>
            <p>Има допуснати самоотлъчки</p>
          </section>
          <Input v-model="d.form.hasSamootluchki" style="width: 25%" :locked="stageTwoDone || !isCurrUserDocHr" />
        </div>

        <div class="row">
          <section class="indicators" style="width: 75%">
            <span>4</span>
            <p>Има закъснения и преждевременни напускания, без оправдателен документ</p>
          </section>
          <Input v-model="d.form.hasDelaysOrDepartures" style="width: 25%" :locked="stageTwoDone || !isCurrUserDocHr" />
        </div>
      </section>

      <div class="row center">
        <Approval
          yes="ОДОБРЯВАМ "
          no="НЕ ОДОБРЯВАМ"
          v-model="d.form.approved"
          :disabled="stageTwoDone || !isCurrUserDocHr"
        />
      </div>

      <div class="row row--background">
        <Input
          icon="C"
          placeholder="Дата на връчване"
          label="Дата на връчване"
          v-model="d.form.createdAtHr"
          type="datepicker"
          :disabled="stageTwoDone || !isCurrUserDocHr"
        />
        <Input
          icon="$"
          placeholder="Минка Димитрова Бакалова-Каменова"
          label="Р-л отдел УЧР"
          type="signature"
          :value="d.form.signatures.hr"
          @signed="d.form.signatures.hr = $event"
          :disabled="stageTwoDone || !isCurrUserDocHr"
          :locked="stageTwoDone || !isCurrUserDocHr"
        />
      </div>
    </template>

    <template v-if="stageTwoDone">
      <div class="brake">ПОДПИСВА СЕ ОТ {{ d.receiver ? d.receiver.name : '' }}</div>

      <div class="row center">
        <Approval
          yes="РАЗРЕШАВАМ"
          no="НЕ РАЗРЕШАВАМ"
          v-model="d.form.approvedByReciever"
          :disabled="!isCurrUserDocMainReceiver"
        />
      </div>

      <div class="row row--background">
        <Input
          icon="C"
          placeholder="Дата на връчване"
          label="Дата на връчване"
          v-model="d.form.createdAtReceiver"
          type="datepicker"
          :disabled="!isCurrUserDocMainReceiver"
        />
        <Input
          icon="$"
          :placeholder="d.receiver ? d.receiver.name : 'Име, Фамилия'"
          label="Директор"
          type="signature"
          :value="d.form.signatures.receiver"
          @signed="d.form.signatures.receiver = $event"
          :disabled="!isCurrUserDocMainReceiver"
          :locked="!isCurrUserDocMainReceiver"
        />
      </div>
    </template>
  </div>
</template>

<script>
import Input from '../CreateDocumentInput';
import Approval from '../CreateDocumentApproval';
export default {
  components: {
    Input,
    Approval,
  },
  props: ['d', 'employees', 'invalid', 'isCurrUserDocHr', 'isCurrUserDocMainReceiver', 'editMode'],
  data() {
    return {
      stageOneDone: false,
      stageTwoDone: false,
    };
  },
  beforeMount() {
    if (!this.d._id) {
      this.$set(this.d.form.signatures, 'user', null);
      this.$set(this.d.form.signatures, 'hr', null);
      this.$set(this.d.form.signatures, 'receiver', null);
      this.$set(this.d.form, 'study', 'средно професионално');
      this.d.form.createdAt = new Date();
    }

    if (this.d.form.signatures.user) this.stageOneDone = true;
    if (this.d.form.signatures.hr && this.d.form.approved) this.stageTwoDone = true;
    if (this.d.form.signatures.user) this.d.form.createdAtHr = new Date();
    if (this.d.form.signatures.hr) this.d.form.createdAtReceiver = new Date();
  },
};
</script>
