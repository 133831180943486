<template>
  <div class="document-types">
    <div
      class="document-type"
      v-for="(t, i) in types"
      :key="`document-type-${i}`"
      @click="$emit('selected', t)"
      v-show="show(t.type)"
      :title="t.title"
    >
      <img :src="require(`@/assets/img/document-types/${t.type}.svg`)" alt="" />
      <img :src="require(`@/assets/img/document-types/${t.mobileIcon}`)" alt="" />
      <p>{{ t.name }}</p>
    </div>
    <div class="mobile-upload-close">
      <DragAndDropUpload />
      <section class="icon-wrapper">
        <span class="icon" @click="$emit('close')">g</span>
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import types from '../../data/trdocTypes.json';
export default {
  components: {
    DragAndDropUpload: () => import('@/components/DragAndDropUpload'),
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  data() {
    return {
      types,
    };
  },
  methods: {
    show(type) {
      let restrictedDocs = ['salaryAgreement', 'positionAgreement', 'mentor', 'mentorNew'];
      let scoreCards = ['scoreCardStudent', 'scoreCardPersonal', 'scoreCardExpert', 'scoreCardSupervisor'];

      if (
        (!this.currentUser.roles.hr && restrictedDocs.includes(type)) ||
        ((!this.currentUser.roles.supervisor && !this.currentUser.roles.admin) && scoreCards.includes(type))
      )
        return false;

      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.document-types {
  display: grid;
  grid-template-columns: repeat(auto-fill, 160px);
  justify-content: space-between;
  max-height: 80vh;
  overflow: auto;
  padding: 5%;
  @include scrollbar(5px, #9eafc1);
}
.document-type {
  align-items: center;
  border-radius: 11px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 210px;
  justify-content: center;
  width: 160px;
  position: relative;
  // width: 25%;
  @include transition($transition);

  &:hover {
    transform: scale(1.3);
  }

  img {
    @include transition(inherit);

    &:last-of-type {
      display: none;
    }
  }
  > p {
    color: #004d94;
    display: none;
    font-weight: 500;
  }
}
.mobile-upload-close {
  display: none;
}
@media screen and (max-width: $s), (orientation: landscape) and (max-width: $landscape) {
  .document-types {
    padding: 30px 1rem;
    grid-template-columns: repeat(auto-fit, 100%);
    row-gap: 1rem;
    overflow-x: hidden;

    .document-type {
      background: white;
      display: flex;
      flex-direction: row;
      height: 40px;
      justify-content: flex-start;
      width: 100%;

      &:hover {
        transform: scale(1);
      }

      img {
        height: 100%;
        padding: 5px 15px;
        &:first-of-type {
          display: none;
        }
        &:last-of-type {
          display: block;
        }
      }

      p {
        display: block;
      }
    }

    .mobile-upload-close {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;

      .icon-wrapper {
        background: #27424e;
        border-radius: 50%;
        color: white;
        display: flex;
        padding: 10px;

        .icon {
          font-size: 40px;
          margin-right: 3px;
        }
      }
    }
  }
}
</style>
