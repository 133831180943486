<template>
  <div class="home__header no-print">
    <main class="home__header-content" :class="{ hiddenMobile: !showFilters && isMobile }">
      <header>
        <span></span>
        <div class="input-wrapper">
          <input
            type="text"
            :placeholder="`ТЪРСЕНЕ НА ДОКУМЕНТ ${
              !isMobile ? `- ПО ИМЕ НА ${$route.name === 'Home' ? 'СЛУЖИТЕЛ' : 'ПОЛУЧАТЕЛ'}` : ''
            }`"
            v-model="search"
            @keypress.enter="pushToRoute()"
          />
          <p class="icon header-mobile-dates" v-if="isMobile" @click="openDates = !openDates">
            <span key="calendar">C</span>
            <span class="arrow" :class="{ reversed: openDates }" key="arrow">E</span>
          </p>
          <span class="icon" v-else>d</span>
        </div>
        <span
          class="icon toggle-filters"
          :class="{ opposite: !showFilters }"
          @click="toggleFilters"
          title="Скрий/Покажи филтри"
          >4</span
        >
      </header>
      <footer :class="{ hidden: !showFilters && !isMobile }" ref="footer">
        <!-- :style="{ marginTop: !showFilters ? `-${footerHeight}px` : '80px' }" -->

        <div
          class="inquiry-footer"
          v-if="
            $route.query.type === 'inquiryAdditional' ||
            $route.query.type === 'exportSalaries' ||
            $route.query.type === 'exportPersonalData' ||
            $route.query.type === 'exportVehicles'
          "
        >
          <div class="inquiry-buttons">
            <button
              href="#"
              class="inquiry-button"
              :class="{ active: $route.query.type === 'inquiryAdditional' }"
              @click="changeType('inquiryAdditional')"
            >
              <span class="icon">~</span>
              СПРАВКА ДС
            </button>
            <button
              v-if="currentUser.roles.admin"
              href="#"
              class="inquiry-button"
              :class="{ active: $route.query.type === 'exportSalaries' }"
              @click="changeType('exportSalaries')"
            >
              <span class="icon">m</span>
              {{ isMobile ? 'НОВИ ЗАПЛАТИ' : 'ЕКСПОРТ НОВИ ЗАПЛАТИ ' }}
            </button>
            <button
              v-if="currentUser.roles.admin"
              href="#"
              class="inquiry-button"
              :class="{ active: $route.query.type === 'exportPersonalData' }"
              @click="changeType('exportPersonalData')"
            >
              <span class="icon">m</span>
              {{ isMobile ? 'ЛИЧНИ ДАННИ' : 'ЕКСПОРТ ЛИЧНИ ДАННИ ' }}
            </button>
            <button
              v-if="currentUser.roles.admin"
              href="#"
              class="inquiry-button"
              :class="{ active: $route.query.type === 'exportVehicles' }"
              @click="changeType('exportVehicles')"
            >
              <span class="icon">m</span>
              {{ isMobile ? 'МПС' : 'ЕКСПОРТ МПС' }}
            </button>
          </div>
        </div>
        <div class="filters" v-else>
          <HeaderFilter :active="!filter" @click="selectFilter('')" icon="x" text="ВСИЧКИ" tooltip="Всички документи" />
          <HeaderFilter
            :active="filter === 'Л'"
            @click="selectFilter('Л')"
            icon="2"
            text="ЛИЧНИ"
            tooltip="Лични документи, образование,<br/>квалификация и опит"
          />
          <HeaderFilter
            :active="filter === 'Д'"
            @click="selectFilter('Д')"
            icon="y"
            text="ДОГОВОРИ И АНЕКСИ"
            tooltip="Договори и анекси"
          />
          <HeaderFilter
            :active="filter === 'З'"
            @click="selectFilter('З')"
            icon="z"
            text="ЗАЯВЛЕНИЯ И ДЕКЛАРАЦИИ"
            tooltip="Молби, заявления, декларации<br/>и длъжностни характеристики"
          />
          <HeaderFilter
            :active="filter === 'С'"
            @click="selectFilter('С')"
            icon="0"
            text="СЛУЖЕБНИ БЕЛЕЖКИ"
            tooltip="Служебни бележки"
          />
          <HeaderFilter
            :active="filter === 'Р'"
            @click="selectFilter('Р')"
            icon="1"
            text="ДРУГИ"
            tooltip="Други документи"
          />
        </div>

        <div class="excel-and-dates-wrapper">
          <transition name="appear" appear>
            <button
              class="excel-button"
              :disabled="!docsLength"
              @click="exportExcel()"
              v-if="
                $route.query.type === 'inquiryAdditional' ||
                $route.query.type === 'exportSalaries' ||
                $route.query.type === 'exportPersonalData' ||
                $route.query.type === 'exportVehicles'
              "
            >
              <template v-if="$route.query.type === 'inquiryAdditional'">
                <img src="@/assets/img/excel.svg" alt="Excel" />
                {{ isMobile ? 'ЕКСПОРТ' : 'ЕКСПОРТ В ЕКСЕЛ ' }}
              </template>
              <template
                v-if="
                  $route.query.type === 'exportSalaries' ||
                  $route.query.type === 'exportPersonalData' ||
                  $route.query.type === 'exportVehicles'
                "
              >
                <img src="@/assets/img/csv.svg" alt="Excel" />
                {{ isMobile ? 'ЕКСПОРТ' : 'ЕКСПОРТ В CSV ' }}
              </template>
            </button>
          </transition>
          <transition name="appear" appear>
            <div class="header__dates" v-show="isMobile ? openDates : true">
              <span class="icon">C</span>
              <div class="dates">
                <span class="date-toggle" @click="selectDate = 'dateFrom'">
                  <strong>От </strong>
                  <span v-if="dateFrom">{{ dateFrom | formatDate }}</span>
                  <span v-else>Избери</span>
                </span>
                <span
                  class="date-toggle"
                  :class="{
                    disabled: $route.query.type === 'exportPersonalData' || $route.query.type === 'exportVehicles',
                  }"
                  @click="
                    $route.query.type !== 'exportPersonalData' && $route.query.type !== 'exportVehicles'
                      ? (selectDate = 'dateTo')
                      : null
                  "
                >
                  <strong>До </strong>
                  <span v-if="dateTo">{{ dateTo | formatDate }}</span>
                  <span v-else>Избери</span>
                </span>
              </div>
            </div>
          </transition>
        </div>
      </footer>

      <transition name="appear" appear>
        <div class="show-only-mine-waiting"
          v-if="$route.query.type === 'waiting' && currentUser._id === '6082c9a811e48575bb09e148'">
          <CheckboxSwitch v-model="showMinkaWaitingDocuments" @input="pushToRoute()"/>
          Покажи само документи, които очакват моя подпис
        </div>
      </transition>

    </main>
    <section class="open-filters">
      <span @click="toggleFilters" class="icon">d</span>
    </section>
    <!-- MODALS -->
    <transition name="fade">
      <DatepickerModal v-if="selectDate" :value="this[selectDate]" @selected="selectedDate" @close="selectDate = ''" />
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HeaderFilter from './HeaderFilter.vue';
import DatepickerModal from '@/components/inputs/DatepickerModal';
import CheckboxSwitch from '@/components/inputs/CheckboxSwitch';
// import { addMonths } from 'date-fns';

export default {
  components: {
    HeaderFilter,
    DatepickerModal,
    CheckboxSwitch
  },
  props: ['docsLength'],
  computed: {
    ...mapGetters(['currentUser']),
  },
  watch: {
    $route: {
      handler: 'handleRoutes',
      immediate: false,
    },
  },
  data() {
    return {
      footerHeight: 0,
      showFilters: true,
      search: '',
      filter: '',
      openDocument: '',
      selectDate: null,
      dateFrom: null,
      dateTo: null,
      isMobile: false,
      openDates: false,
      showMinkaWaitingDocuments: false,
      query: {},
    };
  },
  methods: {
    setFooterHeight() {
      this.footerHeight = this.$refs.footer.clientHeight;
    },
    isMobileMode() {
      if (window.innerWidth < 768) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    toggleFilters() {
      if (this.showFilters) this.setFooterHeight();

      this.showFilters = !this.showFilters;
      this.openDates = false;
    },
    selectFilter(f) {
      this.filter = f;
      this.pushToRoute();
    },
    selectedDate(e) {

      // add 1 month to dateTo, when selecting dateFrom
      // if (this.selectDate === 'dateFrom') {
      //   this.dateTo = addMonths(new Date(e), 1);
      //   this.dateTo = this.dateTo.toISOString();
      // }

      this[this.selectDate] = e.toISOString();
      this.selectDate = '';

      this.pushToRoute();
    },
    changeType(t) {
      const query = { ...this.$route.query, type: t };
      this.$router
        .push({
          path: this.$route.path,
          query: query,
        })
        .catch(() => {});
    },
    exportExcel() {
      this.$root.$emit('export-excel');
    },
    pushToRoute() {
      if (!this.$route.query.type) {
        this.query.type = 'all';
      } else {
        this.query.type = this.$route.query.type;

        if (this.query.type === 'inquiryAdditional' && !(this.currentUser.roles.admin || this.currentUser.roles.hr)) {
          this.query.type = 'all';
        }
      }

      if (!this.$route.query.show) {
        this.query.show = this.currentUser.roles.admin || this.currentUser.roles.hr ? 'all' : 'my';
      } else {
        this.query.show = this.$route.query.show;
      }

      if (this.query.type === 'inquiryAdditional' && this.query.show === 'my') {
        this.query.type = 'all';
        this.query.show = 'all';
      }

      this.query.search = this.search;

      if (this.dateFrom && this.dateTo) {
        this.query.dateFrom = this.dateFrom;
        this.query.dateTo = this.dateTo;
      }

      if(this.showMinkaWaitingDocuments && this.$route.query.type === 'waiting') {
        this.query.showMinkaWaitingDocuments = true;
      } else {
        delete this.query.showMinkaWaitingDocuments
      }

      if (this.filter) {
        this.query.filter = this.filter;
      } else {
        delete this.query.filter;
      }

      this.$router
        .push({
          path: this.$route.path,
          query: this.query,
        })
        .catch(() => {});
    },
    handleRoutes() {
      if (!this.$route.query.dateFrom && !this.$route.query.dateTo) {
        let date = new Date();
        this.dateFrom = new Date(date.getFullYear(), date.getMonth() - 1, 1).toISOString();
        this.dateTo = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString();
      } else {
        this.dateFrom = this.$route.query.dateFrom;
        this.dateTo = this.$route.query.dateTo;
      }
      this.pushToRoute();
    },
  },
  mounted() {
    if(this.currentUser._id === '6082c9a811e48575bb09e148') this.showMinkaWaitingDocuments = true
    this.handleRoutes()
    this.isMobileMode();
    window.addEventListener('resize', this.setFooterHeight);
    window.addEventListener('resize', this.isMobileMode);

    if (this.$route.query.search) this.search = this.$route.query.search;
    if (this.$route.query.filter) this.filter = this.$route.query.filter;
  },
  destroyed() {
    window.removeEventListener('resize', this.setFooterHeight);
    window.removeEventListener('resize', this.isMobileMode);
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.home__header {
  width: 100%;
  header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    @include transition($transition);

    .input-wrapper {
      height: 38px;
      position: relative;
      width: 600px;

      input {
        border: 1px solid #c4d6e8;
        border-radius: 4px;
        font-size: $px12;
        font-weight: 500;
        height: 100%;
        outline: none;
        padding-left: $px12;
        padding-right: $px12 * 2 + $px20;
        width: 100%;
        @include transition($transition);

        &:focus,
        &:hover {
          border-color: #004d94;
        }
      }
      .icon {
        color: #000;
        font-size: $px20;
        position: absolute;
        right: $px12;
        top: 50%;
        @include transform(translateY(-50%));
      }
    }

    .toggle-filters {
      color: #212121;
      font-size: $px18;
      @include noselect();
      @include hover();

      &.opposite {
        @include transform(scaleY(-1));
      }
    }
  }
  footer {
    align-items: flex-start;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    // overflow: hidden;
    margin-bottom: 1rem;
    margin-top: 80px;
    position: relative;
    @include transition($transition);

    &.hidden {
      margin-top: 0;
      overflow: hidden;
      visibility: hidden;
      opacity: 0;
      @include transform(translateY(-200%));
    }

    .inquiry-footer {
      align-items: center;
      display: flex;
      font-size: 12px;
      font-weight: 500;
      height: 100%;
      justify-content: space-between;
      min-height: 32px;
      width: 100%;
      p {
        padding-right: 1rem;
      }
    }
    .inquiry-buttons {
      display: flex;
      gap: 5px;
    }
    .inquiry-button {
      align-items: center;
      background: #fff;
      border-radius: 16px;
      border: 1px solid #c4d6e8;
      cursor: pointer;
      display: inline-flex;
      font-size: 12px;
      gap: 10px;
      padding: 0 17px;
      height: 33px;
      @include transition($transition);

      &:hover {
        background: rgba(41, 45, 50, 0.05);
      }

      &.active {
        background: #292d32;
        border-color: #292d32;
        color: #fff;
      }
    }
    .filters {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
    }
    .excel-and-dates-wrapper {
      display: flex;
      gap: 10px;

      .excel-button {
        align-items: center;
        background: #e8fff3;
        border-radius: 4px;
        border: 1px solid #80d29e;
        cursor: pointer;
        display: inline-flex;
        font-size: 12px;
        font-weight: 500;
        gap: 7px;
        height: 32px;
        padding: 0 9px;
        min-width: max-content;

        img {
          height: 16px;
          width: 16px;
        }

        &:disabled {
          cursor: default;
          opacity: 0.5;
        }

        &:not(:disabled):hover {
          background: #daffec;
        }
      }
    }
    .header__dates {
      align-items: center;
      border: 1px solid #c4d6e8;
      border-radius: 4px;
      display: flex;
      font-size: $px12;
      gap: 1rem;
      height: 32px;
      justify-content: space-between;
      min-width: 246px;
      padding: 10px;
      width: 246px;
      @include transition($transition);
      &:hover {
        border-color: #004d94;
      }
      > .icon {
        color: #212121;
        font-size: $px18;
      }
      .date-toggle {
        @include hover();
        &.disabled {
          cursor: default;
          opacity: 0.2;
        }
        &:last-child {
          margin-left: 1rem;
        }
      }
    }
  }
  .show-only-mine-waiting {
    align-items: center;
    display: flex;
    gap: 5px;

    p{
      white-space: nowrap;
    }
  }
  .open-filters {
    display: none;
  }
}
@media screen and (max-width: $s), (orientation: landscape) and (max-width: $landscape) {
  .home__header {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .home__header-content {
      background: #f1f8ff;
      border-radius: 10px;
      @include transition($transition);

      &.hiddenMobile {
        margin-top: -100px;
      }
    }

    header {
      padding: 10px;
      .toggle-filters {
        display: none;
      }
      .header-mobile-dates {
        align-items: center;
        display: flex;
        gap: 5px;
        .arrow {
          font-size: $px14;
          @include transition($transition);
          &.reversed {
            @include transform(scale(-1));
          }
        }
      }
    }

    footer {
      flex-direction: column-reverse;
      gap: 0;
      margin: 0px;
      overflow: hidden;
      width: 100%;

      .inquiry-footer {
        flex-wrap: wrap;
        padding: 10px;
        overflow: auto;

        .inquiry-buttons {
          justify-content: space-between;
          order: 1;
          width: 100%;
        }
      }
      .filters {
        flex-wrap: nowrap;
        overflow-y: hidden;
        padding: 10px;
        width: 100%;
      }
      .excel-and-dates-wrapper {
        margin: 0 10px;
        overflow: auto;
        width: 100%;

        .header__dates {
          order: -1;
          min-width: fit-content;
          width: fit-content;
        }
      }
    }
  }
  .open-filters {
    display: flex !important;
    font-weight: bold;
    font-size: 20px;
    justify-content: center;
    width: 100%;
    span {
      position: relative;
      padding: 5px 10px;
      z-index: 2;

      &::after {
        background-image: url('../../assets/img/search-bg.svg');
        background-size: contain;
        background-repeat: no-repeat;
        content: '';
        height: 300%;
        position: absolute;
        top: 38%;
        left: -100%;
        transform: rotate(90deg);
        width: 300%;
        z-index: -1;
      }
    }
  }
}
</style>
