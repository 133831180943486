<template>
  <main>
    <div class="signatures">
      <p class="signatures-title">СЪБРАНИ ПОДПИСИ</p>
      <section class="stages-wrapper" v-if="ds">
        <div
          class="stage"
          :class="{
            signed: ds.one.s,
          }"
        >
          <aside>
            <span class="icon">\</span>
            <span class="stage-line" v-if="canHaveStages"></span>
          </aside>

          <div class="info">
            <p>{{ ds.one.p }}</p>
            <span>{{ ds.one.span }}</span>
          </div>
        </div>

        <template v-if="canHaveStages">
          <div
            class="stage"
            :class="{
              signed: ds.two.s,
            }"
          >
            <aside>
              <span class="icon">]</span>
              <span class="stage-line" v-if="ds.three"></span>
            </aside>

            <div class="info">
              <p>{{ ds.two.p }}</p>
              <span>{{ ds.two.span }}</span>
            </div>
          </div>

          <div
            class="stage"
            v-if="ds.three"
            :class="{
              signed: ds.three.s,
            }"
          >
            <aside>
              <span class="icon">^</span>
              <span class="stage-line" v-if="ds.four"></span>
            </aside>
            <div class="info">
              <p>
                {{ ds.three.p }}
              </p>
              <span v-if="ds.three.span">{{ ds.three.span }}</span>
              <!-- <span v-else>{{ d.signedWithCertificateAt | formatDate }}</span> -->
            </div>
          </div>
          <div
            class="stage"
            v-if="ds.four"
            :class="{
              signed: ds.four.s,
            }"
          >
            <aside>
              <span class="icon">`</span>
            </aside>
            <div class="info">
              <p>{{ ds.four.p }}</p>
              <span>{{ ds.four.span }}</span>
            </div>
          </div>
        </template>
      </section>
    </div>
    <div
      class="signatures"
      v-if="d.preFinalized && !d.signedWithCertificate && d.form.signatures.hr && !d.form.signatures.receiverTwo"
    >
      <p class="signatures-title">ЧАКАЩ ПОДПИС</p>
      <section class="stages-wrapper stages-wrapper--kep">
        <div class="stage">
          <aside class="kep">
            <span class="icon">$</span>
          </aside>

          <div class="info kep">
            <section>
              <p>{{ d.receiver.name }}</p>
              <span>Изпълнителен директор</span>
            </section>
            <span class="icon blue">_</span>
          </div>
        </div>
      </section>
    </div>
    <div class="signatures" v-if="d.preFinalized && d.signedWithCertificate">
      <p class="signatures-title" v-if="!d.finalized">ЧАКАЩ ПОДПИС</p>
      <p class="signatures-title">{{ d.form.user.name }}</p>

      <div class="buttons" v-if="!d.finalizedAt" :class="{ 'buttons--disabled': disabled }">
        <a @click="disabled ? null : $emit('selected', true)">СЪГЛАСЕН СЪМ</a>
        &nbsp;/&nbsp;
        <a class="red" @click="disabled ? null : $emit('selected', false)">НЕ СЪМ СЪГЛАСЕН</a>
      </div>
      <div class="buttons" v-else>
        <a class="decision" :class="{ red: !d.userDecision }"
          >ДОКУМЕНТЪТ {{ d.userDecision ? 'E ПРИЕТ' : 'НЕ E ПРИЕТ' }} ОТ СЛУЖИТЕЛЯ НА
          {{ d.finalizedAt | formatDateFull }}</a
        >
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: ['d'],
  computed: {
    ...mapGetters(['currentUser']),
    ds() {
      let s = this.d.form.signatures;
      let d = this.d;

      if (['positionAgreement', 'salaryAgreement', 'position', 'salary', 'retirement'].indexOf(d.type.type) > -1) {
        return {
          one: {
            s: !this.agreementStages ? s.user : s.firstHr,
            p: !this.agreementStages ? 'Служител' : 'Служител УЧР',
            span: this.$options.filters.formatDate(d.form.createdAt),
          },
          two: {
            s: s.hr,
            p: 'УЧР (Минка Бакалова)',
            span: this.$options.filters.formatDate(d.form.createdAtHr),
          },
          three: {
            s: !this.agreementStages ? s.receiver : this.d.signedWithCertificate,
            p: !this.agreementStages
              ? 'Ръководител 1'
              : `Електронно подписан документ на ${this.$options.filters.formatDateFull(
                  d.signedWithCertificateAt
                )} от ${d.receiver.name}`,
            span: !this.agreementStages ? this.$options.filters.formatDate(d.form.createdAtReceiver) : false,
          },
          four: {
            s: !this.agreementStages ? s.receiverTwo : d.finalized,
            p: !this.agreementStages ? 'Ръководител 2' : 'Служител',
            span: !this.agreementStages
              ? this.$options.filters.formatDate(d.form.createdAtReceiverTwo)
              : this.$options.filters.formatDate(d.form.finalizedAt),
          },
        };
      } else if (!this.canHaveStages) {
        return {
          one: {
            s: s.user,
            p: 'Служител',
            span: this.$options.filters.formatDate(d.form.createdAt),
          },
        };
      } else if (d.type.type === 'mentor' || d.type.type === 'mentorNew') {
        return {
          one: {
            s: s.firstHr,
            p: 'Служител УЧР',
            span: this.$options.filters.formatDate(d.form.createdAt),
          },
          two: {
            s: s.hr,
            p: 'УЧР (Минка Бакалова)',
            span: this.$options.filters.formatDate(d.form.createdAtHr),
          },
          three: {
            s: s.user,
            p: 'Служител',
            span: this.$options.filters.formatDate(d.form.createdAtUser),
          },
        };
      } else if (d.type.type === 'scoreCardStudent') {
        return {
          one: {
            s: s.receiver,
            p: 'Ръководител 1',
            span: this.$options.filters.formatDate(d.form.createdAt),
          },
          two: {
            s: s.receiverTwo,
            p: 'Ръководител 2',
            span: this.$options.filters.formatDate(d.form.createdAtReceiverTwo),
          },
          three: {
            s: s.receiverThree,
            p: 'Ръководител 3',
            span: this.$options.filters.formatDate(d.form.createdAtReceiverThree),
          },
        };
      }else if (['scoreCardPersonal', 'scoreCardExpert', 'scoreCardSupervisor'].indexOf(d.type.type) > -1) {
        return {
          one: {
            s: s.receiver,
            p: 'Ръководител 1',
            span: this.$options.filters.formatDate(d.form.createdAtReceiver),
          },
          two: {
            s: s.user,
            p: 'Служител',
            span: this.$options.filters.formatDate(d.form.createdAt),
          },
          three: {
            s: s.receiverTwo,
            p: 'Ръководител 2',
            span: this.$options.filters.formatDate(d.form.createdAtReceiverTwo),
          },
        };
      } else if (d.type.type === 'schoolOtpusk') {
        return {
          one: {
            s: s.user,
            p: 'Служител',
            span: this.$options.filters.formatDate(d.form.createdAt),
          },
          two: {
            s: s.hr,
            p: 'УЧР (Минка Бакалова)',
            span: this.$options.filters.formatDate(d.form.createdAtHr),
          },
          three: {
            s: s.receiver,
            p: 'Изпълнителен Директор',
            span: this.$options.filters.formatDate(d.form.createdAtReceiver),
          },
        };
      }

      return false;
    },
    canHaveStages() {
      return (
        ['email', 'personal', 'introduction', 'introductionChange', 'joining', 'helping', 'newPlastic'].indexOf(this.d.type.type) ===
        -1
      );
    },
  },
  data() {
    return {
      agreementStages: false,
      disabled: false,
    };
  },
  mounted() {
    this.disabled = this.currentUser._id !== this.d.form.user._id;
    this.agreementStages = this.d.type.type === 'positionAgreement' || this.d.type.type === 'salaryAgreement';
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.signatures {
  background: #ffffff;
  border: 1px solid #9eafc1;
  border-radius: 6px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  padding: 5px 10px 10px;
  margin-bottom: 0.5rem;

  .signatures-title {
    font-size: $px14;
    font-weight: bold;
    text-align: center;
  }

  .buttons {
    align-items: center;
    display: flex;
    font-weight: 600;
    gap: 2px;
    justify-content: center;

    &.buttons--disabled {
      opacity: 0.4;
      > a {
        cursor: default;
      }
    }
    a {
      color: #35aadf;
      cursor: pointer;
      display: block;
      font-size: $px16;
      text-align: center;
      @include transition($transition);
      :not(.decision) {
        text-decoration: underline;
      }
      &.red {
        color: #cc1616;
      }
    }
  }

  .stages-wrapper {
    display: flex;
    flex-direction: column;

    &.stages-wrapper--kep {
      .stage {
        padding: 10px 0;
        aside {
          flex-direction: row;
          .icon {
            color: #292d32;
          }
        }
        .info {
          align-items: center;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
        }
      }
    }

    .stage {
      display: flex;
      gap: 10px;
      &.signed {
        color: #349e00;

        .icon {
          color: #349e00;
        }
        .stage-line {
          &::before {
            background: #349e00;
          }
        }
      }
      aside {
        align-items: center;
        display: flex;
        flex-direction: column;
        .stage-line {
          display: flex;
          height: 100%;
          min-height: 24px;
          position: relative;
          width: 2px;

          &::before {
            background: #9dabb8;
            content: '';
            height: calc(100% + 2px);
            left: 0;
            position: absolute;
            top: -1px;
            text-align: center;
            width: 100%;
          }
        }
      }
      .info {
        display: flex;
        flex-direction: column;
        p {
          font-weight: 600;
          font-size: $px14;
        }
        span {
          color: black;
          font-size: 12px;
          opacity: 0.5;
        }
      }

      .icon {
        color: #9dabb8;
        font-size: 18px;
        position: relative;
        z-index: 2;

        &.blue {
          color: #35aadf;
          font-size: 30px;
          opacity: 1;
        }
      }
    }
  }
}
</style>