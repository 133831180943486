<template>
  <main class="create-document-input-wrapper" :class="{ 'no-checkmark': noCheckmark }">
    <label
      class="create-document-input"
      :class="{
        'create-document-input--pointer':
          type === 'dropdown' || type === 'datepicker' || type === 'signature' || type === 'file',
        'create-document-input--invalid': invalid,
        'create-document-input--disabled': disabled || vehicleDisabled,
        'create-document-input--locked': locked,
        'create-document-input--auto-fill': autoFill,
        'create-document-input--changed': changed,
        'create-document-input--file': type === 'file',
        'create-document-input--textarea': type === 'textarea',
        'create-document-input--vehicle': vehicle,
      }"
      v-click-outside="clickedOutside"
      v-if="type !== 'checkbox'"
      ref="input"
    >
      <!-- you couldn't click on the datepicker input because it's disabled, preventing the datepicker from opening so the overlay helps -->
      <div class="datepicker-overlay" v-if="type === 'datepicker'" @click="handleClick"></div>
      <main @click="handleClick">
        <span class="icon">{{ icon }}</span>
        <div>
          <input
            v-if="type === 'dropdown'"
            :class="getValue || vehicleDisabled || vehicleEdit ? 'has-value' : false"
            type="text"
            :placeholder="isDropdownOpen ? 'Търсене...' : getValue ? getValue : placeholder"
            v-model="search"
            :title="getValue"
            :disabled="disabled || locked || vehicleDisabled || vehicleEdit"
            @input="$emit('fetch', search)"
            @focus="$emit('focus'), $emit('fetch', search), (isDropdownOpen = true)"
            @blur="$emit('blur')"
            ref="dd"
          />
          <template v-else-if="type === 'file'">
            <input
              class="input--file"
              :class="getValue ? 'has-value' : false"
              type="file"
              multiple="true"
              :placeholder="placeholder"
              :title="getValue"
              :disabled="disabled || locked"
              @input="$emit('input', $event.target.files), ($event.target.value = null)"
            />

            <template v-if="selectedFiles ? selectedFiles.length : false">
              <p class="file-item" v-for="(f, i) in selectedFiles" :key="`file-${f.name}`" :title="f.name">
                {{ placeholder }} - файл {{ i + 1 }}
              </p>

              <span class="icon delete-files" @click.prevent.stop="$emit('input', [])">{</span>
            </template>
            <template v-else-if="uploadedFiles ? uploadedFiles.length : false">
              <a
                class="file-item"
                v-for="(f, i) in uploadedFiles"
                :key="`file-${f.name}`"
                :title="f.name"
                :href="`${$uploads}/trdocs/${f.file}`"
                target="_blank"
              >
                {{ placeholder }} - файл {{ i + 1 }}
              </a>
            </template>
            <p class="fake-placeholder" v-else>{{ placeholder }}</p>
          </template>
          <template v-else>
            <input
              v-if="type !== 'textarea'"
              v-show="type !== 'signature' && type !== 'textarea'"
              type="text"
              :manualdate="manualDate"
              :readonly="autoFill || disabled || locked || vehicleDisabled || vehicleEdit"
              :maxlength="maxlength ? maxlength : false"
              :minlength="minlength ? minlength : false"
              :placeholder="placeholder"
              :disabled="type === 'datepicker' || type === 'signature' || disabled || vehicleDisabled || vehicleEdit"
              :value="getValue"
              @input="input"
              @focus="$emit('focus')"
              @blur="$emit('blur')"
            />
            <div v-else>
              <textarea
                type="textarea"
                :value="getValue"
                :disabled="disabled"
                :readonly="autoFill || disabled || locked"
                :placeholder="placeholder"
                :maxlength="maxlength ? maxlength : 300"
                :minlength="minlength ? minlength : false"
                @input="input($event), resize($event)"
                @focus="resize($event), $emit('focus')"
                @blur="$emit('blur')"
                id="ta"
                ref="ta"
              />
              <!-- fake-textarea is used when the document is being saved -->
              <strong>
                <h1 class="fake-textarea">
                  {{ getValue }}
                </h1>
              </strong>
            </div>
          </template>
          <p class="fake-input" v-if="type === 'signature'">{{ placeholder }}</p>
          <p>{{ label }}</p>
        </div>
        <template v-if="type === 'dropdown'">
          <template v-if="vehicle && !disabled">
            <transition name="fade" mode="out-in">
              <span
                title="Добавяне на нов автомобил "
                class="vehicle-toggle no-print"
                @click.prevent="$emit('vehicle-enable')"
                v-if="vehicleDisabled"
              >
                <img src="@/assets/img/documents/car-add.svg" />
              </span>
              <div class="vehicle-toggles no-print" v-else>
                <span
                  :title="vehicleEdit ? 'Деактивиране на съществуващ автомобил' : 'Изтриване на нов автомобил'"
                  class="vehicle-toggle"
                  @click.prevent="$emit('vehicle-delete')"
                >
                  <img src="@/assets/img/documents/car-delete.svg" />
                </span>
                <span
                  v-if="vehicleEdit"
                  title="Деактивиране и добавяне на нов автомобил"
                  class="vehicle-toggle"
                  @click.prevent="$emit('vehicle-edit')"
                >
                  <img src="@/assets/img/documents/car-edit.svg" />
                </span>
              </div>
            </transition>
          </template>

          <span
            v-else-if="!disabled && !locked && !vehicleDisabled"
            class="icon"
            :class="{ reversed: (isDropdownOpen && !multiple) || (isDropdownOpen && multiple && !value.length) }"
            @click.prevent="handleArrowClick"
          >
            <transition name="fade" mode="out-in">
              <span v-if="isDropdownOpen && multiple && value.length" style="color: #019437" key="tick">[</span>
              <span v-else key="arrow">E</span>
            </transition>
          </span>
        </template>

        <span class="tooltips" v-if="autoFill">Данните се попълват автоматично</span>
      </main>
      <div class="signature" v-if="type === 'signature'">
        <img :src="value" alt="Вашият подпис" v-if="value" />
        <div class="icon" v-else>6</div>
        <p class="hidden">hidden</p>
      </div>

      <div class="create-document-input__dropdown" v-if="type === 'dropdown' && isDropdownOpen" @click.stop>
        <p
          v-for="(l, i) in getList"
          :class="{ selected: isSelected(l) }"
          :key="`dd-l-${i}`"
          @click.prevent="listItemSelected(l)"
        >
          <span v-if="arrayProp"> {{ prop ? l[prop][arrayProp] : l }} </span>
          <span v-else> {{ prop ? l[prop] : l }} </span>
          <span v-if="propTwo" class="prop-two">{{ l[propTwo] }}</span>
        </p>
        <template v-if="(!getList.length && search) || !this.list.length">
          <p class="empty">няма резултати</p>
          <p v-if="selectSearch" @click.prevent="selectSearchHandler()">Избери '{{ search }}'</p>
        </template>
      </div>

      <template v-else-if="type === 'datepicker'">
        <transition name="fade">
          <DatepickerModal
            v-if="isModalOpen"
            :value="value"
            @selected="dateSelected"
            @close="isModalOpen = false"
            :yearFirst="yearFirst"
            :maxView="maxView"
          />
          <!-- :disabledDates="disabledDates"
        :month="month" -->
        </transition>
      </template>
      <template v-else-if="type === 'signature'">
        <transition name="fade">
          <SignatureModal v-if="isModalOpen" @signed="signed" @close="isModalOpen = false" />
        </transition>
      </template>
    </label>
    <div
      v-else
      class="checkbox-container"
      :class="{
        'checkbox-container--disabled': disabled,
        'checkbox-container--locked': locked,
        'checkbox-container--selected': value,
      }"
      ref="input"
    >
      <label class="checkbox-wrapper">
        <input
          type="checkbox"
          :disabled="disabled || locked"
          :checked="value"
          @change="$emit('input', $event.target.checked), $emit($event.target.checked ? 'activated' : 'deactivated')"
        />
        <span class="checkmark"></span>
        <p v-if="label">{{ label }}</p>
      </label>
      <slot></slot>
    </div>
  </main>
</template>

<script>
import ClickOutside from '@/directives/clickOutside';
import DatepickerModal from '@/components/inputs/DatepickerModal';
import SignatureModal from '@/components/signature/SignatureModal';
export default {
  name: 'DocumentInput',
  directives: {
    ClickOutside,
  },
  components: {
    DatepickerModal,
    SignatureModal,
  },
  props: [
    'autoFill',
    'digitsOnly', // input is string but every non-numeric character is going to be removed
    'lkNumber',
    'dateRegex', // input is string but every non-numeric dot or slash character is going to be removed
    'disabled',
    'identifier',
    'icon',
    'label',
    'list',
    'locked', // same as disabled but without styling
    'maxlength',
    'minlength',
    'maxView',
    'multiple',
    'nonRequired',
    'noCheckmark',
    'placeholder',
    'prop',
    'propTwo',
    'reversedProp',
    'arrayProp', // might be a better way to do that
    'pdf',
    'selectSearch', // allow user to select whatever text is in the search field when no other results are found. Done only for non-multiple dropdowns
    'value',
    'type',
    'invalid',
    'manualDate', // checks if the added date is in valid format when saving the document
    'yearFirst',
    'uppercase',
    'formatWithDots',
    'selectedFiles', // for file input to list the files
    'uploadedFiles', // for file input to list the files
    'removeChars', // remove chars from provided regex
    'vehicle',
    'vehicleDisabled',
    'vehicleEdit', // show also edit button
  ],
  watch: {
    value() {
      this.removeInvalidClass();
    },
  },
  data() {
    return {
      isModalOpen: false,
      isDropdownOpen: false,
      changed: false,
      search: '',
    };
  },
  computed: {
    getValue() {
      let value = this.value;
      if (value) {
        if (this.type === 'dropdown') {
          if (this.multiple) {
            value = this.prop ? this.value.map((x) => x[this.prop]).join(', ') : this.value.join(', ');
          } else if (this.arrayProp)
            value = !this.propTwo
              ? this.prop
                ? this.value[this.prop][this.arrayProp]
                : this.value
              : this.value[this.propTwo];
          else
            value = !this.propTwo
              ? this.prop
                ? this.value[this.prop]
                : this.value
              : this.reversedProp
              ? this.value[this.propTwo]
              : this.value[this.prop];
        } else if (this.type === 'datepicker') {
          if (this.maxView === 'year') value = this.$options.filters.formatDateYear(this.value);
          else if (this.formatWithDots) value = this.$options.filters.formatDateDots(this.value);
          else value = this.$options.filters.formatDate(this.value);
        } else if (this.type === 'signature') {
          value = this.placeholder;
        }
      }

      return value;
    },
    getList() {
      if (this.propTwo) {
        return this.list.filter(
          (x) =>
            x[this.prop].toLowerCase().includes(this.search.toLowerCase()) ||
            x[this.propTwo].toLowerCase().includes(this.search.toLowerCase())
        );
      } else if (!this.prop && !this.identifier && !this.propTwo) {
        return this.list.filter((x) => x.toLowerCase().includes(this.search.toLowerCase()));
      }
      return this.list;
    },
  },
  methods: {
    clickedOutside() {
      if (this.type === 'dropdown') {
        if (this.isDropdownOpen) {
          this.isDropdownOpen = false;
          this.search = '';
        }
      }
    },
    handleClick() {
      if (!this.disabled && !this.locked && !this.autoFill) {
        if (this.type === 'datepicker' || this.type === 'signature') {
          this.isModalOpen = true;
        }
      }
    },
    handleArrowClick() {
      if (!this.isDropdownOpen) this.$emit('fetch', this.search), (this.search = '');
      else this.$refs.dd.blur();
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    selectSearchHandler() {
      let output = this.search;

      if (this.maxlength || this.minlength) {
        if (output.length > this.maxlength) {
          return this.$snotify.error(
            `Максималната дължина на полето е ${this.maxlength} символ${this.maxlength === 1 ? '' : 'а'}`
          );
        } else if (this.minlength > output.length) {
          return this.$snotify.error(
            `Минималната дължина на полето е ${this.minlength} символ${this.minlength === 1 ? '' : 'а'}`
          );
        }
      }
      // for objects
      if (this.prop) {
        if (this.propTwo) {
          output = { [this.propTwo]: this.search, [this.prop]: 'Ръчно добавено' };
        } else {
          output = { [this.prop]: this.search };
        }
      }
      this.listItemSelected(output);
    },
    listItemSelected(l) {
      if (this.multiple) {
        if (this.identifier) {
          const i = this.value.map((x) => x[this.identifier]).indexOf(l[this.identifier]);
          if (i > -1) {
            const value = [...this.value];
            value.splice(i, 1);
            this.$emit('input', value);
          } else {
            this.$emit('input', [...this.value, l]);
          }
        }
        // don't care for now
        // else {
        //   const i = this.value.indexOf(l);
        //   if (i > -1) {
        //     const value = [...this.value];
        //     value.splice(i, 1);
        //     this.$emit('input', value);
        //   } else {
        //     this.$emit('input', [...this/.value, l]);
        //   }
        // }
      } else {
        this.$refs.dd.blur();
        this.isDropdownOpen = false;
        this.$emit('input', l);
        this.search = '';
      }
      this.removeInvalidClass();
      this.addChangedClass();
    },
    isSelected(l) {
      if (this.identifier) {
        if (this.multiple) return this.value.map((x) => x[this.identifier]).indexOf(l[this.identifier]) > -1;
        else return this.value[this.identifier] === l[this.identifier];
      }
      return;
    },
    dateSelected(e) {
      this.$emit('input', e);
      this.isModalOpen = false;
      this.removeInvalidClass();
    },
    signed(signature) {
      this.$emit('signed', signature);
      this.removeInvalidClass();
    },
    removeInvalidClass() {
      const input = this.$refs.input;
      if (input.className) {
        if (input.classList.contains('create-document-input--invalid')) {
          input.classList.remove('create-document-input--invalid');
        }
      }
    },
    addChangedClass() {
      if (!this.nonRequired && !this.changed) {
        this.changed = true;
      }
    },
    resize(e, m) {
      let textarea = m ? e : e.target;
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    },
    input(e) {
      if (this.removeChars) e.target.value = e.target.value.replace(this.removeChars, '');
      if (this.digitsOnly) e.target.value = e.target.value.replace(/\D/g, '');
      if (this.lkNumber) {
        // new id numbers can be of size 15 and can have letters in the start of them
        const input = e.target.value;

        const hasLetters = /^[a-zA-Z]{1,2}/.test(input);
        const notEnglish = /[^\u0020-\u007E]/.test(input);

        if (notEnglish) {
          this.$snotify.error('Буквите в номера на ЛК трябва да са на латиница');
          e.target.value = input.replace(/[^\u0020-\u007E]/g, '');
          return;
        }

        this.$emit('update:isNewLkType', hasLetters);

        e.target.value = input
          .replace(/[^a-zA-Z0-9]/g, '')
          .replace(/^([a-zA-Z]{0,2})(.*)/, (match, p1, p2) => p1 + p2.replace(/[^0-9]/g, ''))
          .toUpperCase();
      }
      if (this.dateRegex) e.target.value = e.target.value.replace(/[^0-9./]/g, '');
      if (this.uppercase) e.target.value = e.target.value.toUpperCase();
      this.$emit('input', e.target.value);
      this.removeInvalidClass();
      this.addChangedClass();
    },
  },
  mounted() {
    if (this.$refs.ta) this.resize(this.$refs.ta, true);
  },
};
</script>

<style lang="scss">
@import '@/scss/base.scss';
.create-document-input-wrapper {
  width: 100%;

  &.no-checkmark {
    width: fit-content;

    .checkbox-container--selected {
      p {
        text-decoration: line-through;
      }
    }
    .checkmark {
      display: none;
    }
    .checkbox-wrapper {
      padding-left: 0;
    }
    p {
      color: $lightBlue;
      font-weight: bold;
      margin: 0;
    }
  }
}
.create-document-input {
  align-items: center;
  background: #fff;
  border: 1px solid #9eafc1;
  border-radius: 6px;
  display: flex;
  height: fit-content;
  min-height: 62px;
  position: relative;
  width: 100%;
  @include transition(all 0.3s $cubic);
  @include noselect();

  &.create-document-input--file {
    height: initial;
    min-height: 62px;
    padding-top: 9px;
    padding-bottom: 9px;
    padding-right: 3rem;

    .input--file {
      height: 0.01px;
      left: -9999vw;
      height: -9999vh;
      position: absolute;
      width: 0.01px;
    }

    .file-item {
      color: #acbfd4;
      font-size: $px14;
      font-weight: 600;
      margin-bottom: 6px;
    }

    a.file-item {
      color: #35aadf;
      text-decoration: underline;
      @include hover();
    }

    .delete-files {
      color: #cc1616;
      font-size: $px16;
      position: absolute;
      right: 1rem;
      top: 1rem;
      @include hover();
    }
  }

  &.create-document-input--textarea {
    &.create-document-input--saving {
      textarea {
        display: none;
      }
      .fake-textarea {
        display: block;
      }
    }

    > main {
      > div {
        padding: 10px 0;
      }
      .fake-textarea {
        display: none;
        word-break: break-word;
      }
    }
  }
  &:not(.create-document-input--locked):hover {
    background: #f1f8ff 0% 0% no-repeat padding-box;
    border: 1px solid #3b71aa;
    .tooltips {
      visibility: visible;
      opacity: 1;
    }
  }
  &:not(.create-document-input--auto-fill):focus-within {
    background: #f1f3f6 0% 0% no-repeat padding-box;
    box-shadow: inset 0px -3px 0px #637382b5;
    border: 1px solid #9eafc1;
    input {
      opacity: 0.2;
    }
    input:not(:placeholder-shown) {
      opacity: 1;
    }
  }
  &.create-document-input--invalid {
    background: #fbf4ef 0% 0% no-repeat padding-box;
    box-shadow: inset 0px -3px 0px #637382b5;
    border: 1px solid #d6624a;
  }

  &.create-document-input--pointer:not(.create-document-input--locked):not(.create-document-input--auto-fill) {
    cursor: pointer;
    & * {
      cursor: inherit;
    }
  }

  &.create-document-input--vehicle {
    main {
      padding-right: 3px;
    }
  }

  &.create-document-input--disabled:not(.create-document-input--locked),
  &.create-document-input--auto-fill {
    cursor: default;
    opacity: 0.5;
    & * {
      cursor: inherit;
    }

    &.create-document-input--vehicle {
      background: rgba($color: #fff, $alpha: 0.5);
      border-color: rgba($color: #9eafc1, $alpha: 0.5);
      opacity: 1;

      main {
        > *:not(.vehicle-toggle) {
          opacity: 0.5;
        }
      }
    }
  }
  // &.create-document-input--vehicle:not(.create-document-input--locked) {
  //   background: rgba($color: #fff, $alpha: 0.5);
  //   opacity: 1;
  // }
  &.create-document-input--auto-fill {
    opacity: 1;
  }

  .datepicker-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
  }

  > main {
    align-items: center;
    display: flex;
    height: 100%;
    padding: 0 1rem;
    width: 100%;
    > .icon {
      color: #004d94;
      font-size: $px20;
      &:first-child {
        margin-right: 10px;
      }
      &:last-child {
        margin-left: 10px;
        @include transition($transition);
      }
      &.reversed {
        @include transform(scale(-1));
      }
    }
    > div {
      width: 100%;

      input,
      textarea,
      .fake-textarea {
        background: inherit;
        border: 0;
        color: #000;
        font-size: $px14;
        font-weight: 600;
        margin-bottom: 0.3rem;
        outline: none;
        padding: 0;
        width: 100%;

        &::placeholder {
          color: #000;
        }
      }
      textarea {
        overflow: hidden;
        resize: none;
        @include transition(all 0.3s $cubic);
      }
      p {
        font-size: $px12;
        color: rgba($color: #000, $alpha: 0.5);
      }
    }
  }

  .fake-input,
  .fake-placeholder {
    color: #000;
    font-size: $px14;
    font-weight: 600;
  }

  .fake-placeholder {
    margin-bottom: 0.3rem;
  }

  > .signature {
    padding-right: 1rem;
    .icon {
      font-size: 43px;
    }
    img {
      height: auto;
      width: 100px;
    }
    .hidden {
      display: none;
    }
  }

  .create-document-input__dropdown {
    background: inherit;
    border-radius: inherit;
    border: inherit;
    left: 0;
    max-height: 25vh;
    overflow: auto;
    position: absolute;
    top: calc(100% + 10px);
    width: 100%;
    z-index: 1;
    @include scrollbar(5px, #004d94);

    > p {
      display: flex;
      flex-direction: column;
      padding: 0.5rem 1rem;

      span {
        font-weight: 700;
      }
      .prop-two {
        font-weight: normal;
        opacity: 0.5;
      }
      &.empty {
        cursor: default;
      }

      &:not(.empty):hover {
        background: rgba($color: #004d94, $alpha: 0.1);
      }
      &:not(.empty).selected {
        background: rgba($color: #004d94, $alpha: 0.2);
      }
    }
  }
}
.checkbox-container {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;

  &.checkbox-container--disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
.checkbox-wrapper {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 18px;
  padding-left: 10px;
  position: relative;
  @include noselect();

  input {
    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;
  }

  p {
    color: #000000;
    font-size: 14px;
    font-weight: 600;
    margin: 0 0 0 15px;
    // white-space: nowrap;
  }
}

/* Create a custom checkbox */
.checkmark {
  border-radius: 50%;
  border: 2px solid #212121;
  height: 18px;
  left: 0;
  position: absolute;
  top: 0;
  width: 18px;
}

.checkmark:after {
  content: '';
  display: none;
  position: absolute;
}

.checkbox-wrapper input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-wrapper .checkmark:after {
  background: #212121;
  border-radius: 50%;
  height: 80%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
}

.checkbox-container--disabled {
  .checkbox-wrapper {
    cursor: default;
    input {
      cursor: default;
    }
  }
}

.vehicle-input-wrapper {
  position: relative;
  width: 100%;
}
.vehicle-toggle {
  border-radius: 4px;
  border: 1px solid transparent;
  cursor: pointer;
  padding: 6px;
  right: 3px;
  top: 50%;
  @include transition(all 0.3s $cubic);

  &:hover {
    background: #fff;
    border-color: #acbfd4;
    transform: scale(1.15);
    z-index: 1;
  }
  img {
    display: block;
  }
}
.vehicle-toggles {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 1%;

  > *:last-child {
    margin-left: -10px;
  }
}

.create-document-input-wrapper.mini {
  min-width: 130px;
  width: 130px;
  .create-document-input {
    min-height: 45px;

    > main > div > input {
      margin: 0;
    }
  }

  .icon {
    display: none;
  }
}
@media screen and (max-width: $sm) {
  .create-document-input {
    &:not(.create-document-input--locked):hover {
      background: #fff;
      border: 1px solid #9eafc1;
    }
    &:not(.create-document-input--auto-fill):focus-within {
      background: #fff;
      border: 1px solid #9eafc1;
    }
  }
}
</style>
