// Author		: Zdravko Pernikov

const ones_teens = ['', 'един', 'два', 'три', 'четири', 'пет', 'шест', 'седем', 'осем', 'девет', 'десет', 'единадесет',
  'дванадесет', 'тринадесет', 'четиринадесет', 'петнадесет', 'шестнадесет', 'седемнадесет', 'осемнадесет', 'деветнадесет'
];
const ones_teens_for_thousands = ['', 'една', 'две', 'три', 'четири', 'пет', 'шест', 'седем', 'осем', 'девет', 'десет', 'единадесет',
  'дванадесет', 'тринадесет', 'четиринадесет', 'петнадесет', 'шестнадесет', 'седемнадесет', 'осемнадесет', 'деветнадесет'
];

const tens = ['', '', 'двадесет', 'тридесет', 'четиридесет', 'петдесет', 'шестдесет', 'седемдесет', 'осемдесет', 'деветдесет'];
const hundreds = ['', 'сто', 'двеста', 'триста', 'четиристотин', 'петстотин', 'шестстотин', 'седемстотин', 'осемстотин', 'деветстотин']
const scales = ['', 'хиляди', 'милиона', 'милиарда', 'трилиона', 'трилиарда', 'квадрилиона',
  'квадрилиарда', 'пентилиона', 'пентилиарда', 'секстилиона', 'секстилиарда'
];

function getDecimals(n, i, fromHundreds) {
  let output = '';

  // logic
  if (n < 20) {
    if (i === 1) {
      output = ones_teens_for_thousands[n]
    } else {
      output = ones_teens[n]
    }
  } else {
    output = tens[Math.floor(n / 10)];

    if (n % 10) {
      if (i === 1) {
        output += ' и ' + ones_teens_for_thousands[n % 10]
      } else {
        output += ' и ' + ones_teens[n % 10]
      }
    }
  }
  if (output && fromHundreds) {
    if (!(n % 10) || n < 20) {
      output = ' и ' + output
    } else if (n % 10) {
      output = ' ' + output
    }
  }
  return output;
}

function toWords(n, i, a) {
  // exception
  if (i === 1 && n === 1) {
    return 'хиляда'
  }

  let output = '';

  if (n < 100) {
    output = getDecimals(n, i, false)
  } else {
    output = hundreds[Math.floor(n / 100)]
    output += getDecimals(n % 100, i, true)
  }
  if((i === 0 && a.length > 1) && !(output.includes(' ')) && !!n) {
    output = 'и ' + scalify(output, i, n === 1);
  } else {
    
    output = scalify(output, i, n === 1);
  }

  return output
}

function chunkify(n) {
  const numbers = [];
  if (n > Number.MAX_SAFE_INTEGER) {
    return numbers;
  }
  while (n > 0) {
    numbers[numbers.length] = n % 1000;
    n = Math.floor(n / 1000);
  }
  return numbers;
}

function scalify(item, index, one) {
  if (item && index) {
    if (index > 1 && one) {
      return item + " " + scales[index].substring(0, scales[index].length - 1);
    } else {
      return item + " " + scales[index];
    }
  }
  return item;
}

function wordify(n) {
  return chunkify(n).map(toWords).reverse().join(" ")
}

export default wordify