<template>
  <label class="radio-wrapper" @click.stop>
    <input type="radio" :checked="value" :disabled="disabled" @change="$emit('input', $event.target.checked)" />
    <span class="radio__circle"></span>

    <!-- <input type="radio" checked="checked" name="radio"> -->

    <div class="labels">
      <p class="label" v-if="label">{{ label }}</p>
      <p class="sublabel" v-if="sublabel">{{ sublabel }}</p>
    </div>
  </label>
</template>

<script>
export default {
  props: ['value', 'label', 'sublabel', 'disabled', 'type'],
};
</script>
<style lang="scss" scoped>
@import '@/scss/base.scss';
.radio-wrapper {
  align-items: center;
  color: #000000;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  min-height: 20px;
  position: relative;
  padding-left: 28px;
  user-select: none;

  .labels {
    text-align: left;
    .sublabel {
      color: #7a7a7a;
      font-size: 12px;
      font-weight: 500;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .radio__circle {
    background-color: transparent;
    border: 2px solid #242424;
    border-radius: 50%;
    height: 20px;
    left: 0;
    position: absolute;
    top: 0;
    width: 20px;
  }

  &:hover input ~ .radio__circle {
    background-color: rgba(171, 171, 171, 0.2);
  }
}

/* Create the indicator (the dot/radio__circle - hidden when not checked) */
.radio__circle:after {
  content: '';
  display: none;
  position: absolute;
}

/* Show the indicator (dot/radio__circle) when checked */
.radio-wrapper input:checked ~ .radio__circle {
  border-color: #0c518f;

  &:after {
    display: block;
  }
}

/* Style the indicator (dot/radio__circle) */
.radio-wrapper .radio__circle:after {
  background: #05579f;
  border-radius: 50%;
  left: 3px;
  height: 10px;
  top: 3px;
  width: 10px;
}
</style>
