<template>
  <div class="document-form-type">
    <div class="row center text-center column">
      <h1>ЗАЯВЛЕНИЕ <br> ЗА ПРИДОБИТО ПРАВО НА ПЕНСИЯ</h1>
      <p class="doc-number" v-if="d.number">№{{ d.numberPrefix }}-{{ d.number }}</p>
    </div>

    <section class="rows-wrapper">
      <div class="row">
        <Input
          style="width: 75%"
          icon="F"
          placeholder="Име Презиме Фамилия"
          label="От Работник/Служител (изберете или потърсете име)"
          type="dropdown"
          @fetch="$emit('fetch-employees', $event)"
          :locked="d._id || isCurrUserDocUser"
          :list="employees"
          prop="name"
          identifier="_id"
          v-model="d.form.user"
        />
        <Input
          style="width: 25%"
          icon="+"
          placeholder="№"
          label="Служебен номер"
          v-model="d.form.user.number"
          :autoFill="true"
          :locked="d._id"
        />
      </div>
      <div class="row">
        <Input
          icon="b"
          placeholder="Длъжност"
          label="Длъжност на работника или служителя (автом.)"
          :value="d.form.user.position ? d.form.user.position.name : ''"
          :autoFill="true"
          :locked="d._id"
        />
        <Input
          :icon="'&'"
          placeholder="Структурно звено"
          label="Структурно звено (участък, цех, лаборатория, отдел, отделение, група)"
          :value="d.form.user.structuralUnit ? d.form.user.structuralUnit.name : ''"
          :autoFill="true"
          :locked="d._id"
        />
      </div>
      <div class="row">
        <Input
          icon=")"
          placeholder="Телефон"
          label="Номер на мобилен телефон"
          v-model="d.form.user.phone"
          :autoFill="true"
        />
      </div>
    </section>

    <div class="row first-letter--margin">
      <p>Придобивам право на пенсия за осигурителен стаж и възраст от:</p>
    </div>

    <div class="row">
      <Input
        type="textarea"
        icon="C"
        placeholder="ДД.ММ.ГГГГ"
        label="От дата"
        :minlength="165"
        :maxlength="165"
        :locked="d._id && (!isCurrUserDocHr || stageTwoDone)"
        v-model="d.form.dateFrom"
      />
    </div>

    <div class="row">
      <p>и желая да се възползвам от правото си на пенсиониране, без прекратяване на трудовото правоотношение.</p>
    </div>

    <div class="row first-letter--margin">
      <p>
        <strong> В тази връзка, моля да ми бъдат издадени необходимите документи за пенсиониране. </strong>
      </p>
    </div>

    <div class="row">
      <p>I. Ползване на неплатен отпуск без трудов стаж през годините</p>
    </div>

    <div class="row">
      <ApprovalBig
        icon="0"
        preYes="През годините съм"
        yes="съм"
        postYes="ползвал неплатен отпуск (над 30 дни в една календарна година)."
        preNo="През годините"
        no="не съм"
        postNo="ползвал неплатен отпуск (над 30 дни в една календарна година)."
        v-model="d.form.hasUsedPaidLeave"
        :locked="d._id"
      />
    </div>

    <div class="row">
      <p>II. Допускане на самоотлъчки</p>
    </div>

    <div class="row">
      <ApprovalBig
        icon="0"
        preYes="През годините съм"
        yes="съм"
        postYes="допускал самоотлъчки."
        preNo="През годините"
        no="не съм"
        postNo="допускал самоотлъчки."
        v-model="d.form.selfExcommunications"
        :locked="d._id"
      />
    </div>

    <div class="row">
      <p>III. Други особености по трудовият ми и осигурителен стаж и доход</p>
    </div>

    <div class="row">
      <Input
      type="textarea"
        icon="Q"
        :maxlength="165"
        placeholder="Попълнете свободен текст (до 165 символа)"
        label="Попълнете свободен текст"
        v-model="d.form.otherFeatures"
        :nonRequired="true"
        :locked="d._id"
      />
    </div>

    <div class="row">
      <p>IV. Известно ми е, че:</p>
    </div>

    <div class="row aside--row row--align-center">
      <aside><p class="circle circle--mini"></p></aside>
      <p>
        срока за подаване на документите в НОИ е 2 месеца от датата на придобиване право на пенсия или от датата на
        подаването им, ако този срок е изтекъл.
      </p>
    </div>
    <div class="row aside--row row--align-center">
      <aside><p class="circle circle--mini"></p></aside>
      <p>
        обезщетенията по чл. 222 ал.3 от Кодекса на труда ще ми бъдат начислени и изплатени при прекратяване на
        трудовият ми договор при условия и размери, действащи към датата на прекратяването.
      </p>
    </div>
    <div class="row aside--row row--align-center">
      <aside><p class="circle circle--mini"></p></aside>
      <p>
        след като съм придобил правото на пенсия за осигурителен стаж и възраст, независимо дали съм упражнил това право
        или не, може да ми бъде отправено едномесечно предизвестие за прекратяване на трудовият договор, на основание
        чл. 328 т. 10 от КТ.
      </p>
    </div>

    <div class="row row--background">
      <Input
        icon="C"
        placeholder="Дата на създаване"
        label="Дата на създаване"
        v-model="d.form.createdAt"
        type="datepicker"
        :locked="d._id"
      />
      <Input
        icon="$"
        :placeholder="d.form.user.name ? d.form.user.name : 'Име, Фамилия'"
        label="Служител - име и подпис"
        type="signature"
        :value="d.form.signatures.user"
        @signed="d.form.signatures.user = $event"
        :locked="d._id"
      />
    </div>

    <template v-if="d._id">
      <div class="brake">ПОДПИСВА СЕ ОТ РЪКОВОДИТЕЛ УЧР</div>

      <div class="row">
        <Input
          icon="O"
          placeholder="Изберете ръководител"
          label="Одобряващ ръководител 1 (изберете или потърсете име)"
          type="dropdown"
          @fetch="$emit('fetch-supervisors', $event)"
          :list="supervisors"
          prop="name"
          :identifier="d.form.receiver ? '_id' : false"
          v-model="d.form.receiver"
          :disabled="!stageTwoDone ? disabledHrFields : false"
          :locked="stageTwoDone"
        />
      </div>

      <div class="row row--background">
        <Input
          icon="C"
          placeholder="Дата на създаване"
          label="Дата на създаване"
          v-model="d.form.createdAtHr"
          type="datepicker"
          :disabled="!stageTwoDone ? disabledHrFields : false"
          :locked="stageTwoDone"
        />
        <Input
          icon="$"
          placeholder="Минка Димитрова Бакалова-Каменова"
          label="УЧР - име и подпис"
          type="signature"
          :value="d.form.signatures.hr"
          @signed="d.form.signatures.hr = $event"
          :disabled="!stageTwoDone ? disabledHrFields : false"
          :locked="stageTwoDone"
        />
      </div>
    </template>
    <template v-if="stageTwoDone">
      <div class="brake">ПОДПИСВА СЕ ОТ РЪКОВОДИТЕЛ 1 - {{ d.form.receiver.name }}</div>

      <div class="row">
        <Input
          icon="O"
          placeholder="Изберете ръководител"
          label="Одобряващ ръководител 2 (изберете или потърсете име)"
          type="dropdown"
          @fetch="$emit('fetch-filtered-supervisors', $event)"
          :list="supervisorsTwo"
          prop="name"
          :identifier="d.form.receiverTwo ? '_id' : false"
          v-model="d.form.receiverTwo"
          :disabled="(!stageThreeDone ? !isCurrUserDocReceiver : false) && !canEdit"
          :locked="stageThreeDone && !canEdit"
        />
      </div>

      <div class="row center">
        <p>
          <strong> Уведомен съм, </strong>
        </p>
      </div>

      <div class="row row--background">
        <Input
          icon="C"
          placeholder="Дата на създаване"
          label="Дата на създаване"
          v-model="d.form.createdAtReceiver"
          type="datepicker"
          :disabled="!stageThreeDone ? !isCurrUserDocReceiver : false"
          :locked="stageThreeDone"
        />
        <Input
          icon="$"
         :placeholder="d.form.receiver.name"
          label="Ръководител 1"
          type="signature"
          :value="d.form.signatures.receiver"
          @signed="d.form.signatures.receiver = $event"
          :disabled="!stageThreeDone ? !isCurrUserDocReceiver : false"
          :locked="stageThreeDone"
        />
      </div>
    </template>
    <template v-if="stageThreeDone">
      <div class="brake">ПОДПИСВА СЕ ОТ РЪКОВОДИТЕЛ 2 - {{ d.form.receiverTwo.name }}</div>

      <div class="row center">
        <p>
          <strong> Уведомен съм, </strong>
        </p>
      </div>

      <div class="row row--background">
        <Input
          icon="C"
          placeholder="Дата на създаване"
          label="Дата на създаване"
          v-model="d.form.createdAtReceiverTwo"
          type="datepicker"
          :disabled="!isCurrUserDocReceiverTwo"
        />
        <Input
          icon="$"
         :placeholder="d.form.receiverTwo.name"
          label="Ръководител 2"
          type="signature"
          :value="d.form.signatures.receiverTwo"
          @signed="d.form.signatures.receiverTwo = $event"
          :disabled="!isCurrUserDocReceiverTwo"
        />
      </div>
    </template>
  </div>
</template>

<script>
import Input from '../CreateDocumentInput';
import ApprovalBig from '../CreateDocumentApprovalBig.vue';
// import mvr from '@/data/mvr.json';
export default {
  components: {
    Input,
    ApprovalBig,
  },
  props: [
    'd',
    'employees',
    'supervisors',
    'supervisorsTwo',
    'pdf',
    'invalid',
    'isCurrUserDocUser',
    'isCurrUserDocHr',
    'isCurrUserDocReceiver',
    'isCurrUserDocReceiverTwo',
    'canEdit',
  ],
  data() {
    return {
      stageTwoDone: false,
      stageThreeDone: false,
    };
  },
  computed: {
    disabledHrFields() {
      return this.stageTwoDone ? !this.currentUser.roles.admin : !this.isCurrUserDocHr;
    },
  },
  beforeMount() {
    if (this.d.form.signatures.hr) this.stageTwoDone = true;
    if (this.d.form.signatures.receiver) this.stageThreeDone = true;

    if (!this.d._id) {
      this.$set(this.d.form, 'receiver', {});
      this.$set(this.d.form.signatures, 'hr', null);
      this.$set(this.d.form.signatures, 'receiver', null);
      this.$set(this.d.form.signatures, 'receiverTwo', null);
    }

    if (!this.d.form.signatures.hr) this.d.form.createdAtHr = new Date();
    if (!this.d.form.signatures.receiver) this.d.form.createdAtReceiver = new Date();
    if (!this.d.form.signatures.receiverTwo) this.d.form.createdAtReceiverTwo = new Date();
  },
};
</script>
