<template>
  <div class="document-form-type">
    <div class="row center text-center column">
      <h1>
        ДЕКЛАРАЦИЯ ЗА <br />
        МПС И ДЕЦА
      </h1>
      <p class="doc-number" v-if="d.number">№{{ d.numberPrefix }}-{{ d.number }}</p>
    </div>
    <div class="row center text-center">
      <p class="smaller-line-height">
        <strong>От долуподписаният:</strong>
      </p>
    </div>

    <section class="rows-wrapper">
      <div class="row">
        <Input
          style="width: 75%"
          icon="F"
          placeholder="Име Презиме Фамилия"
          label="От Работник/Служител (изберете или потърсете име)"
          type="dropdown"
          @fetch="$emit('fetch-employees', $event)"
          :locked="d._id || isCurrUserDocUser"
          :list="employees"
          prop="name"
          identifier="_id"
          v-model="d.form.user"
          @input="setVehicles"
        />
        <Input
          style="width: 25%"
          icon="+"
          placeholder="№"
          label="Служебен номер"
          v-model="d.form.user.number"
          :autoFill="true"
        />
      </div>
      <div class="row">
        <Input
          icon="b"
          placeholder="Длъжност"
          label="Длъжност на работника или служителя (автом.)"
          :value="d.form.user.position ? d.form.user.position.name : ''"
          :autoFill="true"
        />
        <Input
          :icon="'&'"
          placeholder="Структурно звено"
          label="Структурно звено (участък, цех, лаборатория, отдел, отделение, група)"
          :value="d.form.user.structuralUnit ? d.form.user.structuralUnit.name : ''"
          :autoFill="true"
        />
      </div>
    </section>

    <div class="row first-letter--margin">
      <p>
        I. Във връзка с въвеждане на контролиран достъп, паркиране и престой в предзаводското пространство на
        дружеството (паркинги и свободни от автомобили площи),
      </p>
    </div>

    <div class="row">
      <p><strong>Данни за МПС:</strong></p>
    </div>

    <section class="rows-wrapper">
      <!-- <div class="row">
        <ToggleSize
          placeholder="Данни за лични МПС, с които идвам на работа и които сам управлявам."
          v-model="d.form.vehicles"
          :allowZero="true"
          :maxSize="3"
        />
      </div> -->
      <div class="row" v-if="debug()">
        {{ d.form.vehicles }}
      </div>
      <template v-if="!pdf">
        <div class="row" v-for="(v, i) of d.form.vehicles" :key="`vehicle-${i}`">
          <Input
            :icon="`&quot;`"
            placeholder="Регистрационен номер"
            :label="`Регистрационен номер МПС ${i + 1}`"
            :uppercase="true"
            :vehicleDisabled="!v.enabled"
            :vehicleEdit="v.old"
            v-model="v.numberPlate"
            :maxlength="10"
          />
          <Input
            icon="#"
            placeholder="Марка"
            :label="`Марка на МПС ${i + 1}`"
            type="dropdown"
            @fetch="$emit('fetch-employees', $event)"
            :list="carBrands"
            v-model="v.carBrand"
            :selectSearch="true"
            :vehicleDisabled="!v.enabled"
            :vehicle="true"
            :vehicleEdit="v.old"
            @vehicle-enable="$set(v, 'enabled', true)"
            @vehicle-delete="
              v.old ? $set(v, 'prev', { numberPlate: v.numberPlate, carBrand: v.carBrand }) : null;
              $delete(v, 'enabled');
              $delete(v, 'numberPlate');
              $delete(v, 'carBrand');
              $delete(v, 'old');
            "
            @vehicle-edit="
              $set(v, 'prev', { numberPlate: v.numberPlate, carBrand: v.carBrand });
              $delete(v, 'numberPlate');
              $delete(v, 'carBrand');
              $delete(v, 'old');
            "
          />
        </div>
      </template>
      <template v-else>
        <template v-if="disabledCars.length">
          <u>Деактивирани МПС:</u>
          <div class="row" v-for="(v, i) of disabledCars" :key="`disabled-cars-${i}`">
            <Input
              :icon="`&quot;`"
              placeholder="Регистрационен номер"
              :label="`Регистрационен номер МПС ${v.i + 1}`"
              v-model="v.prev.numberPlate"
            />
            <Input
              icon="#"
              placeholder="Марка"
              :label="`Марка на МПС ${v.i + 1}`"
              :list="carBrands"
              v-model="v.prev.carBrand"
              :vehicle="true"
            />
          </div>
        </template>
        <template v-if="newCars.length">
          <u>Добавени МПС:</u>
          <div class="row" v-for="(v, i) of newCars" :key="`new-cars-${i}`">
            <Input
              :icon="`&quot;`"
              placeholder="Регистрационен номер"
              :label="`Регистрационен номер МПС ${v.i + 1}`"
              v-model="v.numberPlate"
            />
            <Input
              icon="#"
              placeholder="Марка"
              :label="`Марка на МПС ${v.i + 1}`"
              :list="carBrands"
              v-model="v.carBrand"
              :vehicle="true"
            />
          </div>
        </template>

        <template v-if="oldCars.length">
          <u>МПС без промяна:</u>
          <div class="row" v-for="(v, i) of oldCars" :key="`old-cars-${i}`">
            <Input
              :icon="`&quot;`"
              placeholder="Регистрационен номер"
              :label="`Регистрационен номер МПС ${v.i + 1}`"
              v-model="v.numberPlate"
            />
            <Input
              icon="#"
              placeholder="Марка"
              :label="`Марка на МПС ${v.i + 1}`"
              :list="carBrands"
              v-model="v.carBrand"
              :vehicle="true"
            />
          </div>
        </template>
      </template>

      <div class="row center text-center" v-if="d.form.vehicles.length">
        <span style="opacity: 0.5"
          >Регистрационните номера на МПС трябва да са изписани пълно, с всички цифри и букви.</span
        >
      </div>
    </section>

    <div class="row center text-center">
      <p style="font-style: Italic">
        Уведомен/а съм, че ако не предоставя исканата информация или предоставя некоректна така-ва, личният ми
        автомобил, с който идвам на работа, няма да бъда допускан в предзаводското пространство на дружеството (паркинги
        и свободни площи).
      </p>
    </div>

    <section class="rows-wrapper">
      <div class="row first-letter--margin">
        <p>
          ІI. За реализиране на социалната политика на работодателя, насочена към децата на КЦМ АД е необходимо да
          разполагаме с информация за децата на работещите в Дружествата от КЦМ 2000 груп по име и дати на раждане.
        </p>
      </div>
      <div class="row first-letter--margin">
        <p>
          Затова, ако прецените, че желаете и вашите деца да са част от подготвяните мероприятия за тях, моля попълнете
          таблицата по-долу, като включите децата си на възраст до 16 години (ненавършени 16 год. към
          {{ lastDay | formatDateDots }}
          година):
        </p>
      </div>
    </section>

    <section class="rows-wrapper">
      <div class="row">
        <ToggleSize
          placeholder="Изберете броя деца, които имате"
          v-model="d.form.children"
          :allowZero="true"
          :maxSize="5"
        />
      </div>

      <div class="row" v-for="(child, i) of d.form.children" :key="`child-${i}`">
        <Input icon="F" placeholder="Име Презиме Фамилия" :label="`Дете ${i + 1}`" v-model="child.name" />
        <Input
          style="width: 60%"
          icon="+"
          placeholder="----------"
          :label="`ЕГН`"
          v-model="child.egn"
          :maxlength="10"
          :digitsOnly="true"
        />
        <Input
          style="width: 60%"
          icon="+"
          type="datepicker"
          placeholder="(автом.)"
          :label="`Дата на раждане`"
          v-model="child.dateOfBirth"
          :autoFill="true"
          :formatWithDots="true"
        />
      </div>
    </section>

    <section class="rows-wrapper">
      <div class="row">
        <p>Лични данни за другият родител на децата</p>
      </div>

      <div class="row">
        <Input
          icon="."
          placeholder="Име Презиме Фамилия"
          label="Имена на другия родител на децата"
          v-model="d.form.partnerName"
          :nonRequired="d.form.children.length ? false : true"
        />
      </div>
      <div class="row">
        <Input
          icon="."
          placeholder="Месторабота"
          label="Месторабота на другия родител"
          v-model="d.form.partnerJob"
          :nonRequired="d.form.children.length ? false : true"
        />
      </div>
      <div class="row">
        <p style="font-style: Italic">
          Задължен съм при промяна на декларираните данни да предоставя актуална информация
        </p>
      </div>
    </section>

    <div class="row">
      <p style="font-style: Italic">
        <strong>
          Уведомен/а съм, че предоставените от мен данни ще се използват с цел изплащане на парични средства, във връзка
          със социалната програма, приета с КТД на КЦМ АД, достъп до паркинга на КЦМ, както и за участие на децата на
          КЦМ в различни мероприятия, а данните ще се съхраняват в личното ми трудово досие, за срока на действие на
          трудовото правоотношение.
        </strong>
      </p>
    </div>
    <div class="row">
      <p>
        На основание чл. 9, параграф 2, б. „а“ от Регламент (ЕС) 2016/679 относно защита на физическите лица, във връзка
        с обработването на лични данни, давам своето информирано и доброволно съгласие за обработване на посочените от
        мен данни за МПС в качеството ми нает на трудов договор в КЦМ АД за целите и сроковете посочени по-горе.
      </p>
    </div>

    <div class="row row--background">
      <Input
        icon="C"
        placeholder="Дата на създаване"
        label="Дата на създаване"
        v-model="d.form.createdAt"
        type="datepicker"
      />
      <Input
        icon="$"
        :placeholder="d.form.user.name ? d.form.user.name : 'Име, Фамилия'"
        label="Служител - име и подпис"
        type="signature"
        :value="d.form.signatures.user"
        @signed="d.form.signatures.user = $event"
      />
    </div>
  </div>
</template>

<script>
import Input from '../CreateDocumentInput';
import ToggleSize from '../ToggleSize';
import dateOfBirthGenerator from '@/components/mixins/dateOfBirthGenerator.js';
export default {
  mixins: [dateOfBirthGenerator],
  components: {
    Input,
    ToggleSize,
  },
  props: ['d', 'employees', 'carBrands', 'invalid', 'isCurrUserDocUser', 'pdf'],
  watch: {
    'd.form.children': {
      handler: function (val) {
        for (let child of val) {
          if (child.egn && child.egn.length >= 6) {
            this.dateOfBirthGenerator(child);
          } else {
            child.dateOfBirth = '';
          }
        }
      },
      deep: true,
    },
    'd.form.vehicles': {
      handler: function () {
        if (this.d.form.vehicles) {
          this.disabledCars = this.d.form.vehicles.map((x, i) => Object.assign({ i }, x)).filter((x) => x.prev);
          this.newCars = this.d.form.vehicles.map((x, i) => Object.assign({ i }, x)).filter((x) => !x.old && x.enabled);
          this.oldCars = this.d.form.vehicles.map((x, i) => Object.assign({ i }, x)).filter((x) => x.old && x.enabled);
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      lastDay: '',
      disabledCars: [],
      newCars: [],
      oldCars: [],
    };
  },
  beforeMount() {
    if (!this.d._id) {
      this.setVehicles();
      this.$set(this.d.form, 'children', []);
    }
    this.lastDay = new Date(new Date().getFullYear(), 11, 31);
  },
  methods: {
    debug() {
      return localStorage.getItem('debug');
    },
    setVehicles() {
      if (this.d.form.user.vehicles ? this.d.form.user.vehicles.length : false) {
        this.$set(this.d.form, 'vehicles', this.d.form.user.vehicles);
        for (let v of this.d.form.vehicles) {
          if (v.enabled) this.$set(v, 'old', true);
        }
      } else {
        this.$set(this.d.form, 'vehicles', [{}, {}, {}]);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
u {
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
}
</style>
