<template>
  <div class="document-form-type">
    <div class="row center text-center column">
      <h1>
        КАРТА ЗА ОЦЕНКА НА <br />
        ИЗПЪЛНИТЕЛСКИ ПЕРСОНАЛ
      </h1>
      <p class="doc-number" v-if="d.number">№{{ d.numberPrefix }}-{{ d.number }}</p>
    </div>

    <section class="rows-wrapper">
      <div class="row">
        <Input
          style="width: 75%"
          icon="F"
          placeholder="Име Презиме Фамилия"
          label="От Работник/Служител (изберете или потърсете име)"
          type="dropdown"
          @fetch="$emit('fetch-employees', $event)"
          :locked="d._id || isCurrUserDocUser"
          :list="employees"
          prop="name"
          identifier="_id"
          v-model="d.form.user"
        />
        <Input
          style="width: 25%"
          icon="+"
          placeholder="№"
          label="Служебен номер"
          v-model="d.form.user.number"
          :autoFill="true"
        />
      </div>
      <div class="row">
        <Input
          :icon="'&'"
          placeholder="Структурно звено"
          label="Структурно звено (участък, цех, лаборатория, отдел, отделение, група)"
          :value="d.form.user.structuralUnit ? d.form.user.structuralUnit.name : ''"
          :autoFill="true"
        />
      </div>
      <div class="row">
        <Input
          style="width: 75%"
          icon="b"
          placeholder="Длъжност"
          label="Длъжност на работника или служителя (автом.)"
          :value="d.form.user.position ? d.form.user.position.name : ''"
          :autoFill="true"
        />
        <Input
          style="width: 25%"
          icon="b"
          placeholder="Смяна"
          label="Смяна"
          :value="d.form.user.schedule ? d.form.user.schedule : ''"
          :autoFill="true"
        />
      </div>
    </section>

    <div class="row center text-center">
      <p class="smaller-line-height">
        <strong
          >Оценка на трудовото представяне по отделните критерии и показатели, <br />
          за следния период:</strong
        >
      </p>
    </div>

    <div class="row">
      <Input
        icon="C"
        placeholder="ДД.ММ.ГГГГ"
        label="От дата"
        v-model="d.form.user.fromDate"
        :minlength="10"
        :maxlength="10"
        :dateRegex="true"
        :locked="d._id"
      />
      <Input
        icon="C"
        placeholder="ДД.ММ.ГГГГ"
        label="До дата"
        v-model="d.form.user.toDate"
        :minlength="10"
        :maxlength="10"
        :locked="d._id"
        :dateRegex="true"
      />
    </div>

    <div class="row row--no-wrap row--align-center">
      <p><strong>Вид на оценката:</strong></p>
      <Input
        label="изтичане на изпитателен срок"
        type="checkbox"
        :value="d.form.scoreType === 'изтичане на изпитателен срок'"
        :locked="d._id"
        @input="$set(d.form, 'scoreType', $event ? 'изтичане на изпитателен срок' : '')"
      />

      <Input
        label="годишна атестация"
        type="checkbox"
        :value="d.form.scoreType === 'годишна атестация'"
        :locked="d._id"
        @input="$set(d.form, 'scoreType', $event ? 'годишна атестация' : '')"
      />

      <Input
        label="при необходимост"
        type="checkbox"
        :value="d.form.scoreType === 'при необходимост'"
        :locked="d._id"
        @input="$set(d.form, 'scoreType', $event ? 'при необходимост' : '')"
      />
    </div>

    <div class="row wrap">
      <div style="width: 80%" class="title">
        <h1>КРИТЕРИИ ЗА ОЦЕНЯВАНЕ НА РАБОТАТА</h1>
      </div>

      <section class="row row--align-center center box" style="width: 25%">
        <p>ОЦЕНКА</p>
      </section>
    </div>

    <section class="rows-wrapper rows--background rows--points">
      <div>
        <p class="title">
          <strong>1. Изпълнение на регламентираните функции и задължения и на поставените задачи</strong>
        </p>
        <div class="row row--align-center row--points">
          <section style="width: 80%">
            <p>
              Познаване на работата. Точно и своевременно изпълнение на трудовите задължения и разпорежданията на прекия
              ръководител
            </p>
          </section>
          <Score v-model="d.form.scores.one" style="width: 20%" :num="true" :locked="d._id" />
        </div>

        <div class="row row--align-center row--points">
          <section style="width: 80%">
            <p>
              Самостоятелност и самоконтрол по време на работа; поемане на отговорност за изпълнението на поставените
              задачи и постигнатите резултати
            </p>
          </section>
          <Score v-model="d.form.scores.two" style="width: 20%" :num="true" :locked="d._id" />
        </div>

        <div class="row row--align-center row--points">
          <section style="width: 80%">
            <p>
              Познаване на потенциалните грешки или възможните трудности/рискове в работата и вземане на мерки за
              отстраняването им
            </p>
          </section>
          <Score v-model="d.form.scores.three" style="width: 20%" :num="true" :locked="d._id" />
        </div>

        <section class="row row--score-sum right">
          <p>
            Брой точки:
            {{ (d.form.scores.one || 0) + (d.form.scores.two || 0) + (d.form.scores.three || 0) }}
          </p>
        </section>
      </div>

      <div>
        <p class="title">
          <strong>2. Качество и интензивност на работата</strong>
        </p>
        <div class="row row--align-center row--points">
          <section style="width: 80%">
            <p>
              Поддържане на приетия стандарт за качество, старателност и сръчност при изпълнение на поставените задачи
            </p>
          </section>
          <Score v-model="d.form.scores.four" style="width: 20%" :num="true" :locked="d._id" />
        </div>

        <div class="row row--align-center row--points">
          <section style="width: 80%">
            <p>
              Изпълнение в срок на възложената работа и поставените задачи. Уплътняване на работ-ното време с
              изпълнението на дейности в интерес на работата и резултатите на звеното
            </p>
          </section>
          <Score v-model="d.form.scores.five" style="width: 20%" :num="true" :locked="d._id" />
        </div>

        <div class="row row--align-center row--points">
          <section style="width: 80%">
            <p>
              Възможности за ускоряване темпа на работа, за изпълнение на повишен обем работа или на допълнително
              поставени задачи
            </p>
          </section>
          <Score v-model="d.form.scores.six" style="width: 20%" :num="true" :locked="d._id" />
        </div>

        <section class="row row--score-sum right">
          <p>
            Брой точки:
            {{ (d.form.scores.four || 0) + (d.form.scores.five || 0) + (d.form.scores.six || 0) }}
          </p>
        </section>
      </div>

      <div>
        <p class="title">
          <strong>3. Степен на използване и опазване на ресурсите</strong>
        </p>
        <div class="row row--align-center row--points">
          <section style="width: 80%">
            <p>
              Изпълнение на работните инструкции и предписания, правилно използване на машините, апаратите и
              инструментите, като и на използваните материали, суровини и реагенти
            </p>
          </section>
          <Score v-model="d.form.scores.seven" style="width: 20%" :num="true" :locked="d._id" />
        </div>

        <div class="row row--align-center row--points">
          <section style="width: 80%">
            <p>
              Поддържане на инструментите и оборудването в работно състояние, грижа за хигиената на работно място и
              работната зона
            </p>
          </section>
          <Score v-model="d.form.scores.eight" style="width: 20%" :num="true" :locked="d._id" />
        </div>

        <div class="row row--align-center row--points">
          <section style="width: 80%">
            <p>
              Принос в осигуряването на икономия на материали и снижаване на загубите, и в опазване на фирмената
              собственост
            </p>
          </section>
          <Score v-model="d.form.scores.nine" style="width: 20%" :num="true" :locked="d._id" />
        </div>

        <section class="row row--score-sum right">
          <p>
            Брой точки:

            {{ (d.form.scores.seven || 0) + (d.form.scores.eight || 0) + (d.form.scores.nine || 0) }}
          </p>
        </section>
      </div>

      <div>
        <p class="title">
          <strong>4. Спазване на изискванията за БЗР, ПАБ и на ИСУ</strong>
        </p>
        <div class="row row--align-center row--points">
          <section style="width: 80%">
            <p>
              Спазване на трудовата и технологична дисциплина, на вътрешно-фирмените разпореждания и инструкции, и на
              законовите разпоредби по БЗР и ПАБ
            </p>
          </section>
          <Score v-model="d.form.scores.ten" style="width: 20%" :num="true" :locked="d._id" />
        </div>

        <div class="row row--align-center row--points">
          <section style="width: 80%">
            <p>
              Използване по предназначение на ЛПС, СРО и др., внимание и грижа за собствената и на другите безопасност
            </p>
          </section>
          <Score v-model="d.form.scores.eleven" style="width: 20%" :num="true" :locked="d._id" />
        </div>

        <div class="row row--align-center row--points">
          <section style="width: 80%">
            <p>
              Познаване на приложимите процедури от СУК, СУОС и СУБЗР, и спазване на всички техни изисквания при
              изпълнение на трудовите задължения
            </p>
          </section>
          <Score v-model="d.form.scores.twelve" style="width: 20%" :num="true" :locked="d._id" />
        </div>

        <section class="row row--score-sum right">
          <p>
            Брой точки:
            {{ (d.form.scores.ten || 0) + (d.form.scores.eleven || 0) + (d.form.scores.twelve || 0) }}
          </p>
        </section>
      </div>

      <div>
        <p class="title">
          <strong>5. Съответствие м/у приложената лична квалификация и изискванията за длъжността</strong>
        </p>
        <div class="row row--align-center row--points">
          <section style="width: 80%">
            <p>
              Степен на съответствие на професионалните знания и умения на работника с изискванията на заеманата
              длъжност и на прилагането/използването им в работата
            </p>
          </section>
          <Score v-model="d.form.scores.thirteen" style="width: 20%" :num="true" :locked="d._id" />
        </div>

        <div class="row row--align-center row--points">
          <section style="width: 80%">
            <p>
              Лична организация на трудовия процес при изпълнението на задачите. Разностранност на уменията и готовност
              за поемане на допълнителни задачи/отговорности
            </p>
          </section>
          <Score v-model="d.form.scores.fourteen" style="width: 20%" :num="true" :locked="d._id" />
        </div>

        <div class="row row--align-center row--points">
          <section style="width: 80%">
            <p>
              Стремеж към подобряване на изпълнението, повишаване на квалификацията, придобиване на допълнителни знания
              и умения/квалификации и тяхното прилагане в работния процес
            </p>
          </section>
          <Score v-model="d.form.scores.fiveteen" style="width: 20%" :num="true" :locked="d._id" />
        </div>

        <section class="row row--score-sum right">
          <p>
            Брой точки:
            {{ (d.form.scores.thirteen || 0) + (d.form.scores.fourteen || 0) + (d.form.scores.fiveteen || 0) }}
          </p>
        </section>
      </div>

      <div>
        <p class="title">
          <strong>6. Лични качества и лоялност към работодателя</strong>
        </p>
        <div class="row row--align-center row--points">
          <section style="width: 80%">
            <p>Приспособимост към промени и полезна инициативност</p>
          </section>
          <Score v-model="d.form.scores.sixteen" style="width: 20%" :num="true" :locked="d._id" />
        </div>

        <div class="row row--align-center row--points">
          <section style="width: 80%">
            <p>Умения за общуване и постигане на сътрудничество</p>
          </section>
          <Score v-model="d.form.scores.seventeen" style="width: 20%" :num="true" :locked="d._id" />
        </div>

        <div class="row row--align-center row--points">
          <section style="width: 80%">
            <p>Отношение към колегите, ръководителите и поставените задачи</p>
          </section>
          <Score v-model="d.form.scores.eighteen" style="width: 20%" :num="true" :locked="d._id" />
        </div>

        <section class="row row--score-sum right">
          <p>
            Брой точки:
            {{ (d.form.scores.sixteen || 0) + (d.form.scores.seventeen || 0) + (d.form.scores.eighteen || 0) }}
          </p>
        </section>
      </div>
    </section>

    <div class="row wrap">
      <div style="width: 80%" class="title">
        <h1>КРАЙНА ОЦЕНКА на трудовото представяне за периода</h1>
      </div>

      <section class="row row--align-center center points" style="width: 25%">
        <p>ТОЧКИ:</p>
        <p>{{ total.points }}</p>
      </section>
      <section class="row row--align-center center points" style="width: 25%">
        <p>ОЦЕНКА:</p>
        <p>{{ total.score }}</p>
      </section>
    </div>

    <section class="rows-wrapper">
      <div class="row center text-center">
        <p>Оценката може да бъде</p>
      </div>

      <section class="rows-wrapper rows--background">
        <div class="row row--score-table">
          <p>Оценка 1</p>
          <p>“Неприемливо представяне”</p>
          <p>точки 18 - 26</p>
        </div>

        <div class="row row--score-table">
          <p>Оценка 2</p>
          <p>“Приемливо представяне, но е необходимо подобрение”</p>
          <p>точки 27 - 44</p>
        </div>

        <div class="row row--score-table">
          <p>Оценка 3</p>
          <p>“Представянето отговаря на изискванията”</p>
          <p>точки 45 - 62</p>
        </div>

        <div class="row row--score-table">
          <p>Оценка 4</p>
          <p>“Представянето е над изискванията”</p>
          <p>точки 63 - 80</p>
        </div>

        <div class="row row--score-table">
          <p>Оценка 5</p>
          <p>“Много добро представяне”</p>
          <p>точки 81 - 90</p>
        </div>
      </section>

      <div class="row">
        <p><strong>Аргументиране на оценката, препоръки за подобряване и цели за развитие на оценявания</strong></p>
      </div>

      <div class="row">
        <p>1. Относно представянето през изминалия оценъчен период:</p>
      </div>

      <div class="row">
        <Input
          type="textarea"
          icon="."
          :maxlength="165"
          placeholder="Попълнете свободен текст (до 165 символа)"
          label="(попълва се задължително при оценки 1 и 5)"
          :nonRequired="total.score === 1 || total.score === 5 ? false : true"
          :locked="d._id"
          v-model="d.form.performanceText"
        />
      </div>

      <div class="row">
        <p>2. Препоръки за усъвършенстване:</p>
      </div>

      <div class="row">
        <Input
          type="textarea"
          icon="."
          :maxlength="165"
          placeholder="Попълнете свободен текст (до 165 символа)"
          label="(попълва се задължително при оценки 1 и 2)"
          :nonRequired="total.score === 1 || total.score === 2 ? false : true"
          :locked="d._id"
          v-model="d.form.improovementsText"
        />
      </div>
    </section>

    <div class="row row--background">
      <Input
        icon="C"
        placeholder="Дата на създаване"
        label="Дата на създаване"
        v-model="d.form.createdAtReceiver"
        :locked="d._id"
        type="datepicker"
      />
      <Input
        icon="$"
        :placeholder="d.form.user.supervisorFirst ? d.form.user.supervisorFirst.name : 'Имена на Рък 1 (автом.)'"
        label="Оценяващ ръководител 1"
        type="signature"
        :value="d.form.signatures.receiver"
        :locked="d._id"
        @signed="d.form.signatures.receiver = $event"
      />
    </div>

    <template v-if="d._id">
      <div class="brake">ПОПЪЛВА СЕ ОТ СЛУЖИТЕЛЯ</div>

      <section class="rows-wrapper">
        <div class="row center text-center">
          <p><strong>Коментар на оценявания:</strong></p>
        </div>

        <div class="row">
          <p>1. Относно оценката:</p>
        </div>

        <div class="row">
          <Input
            type="textarea"
            :maxlength="165"
            icon="."
            placeholder="Попълнете свободен текст (до 165 символа)"
            label="(попълва се по желание)"
            :nonRequired="true"
            :locked="stageTwoDone || !isCurrUserDocUser"
            v-model="d.form.aboutScoreText"
          />
        </div>

        <div class="row">
          <p>2. Относно препоръките:</p>
        </div>

        <div class="row">
          <Input
            type="textarea"
            :maxlength="165"
            icon="."
            placeholder="Попълнете свободен текст (до 165 символа)"
            label="(попълва се по желание)"
            :nonRequired="true"
            :locked="stageTwoDone || !isCurrUserDocUser"
            v-model="d.form.aboutImproovementsText"
          />
        </div>

        <div class="row center">
          <Approval
            pre="ПОТВЪРЖДАВАМ С ПОДПИСА СИ,"
            yes="ЧЕ СЪМ"
            no="НЕ СЪМ"
            post="СЪГЛАСЕН С ОЦЕНКАТА НА МОЯТА РАБОТА"
            v-model="d.form.approvedByUser"
            :locked="stageTwoDone || !isCurrUserDocUser"
          />
        </div>
      </section>

      <div class="row row--background">
        <Input
          icon="C"
          placeholder="Дата на създаване"
          label="Дата на създаване"
          v-model="d.form.createdAt"
          :locked="stageTwoDone || !isCurrUserDocUser"
          type="datepicker"
        />
        <Input
          icon="$"
          :placeholder="d.form.user.name ? d.form.user.name : 'Име, Фамилия'"
          label="Служител - име и подпис"
          type="signature"
          :value="d.form.signatures.user"
          @signed="d.form.signatures.user = $event"
          :locked="stageTwoDone || !isCurrUserDocUser"
        />
      </div>
    </template>

    <template v-if="stageTwoDone">
      <div class="brake">ПОПЪЛВА СЕ ОТ {{ d.form.user.supervisorSecond.name }}</div>

      <section class="rows-wrapper">
        <div class="row center text-center">
          <p><strong>Мнение на контролиращия ръководител 2:</strong></p>
        </div>

        <div class="row">
          <p>1. Относно оценката за представянето :</p>
        </div>

        <div class="row">
          <Input
            type="textarea"
            :maxlength="165"
            icon="."
            placeholder="Попълнете свободен текст (до 165 символа)"
            label="(попълва се по желание)"
            :nonRequired="true"
            :locked="!isCurrUserUserSupervisorTwo"
            v-model="d.form.isCurrUserUserSupervisorTwo"
          />
        </div>

        <div class="row">
          <p>2. Относно коментара на оценявания :</p>
        </div>

        <div class="row">
          <Input
            type="textarea"
            :maxlength="165"
            icon="."
            placeholder="Попълнете свободен текст (до 165 символа)"
            label="(попълва се по желание)"
            :nonRequired="true"
            :locked="!isCurrUserUserSupervisorTwo"
            v-model="d.form.aboutImproovementsTextSupervisor"
          />
        </div>
      </section>

      <div class="row row--background">
        <Input
          icon="C"
          placeholder="Дата на създаване"
          label="Дата на създаване"
          v-model="d.form.createdAtReceiverTwo"
          :locked="!isCurrUserUserSupervisorTwo"
          type="datepicker"
        />
        <Input
          icon="$"
          :placeholder="d.form.user.supervisorSecond ? d.form.user.supervisorSecond.name : 'Имена на Рък 2 (автом.)'"
          label="Служител - име и подпис"
          type="signature"
          :value="d.form.signatures.receiverTwo"
          :locked="!isCurrUserUserSupervisorTwo"
          @signed="d.form.signatures.receiverTwo = $event"
        />
      </div>
    </template>
  </div>
</template>

<script>
import Approval from '../CreateDocumentApproval';
import Input from '../CreateDocumentInput';
import Score from '../CreateDocumentScore';
export default {
  components: {
    Approval,
    Input,
    Score,
  },
  props: ['d', 'employees', 'pdf', 'invalid', 'isCurrUserDocUser', 'isCurrUserReceiver', 'isCurrUserUserSupervisorTwo'],
  computed: {
    total() {
      let points = 0;
      let score = 0;
      let scores = this.d.form.scores;
      let size = Object.keys(scores).length;

      for (const property in scores) {
        points += scores[property];
      }

      if (size === 18) {
        if (points >= 18 && points <= 26) score = 1;
        if (points >= 27 && points <= 44) score = 2;
        if (points >= 45 && points <= 62) score = 3;
        if (points >= 63 && points <= 80) score = 4;
        if (points >= 81 && points <= 90) score = 5;
      }

      return { points, score };
    },
  },
  watch: {
    'd.form.user'() {
      this.$set(this.d.form.user, 'supervisorSecond', this.d.form.user.supervisorSecond);
    },
  },
  data() {
    return {
      stageTwoDone: false,
      first: 0,
    };
  },
  beforeMount() {
    if (this.d.form.signatures.user) this.stageTwoDone = true;

    if (!this.d._id) {
      this.$set(this.d.form, 'scores', {});
      this.$set(this.d.form.signatures, 'receiver', null);
      this.$set(this.d.form.signatures, 'user', null);
      this.$set(this.d.form.signatures, 'receiverTwo', null);
      this.d.form.createdAtReceiver = new Date();
    }
    if (this.d.form.signatures.receiver) this.d.form.createdAt = new Date();
    if (this.d.form.signatures.user) this.d.form.createdAtReceiverTwo = new Date();
  },
};
</script>