<template>
  <div class="table">
    <p
      class="export-title no-print"
      v-if="
        $route.query.type === 'exportSalaries' ||
        $route.query.type === 'exportPersonalData' ||
        $route.query.type === 'exportVehicles'
      "
    >
      ИЗБЕРЕТЕ ПЕРИОД, ЗА КОЙТО ИСКАТЕ ДА НАПРАВИТЕ ЕКСПОРТ, И НАТИСНЕТЕ БУТОНА ЗА ЕКСПОРТ
    </p>
    <table
      id="Table2XLSX"
      class="no-print"
      :class="{ 'inquiry-table': $route.query.type === 'inquiryAdditional' }"
      v-else
    >
      <thead>
        <tr v-if="$route.query.type === 'inquiryAdditional'">
          <th>№</th>
          <th>ИМЕ</th>
          <th>ДЛЪЖНОСТ</th>
          <th>ВИД ДС</th>
          <th>СЧИТАНО ОТ</th>
          <th>НОВА ЗАПЛАТА</th>
          <th :style="{ width: 'initial', 'text-align': 'left' }">НОВА ДЛЪЖНОСТ</th>
        </tr>
        <tr v-else>
          <th v-if="!manualDocs">№</th>
          <th>ДАТА</th>
          <th>ДОКУМЕНТ</th>
          <th v-if="!manualDocs">ЗА СЛУЖИТЕЛ</th>
          <th>СЪЗДАДЕН ОТ</th>
          <th>ПОЛУЧАТЕЛ</th>
          <th v-if="!manualDocs">СТАТУС</th>
          <template v-if="manualDocs">
            <th v-if="currentUser.roles.admin || currentUser.roles.hr">КАТЕГОРИЯ</th>
            <th>ДЕЙСТВИЯ</th>
            <th v-if="currentUser.roles.admin || currentUser.roles.hr"></th>
          </template>
        </tr>
        <tr v-if="(!isMobile ? !manualDocs : true) && $route.query.type !== 'inquiryAdditional'">
          <td colspan="9">
            <CreateDocument
              @click="
                selectedDoc = null;
                selectedDocType = null;
                isDocumentModalOpen = true;
              "
            />
          </td>
        </tr>
      </thead>
      <tbody v-if="!docs.length">
        <tr class="no-hover">
          <td colspan="11" class="center">
            <i v-if="$route.query.type === 'inquiryAdditional'"
              >ЗА ИЗБРАНИЯ ПЕРИОД НЯМА ГЕНЕРИРАНИ ДОПЪЛНИТЕЛНИ СПОРАЗУМЕНИЯ</i
            >
            <i v-else>Няма документи</i>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr v-for="d in docs" :key="`doc-${d._id}`" @click="!manualDocs ? openDoc(d) : null">
          <template v-if="$route.query.type === 'inquiryAdditional'">
            <td>{{ d.form.user.number }}</td>
            <td>{{ d.form.user ? d.form.user.name : '' }}</td>
            <td>{{ d.form.user ? (d.form.user.position ? d.form.user.position.name : '') : '' }}</td>
            <td>{{ d.type.name }}</td>
            <td>
              <template v-if="d.type.type === 'salaryAgreement'">
                {{ d.parent ? d.parent.form.probationFrom : d.form.yearFrom | formatDateDots }}
              </template>
              <template v-else-if="d.type.type === 'positionAgreement'">
                {{ d.parent ? d.parent.form.probationFrom : d.form.contractFrom | formatDateDots }}
              </template>
            </td>
            <td>
              <template v-if="d.type.type === 'salaryAgreement'">
                {{ d.parent ? d.parent.form.mainSalary : d.form.price }}
              </template>
              <template v-else-if="d.type.type === 'positionAgreement'">
                {{ d.parent ? d.parent.form.mainSalary : d.form.mainSalary }}
              </template>
            </td>
            <td :style="{ width: 'initial' }">
              <template v-if="d.type.type === 'salaryAgreement'">
                {{ d.form.user.position.name }}
              </template>
              <template v-else-if="d.type.type === 'positionAgreement'">
                {{ d.parent ? d.parent.form.position : d.form.position }}
              </template>
            </td>
          </template>
          <template v-else>
            <td v-if="!manualDocs">
              <strong>{{ d.finalized ? `${d.numberPrefix}-${d.number}` : '-' }}</strong>
            </td>
            <td>
              <p>{{ d.createdAt | formatDate }}</p>
              <p>{{ d.createdAt | formatDateHours }}</p>
            </td>
            <td>
              <strong>{{ d.type.name }}</strong>
            </td>
            <td v-if="!manualDocs">{{ d.form.user ? d.form.user.name : '' }}</td>
            <td>{{ d.createdBy ? d.createdBy.name : '' }}</td>
            <td>{{ d.receiver ? d.receiver.name : '' }}</td>
            <td v-if="!manualDocs">
              <DocumentStatus :type="getStatus(d)" :d="d" />
            </td>
            <template v-if="manualDocs">
              <td
                v-if="currentUser.roles.admin || currentUser.roles.hr"
                @click="
                  currentUser.roles.admin || currentUser.roles.hr
                    ? ((isCategoryChangeModalOpen = true), (selectedDoc = d))
                    : null
                "
              >
                <section class="manual-category">
                  <span class="icon">{{ categoryIcons[d.category] }}</span>
                  <p>{{ d.category }}</p>
                </section>
              </td>
              <td
                :class="{ disabled: !d.finalizedAt && d.preFinalized }"
                v-if="d.needsConfirmation"
                @click="!d.finalizedAt && !d.preFinalized ? ((isAgreementModalOpen = true), (selectedDoc = d)) : null"
              >
                <section class="manual-needs-agreement">
                  <p v-if="d.finalizedAt" class="agreed">Потвърден документ {{ d.finalizedAt | formatDateFull }}</p>
                  <p v-else>Нужда от потвърждение</p>
                </section>
              </td>
              <td v-else></td>
              <!-- <td>
                <a
                  :href="`/api/trdocs/file/${d._id}#toolbar=0&navpanes=0`"
                  class="icon2 download"
                  target="_blank"
                  title="Свали документа"
                  >F</a
                >
              </td> -->
            </template>
            <td class="center">
              <div class="btns">
                <template v-if="manualDocs">
                  <a
                    :href="`/api/trdocs/file/${d._id}#toolbar=0&navpanes=0`"
                    class="icon2 download"
                    target="_blank"
                    title="Свали документа"
                    >F</a
                  >
                  <a
                    class="icon2 rename"
                    v-if="currentUser.roles.admin || currentUser.roles.hr"
                    @click.stop="renameTrdoc = d"
                    title="Преименувай документа"
                    >G</a
                  >
                </template>
                <a class="icon2 delete" v-if="canDelete(d)" @click.stop="deleteDoc(d)" title="Изтрий документа">H</a>
                <a class="icon2 delete" v-if="canAnnul(d)" @click.stop="annulDoc(d)" title="Анулирай документа">I</a>
              </div>
            </td>
          </template>
        </tr>
      </tbody>
    </table>
    <transition name="fade">
      <CreateDocumentModal
        v-if="isDocumentModalOpen"
        :id="selectedDoc"
        :oldDocument="selectedDocType"
        @close="closeDocumentModal"
      />
      <CategoryChangeModal
        v-if="isCategoryChangeModalOpen"
        :d="selectedDoc"
        @close="updateDocument(selectedDoc, $event)"
      />
      <CreateDocumentModalConfirm
        v-if="isAgreementModalOpen"
        yes="ЗАПОЗНАХ СЕ"
        :question="`Моля, потвърдете, че сте се запознали с прикачения документ`"
        :post="`<p>Запознах се с ${
          selectedDoc.type.name
        }, на <span style=\'color: #35AADF\'> ${this.$options.filters.formatDate(new Date())} </span></p>`"
        html="true"
        @selected="userAgreement(selectedDoc)"
      />
      <RenameTrdocModal v-if="renameTrdoc" :d="renameTrdoc" @close="closeRenameTrdoc" />
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CreateDocument from './CreateDocument';
import DocumentStatus from './DocumentStatus.vue';
import CreateDocumentModal from './CreateDocumentModal.vue';
import CategoryChangeModal from './CategoryChangeModal.vue';
import CreateDocumentModalConfirm from './CreateDocumentModalConfirm.vue';
import RenameTrdocModal from './RenameTrdocModal.vue';
import XLSX from 'xlsx';

export default {
  components: {
    CreateDocument,
    CreateDocumentModal,
    DocumentStatus,
    CategoryChangeModal,
    CreateDocumentModalConfirm,
    RenameTrdocModal,
  },
  props: ['docs', 'manualDocs'],
  computed: {
    ...mapGetters(['currentUser']),
  },
  data() {
    return {
      isMobile: false,
      isDocumentModalOpen: false,
      isCategoryChangeModalOpen: false,
      isAgreementModalOpen: false,
      selectedDoc: null,
      selectedDocType: null,
      renameTrdoc: null,
      categoryIcons: {
        'Лични документи, образование, квалификация и опит': '2',
        'Договори и допълнителни споразумения': 'y',
        'Заявления, декларации и длъжностни характеристики': 'z',
        'Служебни бележки': '0',
        'Други документи': '1',
      },
    };
  },
  methods: {
    canDelete(d) {
      return (
        (d.type.type === 'positionAgreement' ||
          d.type.type === 'salaryAgreement' ||
          d.type.type === 'mentor' ||
          d.type.type === 'mentorNew' ||
          this.manualDocs) &&
        (this.currentUser.roles.admin || this.currentUser.roles.hr)
      );
    },
    canAnnul(d) {
      return (
        d.finalized &&
        d.canBeUsedAsParent &&
        !d.annulled &&
        (d.type.type === 'position' || d.type.type === 'salary') &&
        (this.currentUser.roles.admin || this.currentUser.roles.hr)
      );
    },
    isMobileMode() {
      if (window.innerWidth < 1280) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    getStatus(d) {
      if (d.annulled) return 'annulled';
      if (d.finalized)
        return d.canBeUsedAsParent === false
          ? d.type.type === 'scoreCardStudent'
            ? 'used-as-parent-card'
            : 'used-as-parent'
          : 'approved';
      if (d.form.approved === false || d.form.approvedByRecieverTwo === false || d.form.approvedByReciever === false)
        return 'not-approved';
      else return 'waiting';
    },
    deleteDoc(d) {
      if (confirm('Сигурни ли сте, че искате да изтриете този документ?')) {
        if ((d.type.type === 'positionAgreement' || d.type.type === 'salaryAgreement') && d.parent) {
          this.updateDocument(d.parent, true);
        }

        this.$apiService
          .delete(`/trdocs/${d._id}`)
          .then(() => {
            this.$emit('getDocs', true);
          })
          .catch((err) => console.log(err));
      }
    },
    annulDoc(d) {
      if (confirm('Сигурни ли сте, че искате да анулирате този документ?')) {
        this.$apiService
          .put(`/trdocs/annul/${d._id}`)
          .then(() => {
            this.$set(d, 'annulled', true);
            this.$snotify.success('Документът е анулиран');
          })
          .catch((err) => console.log(err));
      }
    },
    userAgreement(d) {
      d.finalized = true;
      d.finalizedAt = new Date();
      d.userDecision = true;
      this.$apiService
        .put(`/trdocs/user-decision/${d._id}?confirmDocument=true`, d)
        .then(() => {
          this.isAgreementModalOpen = null;
          this.$emit('getDocs', true);
        })
        .catch((err) => console.log(err));
    },
    updateDocument(d, change) {
      if (change) {
        this.$apiService
          .put(`/trdocs/update-document/${d._id}`, d)
          .then(() => {
            this.$emit('getDocs', true);
          })
          .catch((err) => console.log(err));
      }
      this.isCategoryChangeModalOpen = false;
    },
    closeDocumentModal(route) {
      this.isDocumentModalOpen = false;
      const query = { ...this.$route.query };
      delete query.openDocument;

      query.t = new Date().getTime();
      if (route) {
        this.$router.push(route);
      } else {
        this.$router.push({
          path: this.$route.path,
          query: query,
        });
      }
      // this.$emit('getDocs', true);
    },
    closeRenameTrdoc(update) {
      if (update) {
        this.$emit('getDocs');
      }
      this.renameTrdoc = null;
    },
    openDoc(document) {
      this.selectedDoc = document._id;
      this.selectedDocType = document.type;
      this.isDocumentModalOpen = true;

      setTimeout(() => {
        // only agreement documents(7,9) have preFinalized
        if (this.isMobile && !document.preFinalized) {
          window.open(`/api/trdocs/file/${document._id}#toolbar=0&navpanes=0`, '_blank');
        }
      });
    },
    exportExcel() {
      if (this.$route.query.type === 'exportSalaries') {
        window.open(
          `${process.env.VUE_APP_BASE_URI}/exports/salaries/${this.$route.query.dateFrom}/${this.$route.query.dateTo}`
        );
      } else if (this.$route.query.type === 'exportPersonalData') {
        window.open(
          `${process.env.VUE_APP_BASE_URI}/exports/personal-data/${this.$route.query.dateFrom}/${this.$route.query.dateFrom}`
        );
      } else if (this.$route.query.type === 'exportVehicles') {
        window.open(
          `${process.env.VUE_APP_BASE_URI}/exports/vehicles/${this.$route.query.dateFrom}/${this.$route.query.dateFrom}`
        );
      } else if (this.$route.query.type === 'inquiryAdditional') {
        const table = document.getElementById('Table2XLSX');
        const wb = XLSX.utils.table_to_book(table, { cellDates: false, raw: true });

        wb.Sheets[Object.keys(wb.Sheets)[0]]['!cols'] = [
          { wpx: 50 },
          { wpx: 150 },
          { wpx: 250 },
          { wpx: 350 },
          { wpx: 80 },
          { wpx: 100 },
          { wpx: 350 },
        ];

        XLSX.writeFile(
          wb,
          `Справка_ДС_${this.$options.filters.formatDate(
            this.$route.query.dateFrom
          )}-${this.$options.filters.formatDate(this.$route.query.dateTo)}.xlsx`
        );
      }
    },
  },
  mounted() {
    this.isMobileMode();
    window.addEventListener('resize', this.isMobileMode);

    if (this.$route.query.openDocument) {
      this.selectedDoc = this.$route.query.openDocument;
      this.isDocumentModalOpen = true;
    }
    this.$root.$on('export-excel', () => {
      this.exportExcel();
    });
  },
  destroyed() {
    window.removeEventListener('resize', this.isMobileMode);
    this.$root.$off('export-excel');
  },
};
</script>

<style DocumentStatus lang="scss" scoped>
@import '@/scss/base.scss';

.table {
  width: 100%;
  overflow: auto;
  .export-title {
    font-size: 12px;
    font-weight: 500;
    padding-top: 75px;
    text-align: center;
  }
  table {
    border-spacing: 0 10px;
    // border-collapse: collapse;
    width: 100%;
  }
  th {
    color: #a7b7c8;
    font-size: $px12;
    font-weight: 700;
    padding: 1rem 0 0;
    text-align: left;

    &:last-child {
      text-align: center;
    }
  }
  th,
  td {
    &:first-child:not(:last-child) {
      padding-left: 1rem;
    }
    &:last-child:not(:first-child) {
      padding-right: 1rem;
    }
  }

  tbody {
    tr:not(.no-hover) {
      td {
        cursor: pointer;
      }
    }
    td {
      background: #f1f8ff;
      border: 1px solid #f1f8ff;
      color: #373737;
      font-size: 1rem;
      height: 70px;

      &:last-child {
        width: 1%;
      }
      &.center {
        text-align: center;
      }
      &.disabled {
        p {
          opacity: 0.5;
        }
      }
      strong {
        font-size: $px14;
      }

      &:first-child {
        border-bottom-left-radius: 9px;
        border-top-left-radius: 9px;
      }
      &:last-child {
        border-bottom-right-radius: 9px;
        border-top-right-radius: 9px;
      }
      .manual-needs-agreement {
        p {
          color: #35aadf;
          font-weight: bold;
          text-decoration: underline;
          max-width: 200px;
          &.agreed {
            color: #04a123;
          }
        }
      }
      .manual-category {
        align-items: center;
        color: #004d94;
        display: flex;
        gap: 10px;
        @include hover();
        &:hover {
          text-decoration: underline;
        }
        p {
          text-transform: uppercase;
        }
        span {
          font-size: $px18;
        }
      }
      .btns {
        display: flex;
        gap: 4px;

        .icon2 {
          color: #004d94;
          font-size: 24px;
          @include hover();

          &:hover {
            opacity: 1;
          }

          &.download:hover {
            color: #199139;
          }
          &.rename:hover {
            color: #056aae;
          }
          &.delete:hover {
            color: #920606;
          }
        }
      }
    }

    tr:not(.no-hover):hover {
      td {
        background: #fff;
        border-color: #fff;
        border-bottom: 1px solid #35aadf;
        border-top: 1px solid #35aadf;
        &:first-child {
          border-left: 1px solid #35aadf;
        }
        &:last-child {
          border-right: 1px solid #35aadf;
        }
      }
    }
  }
}
@media screen and (max-width: $s), (orientation: landscape) and (max-width: $landscape) {
  table {
    padding: 0 10px;
  }
}

@media print {
  .table {
    overflow: initial;
  }
}
</style>
