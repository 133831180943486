export default {
  methods: {
    dateOfBirthGenerator(child) {
      const split = child.egn.split('');

      let year = split[0] + split[1];
      let month = 0 + split[3];
      let date = split[4] + split[5];

      let newEgnType = split[2] === '4' || split[2] === '5';

      if (newEgnType) {
        year = 20 + year;
        if(split[2] === '5')  month = `1${split[3]}`;
      } else year = 19 + year;
      
      let dateOfBirth = `${year}-${month}-${date}`;

      return (child.dateOfBirth = new Date(dateOfBirth));
    },
  },
};
