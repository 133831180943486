<template>
  <div
    class="sidepanel no-print"
    :class="{
      'sidepanel--in': isAuthenticated,
      'sidepanel--hidden': !isSidePanelOpen || !isAuthenticated || !twoFA,
      'sidepanel--settings': $route.path.includes('settings'),
    }"
  >
    <transition name="sidepanel-toggle">
      <div class="sidepanel__toggle" @click="$store.dispatch('toggleSidePanel')" v-if="isAuthenticated">
        <img src="@/assets/img/sidepanel/trapezoid.svg" alt="Trapezoid for sidepanel opening/closing" />
        <img src="@/assets/img/sidepanel/arrow.svg" class="arrow" alt="Arrow" />
        <Tooltip :tooltip="getTooltip" />
      </div>
    </transition>
    <transition name="fade" mode="out-in">
      <Settings
        key="sidepanel__settings"
        v-if="$route.name === 'settingsSupervisors' || $route.name === 'settingsExecutiveAndOperationalManagement'"
      />
      <Trdocs key="sidepanel__trdocs" v-else />
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Trdocs from './sidepanel/Trdocs';
import Settings from './sidepanel/Settings';
import Tooltip from './Tooltip';
import config from '../../config-public.json';
export default {
  components: {
    Tooltip,
    Trdocs,
    Settings,
  },
  computed: {
    ...mapGetters(['isSidePanelOpen', 'isAuthenticated', 'currentUser', 'twoFA']),
    getTooltip() {
      const tooltips = {
        Home: 'Кликни тук за повече опции и създаване на нов документ.',
        ManuallyUploaded: 'Кликни тук за повече опции и създаване на нов ръчнокачен документ.',
        settingsSupervisors: 'Кликни тук за различните настройки.',
        settingsSpecial: 'Кликни тук за различните настройки.',
        vacations: 'Кликни тук за повече опции и създаване на нов отпуск.',
        vacationsHourly: 'Кликни тук за повече опции и създаване на ново отсъствие.',
        scheduleGroup: 'Кликни тук за избор между Общ график и График смяна.',
        lps: 'Кликни тук за съобщение за ЛПС (ще се промени).',
        users: 'Кликни тук за търсене на служители',
        settingsHolidays: 'Кликни тук за различните настройки.',
        userPersonalData: 'Кликни тук за различни настройки на профила.',
      };

      // if (
      //   this.$route.name === 'userPersonalData' &&
      //   this.currentUser._id !== this.$route.params.id
      // ) {
      //   return;
      // }

      return this.isMobile ? 'Кликни тук за повече опции' : tooltips[this.$route.name];
    },
  },
  watch: {
    isAuthenticated(to) {
      if (!to && window.innerWidth <= 768 && !this.isSidePanelOpen) {
        // if (to && window.innerWidth <= 1280) {
        this.$store.dispatch('toggleSidePanel');
      }
    },
    $route(to) {
      if (to.query.type) if (this.type !== to.query.type) this.type = to.query.type;
    },
    '$route.name'() {
      if (this.isSidePanelOpen && window.innerWidth <= 768 && this.isAuthenticated) {
        // if (this.isSidePanelOpen && window.innerWidth <= 1280) {
        this.$store.dispatch('toggleSidePanel');
      }
    },
  },
  data() {
    return {
      config,
      type: '',
      query: {},
      isMobile: false,
    };
  },
  methods: {
    logout() {
      this.$store.dispatch('logout').then(() => {
        location.reload();
      });
    },
    selectType(t, isManual) {
      if (this.type !== t) {
        this.type = t;
        this.pushToRoute(isManual);
      }
    },
    pushToRoute(isManual) {
      if (this.type) {
        this.query.type = this.type;
      }
      if (this.$route.query.search) {
        this.query.search = this.$route.query.search;
      } else {
        delete this.query.search;
      }
      if (this.$route.query.filter) {
        this.query.filter = this.$route.query.filter;
      } else {
        delete this.query.filter;
      }
      if (this.$route.query.dateFrom && this.$route.query.dateTo) {
        this.query.dateFrom = this.$route.query.dateFrom;
        this.query.dateTo = this.$route.query.dateTo;
      }
      this.query.t = new Date().getTime();

      this.$router
        .push({
          path: isManual ? '/manually-uploaded' : '/',
          query: this.query,
        })
        .catch(() => {});
    },
    isMobileMode() {
      if (window.innerWidth < 1280) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  },
  mounted() {
    this.isMobileMode();
    window.addEventListener('resize', this.isMobileMode);

    this.$router.onReady(() => {
      if (this.$route.name === 'Home') {
        if (this.$route.query.openDocument) this.query.openDocument = this.$route.query.openDocument;
        delete this.query.openDocument;
      }

      // if (this.$route.query.type) {
      //   this.type = this.$route.query.type;
      // } else {
      //   if (this.$route.name === 'Home') {
      //     if (this.currentUser.roles.admin || this.currentUser.roles.hr) this.selectType('all');
      //     else this.selectType('my');
      //   } else {
      //     if (this.currentUser.roles.admin || this.currentUser.roles.hr) this.selectType('allManual');
      //     else this.selectType('myManual');
      //   }
      // }
    });
  },
  destroyed() {
    window.removeEventListener('resize', this.isMobileMode);
  },
};
</script>

<style lang="scss">
@import '@/scss/base.scss';
.sidepanel {
  background: linear-gradient(180deg, rgba(239, 246, 252, 1) 0%, rgba(232, 233, 235, 1) 100%);
  border: 2px solid #fff;
  border-radius: 10px;
  height: calc(100% - 8px);
  left: 4px;
  position: fixed;
  top: 4px;
  width: $sidepanel;
  z-index: 12;
  @include transition($transition);

  &.sidepanel--settings {
    background: $mainColor;

    .sidepanel__settings {
      align-items: center;
      background: inherit;
      display: flex;
      flex-direction: column;
      padding: 70px 10px 10px;
      overflow-x: hidden;
      min-height: 100%;
    }
  }
  &.sidepanel--in {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    height: calc(100% - 8px - #{$nav});
    @include transform(initial);
  }

  &.sidepanel--hidden {
    // border: 2px solid $mainColor;
    // border: 2px solid transparent;
    // left: 0;
    left: 0;
    @include transform(translateX(-100%));
    &:not(.sidepanel--in) {
      left: 0;
    }

    .sidepanel__toggle {
      right: -5px;
    }
  }

  > div:not(.sidepanel__toggle) {
    max-height: 100%;
    overflow: auto;
    @include scrollbar(3px, $mainColor);
  }
}

.sidepanel__toggle,
.logout__toggle {
  align-items: center;
  bottom: 5px;
  // clip-path: polygon(0 0, 100% 20%, 100% 80%, 0% 100%);
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 1px;
  @include transform(translateX(100%));
  @include noselect();
  @include transition($transition);
  img {
    width: 100%;
  }
  .arrow {
    position: absolute;
    width: 60%;
    @include transition($transition);
  }
}

.sidepanel--hidden {
  .sidepanel__toggle {
    .arrow {
      @include transform(scaleX(-1));
    }
  }
}

.logout__toggle {
  display: none;
  .icon {
    color: $darkYellow;
    font-size: 1.3rem;
    left: 50%;
    position: absolute;
    top: 50%;
    @include transform(translateX(-50%) translateY(-50%));
  }
}

.sidepanel__texts {
  color: #fff;
  font-size: $px11;
  margin: 3rem 0;
  // width: 330px;
  .title {
    color: $lightAccent;
    font-size: $px25;
    font-weight: 500;
  }
}

.sidepanel__link {
  align-items: center;
  background-color: rgba($color: #fff, $alpha: 0.1);
  border-radius: 2px;
  color: #fff;
  display: flex;
  font-weight: 700;
  height: 40px;
  margin-bottom: 4px;
  min-height: 40px;
  padding: 0 10px;
  position: relative;
  width: 100%;
  @include transition($transition);

  &::after {
    content: 'V';
    color: $darkBlue;
    font-family: 'icons';
    font-weight: 400;
    opacity: 0;
    position: absolute;
    right: 10px;
    top: 50%;
    @include transform(translateY(-50%));
    @include transition($transition);
  }

  &.router-link-exact-active {
    background-color: #e0eaf3;
    color: $darkBlue;
    &::after {
      opacity: 1;
      @include transform(translateY(-50%) translateX(4px));
    }
  }
}

.portal-animation {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
  img {
    width: 86%;
  }
}

.sidepanel__content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 10px;
  width: 100%;

  > div {
    display: flex;
    flex-direction: column;
  }

  .logo {
    display: flex;
    width: 100%;
    img {
      width: 100%;
    }
  }

  .user-switch {
    align-items: center;
    background: #004d94;
    border-radius: 207px;
    color: #fff;
    display: inline-flex;
    height: 16px;
    margin: 12px auto 0;
    padding: 0.25rem 2rem;
    > span {
      font-size: 10px;
      line-height: 10px;
      &:not(.icon) {
        cursor: pointer;
        font-weight: 700;
        opacity: 0.5;
        &:hover {
          opacity: 0.75;
        }
        &.active {
          opacity: 1;
        }
      }
      &.icon {
        margin: 0 0.5rem;
      }
    }
    svg {
      margin: 0 0.5rem;
    }
  }

  .links {
    margin-top: 3rem;
    &.links--less-margin {
      margin-top: 0;
    }
    > p {
      color: rgba(167, 183, 200, 0.7);
      font-size: $px12;
      margin-top: 20px;
      margin-bottom: 8px;
    }
    a {
      align-items: center;
      background: #ffffff;
      border-radius: 10px;
      color: #5f798f;
      cursor: pointer;
      display: flex;
      font-size: $px12;
      font-weight: 500;
      height: 35px;
      position: relative;
      width: 100%;

      & + a {
        background: #ffffff66;
        padding-left: 1rem;
      }

      &:hover:not(.active):not(.my-user-trdocs) {
        background: #fff;
        .tooltips {
          color: #282829;
          opacity: 1;
          visibility: visible;
          @include transform(translateY(0));
        }
      }

      &:not(:last-child) {
        margin-bottom: 8px;
      }
      .icon {
        color: #004c92;
        font-size: 20px;
        margin: 0 12px;
      }

      &.my-user-trdocs {
        background: #004d94;
        color: #ffffff;
        margin: 20px 0;

        .icon {
          color: #ffffff;
        }
      }

      &.green {
        .icon {
          color: #337500;
        }
      }
      &.orange {
        .icon {
          color: #d96214;
        }
      }
      &.red {
        .icon {
          color: #c13030;
        }
      }
      &.blue {
        .icon {
          color: #004d94;
        }
      }

      &.active {
        background: #004d94;
        color: #fff;
        :not(.icon) {
          font-weight: 700;
        }
        .icon {
          color: #fff;
        }
      }
    }
  }
}

.sidepanel-toggle-enter-active,
.sidepanel-toggle-leave-active {
  @include transition($transition);
}

.sidepanel-toggle-enter,
.sidepanel-toggle-leave-to {
  opacity: 0;
  @include transform(translateX(-100%));
}

@media screen and (max-width: $s), (orientation: landscape) and (max-width: $landscape) {
  .drag-upload-component {
    display: none !important;
  }
  .sidepanel {
    filter: drop-shadow(102px 0 50px #004d9447);
    &.sidepanel--in {
      height: calc(100% - 8px - #{$nav-s});
    }
  }
  .sidepanel--hidden {
    filter: none;
  }
  .sidepanel__toggle {
    bottom: 60px;
  }
  .logout__toggle {
    bottom: 70px;
    display: flex;
  }
}

@media screen and (max-width: $xxs) {
  .sidepanel {
    width: $sidepanel-xxs;
    &.sidepanel--in {
      width: $sidepanel-xxs;
    }
  }
  .sidepanel__texts {
    width: 260px;
  }
  .sidepanel__link {
    font-size: $px10;
  }
}
</style>
