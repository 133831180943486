<template>
  <div class="document-form-type">
    <div class="row center text-center column">
      <h1>
        СПОРАЗУМЕНИЕ <br />
        НАСТАВНИК НА НОВ РАБОТНИК
      </h1>
      <p class="doc-number" v-if="d.number">№{{ d.numberPrefix }}-{{ d.number }}</p>
    </div>

    <div class="row">
      <p>
        Днес, <strong class="underline">{{ d.form.documentDate | formatDateDots }}</strong
        >, в. гр. Пловдив.
      </p>
    </div>

    <div class="row first-letter--margin">
      <p>
        <strong>КЦМ АД</strong> със седалище и адрес на управление гр. Пловдив, Асеновградско шосе, ЕИК 115007471,
        наричан РАБОТОДАТЕЛ, представляван от инж. Иван Николов Добрев, в качеството му на Изпълнителен директор, и
      </p>
    </div>

    <section class="rows-wrapper">
      <div class="row">
        <Input
          style="width: 75%"
          icon="F"
          placeholder="Име Презиме Фамилия"
          label="От Работник/Служител (изберете или потърсете име)"
          type="dropdown"
          @fetch="$emit('fetch-employees', $event)"
          :locked="d._id"
          :list="employees"
          prop="name"
          identifier="_id"
          v-model="d.form.user"
        />
        <Input
          style="width: 25%"
          icon="+"
          placeholder="№"
          label="Служебен номер"
          v-model="d.form.user.number"
          :autoFill="true"
          :locked="d._id"
        />
      </div>

      <div class="row">
        <Input
          icon="b"
          placeholder="Длъжност"
          label="Назначен на длъжност"
          :value="d.form.user.position ? d.form.user.position.name : ''"
          :autoFill="true"
          :locked="d._id"
        />
        <Input
          icon="/"
          prop="name"
          placeholder="Структурно звено"
          label="Работно място"
          :value="d.form.user.structuralUnit ? d.form.user.structuralUnit.name : ''"
          :autoFill="true"
          :locked="d._id"
        />
      </div>

      <div class="row center text-center">
        <p><strong>На основание чл. 26 от "ПРАВИЛА ЗА РАБОТНАТА ЗАПЛАТА", се споразумяха:</strong></p>
      </div>

      <div class="row">
        <Input icon="C" placeholder="Дата" label="Считано от дата" v-model="d.form.fromDate" type="datepicker" />
        <Input icon="C" placeholder="Дата (автом.)" label="До дата, вкл." v-model="d.form.toDate" type="datepicker" />
      </div>
    </section>

    <section class="rows-wrapper">
      <div class="row center">
        <p>
          <strong
            >1. Считано от {{ d.form.fromDate | formatDate }}, за срок до {{ d.form.toDate | formatDate }} вкл., <br />
            наред със задълженията си да изпълнява и дейности като наставник на:</strong
          >
        </p>
      </div>

      <div class="row">
        <Input
          icon="F"
          placeholder="Име Презиме Фамилия"
          label="От Работник/Служител (изберете или потърсете име)"
          type="dropdown"
          @fetch="$emit('fetch-employees', $event)"
          :list="employees"
          prop="name"
          :identifier="d.form.employeeSubstitute ? '_id' : false"
          v-model="d.form.employeeSubstitute"
          :locked="stageTwoDone"
        />
      </div>

      <div class="row">
        <p>при изпълнение на задълженията и за длъжността</p>
      </div>

      <div class="row">
        <Input
          icon="b"
          placeholder="Длъжност"
          label="Длъжност на работника или служителя"
          :value="d.form.employeeSubstitute ? d.form.employeeSubstitute.position.name : ''"
          :autoFill="true"
          :locked="d._id"
        />
      </div>
    </section>

    <div class="row first-letter--margin">
      <p>
        1.1. Да посрещне новия сътрудник в първия му работен ден, да го запознае с работното му място, организацията на
        работа, режима на труд и почивки;
      </p>
    </div>
    <div class="row first-letter--margin">
      <p>1.2. Да го запознае с колектива, прекия ръководител и субординацията в структурното звено;</p>
    </div>

    <div class="row first-letter--margin">
      <p>
        1.3. Да запознае наставлявания с целия процес, мястото на съответната структурна единица в него и ролята на
        работното място/позицията за постигане на зададените цели;
      </p>
    </div>

    <div class="row first-letter--margin">
      <p>
        1.4. Да го съветва относно практическото прилагане на работни и технологични инструкции, вътрешни разпореждания
        и др., които дефинират конкретни задължения и отговорности на работното място;
      </p>
    </div>

    <div class="row first-letter--margin">
      <p>
        1.5. Да му обърне внимание на всички опасности и рискове за здравето в работната зона, и начините/средствата за
        овладяването им; да му покаже безопасните методи на работа;
      </p>
    </div>

    <div class="row first-letter--margin">
      <p>
        1.6. Да демонстрира и обяснява ясно и разбираемо всяко действие, което новият работник трябва да изпълнява,
        както и резултатите/последствията от правилното или неправилно изпълнение на дадена задача;
      </p>
    </div>

    <div class="row first-letter--margin">
      <p>
        1.7. Да подпомага наставлявания в усвояването на трудовите задължения, като го напътства, коригира, изслушва,
        отговаря на зададените въпроси, следи за правилното изпълнение на задачите и споделя впечатленията си от
        неговата работа честно, непредубедено и тактично;
      </p>
    </div>

    <div class="row first-letter--margin">
      <p>
        1.8. Да улеснява адаптацията му към колектива и интеграцията в организационната среда, като го запознае с
        хората, традициите, ритуалите, писаните и неписани правила за поведение в звеното и дружеството, и с всички
        други специфични особености, които формират облика на екипа и спомагат за добрата (неконфликтна) работна
        атмосфера.
      </p>
    </div>

    <div class="row first-letter--margin">
      <p>
        <strong>
          2. За изпълнение на задълженията по т. 1 от това споразумение ще получава допълнително възнаграждение в размер
          на 50 лева месечно. Сумата е дължима за реално отработено време.
        </strong>
      </p>
    </div>

    <div class="row first-letter--margin">
      <p>
        <strong>
          3. Ще получи и сума от 250 лв. при изпълнение на следните условия: навършване на непрекъснат стаж от 1 година
          в дружеството на обучавания работник/служител и оценка на трудовото му представяне в съответствие или над
          изискванията за заеманата длъжност.
        </strong>
      </p>
    </div>

    <div class="row first-letter--margin">
      <p>
        <strong> 4. Отговаря за: </strong>
      </p>
    </div>

    <div class="row aside--row">
      <aside></aside>
      <section>
        <section class="section--aside">
          <p>4.1. успешното постигане на поставените за периода на наставничеството задачи/цели;</p>
        </section>
        <section class="section--aside">
          <p>4.2. обективно оценяване на наставлявания</p>
        </section>
        <section class="section--aside">
          <p>4.3. поддържане положителна атмосфера и обратна връзка по време на наставничеството</p>
        </section>
        <section class="section--aside">
          <p>4.4. познаването и надлежно водене на документацията по наставничеството</p>
        </section>
      </section>
    </div>

    <div class="row first-letter--margin">
      <p>
        <strong> 5. Има право : </strong>
      </p>
    </div>

    <div class="row aside--row">
      <aside></aside>
      <section>
        <section class="section--aside">
          <p>
            5.1. На съдействие от страна на прекия р-л и останалия колектив за успешното осъществяване на целите на
            наставничеството
          </p>
        </section>
        <section class="section--aside">
          <p>5.2. При възникване на конфликт на интереси, своевременно да информира прекия си ръководител</p>
        </section>
        <section class="section--aside">
          <p>5.3. да даде мнение за наставлявания в срока на изпитване.</p>
        </section>
      </section>
    </div>

    <div class="row first-letter--margin">
      <p>
        <strong>
          6. След края на периода по т.1, наставникът се задължава да се яви в отдел УЧР и да попълни Обратна информация
          (по макет) за наставлявания от него работник.
        </strong>
      </p>
    </div>

    <div class="row row--background">
      <Input
        icon="C"
        placeholder="Дата на създаване"
        label="Дата на създаване"
        v-model="d.form.createdAt"
        type="datepicker"
        :locked="d._id"
      />
      <Input
        icon="$"
        :placeholder="d.form.firstHr"
        label="Служител УЧР"
        type="signature"
        :value="d.form.signatures.firstHr"
        @signed="d.form.signatures.firstHr = $event"
        :locked="d._id"
      />
    </div>

    <template v-if="d._id">
      <div class="brake">ПОДПИСВА СЕ ОТ РЪКОВОДИТЕЛ УЧР</div>

      <div class="row row--background">
        <Input
          icon="C"
          placeholder="Дата на създаване"
          label="Дата на създаване"
          v-model="d.form.createdAtHr"
          type="datepicker"
          :disabled="!isCurrUserDocHr"
        />
        <Input
          icon="$"
          placeholder="Минка Димитрова Бакалова-Каменова"
          label="Р-л отдел УЧР"
          type="signature"
          :value="d.form.signatures.hr"
          @signed="d.form.signatures.hr = $event"
          :disabled="!isCurrUserDocHr"
          :locked="!isCurrUserDocHr"
        />
      </div>
    </template>

    <template v-if="stageTwoDone">
      <div class="brake">ПОПЪЛВА СЕ ОТ СЛУЖИТЕЛЯ</div>

      <section class="rows-wrapper">
        <div class="row center">
          <Approval
            pre="Запознах се и"
            yes="СЪГЛАСЕН СЪМ "
            no="НЕ СЪМ СЪГЛАСЕН"
            post="със споразумението."
            v-model="d.form.approved"
            :disabled="!isCurrUserDocUser"
          />
        </div>

        <div class="row">
          <p>Съгласен съм, номера на мобилния ми телефон да бъде предоставен на наставлявания от мен работник.</p>
        </div>
      </section>

      <div class="row">
        <Input
          icon=")"
          placeholder="Телефон"
          label="Номер на мобилен телефон"
          v-model="d.form.user.phone"
          :autoFill="true"
        />
      </div>

      <div class="row row--background">
        <Input
          icon="C"
          placeholder="Дата на връчване"
          label="Дата на връчване"
          v-model="d.form.createdAtUser"
          type="datepicker"
          :disabled="!isCurrUserDocUser"
        />
        <Input
          icon="$"
          :placeholder="d.form.user.name ? d.form.user.name : 'Име, Фамилия'"
          label="Име, Подпис"
          type="signature"
          :value="d.form.signatures.user"
          @signed="d.form.signatures.user = $event"
          :disabled="!isCurrUserDocUser"
          :locked="!isCurrUserDocUser"
        />
      </div>
    </template>
  </div>
</template>

<script>
import Input from '../CreateDocumentInput';
import Approval from '../CreateDocumentApproval';
export default {
  components: {
    Input,
    Approval,
  },
  props: ['d', 'employees', 'invalid', 'isCurrUserDocUser', 'isCurrUserDocHr', 'editMode', 'canEdit'],
  data() {
    return {
      stageTwoDone: false,
    };
  },
  watch: {
    'd.form.fromDate'() {
      if (this.d.form.fromDate) {
        let copy = new Date(this.d.form.fromDate);
        copy.setMonth(copy.getMonth() + 3)
        this.$set(this.d.form, 'toDate', copy.setDate(copy.getDate() - 1));
      }
    },
  },
  beforeMount() {
    if (!this.d._id) {
      this.$set(this.d.form.signatures, 'firstHr', null);
      this.$set(this.d.form.signatures, 'hr', null);
      this.$set(this.d.form, 'documentDate', new Date());
    }

    if (this.d.form.signatures.hr) (this.stageTwoDone = true), (this.d.form.createdAtUser = new Date());
    if (this.d._id) this.d.form.createdAtHr = new Date();
  },
};
</script>
