<template>
  <div class="modal-wrapper">
    <transition name="appear" appear>
      <span class="icon close" @click="$emit('close')">X</span>
    </transition>
    <div class="portrait"></div>
    <transition name="modal" appear>
      <div class="modal">
        <p>
          Поставете ръчно вашите име и подпис, като изписвате старателно буквите.
          <br />
          Не излизайте от жълтия правоъгълник. Ако сгрешите, изберете бутона Направи отново.
        </p>
        <div class="signature">
          <canvas ref="signCanvas"></canvas>
        </div>
        <footer>
          <Button class="button--orange" @click="clear">Направи отново</Button>
          <Button class="button--blue" @click="confirm">Потвърди подпис</Button>
        </footer>
      </div>
    </transition>
  </div>
</template>

<script>
import SignaturePad from 'signature_pad/dist/signature_pad';
import Button from '@/components/Button';
export default {
  components: {
    Button,
  },
  data() {
    return {
      signaturePad: null,
      currentWidth: (window.innerWidth / 100) * 98,
      ratio: Math.max(window.devicePixelRatio || 1, 1),
    };
  },
  methods: {
    recalculatePoints(data) {
      const pr = this.currentWidth;
      this.currentWidth = this.$refs.signCanvas.offsetWidth;
      const cu = this.currentWidth;
      const d = cu / pr;

      this.signaturePad.minWidth = this.signaturePad.minWidth * d;
      this.signaturePad.maxWidth = this.signaturePad.maxWidth * d;

      data.map((x) => {
        return x.points.map((p) => {
          p.x = p.x * d;
          p.y = p.y * d;
        });
      });
    },
    resize() {
      const data = this.signaturePad.toData();
      this.recalculatePoints(data);

      this.prepareCanvas();

      this.checkOrientation();
      this.signaturePad.fromData(data);
    },
    checkOrientation() {
      //  this.signaturePad.set('minWidth', 1);
      // this.signaturePad.set(
      //   'canvasWidth',
      //   this.$refs.signCanvas.offsetWidth * this.ratio
      // );
      // this.signaturePad.set(
      //   'canvasHeight',
      //   this.$refs.signCanvas.offsetHeight * this.ratio
      // );
      // const orientation =
      //   (screen.orientation || {}).type ||
      //   screen.mozOrientation ||
      //   screen.msOrientation;
      // if (
      //   (orientation === 'portrait' ||
      //     orientation === 'portrait-secondary' ||
      //     orientation === 'portrait-primary') &&
      //   window.innerWidth <= 768
      // ) {
      //   const canvas = this.$refs.signCanvas;
      //   const ctx = canvas.getContext('2d');
      //   ctx.rotate(1.5 * Math.PI);
      //   ctx.translate(-canvas.height, 0);
      // }
    },
    prepareCanvas() {
      this.ratio = Math.max(window.devicePixelRatio || 1, 1);

      this.$refs.signCanvas.width = this.$refs.signCanvas.offsetWidth * this.ratio;
      this.$refs.signCanvas.height = this.$refs.signCanvas.offsetHeight * this.ratio;

      this.$refs.signCanvas.getContext('2d').scale(this.ratio, this.ratio);

      // const orientation =
      //   (screen.orientation || {}).type ||
      //   screen.mozOrientation ||
      //   screen.msOrientation;
      // if (
      //   (orientation === 'portrait' ||
      //     orientation === 'portrait-secondary' ||
      //     orientation === 'portrait-primary') &&
      //   window.innerWidth <= 768
      // ) {
      //   const canvas = this.$refs.signCanvas;
      //   const ctx = canvas.getContext('2d');
      //   // ctx.rotate(1.5 * Math.PI);
      //   ctx.rotate(90 * Math.PI / 180);
      //   ctx.translate(-canvas.height, 0);
      // }

      this.clear();
    },
    clear() {
      this.signaturePad.clear();
    },
    confirm() {
      if (!this.signaturePad.isEmpty()) {
        this.$emit('signed', this.signaturePad.toDataURL('image/svg+xml'));
        this.$emit('close');
      } else {
        this.$snotify.error('Не може да запазите празен подпис');
      }
    },
    enter(e) {
      if (e.key === 'Enter') {
        this.confirm();
      } else if (e.key === 'Escape') {
        this.$emit('close');
      }
    },
  },
  async mounted() {
    // document.body.classList.add('stop-scrolling');

    this.signaturePad = new SignaturePad(this.$refs.signCanvas, {
      minWidth: ((this.currentWidth / 100) * 0.2) / this.ratio,
      maxWidth: ((this.currentWidth / 100) * 0.4) / this.ratio,
    });

    this.prepareCanvas();

    this.currentWidth = this.$refs.signCanvas.offsetWidth;

    // if (this.signature) {
    //   const s = JSON.parse(JSON.stringify(this.signature));
    //   this.signaturePad.fromData(s.data);
    // }
    window.addEventListener('resize', this.resize);
    window.addEventListener('keydown', this.enter);
  },
  async destroyed() {
    window.removeEventListener('resize', this.resize);
    window.removeEventListener('keydown', this.enter);
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.modal-wrapper {
  align-items: center;
  background-color: #000;
  height: 100%;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  @include noselect();
}
.close {
  color: #ffffb8;
  cursor: pointer;
  font-size: 2rem;
  padding: 1rem;
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 2;
  @include transition($transition);
  &:hover {
    background-color: rgba($color: #ffffb8, $alpha: 0.2);
  }
}
.modal {
  padding: 0 1vw;
  > p {
    color: #e65100;
    font-size: $px18;
    margin: 1rem 0;
    text-align: center;
  }
  footer {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    width: 100%;
  }
}
.signature {
  background-color: #ffffb8;
  height: 23.7576vw;
  width: 98vw;
  canvas {
    height: 100%;
    width: 100%;
  }
}

.portrait {
  display: none;
}

@media screen and (max-width: $s), (orientation: landscape) and (max-width: $landscape) {
  .modal {
    > p {
      font-size: $px13;
      line-height: $px13;
    }
  }
}
@media screen and (max-width: $s) and (orientation: portrait) {
  .portrait {
    align-items: center;
    background-color: #000;
    color: #ffffb8;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    text-align: center;
    position: fixed;
    width: 100%;
    z-index: 1;
    &::before {
      font-size: 8rem;
      font-family: 'icons';
      content: 'M';
      color: #ffffb8;
      opacity: 0.3;
      margin: 0 0 2rem;
    }
    &::after {
      opacity: 0.8;
      content: 'Моля, завъртете устройството си в пейзажен режим, за да може да се подпишете  ';
    }
  }
}
</style>