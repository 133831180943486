<template>
  <div class="document-form-type">
    <div class="row center text-center column">
      <h1>
        ДЕКЛАРАЦИЯ ЗА ЗАПОЗНАВАНЕ С <br />
        ВЪТРЕШНО-ФИРМЕНИ ДОКУМЕНТИ
      </h1>
      <p class="doc-number" v-if="d.number">№{{ d.numberPrefix }}-{{ d.number }}</p>
    </div>
    <div class="row center text-center">
      <p class="smaller-line-height">
        <strong>От долуподписаният:</strong>
      </p>
    </div>

    <section class="rows-wrapper">
      <div class="row">
        <Input
          style="width: 75%"
          icon="F"
          placeholder="Име Презиме Фамилия"
          label="От Работник/Служител (изберете или потърсете име)"
          type="dropdown"
          @fetch="$emit('fetch-employees', $event)"
          :locked="d._id || isCurrUserDocUser"
          :list="employees"
          prop="name"
          identifier="_id"
          v-model="d.form.user"
        />
        <Input
          style="width: 25%"
          icon="+"
          placeholder="№"
          label="Служебен номер"
          v-model="d.form.user.number"
          :autoFill="true"
        />
      </div>
      <div class="row">
        <Input
          v-if="!d.form.user.position"
          icon="b"
          placeholder="Длъжност"
          label="Длъжност на работника или служителя (автом.)"
          :autoFill="true"
        />
        <Input
          v-else
          icon="b"
          placeholder="Длъжност"
          label="Длъжност на работника или служителя (автом.)"
          v-model="d.form.user.position.name"
        />
        <Input
          v-if="!d.form.user.structuralUnit"
          :icon="'&'"
          placeholder="Структурно звено"
          label="Структурно звено (участък, цех, лаборатория, отдел, отделение, група)"
          :autoFill="true"
        />
        <Input
          v-else
          :icon="'&'"
          placeholder="Структурно звено"
          label="Структурно звено (участък, цех, лаборатория, отдел, отделение, група)"
          v-model="d.form.user.structuralUnit.name"
        />
      </div>
    </section>

    <div class="row center text-center">
      <p class="smaller-line-height">
        <strong
          >Във връзка с проведено въвеждащо обучение „Човешки ресурси –Организация, поведение и отговорности на
          участниците в трудовия процес. Вътрешно фирмени документи, йерархия на документите в Дружеството.“ на</strong
        >
      </p>
    </div>

    <div class="row">
      <Input
        type="datepicker"
        icon="C"
        placeholder="дата на провеждане на обучението"
        label="дата на провеждане на обучението"
        v-model="d.form.user.createdAt"
        :yearFirst="true"
      />
    </div>

    <div class="row center text-center">
      <p>при работодателя КЦМ АД, гр. Пловдив,</p>
    </div>

    <div class="row center text-center">
      <p class="smaller-line-height">
        <strong>ДЕКЛАРИРАМ</strong>
      </p>
    </div>

    <div class="row aside--row">
      <aside><strong>I.</strong></aside>
      <p>Че съм запознат:</p>
    </div>

    <div class="row aside--row">
      <aside>1.</aside>

      <section>
        <span><strong>Кодекс за корпоративно управление</strong> на ХОЛДИНГ КЦМ 2000.</span>
      </section>
    </div>

    <div class="row aside--row">
      <aside>2.</aside>

      <section>
        <span
          ><strong>Органиграма на Дружеството</strong>, част от Правилник за устройството и дейността на КЦМ АД.</span
        >
      </section>
    </div>

    <div class="row aside--row">
      <aside>3.</aside>

      <section>
        <span><strong>Правилника за вътрешния трудов ред</strong> и неговите приложения:</span>

        <section class="section--aside">
          <aside>3.1.</aside>
          <p><strong>Правилник за реда и условията за хранене</strong> в Зала 1 от 07.12.2022г.</p>
        </section>
        <section class="section--aside">
          <aside>3.2.</aside>
          <p>
            <strong>Процедура „Фирмена сигурност“</strong>, в т.ч. И-2 „Ред и условия за работа със Система за контрол
            на достъпа на територията на КЦМ 2000 груп“ и И-6 „Ред и условия за движение, внасяне и/или изнасяне на
            стоково материални ценности на/от охраняемата територия на КЦМ 2000 груп“
          </p>
        </section>
        <section class="section--aside">
          <aside>3.3.</aside>
          <p>
            <strong>Процедура „Защита на личните данни“</strong> и <strong>Процедура „Информационна сигурност“</strong>
          </p>
        </section>
        <section class="section--aside">
          <aside>3.4.</aside>
          <p>
            <strong>Заповед №28/27.04.2023г на ГИД</strong> за регулиране и контрол на достъпа на хора, превозни
            средства и пратки към и от дружествата на ХОЛДИНГ КЦМ 2000 в т.ч. и паркирането и престой на МПС.
          </p>
        </section>
        <section class="section--aside">
          <aside>3.5.</aside>
          <p>
            <strong>Заповед № 27/27.04.2023г на ГИД</strong> за подобряване състоянието на фирмената сигурност на
            заводската площадка на КЦМ.
          </p>
        </section>
        <section class="section--aside">
          <aside>3.6.</aside>
          <p>
            <strong>Заповед №18/14.02.2019г.</strong> на Изпълнителния директор на КЦМ АД за забрана на внасяне,
            разпространение, продажба, употребата на алкохол и/или на други упойващи вещества на територията на КЦМ АД.
          </p>
        </section>
        <section class="section--aside">
          <aside>3.7.</aside>
          <p>
            <strong>Заповед №38/25.03.2019г.</strong> на Изпълнителния директор на КЦМ АД за забрана на пушенето на
            територията на КЦМ АД, с изкл. на обособените за целта открити места
          </p>
        </section>
        <section class="section--aside">
          <aside>3.8.</aside>
          <p>Инструкция за реда и начина за отчитане на работното време от 20.01.2023г.</p>
        </section>
        <section class="section--aside">
          <aside>3.9.</aside>
          <p>Инструкция за видовете, реда и условията за ползване на отпуск от 20.01.2023г.</p>
        </section>
      </section>
    </div>

    <div class="row aside--row">
      <aside>4.</aside>

      <section>
        <span><strong>Правила за работната заплата</strong> (ПРЗ) и техните приложения:</span>

        <section class="section--aside">
          <aside>4.1.</aside>
          <p><strong>Приложение №1</strong> – Обхвати на основни заплати по нива.</p>
        </section>
        <section class="section--aside">
          <aside>4.2.</aside>
          <p><strong>Приложение №2</strong> – Правила за оценка на трудовото представяне (годишно атестиране);</p>
        </section>
        <section class="section--aside">
          <aside>4.3.</aside>
          <p>
            <strong>Приложение №3</strong> - Правилата за възлагане на допълнителни задачи, определяне на допълнително
            възнаграждение за трудово представяне и налагане на санкции, ведно със Списък на Лицата, които имат право да
            налагат санкции
          </p>
        </section>
        <section class="section--aside">
          <aside>4.4.</aside>
          <p><strong>Приложение №4</strong> - Правила за стимулиране предаването на вторични суровини.</p>
        </section>
      </section>
    </div>

    <div class="row aside--row">
      <aside>5.</aside>

      <section>
        <span
          >със <strong>Заповед</strong> на Изпълнителния директор на КЦМ АД за утвърждаване на графици за работа за
          съответната година на назначаване.</span
        >
      </section>
    </div>

    <div class="row aside--row">
      <aside>6.</aside>

      <section>
        <span
          >със <strong>Заповед №113/12.10.2018</strong> на Изпълнителния директор на КЦМ АД, забраняваща ползването на
          лични електронни устройства за лични цели в работно време и на работното място.</span
        >
      </section>
    </div>

    <div class="row aside--row">
      <aside>7.</aside>

      <section>
        <span>с <strong>Колективния трудов договор</strong>.</span>
      </section>
    </div>

    <div class="row aside--row">
      <aside>8.</aside>

      <section>
        <span>с <strong>Правилника за вътрешния ред на БПО „Копривките”</strong> и приложенията към него:</span>

        <section class="section--aside">
          <aside>8.1.</aside>
          <p>Правила за организацията на смените, настаняване и отпътуване;</p>
        </section>
        <section class="section--aside">
          <aside>8.2.</aside>
          <p>правила за организация на престоя в БПО;</p>
        </section>
        <section class="section--aside">
          <aside>8.3.</aside>
          <p>правила за отчитане на престоя в БПО „Копривките”.</p>
        </section>
      </section>
    </div>

    <div class="row aside--row">
      <aside>9.</aside>

      <section>
        <span
          >със <strong>Заповед №11/11.02.2016г.</strong> на Изпълнителния директор за изпълнение на програма ”Здраве за
          всички”.</span
        >
      </section>
    </div>

    <div class="row aside--row">
      <aside>10.</aside>

      <section>
        <span>с <strong>Кодекса на труда</strong>, Трудова дисциплина и отговорности</span>
      </section>
    </div>

    <div class="row aside--row">
      <aside><strong>II.</strong></aside>

      <section>
        <span><strong>Запознат съм</strong>:</span>

        <section class="section--aside">
          <aside>a.</aside>
          <p>
            че всяко неизпълнение на задълженията регламентирани с посочените по- горе вътрешно-фирмени документи е
            основание за налагане на парични санкции по ПРЗ, както и дисциплинарно наказание, вкл. дисциплинарно
            уволнение.
          </p>
        </section>
        <section class="section--aside">
          <aside>b.</aside>
          <p>
            че всички вътрешно фирмени документи са публикувани на електронната страницата на вътрешен корпоративен сайт
            на адрес: http://vkus.kcm/ и мога да получа по всяко време достъп до тях чрез прекият ми ръководител или да
            ги поискам на хартиен носител от специалисти по човешките ресурси за допълнително запознаване с тях на
            място.
          </p>
        </section>
      </section>
    </div>

    <div class="row aside--row">
      <aside><strong>III.</strong></aside>
      <p>
        Получих Персонална идентификационна карта за достъп до Територията на Дружеството и съм запознат, че при загубен
        пропуск трябва да заплатя глоба в размер, определен от Работодателя.
      </p>
    </div>

    <div class="row aside--row">
      <aside><strong>IV.</strong></aside>
      <p>
        Уведомен съм, че при предоставяне на персонално устройство за достъп тип TAG за преминаване през Бариера №1 и
        бариера №2 , съм длъжен да го съхранявам правилно, да го опазвам от повреда, унищожаване или загуба и декларирам
        че при неговата загуба, увреждане или унищожаване дължа глоба в размер определен чрез констативен протокол по
        реда на Заповед №28/27.04.2023г. на ГИД.
      </p>
    </div>

    <div class="row aside--row">
      <aside><strong>V.</strong></aside>

      <section>
        <span
          >Във връзка с въвеждане и съхранение на електронни документи в трудовото ми досие и изискванията на „Наредба
          за вида и изискванията за създаването и съхраняването на електронни документи в трудовото досие“:</span
        >

        <section class="section--aside">
          <aside>1.</aside>
          <p>
            <strong>Декларирам съгласие</strong> за размяната на електронни документи между работодателя и мен по повод
            трудовото ми правоотношение и воденето и съхранението на трудовото ми досие в електронен вид по създадената
            от работодателя ми електронна система.
          </p>
        </section>
        <section class="section--aside">
          <aside>2.</aside>
          <p>
            <strong>Запознат/а съм</strong>, че електронните изявления стават чрез електронната поща, декларирана от мен
            при Работодателя и че всяко съобщение получено по този ред се счита за получено и връчено.
          </p>
        </section>
        <section class="section--aside">
          <aside>3.</aside>
          <p><strong>Запознат/а съм</strong> с електронните документи, част от трудовото ми досие.</p>
        </section>
      </section>
    </div>

    <div class="row row--background">
      <Input
        icon="C"
        placeholder="Дата на създаване"
        label="Дата на създаване"
        v-model="d.form.createdAt"
        type="datepicker"
      />
      <Input
        icon="$"
        :placeholder="d.form.user.name ? d.form.user.name : 'Име, Фамилия'"
        label="Служител - име и подпис"
        type="signature"
        :value="d.form.signatures.user"
        @signed="d.form.signatures.user = $event"
      />
    </div>

    <!-- <div class="row aside--row">
      <aside><strong>VI.</strong></aside>
      <Approval
        yes="ИДВАМ"
        no="НЕ ИДВАМ"
        post="на работа и с лично моторно превозно средство, което"
        v-model="d.form.ownTransport"
      />
    </div>

    <div class="row center">
      <Approval yes="САМ УПРАВЛЯВАМ" no="НЕ УПРАВЛЯВАМ" :disabled="!d.form.ownTransport" v-model="d.form.isDriver" />
    </div>

    <div class="row">
      <p><strong>Данни за МПС:</strong></p>
    </div>

    <section class="rows-wrapper">
      // comment
      <div class="row">
        <ToggleSize
          placeholder="Изберете броя превозни средства, които имате"
          v-model="d.form.vehicles"
          :maxSize="3"
          :disabled="!d.form.ownTransport"
        />
      </div>
      // comment
      <div class="row" v-for="(vehicle, i) of d.form.vehicles" :key="`vehicle-${i}`">
        <Input
          :icon="`&quot;`"
          placeholder="Регистрационен номер"
          :label="`Регистрационен номер МПС ${i + 1}`"
          :uppercase="true"
          v-model="vehicle.numberPlate"
          :disabled="!d.form.ownTransport"
          :vehicleDisabled="!vehicle.enabled"
          :maxlength="10"
        />
        <Input
          icon="#"
          placeholder="Марка"
          :label="`Марка на МПС ${i + 1}`"
          type="dropdown"
          @fetch="$emit('fetch-employees', $event)"
          :list="carBrands"
          v-model="vehicle.carBrand"
          :disabled="!d.form.ownTransport"
          :vehicleDisabled="!vehicle.enabled"
          :selectSearch="true"
          :vehicle="true"
          @vehicle-enable="$set(vehicle, 'enabled', true)"
          @vehicle-delete="
            $set(vehicle, 'enabled', false);
            $delete(vehicle, 'numberPlate');
            $delete(vehicle, 'carBrand');
          "
          @vehicle-edit="
            $delete(vehicle, 'numberPlate');
            $delete(vehicle, 'carBrand');
          "
        />
      </div>

      <div class="row center text-center">
        <span style="opacity: 0.5"
          >Регистрационните номера на МПС трябва да са изписани пълно, с всички цифри и букви.</span
        >
      </div>
    </section>

    <div class="row first-letter--margin">
      <p>
        Уведомен/а съм, че предоставените от мен данни ще се използват с цел достъп до паркинга на КЦМ, а данните ще се
        съхраняват в личното ми трудово досие, за срока на действие на трудовото правоотношение.
      </p>
    </div>

    <div class="row aside--row">
      <aside><strong>VII.</strong></aside>
      <p>
        За реализиране на социалната политика на работодателя, насочена към децата на КЦМ АД <br />
        <br />Декларирам следните данни за деца до 18 години:
      </p>
    </div>

    <section class="rows-wrapper">
      <div class="row">
        <ToggleSize
          placeholder="Изберете броя деца, които имате"
          v-model="d.form.children"
          :allowZero="true"
          :maxSize="5"
        />
      </div>

      <div class="row" v-for="(child, i) of d.form.children" :key="`child-${i}`">
        <Input icon="F" placeholder="Име Презиме Фамилия" :label="`Дете ${i + 1}`" v-model="child.name" />
        <Input
          style="width: 60%"
          icon="+"
          placeholder="----------"
          :label="`ЕГН`"
          v-model="child.egn"
          :maxlength="10"
          :digitsOnly="true"
        />
        <Input
          style="width: 60%"
          icon="+"
          type="datepicker"
          placeholder="(автом.)"
          :label="`Дата на раждане`"
          v-model="child.dateOfBirth"
          :autoFill="true"
          :formatWithDots="true"
        />
      </div>
    </section>

    <div class="row first-letter--margin">
      <p>
        На основание чл. 9, параграф 2, б. „а“ от Регламент (ЕС) 2016/679 относно защита на физическите лица във връзка
        с обработването на лични данни, давам своето информирано и доброволно съгласие за обработване на посочените от
        мен данни в качеството ми нает на трудов договор в КЦМ АД за целите и сроковете посочени по-горе.
      </p>
    </div>

    <div class="row row--background">
      <Input
        icon="C"
        placeholder="Дата на създаване"
        label="Дата на създаване"
        v-model="d.form.createdAt"
        type="datepicker"
      />
      <Input
        icon="$"
        :placeholder="d.form.user.name ? d.form.user.name : 'Име, Фамилия'"
        label="Служител - име и подпис"
        type="signature"
        :value="d.form.signatures.user"
        @signed="d.form.signatures.user = $event"
      />
    </div> -->
  </div>
</template>

<script>
import Input from '../CreateDocumentInput';
// import Approval from '../CreateDocumentApproval';
// import ToggleSize from '../ToggleSize';
import dateOfBirthGenerator from '@/components/mixins/dateOfBirthGenerator.js';
export default {
  mixins: [dateOfBirthGenerator],
  components: {
    Input,
    // Approval,
    // ToggleSize,
  },
  props: ['d', 'employees', 'carBrands', 'invalid', 'isCurrUserDocUser'],
  watch: {
    'd.form.children': {
      handler: function (val) {
        for (let child of val) {
          if (child.egn && child.egn.length >= 6) {
            this.dateOfBirthGenerator(child);
          } else {
            child.dateOfBirth = '';
          }
        }
      },
      deep: true,
    },
  },
  beforeMount() {
    if (!this.d._id) {
      this.$set(this.d.form, 'vehicles', [{}, {}, {}]);
      this.$set(this.d.form, 'children', []);
    }
  },
};
</script>

<style></style>
