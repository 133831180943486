<template>
  <div class="home" :class="{ 'home--in': isAuthenticated, 'home--2000': activeCompany === 'КЦМ 2000' }">
    <Header :docsLength="docs.length" />
    <Table :docs="docs" :manualDocs="$route.name === 'ManuallyUploaded'" @getDocs="getDocs()" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from './home/Header';
import Table from './home/Table.vue';
export default {
  computed: {
    ...mapGetters(['isAuthenticated', 'currentUser', 'activeCompany']),
    isDigitalSigner() {
      return ['6082c9b411e48575bb09e1ec', '6082c97e11e48575bb09df08'].indexOf(this.currentUser._id) > -1;
    },
  },
  components: {
    Header,
    Table,
  },
  name: 'Home',
  data() {
    return {
      docs: [],
    };
  },
  watch: {
    $route() {
      this.getDocs();
    },
  },
  methods: {
    getDocs() {
      // TODO: WTF?
      let filter = '';
      if (this.$route.query.filter) {
        filter = this.$route.query.filter;
      }
      let url = `/trdocs?filter=${filter}`;
      // TODO: WTF?

      if (this.$route.query.dateFrom && this.$route.query.dateTo) {
        url += `&dateFrom=${this.$route.query.dateFrom}&dateTo=${this.$route.query.dateTo}`;
      }

      if (this.$route.name === 'ManuallyUploaded') {
        url += '&manual=true';
      }

      if (this.$route.query.type) {
        url += `&type=${this.$route.query.type}`;
      }
      if (this.$route.query.show) {
        url += `&show=${this.$route.query.show}`;
      }
      if (this.$route.query.search) {
        url += `&search=${this.$route.query.search}`;
      }

      if (this.isDigitalSigner && this.$route.name !== 'ManuallyUploaded') {
        url += `&digitalSigner=true`;
      }

      if(this.$route.query.showMinkaWaitingDocuments) {
        url += `&showMinkaWaitingDocuments=true`;
      }

      this.$apiService.get(url).then((res) => {
        this.docs = res.data;
      });
    },
  },
  mounted() {
    this.getDocs();
  },
};
</script>

<style lang="scss">
@import '@/scss/base.scss';
.home {
  align-items: center;
  background-color: #fff;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-items: center;
  min-height: 100%;
  padding: 1rem 80px;
  position: relative;

  > img {
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .home__title {
    color: #004d94;
    font-size: $px20;
    &.mobile {
      display: none;
    }
  }

  > header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 2rem 3rem;
    position: relative;
    width: 100%;

    > div {
      align-items: center;
      display: flex;
      p {
        text-align: right;
        margin-right: 1rem;
      }
      button {
        align-items: center;
        background-color: #fff;
        border: 1px solid #35aadf;
        border-radius: 4px;
        color: #000;
        display: flex;
        height: 32px;
        justify-content: space-between;
        padding: 0 $px12;
        width: 221px;
        @include hover();
      }
    }
  }
  nav {
    display: none;
  }
}

.home__content {
  display: flex;
  justify-items: center;
  min-height: 100%;
  gap: 4rem;
  padding: 2rem 3rem;
  position: relative;
  width: 100%;
  &.mobile {
    display: none;
  }
  > aside {
    flex-basis: 20%;
    min-width: 320px;
  }
}

.home__logo {
  img {
    width: 204px;
  }
}

@media screen and (max-width: $sm) {
  .home__content {
    gap: 2rem;
  }
}

@media screen and (max-width: $s), (orientation: landscape) and (max-width: $landscape) {
  .home {
    padding: 0;

    .home__title {
      text-align: center;
      &.desktop {
        display: none;
      }
      &.mobile {
        display: block;
      }
    }
    nav {
      background-color: #bdccda;
      border-radius: 10px;
      display: flex;
      position: relative;
      width: calc(100% - 2rem);

      &::before {
        background-color: #004c92;
        border-radius: inherit;
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 33.33%;
        @include transition($transition);
      }

      &.active--news::before {
        left: 33.33%;
      }
      &.active--events::before {
        left: 66.66%;
      }

      a {
        align-items: center;
        border-radius: inherit;
        cursor: pointer;
        display: flex;
        flex-basis: 33.33%;
        font-size: $px20;
        font-weight: 500;
        height: 35px;
        justify-content: center;
        position: relative;
        width: 33.33%;
        @include transition($transition);
        &:nth-child(2) {
          &::before,
          &::after {
            background-color: #8ea8c1;
            content: '';
            height: 60%;
            position: absolute;
            top: 20%;
            width: 1px;
          }
          &::before {
            left: 0;
          }
          &::after {
            right: 0;
          }
        }
        &.active {
          color: #fff;
          font-weight: 700;
        }
      }
    }
    > header {
      flex-direction: column;
      text-align: center;
      padding: 1rem;

      &.desktop {
        display: none;
      }

      > div {
        flex-direction: column;
        p {
          margin: 1rem 0;
        }
      }
    }
  }
  .home__content {
    flex-direction: column;
    gap: 0;
    padding: 1rem;
    width: 100%;
    &.desktop {
      display: none;
    }
    &.mobile {
      display: flex;
    }
  }
}

@media print {
  .home {
    display: block;
    padding: 0;
    position: initial;
  }
}
</style>
