<template>
  <p
    class="approval"
    :class="{
      'approval--selected': typeof value === 'boolean',
      'approval--disabled': disabled,
      'approval--locked': locked,
    }"
  >
    {{ pre }}
    <span @click="disabled || locked ? null : $emit('input', true)" :class="{ active: value === true }">{{ yes }}</span>
    &nbsp;/&nbsp;
    <span @click="disabled || locked ? null : $emit('input', false)" :class="{ active: value === false }">{{ no }}</span>
    {{ post }}
  </p>
</template>

<script>
export default {
  props: ['value', 'pre', 'yes', 'no', 'post', 'disabled', 'locked'],
};
</script>

<style lang="scss">
@import '@/scss/base.scss';
.approval {
  @include noselect();
  > span {
    border-radius: 4px;
    cursor: pointer;
    font-weight: 700;
    padding: 2px 4px;
    text-decoration: underline;
    @include transition($transition);
    &:first-child {
      color: #35aadf;
    }
    &:last-child {
      color: #cc1616;
    }

    &.active {
      color: #fff;
      &:first-child {
        background: #35aadf;
      }
      &:last-child {
        background: #cc1616;
      }
    }
  }

  &.approval--selected {
    > span {
      &:not(.active) {
        color: #777777;
        text-decoration: line-through;
      }
    }
  }
  &.approval--disabled {
    opacity: 0.4;
    > span {
      cursor: default;
    }
  }
  &.approval--locked {
    opacity: 1;
  }
}
</style>