<template>
  <span class="filter" :class="{ active: active }" @click="$emit('click')">
    <span class="icon">{{ icon }}</span>
    {{ text }}
    <span class="tooltips" v-if="tooltip" v-html="tooltip" />
  </span>
</template>

<script>
export default {
  props: ['active', 'icon', 'text', 'tooltip'],
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.filter {
  align-items: center;
  border: 1px solid #c4d6e8;
  border-radius: 16px;
  color: #282829;
  cursor: pointer;
  display: inline-flex;
  font-size: $px12;
  height: 32px;
  overflow: hidden;
  padding: 0 1rem;
  position: relative;
  white-space: nowrap;
  .icon {
    color: #212121;
    font-size: $px18;
    margin-right: 0.5rem;
  }

  &:hover:not(.active) {
    border-color: #004d94;
    overflow: initial;
    .tooltips {
      opacity: 1;
      visibility: visible;
      @include transform(translateY(0));
    }
  }

  &.active {
    background-color: #004d94;
    color: #fff;
    .icon {
      color: #fff;
    }
  }
}
@media screen and (max-width: $s), (orientation: landscape) and (max-width: $landscape) {
  .filter {
    overflow: visible;
    &:hover {
      .tooltips {
        opacity: 0 !important;
        visibility: hidden !important;
        @include transform(translateY(-50%));
      }
    }
  }
}
</style>